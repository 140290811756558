import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, UncontrolledTooltip, Badge
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
// @ts-ignore
import  filterFactory, { selectFilter, multiSelectFilter, textFilter  } from 'react-bootstrap-table2-filter';

import { Chart } from "react-google-charts";

import { GoogleChartWrapperChartType } from "react-google-charts/dist/types";



import * as Utilities from "./Utilities"

import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

import Collapse from "@kunukn/react-collapse";

type State = {    
    hiddenRows: (string|undefined)[]
}

type Props = {    
    jahr: number,
    krebene: KSLPlusAPI.KREbene,    
    settings: KSLPlusAPI.SettingsModel    
}



export class Kassenreste extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
        
    }

    state: State = {                           
        hiddenRows: []        
    }              
    

    node: any;        
    container: any = null;
    

    createGraphData = ( typ: number ) => {
        var grdata: any[] = [];
        //grdata.push([headerX, headerY]);

        

        var x: number[] = [];
        
        var header = ["Jahr"];
        //var bez = (zeile.bezeichnung ? zeile.bezeichnung : "") + " (" + (zeile.einheit ? zeile.einheit : "") + ")";        
        header.push("Einnahmen (EUR)");
        header.push("Ausgaben (EUR)");               
        grdata.push(header);

        var vorWert: number|undefined = undefined;
        
        if ( typ == 0 )
        {
            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr - 4) >= 0) && this.props.krebene.krfJ_PJM4_haben !== undefined && this.props.krebene.krfJ_PJM4_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-4).toString()];
                row.push(this.props.krebene.krfJ_PJM4_haben);            
                row.push(this.props.krebene.krfJ_PJM4_soll);            
                grdata.push(row);
            }

            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) && this.props.krebene.krfJ_PJM3_haben !== undefined && this.props.krebene.krfJ_PJM3_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-3).toString()];
                row.push(this.props.krebene.krfJ_PJM3_haben);            
                row.push(this.props.krebene.krfJ_PJM3_soll);            
                grdata.push(row);
            }

            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) && this.props.krebene.krfJ_PJM2_haben !== undefined && this.props.krebene.krfJ_PJM2_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-2).toString()];
                row.push(this.props.krebene.krfJ_PJM2_haben);            
                row.push(this.props.krebene.krfJ_PJM2_soll);            
                grdata.push(row);
            }
        
            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) && this.props.krebene.krfJ_PJM1_haben !== undefined && this.props.krebene.krfJ_PJM1_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-1).toString()];
                row.push(this.props.krebene.krfJ_PJM1_haben);            
                row.push(this.props.krebene.krfJ_PJM1_soll);            
                grdata.push(row);
            }

            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr) >= 0) && this.props.krebene.krfJ_haben !== undefined && this.props.krebene.krfJ_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr).toString()];
                row.push(this.props.krebene.krfJ_haben);            
                row.push(this.props.krebene.krfJ_soll);            
                grdata.push(row);
            }
        }
        else if ( typ == 1 )
        {
            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr - 4) >= 0) && this.props.krebene.krfJ_PJM4_VW_haben !== undefined && this.props.krebene.krfJ_PJM4_VW_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-4).toString()];
                row.push(this.props.krebene.krfJ_PJM4_VW_haben);            
                row.push(this.props.krebene.krfJ_PJM4_VW_soll);            
                grdata.push(row);
            }

            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) && this.props.krebene.krfJ_PJM3_VW_haben !== undefined && this.props.krebene.krfJ_PJM3_VW_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-3).toString()];
                row.push(this.props.krebene.krfJ_PJM3_VW_haben);            
                row.push(this.props.krebene.krfJ_PJM3_VW_soll);            
                grdata.push(row);
            }

            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) && this.props.krebene.krfJ_PJM2_VW_haben !== undefined && this.props.krebene.krfJ_PJM2_VW_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-2).toString()];
                row.push(this.props.krebene.krfJ_PJM2_VW_haben);            
                row.push(this.props.krebene.krfJ_PJM2_VW_soll);            
                grdata.push(row);
            }
        
            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) && this.props.krebene.krfJ_PJM1_VW_haben !== undefined && this.props.krebene.krfJ_PJM1_VW_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-1).toString()];
                row.push(this.props.krebene.krfJ_PJM1_VW_haben);            
                row.push(this.props.krebene.krfJ_PJM1_VW_soll);            
                grdata.push(row);
            }

            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr) >= 0) && this.props.krebene.krfJ_VW_haben !== undefined && this.props.krebene.krfJ_VW_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr).toString()];
                row.push(this.props.krebene.krfJ_VW_haben);            
                row.push(this.props.krebene.krfJ_VW_soll);            
                grdata.push(row);
            }
        }
        else if ( typ == 2 )
        {
            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr - 4) >= 0) && this.props.krebene.krfJ_PJM4_VM_haben !== undefined && this.props.krebene.krfJ_PJM4_VM_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-4).toString()];
                row.push(this.props.krebene.krfJ_PJM4_VM_haben);            
                row.push(this.props.krebene.krfJ_PJM4_VM_soll);            
                grdata.push(row);
            }

            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) && this.props.krebene.krfJ_PJM3_VM_haben !== undefined && this.props.krebene.krfJ_PJM3_VM_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-3).toString()];
                row.push(this.props.krebene.krfJ_PJM3_VM_haben);            
                row.push(this.props.krebene.krfJ_PJM3_VM_soll);            
                grdata.push(row);
            }

            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) && this.props.krebene.krfJ_PJM2_VM_haben !== undefined && this.props.krebene.krfJ_PJM2_VM_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-2).toString()];
                row.push(this.props.krebene.krfJ_PJM2_VM_haben);            
                row.push(this.props.krebene.krfJ_PJM2_VM_soll);            
                grdata.push(row);
            }
        
            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) && this.props.krebene.krfJ_PJM1_VM_haben !== undefined && this.props.krebene.krfJ_PJM1_VM_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-1).toString()];
                row.push(this.props.krebene.krfJ_PJM1_VM_haben);            
                row.push(this.props.krebene.krfJ_PJM1_VM_soll);            
                grdata.push(row);
            }

            if ((!this.props.krebene.jahre || this.props.krebene.jahre.findIndex(d => d == this.props.jahr) >= 0) && this.props.krebene.krfJ_VM_haben !== undefined && this.props.krebene.krfJ_VM_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr).toString()];
                row.push(this.props.krebene.krfJ_VM_haben);            
                row.push(this.props.krebene.krfJ_VM_soll);            
                grdata.push(row);
            }
        }
                        
        return grdata;
    }


    createColumns = () => {
        var columns = [];


        var id = 1;
        columns.push({
            dataField: 'verbergen',
            text: "",
            headerStyle: (colum: any, colIndex: any) => {
                return { maxWidth: '20px', width: '20px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.KREbene) => {                                
                if ( row.isHHS || row.gesamtSumme || !this.props.krebene.unterEbenen )
                    return null;
                /*
                const rows = this.props.krebene.unterEbenen.filter(d => d.isHHS && (row.istEinnahme === d.istEinnahme && row.isVMHH === d.isVMHH) );              
                if (this.props.krebene.unterEbenen.findIndex(d => rows.findIndex(z => d.istEinnahme === z.istEinnahme && d.isVMHH === z.isVMHH) >= 0 && d.verbergen) >= 0) {                    
                    return (                                            
                        <div className="kslplus-row-sum">(+)</div>
                    );
                }                    
                else {

                    return (                                            
                        <div className="kslplus-row-sum">(-)</div>
                    );
                }            
                */
                
                if ( row.verbergen  )
                {
                    return (                                            
                        <div className="kslplus-row-sum">(+)</div>
                    );
                }
                else
                {
                    return (                                            
                        <div className="kslplus-row-sum">(-)</div>
                    );
                }
                

            }/*,
            footer: id.toString(),
            footerFormatter: (column: any, columnIndex: any) => {                
                return (<div></div>);
            }*/
        });

        columns.push({
            dataField: 'isVMHH',
            text: "Haushalt",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '75px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.KREbene) => {                                
                if ( row.gesamtSumme  )
                {
                    return (                                            
                        <div className="kslplus-row-sum">Gesamt</div>
                    );
                }
                else
                {
                    return (                                            
                        <div className={row.isHHS ? "" : "kslplus-row-sum"}>{row.isVMHH ? "VMHH" : "VWHH" }</div>
                    );
                }

            },
            /*footer: id.toString(),
            footerFormatter: (column: any, columnIndex: any) => {                
                return (<div></div>);
            },*/                     
            filter: selectFilter({
                options: {
                    false: 'VWHH',
                    true: 'VMHH'
                },
                onFilter: (filterVal:string, data: KSLPlusAPI.KREbene[]) => {
                    
                    if ( filterVal === "" )
                        return data;                    
                    else if ( filterVal == "false" )
                        return data.filter(row => !row.gesamtSumme && !row.isVMHH);                    
                    else
                        return data.filter(row => !row.gesamtSumme && row.isVMHH);                    
                }    
            }) 
        });

        id++;
        columns.push({
            dataField: 'istEinnahme',
            text: "Einn./Ausg.",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '75px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.KREbene) => {                                
                if ( row.isHHS )
                {
                    return (                                            
                        <div>{row.istEinnahme ? "Einnahme" : "Ausgabe" }</div>
                    );
                }
                else
                {
                    return (                                            
                        <div className="kslplus-row-sum">{row.istEinnahme ? "Summe Einnahmen" : "Summe Ausgaben" }</div>
                    );
                }

            },
            /*footer: id.toString(),
            footerFormatter: (column: any, columnIndex: any) => {                
                return (<div></div>);
            },*/                     
            filter: selectFilter({
                options: {
                    false: 'Ausgaben',
                    true: 'Einnahmen'
                }
            })            
        });

        id++;
        columns.push({
            dataField: 'bezeichnung',
            text: "Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '120px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.KREbene) => {                                
                return (                                            
                    <div>{row.isHHS && row.unterabschnitt !== undefined ? row.bezeichnung : ""}</div>
                );

            },
            /*footer: id.toString(),
            footerFormatter: (column: any, columnIndex: any) => {                
                return (<div></div>);
            },*/                       
            filter: textFilter(
                {
                    onFilter: (filterVal: string, data: KSLPlusAPI.KREbene[]) => {

                        if (filterVal === "")
                            return data;
                        else
                            return data.filter(row => !row.bezeichnung || !row.isHHS || row.bezeichnung.toLowerCase().indexOf(filterVal.toLowerCase()) !== -1);
                    }
                }
            )
        });

        id++;
        columns.push({
            dataField: 'gliederung',
            text: "Gliederung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '60px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.KREbene) => {                                
                return (                                            
                    <div>{row.isHHS && row.unterabschnitt !== undefined ? Utilities.Num2Str(row.unterabschnitt, row.uaStellen) : ""}</div>
                );

            },
            /*footer: id.toString(),
            footerFormatter: (column: any, columnIndex: any) => {                
                return (<div></div>);
            },*/                       
            filter: textFilter({
                onFilter: (filterVal: string, data: KSLPlusAPI.KREbene[]) => {                    
                    if (filterVal)
                        return data.filter(row => !row.isHHS || (row.unterabschnitt !== undefined && Utilities.Num2Str(row.unterabschnitt, row.uaStellen).indexOf(filterVal) != -1));

                    return data;
                }

            })
        });

        id++;
        columns.push({
            dataField: 'massnahme',
            text: "MN",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '50px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.KREbene) => {                                
                return (                                            
                    <div>{row.isHHS && row.massnahme ? Utilities.Num2Str(row.massnahme, 3) : ""}</div>
                );

            },
            /*footer: id.toString(),
            footerFormatter: (column: any, columnIndex: any) => {                
                return (<div></div>);
            },*/                       
            filter: textFilter({
                onFilter: (filterVal: string, data: KSLPlusAPI.KREbene[]) => {                    
                    if (filterVal)
                        return data.filter(row => !row.isHHS || (row.massnahme !== undefined && Utilities.Num2Str(row.massnahme, 3).indexOf(filterVal) != -1));

                    return data;
                }

            })
        });

        id++;
        columns.push({
            dataField: 'gruppierung',
            text: "Gruppierung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '60px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.KREbene) => {                                
                return (                                            
                    <div>{row.isHHS && row.untergruppe !== undefined ? Utilities.Num2Str(row.untergruppe, row.ugStellen) : ""}</div>
                );

            },
            /*footer: id.toString(),
            footerFormatter: (column: any, columnIndex: any) => {
                
                //return (<div><div>Einnahmen gesamt</div><div>Ausgaben gesamt</div><div>Ergebnis E-A</div></div>);
                return (<div><div>Kassenreste Einnahmen VWHH</div><div>Kassenreste Einnahmen VMHH</div><div>Kassenreste Einnahmen gesamt</div><div>&nbsp;</div><div>Kassenreste Ausgaben VWHH</div><div>Kassenreste Ausgaben VMHH</div><div>Kassenreste Ausgaben gesamt</div></div>);

            },*/            
            filter: textFilter({
                onFilter: (filterVal: string, data: KSLPlusAPI.KREbene[]) => {                    
                    if (filterVal)
                        return data.filter(row => !row.isHHS || (row.untergruppe !== undefined && Utilities.Num2Str(row.untergruppe, row.ugStellen).indexOf(filterVal) != -1));

                    return data;
                }

            })
        });

        id++;
        columns.push({
            dataField: 'unterkonto',
            text: "UK",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '50px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.KREbene) => {                                
                return (                                            
                    <div>{row.isHHS && row.unterkonto ? Utilities.Num2Str(row.unterkonto, 2) : ""}</div>
                );

            },
            /*footer: id.toString(),
            footerFormatter: (column: any, columnIndex: any) => {                
                return (<div></div>);
            },*/                       
            filter: textFilter({
                onFilter: (filterVal: string, data: KSLPlusAPI.KREbene[]) => {                    
                    if (filterVal)
                        return data.filter(row => !row.isHHS || (row.unterkonto !== undefined && Utilities.Num2Str(row.unterkonto, 2).indexOf(filterVal) != -1));

                    return data;
                }

            })
        });

        id++;
        columns.push({
            dataField: 'kr',
            text: "Kassenreste aus Vorjahren",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '100px', textAlign: 'right' };
            },            
            align: 'right',                       
            formatter: (cellContent: string, row: KSLPlusAPI.KREbene) => {                                
                var text = "";
                if ( row.istEinnahme && row.krvJ_haben !== undefined )
                    text = Utilities.FormatCurrency(row.krvJ_haben, 2, 3, '.', ',')
                else if (!row.istEinnahme && row.krvJ_soll !== undefined )
                    text = Utilities.FormatCurrency(row.krvJ_soll, 2, 3, '.', ',')

                return (                                            
                    <div className={row.isHHS ? "" : "kslplus-row-sum"}>{text}</div>
                );

            }/*,            
            footer: id.toString(),
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                var einnahmen = "";
                var einnahmenVW = "";         
                var einnahmenVM = "";         
                var ausgaben = "";
                var ausgabenVW = "";
                var ausgabenVM = "";
                
                if ( this.props.krebene.krvJ_haben !== undefined )
                    einnahmen = Utilities.FormatCurrency(this.props.krebene.krvJ_haben, 2, 3, '.', ',')
                if ( this.props.krebene.krvJ_soll !== undefined )
                    ausgaben = Utilities.FormatCurrency(this.props.krebene.krvJ_soll, 2, 3, '.', ',')
                if ( this.props.krebene.krvJ_VW_haben !== undefined )                
                    einnahmenVW = Utilities.FormatCurrency(this.props.krebene.krvJ_VW_haben, 2, 3, '.', ',')                
                if ( this.props.krebene.krvJ_VW_soll !== undefined )
                    ausgabenVW = Utilities.FormatCurrency(this.props.krebene.krvJ_VW_soll, 2, 3, '.', ',')
                if ( this.props.krebene.krvJ_VM_haben !== undefined )
                    einnahmenVM = Utilities.FormatCurrency(this.props.krebene.krvJ_VM_haben, 2, 3, '.', ',')
                if ( this.props.krebene.krvJ_VM_soll !== undefined )
                    ausgabenVM = Utilities.FormatCurrency(this.props.krebene.krvJ_VM_soll, 2, 3, '.', ',')
                
                return <div><div>{einnahmenVW}</div><div>{einnahmenVM}</div><div>{einnahmen}</div><div>&nbsp;</div><div>{ausgabenVW}</div><div>{ausgabenVM}</div><div>{ausgaben}</div></div>;
            }*/
        });
        
        id++;
        columns.push({
            dataField: 'akr',
            text: "- Abgang auf KR a. VJ",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '100px', textAlign: 'right' };
            },            
            align: 'right',       
            formatter: (cellContent: string, row: KSLPlusAPI.KREbene) => {                                
                var text = "";
                if ( row.istEinnahme && row.akrvJ_haben !== undefined )
                    text = Utilities.FormatCurrency(row.akrvJ_haben, 2, 3, '.', ',')
                else if (!row.istEinnahme && row.akrvJ_soll !== undefined )
                    text = Utilities.FormatCurrency(row.akrvJ_soll, 2, 3, '.', ',')

                return (                                            
                    <div className={row.isHHS ? "" : "kslplus-row-sum"}>{text}</div>
                );            
            }
            /*
            ,
            footer: id.toString(),
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                var einnahmen = "";
                var einnahmenVW = "";         
                var einnahmenVM = "";         
                var ausgaben = "";
                var ausgabenVW = "";
                var ausgabenVM = "";
                
                if ( this.props.krebene.akrvJ_haben !== undefined )
                    einnahmen = Utilities.FormatCurrency(this.props.krebene.akrvJ_haben, 2, 3, '.', ',')
                if ( this.props.krebene.akrvJ_soll !== undefined )
                    ausgaben = Utilities.FormatCurrency(this.props.krebene.akrvJ_soll, 2, 3, '.', ',')
                if ( this.props.krebene.akrvJ_VW_haben !== undefined )
                    einnahmenVW = Utilities.FormatCurrency(this.props.krebene.akrvJ_VW_haben, 2, 3, '.', ',')
                if ( this.props.krebene.akrvJ_VW_soll !== undefined )
                    ausgabenVW = Utilities.FormatCurrency(this.props.krebene.akrvJ_VW_soll, 2, 3, '.', ',')
                if ( this.props.krebene.akrvJ_VM_haben !== undefined )
                    einnahmenVM = Utilities.FormatCurrency(this.props.krebene.akrvJ_VM_haben, 2, 3, '.', ',')
                if ( this.props.krebene.akrvJ_VM_soll !== undefined )
                    ausgabenVM = Utilities.FormatCurrency(this.props.krebene.akrvJ_VM_soll, 2, 3, '.', ',')
                
                return <div><div>{einnahmenVW}</div><div>{einnahmenVM}</div><div>{einnahmen}</div><div>&nbsp;</div><div>{ausgabenVW}</div><div>{ausgabenVM}</div><div>{ausgaben}</div></div>;                                
            }
            */
        });
    
        id++;
        columns.push({
            dataField: 'ikr',
            text: "- Ist auf KR a. VJ	",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '100px', textAlign: 'right' };
            },            
            align: 'right',                       
            formatter: (cellContent: string, row: KSLPlusAPI.KREbene) => {                                
                var text = "";

                if ( row.istEinnahme && row.ikrvJ_haben !== undefined )
                    text = Utilities.FormatCurrency(row.ikrvJ_haben, 2, 3, '.', ',')
                else if (!row.istEinnahme && row.ikrvJ_soll !== undefined )
                    text = Utilities.FormatCurrency(row.ikrvJ_soll, 2, 3, '.', ',')

                return (                                            
                    <div className={row.isHHS ? "" : "kslplus-row-sum"}>{text}</div>
                );

            }/*,
            footer: id.toString(),
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                var einnahmen = "";
                var einnahmenVW = "";         
                var einnahmenVM = "";         
                var ausgaben = "";
                var ausgabenVW = "";
                var ausgabenVM = "";
                
                if ( this.props.krebene.ikrvJ_haben !== undefined )
                    einnahmen = Utilities.FormatCurrency(this.props.krebene.ikrvJ_haben, 2, 3, '.', ',')
                if ( this.props.krebene.ikrvJ_soll !== undefined )
                    ausgaben = Utilities.FormatCurrency(this.props.krebene.ikrvJ_soll, 2, 3, '.', ',')
                if ( this.props.krebene.ikrvJ_VW_haben !== undefined )
                    einnahmenVW = Utilities.FormatCurrency(this.props.krebene.ikrvJ_VW_haben, 2, 3, '.', ',')
                if ( this.props.krebene.ikrvJ_VW_soll !== undefined )
                    ausgabenVW = Utilities.FormatCurrency(this.props.krebene.ikrvJ_VW_soll, 2, 3, '.', ',')
                if ( this.props.krebene.ikrvJ_VM_haben !== undefined )
                    einnahmenVM = Utilities.FormatCurrency(this.props.krebene.ikrvJ_VM_haben, 2, 3, '.', ',')
                if ( this.props.krebene.ikrvJ_VM_soll !== undefined )
                    ausgabenVM = Utilities.FormatCurrency(this.props.krebene.ikrvJ_VM_soll, 2, 3, '.', ',')
                
                return <div><div>{einnahmenVW}</div><div>{einnahmenVM}</div><div>{einnahmen}</div><div>&nbsp;</div><div>{ausgabenVW}</div><div>{ausgabenVM}</div><div>{ausgaben}</div></div>;
            }*/
        });

        id++;
        columns.push({
            dataField: 'nkr',
            text: "+ Neue KR",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '100px', textAlign: 'right' };
            },            
            align: 'right',                       
            formatter: (cellContent: string, row: KSLPlusAPI.KREbene) => {                                
                var text = "";
                
                if ( row.istEinnahme && row.nkR_haben !== undefined )
                    text = Utilities.FormatCurrency(row.nkR_haben, 2, 3, '.', ',')
                else if (!row.istEinnahme && row.nkR_soll !== undefined )
                    text = Utilities.FormatCurrency(row.nkR_soll, 2, 3, '.', ',')

                return (                                            
                    <div className={row.isHHS ? "" : "kslplus-row-sum"}>{text}</div>
                );

            }/*,
            footer: id.toString(),
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                var einnahmen = "";
                var einnahmenVW = "";         
                var einnahmenVM = "";         
                var ausgaben = "";
                var ausgabenVW = "";
                var ausgabenVM = "";
                
                if ( this.props.krebene.nkR_haben !== undefined )
                    einnahmen = Utilities.FormatCurrency(this.props.krebene.nkR_haben, 2, 3, '.', ',')
                if ( this.props.krebene.nkR_soll !== undefined )
                    ausgaben = Utilities.FormatCurrency(this.props.krebene.nkR_soll, 2, 3, '.', ',')
                if ( this.props.krebene.nkR_VW_haben !== undefined )
                    einnahmenVW = Utilities.FormatCurrency(this.props.krebene.nkR_VW_haben, 2, 3, '.', ',')
                if ( this.props.krebene.nkR_VW_soll !== undefined )
                    ausgabenVW = Utilities.FormatCurrency(this.props.krebene.nkR_VW_soll, 2, 3, '.', ',')
                if ( this.props.krebene.nkR_VM_haben !== undefined )
                    einnahmenVM = Utilities.FormatCurrency(this.props.krebene.nkR_VM_haben, 2, 3, '.', ',')
                if ( this.props.krebene.nkR_VM_soll !== undefined )
                    ausgabenVM = Utilities.FormatCurrency(this.props.krebene.nkR_VM_soll, 2, 3, '.', ',')
                
                return <div><div>{einnahmenVW}</div><div>{einnahmenVM}</div><div>{einnahmen}</div><div>&nbsp;</div><div>{ausgabenVW}</div><div>{ausgabenVM}</div><div>{ausgaben}</div></div>;
            }*/
        });

        id++;
        columns.push({
            dataField: 'krfj',
            text: "= Vortrag Folgejahr",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '100px', textAlign: 'right' };
            },            
            align: 'right',                       
            formatter: (cellContent: string, row: KSLPlusAPI.KREbene) => {                                
                var text = "";
                if ( row.istEinnahme && row.krfJ_haben !== undefined )
                    text = Utilities.FormatCurrency(row.krfJ_haben, 2, 3, '.', ',')
                else if (!row.istEinnahme && row.krfJ_soll !== undefined )
                    text = Utilities.FormatCurrency(row.krfJ_soll, 2, 3, '.', ',')

                return (                                            
                    <div className={row.isHHS ? "" : "kslplus-row-sum"}>{text}</div>
                );

            }/*,
            footer: id.toString(),
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                var einnahmen = "";
                var einnahmenVW = "";         
                var einnahmenVM = "";         
                var ausgaben = "";
                var ausgabenVW = "";
                var ausgabenVM = "";
                
                if ( this.props.krebene.krfJ_haben !== undefined )
                    einnahmen = Utilities.FormatCurrency(this.props.krebene.krfJ_haben, 2, 3, '.', ',')
                if ( this.props.krebene.krfJ_soll !== undefined )
                    ausgaben = Utilities.FormatCurrency(this.props.krebene.krfJ_soll, 2, 3, '.', ',')
                if ( this.props.krebene.krfJ_VW_haben !== undefined )
                    einnahmenVW = Utilities.FormatCurrency(this.props.krebene.krfJ_VW_haben, 2, 3, '.', ',')
                if ( this.props.krebene.krfJ_VW_soll !== undefined )
                    ausgabenVW = Utilities.FormatCurrency(this.props.krebene.krfJ_VW_soll, 2, 3, '.', ',')
                if ( this.props.krebene.krfJ_VM_haben !== undefined )
                    einnahmenVM = Utilities.FormatCurrency(this.props.krebene.krfJ_VM_haben, 2, 3, '.', ',')
                if ( this.props.krebene.krfJ_VM_soll !== undefined )
                    ausgabenVM = Utilities.FormatCurrency(this.props.krebene.krfJ_VM_soll, 2, 3, '.', ',')
                
                return <div><div>{einnahmenVW}</div><div>{einnahmenVM}</div><div>{einnahmen}</div><div>&nbsp;</div><div>{ausgabenVW}</div><div>{ausgabenVM}</div><div>{ausgaben}</div></div>;                
            }*/
        });

        return columns;
    }

    hideRows = (rows: KSLPlusAPI.KREbene[]|undefined) => {
        if ( this.props.krebene && this.props.krebene.unterEbenen ) {
            const rows1 = this.props.krebene.unterEbenen.filter(d => !rows || d.isHHS && (rows && rows.findIndex(z => d.istEinnahme === z.istEinnahme && d.isVMHH === z.isVMHH) >= 0 ) );
            rows1.forEach(r => r.verbergen = true);
            this.setState({ hiddenRows: this.props.krebene.unterEbenen.filter(d => d.isHHS && d.verbergen).map(d => d.id) });            
        }
    }

    showRows = (rows: KSLPlusAPI.KREbene[]|undefined) => {
         if ( this.props.krebene && this.props.krebene.unterEbenen ) {
            const rows1 = this.props.krebene.unterEbenen.filter(d => !rows || d.isHHS && (rows && rows.findIndex(z => d.istEinnahme === z.istEinnahme && d.isVMHH === z.isVMHH) >= 0 ) );
            rows1.forEach(r => r.verbergen = false);
            this.setState({ hiddenRows: this.props.krebene.unterEbenen.filter(d => d.isHHS && d.verbergen).map(d => d.id) });            
        }
    }

    rowEvents = {
        onClick: (e:any, row: KSLPlusAPI.KREbene, rowIndex:number) => 
        {
            if ( this.props.krebene && this.props.krebene.unterEbenen && !row.isHHS && !row.gesamtSumme) {             
                const rows = this.props.krebene.unterEbenen.filter(d => d.isHHS && (row.istEinnahme === d.istEinnahme && row.isVMHH === d.isVMHH) );              
                if (this.props.krebene.unterEbenen.findIndex(d => rows.findIndex(z => d.istEinnahme === z.istEinnahme && d.isVMHH === z.isVMHH) >= 0 && d.verbergen) >= 0) {
                    row.verbergen = !row.verbergen;
                    this.showRows(rows);
                }                    
                else {
                    row.verbergen = !row.verbergen;
                    this.hideRows(rows);
                }            
            }
        }
    };

    componentDidUpdate(prevProps: Props) {        
        if ( prevProps.krebene != this.props.krebene && this.props.krebene && this.props.krebene.unterEbenen )
            this.setState({ hiddenRows: this.props.krebene.unterEbenen.filter(d => d.isHHS && d.verbergen).map(d => d.id)  });
    }

    componentDidMount() {        
        if ( this.props.krebene && this.props.krebene.unterEbenen )
        {            
            this.setState({ hiddenRows: this.props.krebene.unterEbenen.filter(d => d.isHHS && d.verbergen).map(d => d.id)  });
        }
    }
    
    render() {         
        
        var jahrStart = "";
        if ( this.props.krebene.jahre && this.props.krebene.jahre.length > 0 )
            jahrStart = this.props.krebene.jahre[0].toString();

        var jahrEnd = "";
        if ( this.props.krebene.jahre && this.props.krebene.jahre.length > 0 )
            jahrEnd = this.props.krebene.jahre[this.props.krebene.jahre.length-1].toString();

        return (
            
                <div>                                        
                    <div className="kslplus-content">                                                                                              
                        
                        <Row>
                            <Col>
                                <BootstrapTable hover={true} bordered={true} keyField='id' data={this.props.krebene.unterEbenen} columns={this.createColumns()} filter={filterFactory()} filterPosition="top" hiddenRows={this.state.hiddenRows} rowEvents={ this.rowEvents }/>

                            </Col>
                        </Row>    

                        {!this.props.settings.jrSettings || !this.props.settings.jrSettings.kresteDiagrammeAus &&
                            <Row>
                                <Col>
                                    <div className="kslplus-headline-third">{"Entwicklung der gesamthaften Kassenrestvorträge " + jahrStart + " bis " + jahrEnd}</div>
                                </Col>
                            </Row>
                        }
                        {!this.props.settings.jrSettings || !this.props.settings.jrSettings.kresteDiagrammeAus &&
                            <Row>
                                <Col>

                                    <div className="ksl-grafik-container">
                                        <Chart
                                            width="100%"
                                            height={'300px'}
                                            chartType="ColumnChart"
                                            chartLanguage='de'
                                            loader={<div>Loading Chart</div>}
                                            data={this.createGraphData(0)}
                                            options={{
                                                colors: ['#264d99', '#dc3912'],
                                                vAxes: { 0: { title: "EUR", minValue: 0 } },
                                                hAxis: { title: 'Jahr', format: "####" },
                                                series: { 0: { type: "bars", targetAxisIndex: 0 } }
                                            }}
                                            formatters={Utilities.createFormatters(2, 2)}

                                            rootProps={{ 'data-testid': '2' }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }

                        {!this.props.settings.jrSettings || !this.props.settings.jrSettings.kresteDiagrammeAus &&
                            <Row>
                                <Col>
                                    <div className="kslplus-headline-third">{"Entwicklung Kassenrestvorträge VWHH  " + jahrStart + " bis " + jahrEnd}</div>
                                </Col>
                            </Row>
                        }
                        {!this.props.settings.jrSettings || !this.props.settings.jrSettings.kresteDiagrammeAus &&
                            <Row>
                                <Col>

                                    <div className="ksl-grafik-container">
                                        <Chart
                                            width="100%"
                                            height={'300px'}
                                            chartType="ColumnChart"
                                            chartLanguage='de'
                                            loader={<div>Loading Chart</div>}
                                            data={this.createGraphData(1)}
                                            options={{
                                                colors: ['#264d99', '#dc3912'],
                                                vAxes: { 0: { title: "EUR", minValue: 0 } },
                                                hAxis: { title: 'Jahr', format: "####" },
                                                series: { 0: { type: "bars", targetAxisIndex: 0 } }
                                            }}
                                            formatters={Utilities.createFormatters(2, 2)}

                                            rootProps={{ 'data-testid': '2' }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }

                        {!this.props.settings.jrSettings || !this.props.settings.jrSettings.kresteDiagrammeAus &&

                            <Row>
                                <Col>
                                    <div className="kslplus-headline-third">{"Entwicklung Kassenrestvorträge VMHH  " + jahrStart + " bis " + jahrEnd}</div>
                                </Col>
                            </Row>
                        }
                        {!this.props.settings.jrSettings || !this.props.settings.jrSettings.kresteDiagrammeAus &&
                            <Row>
                                <Col>

                                    <div className="ksl-grafik-container">
                                        <Chart
                                            width="100%"
                                            height={'300px'}
                                            chartType="ColumnChart"
                                            chartLanguage='de'
                                            loader={<div>Loading Chart</div>}
                                            data={this.createGraphData(2)}
                                            options={{
                                                colors: ['#264d99', '#dc3912'],
                                                vAxes: { 0: { title: "EUR", minValue: 0 } },
                                                hAxis: { title: 'Jahr', format: "####" },
                                                series: { 0: { type: "bars", targetAxisIndex: 0 } }
                                            }}
                                            formatters={Utilities.createFormatters(2, 2)}

                                            rootProps={{ 'data-testid': '2' }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }
       
                                                
                    </div>
                </div>
        );
    }
}
import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, UncontrolledTooltip, Badge, Nav, NavItem, NavLink, TabContent, TabPane, Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import Collapse from "@kunukn/react-collapse";

import Chart from "react-google-charts";

import { Kennzahl } from './KennzahlComp';


import * as Utilities from "./Utilities"

import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";



type State = {
    ready: boolean,
    activeMainTab: string,
    openBeschreibungen:boolean[],
    isVisible: boolean;
    
}

type Props = {        
    jahr: number,
    kunde: number,
    gemeinde: number,    
    modul: number,
    tab: KSLPlusAPI.Vbtab,
    datasets: KSLPlusAPI.Datensaetze[],
    settings: KSLPlusAPI.SettingsModel,      
    className: string,
    isVisible: boolean
}



export class VBTab extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
        this.tabs=  [];
        this.texte=[];
    }

    node: any;
    tabs: KSLPlusAPI.Vbtab[];
    texte:KSLPlusAPI.Texte[];
    container: any = null;

    

    
    state: State = {
        ready: false,
        activeMainTab: "tab0",
        openBeschreibungen: [],
        isVisible: false
    }

    redrawCharts = () => {                
    }
  
    componentDidUpdate(prevProps: Props) {
        if (prevProps.isVisible !== this.props.isVisible && this.props.isVisible !== this.state.isVisible)        
            this.setState({activeMainTab: "tab0"});   

    }

    componentDidMount() {
        window.addEventListener('resize', this.redrawCharts, false);
            
        this.readTexte();

             
    
    }

    readTexte = (tries: number = 0) => {        
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        jahr = this.props.jahr;


        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);
               
        client.getTabTexte(kunde, gemeinde, jahr, this.props.tab.tabId?this.props.tab.tabId:"", "").then((texte) => {                   
            this.texte = texte;
            this.readTabs();
            /*
            if ( this.props.tab.istOberTab )    
                this.readTabs();
            else    
                this.setState({ ready: true });
            */
        }).catch(Reason => {            
            if (tries < 3)
                setTimeout(() => { this.readTexte(++tries) }, 2000);                
            else
                alert("Fehler beim Lesen der Beschreibungstexte!");
        });

    }
    
    readTabs = (tries: number = 0) => {        
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        jahr = this.props.jahr;


        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);
               
        client.tabsByMainTab(kunde, gemeinde, jahr, this.props.tab.tabId?this.props.tab.tabId:"", this.props.modul, "").then((tabs) => {            
            this.tabs = tabs;
            this.setState({ ready: true });
        }).catch(Reason => {            
            if ( tries < 3 )
                setTimeout(() => { this.readTabs(++tries) }, 2000);
            else
                alert("Fehler beim Lesen der Datenreiter!");
        });

    }

    toggleBeschreibung = (idx: number) => {        
        this.state.openBeschreibungen[idx] = !this.state.openBeschreibungen[idx];
        this.setState({ready: true});
    }

    createBeschreibungsItems = (texte: KSLPlusAPI.Texte[]|undefined) => { 
        var items: JSX.Element[] = [];
        
        
        if ( !texte )
            return items;

        var numInRow = texte.length > 4 || texte.length == 3 ? 3 : 2;
        
        for ( var i = 0; i < texte.length ; i = i + numInRow )
        {
            const idx = i / numInRow;            
            this.state.openBeschreibungen.push(false);
            var spanItems : JSX.Element[] = [];
            var colItems : JSX.Element[] = [];
            for ( var j = i; j < i+numInRow && j < texte.length; j++ )
            {
                spanItems.push(
                    <span className="kslplus-beschreibung-text" key={"bespan" + j.toString()}>{texte[j].bezeichnung}</span>
                );
                colItems.push(
                    <Col className="kslplus-beschreibung-content" key={"becol" + j.toString()}>
                            {texte[j].text}                                                      
                    </Col>
                );
            }
            items.push(
                <button
                          className={"kslplus-beschreibung-toggle" + (this.state.openBeschreibungen[idx]?" active":"")}
                                                      onClick={() => this.toggleBeschreibung(idx)}
                            key={"beb" + idx.toString()}
                        >
                          
                          {spanItems}
                          <div className="rotate90">
                            <svg
                              className={"kslplus-beschreibung-icon" + (this.state.openBeschreibungen[idx]?" expanded":"")}
                              viewBox="6 0 12 24"
                            >
                              <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12"  />
                            </svg>
                          </div>
                            
                        </button>
            );
            items.push(
                <Collapse
                            isOpen={this.state.openBeschreibungen[idx]}
                            className={"kslplus-beschreibung-collapse-gradient " + + (this.state.openBeschreibungen[idx]?" active":"")}
                            key={"bec" + idx.toString()}
                        >
                        <Row>   
                        {colItems}
                        </Row>
                </Collapse>
            );
        }

        return items;
        
    }

    createTabItems = (tabs: KSLPlusAPI.Vbtab[]) => {
        //if ( tabs.length > 1 )
          //  return this.createTabItemsDropDown(tabs);

        var mainContentItems: JSX.Element[] = [];
        var mainNavItems: JSX.Element[] = [];   

        for (var i = 0; i < tabs.length; i++) {
            const tabId = "tab" + i.toString();
            const tab = tabs[i];
            mainNavItems.push(
                <NavItem key={"kenav" + tabId}>
                    <NavLink active={this.state.activeMainTab === tabId} onClick={() => this.setState({ activeMainTab: tabId })} >
                        {tab.bezeichnung}
                    </NavLink>
                </NavItem>
            );
            mainContentItems.push(
                <TabPane tabId={tabId} key={"ketab" + tabId}> 
                    <VBTab modul={this.props.modul} tab={tabs[i]} datasets={this.props.datasets} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} settings={this.props.settings} className="kslplus-haushaltstab-wrapper-very-bottom" isVisible={this.state.activeMainTab === tabId}/>                         
                </TabPane>
            );
        }

        return { navItems: mainNavItems, contentItems: mainContentItems };        
    }
    /*
    createTabItemsDropDown = (tabs: KSLPlusAPI.Vbtab[]) => {
        var mainContentItems: JSX.Element[] = [];
        var mainNavItems: JSX.Element[] = [];   

        for (var i = 0; i < tabs.length; i++) {
            const tabId = "tab" + i.toString();
            const tab = tabs[i];
            mainNavItems.push(
                <DropdownItem active={this.state.activeMainTab === tabId} key={"datanav" + tabId} onClick={() => this.setState({ activeMainTab: tabId, dropDownOpen: false, tab: tab.bezeichnung?tab.bezeichnung:"" })}>                         
                            {tab.bezeichnung}                        
                </DropdownItem>

            );
            mainContentItems.push(
                <TabPane tabId={tabId} key={"ketab" + tabId}>  
                    <VBTab modul={this.props.modul} tab={tabs[i]} datasets={this.props.datasets} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} className="kslplus-haushaltstab-wrapper-very-bottom" />                         
                </TabPane>
            );
        }

        return { navItems: mainNavItems, contentItems: mainContentItems };        
    }*/

    createDataSetColumnsMirrored = (sets: KSLPlusAPI.Datensaetze[] | null, columnHeaders: string[], decimalPlaces: number, unit: string) => {
        var columns = [];
        columns.push({
            dataField: "0",
            text: "Jahr",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '180px', textAlign: 'left' };
            },

            style: (colum: any, colIndex: any) => {
                return { width: '180px' };
            },
            formatter: (cellContent: number|undefined|any, row: any) => {                    
                return (
                    cellContent !== undefined ?
                        <div className="kslplus-tabellen-data">{cellContent.text} </div>
                        :
                        <div className="kslplus-tabellen-data"></div>
                );

            }
        });


        if (sets == null || sets[0].datens === undefined)
            return columns;

        for (var i = 0; i < sets.length; i++) {                   
            columns.push({
                dataField: (i + 1).toString(),
                text: i < columnHeaders.length ? columnHeaders[i] : sets[i].bezeichnung + (unit.length > 0 ? " (" + unit + ")": ""),
                headerAlign: 'right',
                align: 'right',
                formatter: (cellContent: number|undefined|any, row: any) => {                    
                    return (
                        cellContent !== undefined ?
                            <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(cellContent.text, cellContent.nks !== undefined ? cellContent.nks : decimalPlaces, 3, '.', ',')} </div>
                            :
                            <div className="kslplus-tabellen-data"></div>
                    );

                }


            });
        }

        return columns;
    }

    createCombinedTableDataMirrored = (sets: KSLPlusAPI.Datensaetze[] | undefined, headerX: string, headerY: string) => {
        var grdata: any[] = [];
        //grdata.push([headerX, headerY]);

        if (sets === undefined)
            return grdata;

        if (sets[0].datens === undefined)
            return grdata;

        var x: number[] = [];

        // find common X values
        for (var j = 0; j < sets[0].datens.length; j++) {
            var found = true;

            if (sets[0].datens[j].x === undefined)
                continue;

            var xVal = sets[0].datens[j].x;

            for (var i = 1; i < sets.length; i++) {


                var data = sets[i].datens;
                if (data === undefined || data.find(d => sets[0].datens !== undefined && d.x == xVal) === undefined) {
                    found = false;
                    break;
                }
            }
            if (found && xVal !== undefined) {
                x.push(xVal);
            }
        }


        for (var i = 0; i < x.length; i++) {
            var row: {[key: string]: {[key: string]: any}} = {};

            //row.push(x[i].toString());
            row[0] = {};
            row[0].text = x[i].toString();    
            for (var j = 0; j < sets.length; j++) {
                var data = sets[j].datens;
                if (data === undefined)
                    continue;

                var pair = data.find(d => d.x == x[i])
                if (pair && pair.y !== undefined) {
                    row[j+1] = {};                    
                    row[j+1].text = pair.y;
                    row[j+1].nks = sets[j].nachkommastellen;
                    //row.push(pair.y);
                }
            }
            grdata.push(row);
        }

        return grdata;
    }

    createDataSetColumns = (sets: KSLPlusAPI.Datensaetze[] | null, decimalPlaces: number, unit: string) => {
        var columns = [];
        columns.push({
            dataField: "0",
            text: "Bezeichnung/Jahr",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '300px', textAlign: 'left' };
            },

            style: (colum: any, colIndex: any) => {
                return { width: '300px' };
            },            
            formatter: (cellContent: number|undefined|any, row: any) => {                    
                return (
                    cellContent !== undefined ?
                        <div className="kslplus-tabellen-data">{cellContent.text} </div>
                        :
                        <div className="kslplus-tabellen-data"></div>
                );

            }
        });

        
        if (sets == null || sets[0].datens === undefined)
            return columns;
        
        // Ersten Datensatz mit Daten finden
        var xset = sets.find(s => s.datens && s.datens.length > 0 );
        if ( !xset || !xset.datens )
            return columns;

        //for (var i = 0; i < sets[0].datens.length; i++) {
        for (var i = 0; i < xset.datens.length; i++) {
            if (xset.datens[i].x === undefined)
                continue;
            var x = xset.datens[i].x;
            var label = xset.datens[i].label;
            if (!label || label.length == 0 )
                label = "";
            else
                label = label + " ";

            if (x === undefined)
                continue;

            columns.push({
                dataField:  (i+1).toString(),
                text: label + x.toString() + (unit && unit.length > 0?" (" + unit + ")": ""),
                headerAlign: 'right',
                align: 'right',
                formatter: (cellContent: number|undefined|any, row: any) => {                    
                    return (
                        cellContent !== undefined ?
                            <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(cellContent.text, cellContent.nks !== undefined ? cellContent.nks : decimalPlaces, 3, '.', ',')} </div>
                            :
                            <div className="kslplus-tabellen-data"></div>
                    );

                }
                
                
            });
        }

        return columns;
    }
    
    createCombinedTableData = (sets: KSLPlusAPI.Datensaetze[] | undefined, headerX: string, headerY: string) => {
        var grdata: any[] = [];
        //grdata.push([headerX, headerY]);

        if (sets === undefined)
            return grdata;

        if (sets[0].datens === undefined)
            return grdata;

        var x: number[] = [];

        // Ersten Datensatz mit Daten finden
        var xset = sets.find(s => s.datens && s.datens.length > 0 );
        if ( !xset || !xset.datens )
            return grdata;

        
        //for (var j = 0; j < sets[0].datens.length; j++) {
        for (var j = 0; j < xset.datens.length; j++) {
            var found = true;

            if (xset.datens[j].x === undefined)
                continue;

            var xVal = xset.datens[j].x;

            for (var i = 0; i < sets.length; i++) {

                var data = sets[i].datens;
                if ( !data || !data.length )
                    continue;

                if (data === undefined || data.find(d => xset && xset.datens !== undefined && d.x == xVal) === undefined) {
                    found = false;
                    break;
                }
            }
            if (found && xVal !== undefined) {
                x.push( xVal );                
            }
        }
                               
        for (var i = 0; i < sets.length; i++) {
            var row: {[key: string]: {[key: string]: any}} = {};
            
            row[0] = {};
            row[0].text = sets[i].bezeichnung;
            //row.push({ i: sets[i].bezeichnung });
            for (var j = 0; j < x.length; j++) {
                var data = sets[i].datens;
                if (data === undefined)
                {
                    
                    continue;
                }
                row[j+1] = {}
                var pair = data.find(d => d.x == x[j])
                if (pair && pair.y !== undefined) {                    
                    row[j+1].text = pair.y;
                    row[j+1].nks = sets[i].nachkommastellen;
                    //row.push(pair.y);                    
                }
                else
                { 
                    row[j+1].text = undefined;                    
                }

                
            }
            
            grdata.push(row);
        }
        
        return grdata;
    }

    createCombinedGraphData = (sets: KSLPlusAPI.Datensaetze[] | undefined, headerX: string, headerY: string, unit: string, columnHeaders: string[], min: any[], max: any[] ) => {
        var grdata: any[] = [];
        //grdata.push([headerX, headerY]);

        if (sets === undefined)
            return grdata;

        var x: number[] = [];
        var labels: string[] = [];
        
        var header = [headerX];


        for (var j = 0; j < sets.length; j++) {
            var t = j < columnHeaders.length ? columnHeaders[j] : sets[j].bezeichnung;
            header.push(t !== undefined ? t + unit : "");
        }
        grdata.push(header);

        if (sets[0].datens === undefined)
            return grdata;

        for (var j = 0; j < sets[0].datens.length; j++) {
             var found = true;

            
            if (sets[0].datens[j].x === undefined)
                continue;

            var xVal = sets[0].datens[j].x;
            var label = sets[0].datens[j].label;

            for (var i = 1; i < sets.length; i++) {
                var data = sets[i].datens;
                if (data === undefined || data.find(d => sets[0].datens !== undefined && d.x == xVal) === undefined) {
                    found = false;
                    break;
                }                
            }
            if (found && xVal !== undefined) {
                x.push(xVal);
                labels.push(label ? label + " " : "");
            }
        }

        

        for (var j = 0; j < x.length; j++) {
            var row: any[] = [];
            row.push(labels[j] +  x[j].toString());
            for (var i = 0; i < sets.length; i++) {
                var data = sets[i].datens;
                if (data === undefined)
                    continue;

                var pair = data.find(d => d.x == x[j])
                if (pair && pair.y !== undefined) {
                    row.push(pair.y);
                }
            }
            grdata.push(row);
        }

        for (var i = 1; i < grdata.length; i++) {
            for (var j = 1; j < grdata[i].length; j++) {
                if (grdata[i][j] < min[0])
                    min[0] = grdata[i][j];
                if (grdata[i][j] > max[0])
                    max[0] = grdata[i][j];
            }

        }
                        
        return grdata;
    }

    createDataSetsItem = (sets: KSLPlusAPI.Datensaetze[], headerY: string, unit: string, columnHeaders: string[], colors: string[]|undefined, decimalPlaces: number) => {        
        
        var sets2graph = sets.filter(s => !s.keinDiagramm);        
        var series = sets2graph.map(s => { return { targetAxisIndex: s.axis !== undefined ? s.axis - 1 : 0 } });
        var max: any[] = [-Number.MAX_VALUE, -Number.MAX_VALUE];
        var min: any[] = [Number.MAX_VALUE, Number.MAX_VALUE];
        var data: any[] = [];
        if (sets2graph.length > 0)
            data = this.createCombinedGraphData(sets2graph, "Jahr", headerY, unit.length > 0 ? " (" + unit + ")" : "", columnHeaders, min, max);
        var graphOpt = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);
        return (                            
            <div>                
                <BootstrapTable ref={(n: any) => this.node = n} keyField="0" data={this.createCombinedTableData(sets, "bezeichnung", "")} columns={this.createDataSetColumns(sets, decimalPlaces, unit)} />
                {sets2graph.length > 0 ?
                <div className="ksl-grafik-container">
                    <Chart
                        width="100%"
                        height={'300px'}
                        chartType="ColumnChart"
                        chartLanguage='de'
                        loader={<div>Loading Chart</div>}
                        data={data}
                        options={{
                            colors: colors,
                            vAxes: { 0: { title: this.props.tab.axis1Bezeichnung ? this.props.tab.axis1Bezeichnung : "", minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max0, min: graphOpt.min0 } }, 1: { title: this.props.tab.axis2Bezeichnung ? this.props.tab.axis2Bezeichnung : "", minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max1, min: graphOpt.min1 } } },                            
                            hAxis: { title: 'Jahr', format: "####" },                            
                            series: series
                        }}
                        formatters={Utilities.createFormatters1(sets.length, sets)}
                        rootProps={{ 'data-testid': '2' }}
                    />
                </div> : null
                }
            </div>                            
            )
    }


    createDataSetsItemMirrored = (sets: KSLPlusAPI.Datensaetze[], headerY: string, unit: string, columnHeaders: string[], colors: string[] | undefined, decimalPlaces: number) => {
        var sets1 = sets.filter(s => !s.proEinwohner && !s.keinDiagramm);        
        var sets2 = sets.filter(s => s.proEinwohner && !s.keinDiagramm);
        var series1 = sets1.map(s => { return { targetAxisIndex: s.axis !== undefined ? s.axis - 1 : 0 } });
        var series2 = sets2.map(s => { return { targetAxisIndex: s.axis !== undefined ? s.axis - 1 : 0 } });
        var max: any[] = [-Number.MAX_VALUE, -Number.MAX_VALUE];
        var min: any[] = [Number.MAX_VALUE, Number.MAX_VALUE];
        var data1: any[] = [];
        if ( sets1.length > 0 )
            data1 = this.createCombinedGraphData(sets1, "Jahr", headerY, unit.length > 0 ? " (" + unit + ")" : "", columnHeaders, min, max);

        var graphOpt1 = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);
        max = [-Number.MAX_VALUE, -Number.MAX_VALUE];
        min = [Number.MAX_VALUE, Number.MAX_VALUE];
        var data2: any[] = [];
        if (sets2.length > 0)
            data2 = this.createCombinedGraphData(sets2, "Jahr", headerY, unit.length > 0 ? " (" + unit + ")" : "", columnHeaders, min, max);
        var graphOpt2 = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);

        return (
            <div>
                <BootstrapTable ref={(n: any) => this.node = n} keyField="0" data={this.createCombinedTableDataMirrored(sets, "bezeichnung", "")} columns={this.createDataSetColumnsMirrored(sets, columnHeaders, decimalPlaces, unit)} />
                {sets1.length > 0 &&
                    <div className="ksl-grafik-container">
                        <Chart
                            width="100%"
                            height={'300px'}
                            chartType="ColumnChart"
                            chartLanguage='de'
                            loader={<div>Loading Chart</div>}
                            data={data1}
                            options={{
                                colors: colors,
                                vAxes: { 0: { title: this.props.tab.axis1Bezeichnung ? this.props.tab.axis1Bezeichnung : "", minValue: graphOpt1.minG, maxValue: graphOpt1.maxG, viewWindow: { max: graphOpt1.max0, min: graphOpt1.min0 } }, 1: { title: this.props.tab.axis2Bezeichnung ? this.props.tab.axis2Bezeichnung : "", minValue: graphOpt1.minG, maxValue: graphOpt1.maxG, viewWindow: { max: graphOpt1.max1, min: graphOpt1.min1 } } },
                                hAxis: { title: 'Jahr', format: "####" },
                                series: series1
                            }}
                            formatters={Utilities.createFormatters1(sets1.length, sets1)}
                            rootProps={{ 'data-testid': '2' }}
                        />
                    </div>
                }
                {sets2.length > 0 &&
                    <div className="ksl-grafik-container">
                        <Chart
                            width="100%"
                            height={'300px'}
                            chartType="ColumnChart"
                            chartLanguage='de'
                            loader={<div>Loading Chart</div>}
                            data={data2}
                            options={{
                                colors: colors,
                                vAxes: { 0: { title: this.props.tab.axis1Bezeichnung ? this.props.tab.axis1Bezeichnung : "", minValue: graphOpt2.minG, maxValue: graphOpt2.maxG, viewWindow: { max: graphOpt2.max0, min: graphOpt2.min0 } }, 1: { title: this.props.tab.axis2Bezeichnung ? this.props.tab.axis2Bezeichnung : "", minValue: graphOpt2.minG, maxValue: graphOpt2.maxG, viewWindow: { max: graphOpt2.max1, min: graphOpt2.min1 } } },
                                hAxis: { title: 'Jahr', format: "####" },
                                series: series2
                            }}
                            formatters={Utilities.createFormatters(sets2.length, decimalPlaces)}
                            rootProps={{ 'data-testid': '2' }}
                        />
                    </div>
                }
            </div>
        )
    }



    createDataSetItems = (sets: KSLPlusAPI.Datensaetze[]) => {                
        var ownsets = sets.filter(s => s.tabId == this.props.tab.tabId);        
        
        if ( ownsets.length && ownsets[0].typ !== undefined )
        {    
            if ( ownsets[0].typ < 15  )        
                return this.createDataSetsItem(ownsets, ownsets[0].bezeichnung?ownsets[0].bezeichnung:"", ownsets[0].einheit?ownsets[0].einheit:"", [], ["#264d99", "green", "#dc3912", "orange"], ownsets[0].nachkommastellen?ownsets[0].nachkommastellen:0);
            else if ( ownsets[0].typ > 14 && ownsets[0].typ < 23)
                return this.createDataSetsItemMirrored(ownsets, ownsets[0].bezeichnung?ownsets[0].bezeichnung:"", ownsets[0].einheit?ownsets[0].einheit:"", [], ["#264d99", "green", "#dc3912", "orange"], ownsets[0].nachkommastellen?ownsets[0].nachkommastellen:0);                
            else if ( ownsets[0].typ > 22 && ownsets[0].typ < 30 )        
            {
                if ( ownsets[0].typ === 27 ) // Schuldendienst
                    return this.createDataSetsItemMirrored(ownsets, ownsets[0].bezeichnung?ownsets[0].bezeichnung:"", ownsets[0].einheit?ownsets[0].einheit:"", [], ["#dc3912", "green", "orange"], ownsets[0].nachkommastellen?ownsets[0].nachkommastellen:0);                
                else // Kredit
                    return this.createDataSetsItemMirrored(ownsets, ownsets[0].bezeichnung?ownsets[0].bezeichnung:"", ownsets[0].einheit?ownsets[0].einheit:"", [], ["#264d99", "#dc3912", "green", "orange"], ownsets[0].nachkommastellen?ownsets[0].nachkommastellen:0);                
            }
        }

        return null;        
    };

    
    render() {         

        /*{tabItems.navItems.length > 1 &&                                                        
                        <Dropdown nav isOpen={this.state.dropDownOpen} toggle={() => this.setState({dropDownOpen: !this.state.dropDownOpen})}>                            
                            <DropdownToggle nav caret className={this.state.activeMainTab.indexOf("tab") == 0 ? "active" : ""}>               
                                <div className="kslplus-reiter-text-nebenzeile">{this.props.tab.bezeichnung}</div>                 
                                {this.state.tab}
                            </DropdownToggle>
                            <DropdownMenu>
                                {tabItems.navItems}     
                            </DropdownMenu>
                        </Dropdown>                                
                    }
                    {tabItems.navItems.length < 2 &&        
                        tabItems.navItems
                    }
        */
        var tabItems = this.createTabItems(this.tabs);
        var beschreibungen = this.createBeschreibungsItems(this.texte); 
        //var setItems = this.createDataSetItems(this.props.datasets);
        return (
            
            <div className={this.props.className}>
                {beschreibungen}
                <Nav tabs>                    
                    
                    {tabItems.navItems}     
                </Nav>                
                <TabContent activeTab={this.state.activeMainTab}>
                    {tabItems.contentItems}
                </TabContent>
                {this.createDataSetItems(this.props.datasets)}
            </div>
        );
    }
}
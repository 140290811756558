import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import { Tooltip, Button, Modal, ModalBody, ModalHeader, ModalFooter 
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

type State = {            
}

type Props = {
    header: string,
    text: string
    open: boolean,
    b1: string,    
    b2: string,        
    onB1: () => void|undefined;
    onB2: () => void|undefined;
    onCancel:() => void;
}

export class MessageBox extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        
    }

    
    static defaultProps = {
        b2: "",
        onB2: undefined        
    }

    state: State = {
        
         
    }

    

    render() {        
        return (
                <Modal className="kslplus-modal-Mmessagebox" size="xs" isOpen={this.props.open} toggle={this.props.onCancel}>
                    <ModalHeader toggle={this.props.onCancel}>{this.props.header}</ModalHeader>
                    <ModalBody>
                        <div>{this.props.text}</div>
                    </ModalBody>
                    <ModalFooter>
                        {this.props.onB1 && <Button color="primary" onClick={this.props.onB1}>{this.props.b1}</Button>}
                        {this.props.onB2 && <Button color="primary" onClick={this.props.onB2}>{this.props.b2}</Button>}
                    </ModalFooter>
                </Modal>
                
        );
    }
}

import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, UncontrolledTooltip, Badge
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
// @ts-ignore
import  filterFactory, { selectFilter, multiSelectFilter, textFilter  } from 'react-bootstrap-table2-filter';

import { Chart } from "react-google-charts";

import { GoogleChartWrapperChartType } from "react-google-charts/dist/types";



import * as Utilities from "./Utilities"

import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

import Collapse from "@kunukn/react-collapse";

type State = {
    hiddenRows: (string|undefined)[]
}

type Props = {    
    jahr: number,
    ermebene: KSLPlusAPI.ErmEbene,
    auszahlungen: boolean,
    settings: KSLPlusAPI.SettingsModel    
}



export class Ermaechtigungen extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
        
    }

    state: State = {                           
        hiddenRows: []        
    }              
    

    node: any;        
    container: any = null;
    

    createGraphData = ( typ: number ) => {
        var grdata: any[] = [];
        //grdata.push([headerX, headerY]);

        

        var x: number[] = [];
        
        var header = ["Jahr"];
        //var bez = (zeile.bezeichnung ? zeile.bezeichnung : "") + " (" + (zeile.einheit ? zeile.einheit : "") + ")";        
        

        var vorWert: number|undefined = undefined;
        
        if ( typ == 0 )
        {
            header.push("Erträge (EUR)");
            header.push("Aufwendungen (EUR)");               
            grdata.push(header);

            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 4) >= 0) && this.props.ermebene.hrfJ_PJM4_haben !== undefined && this.props.ermebene.hrfJ_PJM4_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-4).toString()];
                row.push(this.props.ermebene.hrfJ_PJM4_haben);            
                row.push(this.props.ermebene.hrfJ_PJM4_soll);            
                grdata.push(row);
            }

            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) && this.props.ermebene.hrfJ_PJM3_haben !== undefined && this.props.ermebene.hrfJ_PJM3_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-3).toString()];
                row.push(this.props.ermebene.hrfJ_PJM3_haben);            
                row.push(this.props.ermebene.hrfJ_PJM3_soll);            
                grdata.push(row);
            }

            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) && this.props.ermebene.hrfJ_PJM2_haben !== undefined && this.props.ermebene.hrfJ_PJM2_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-2).toString()];
                row.push(this.props.ermebene.hrfJ_PJM2_haben);            
                row.push(this.props.ermebene.hrfJ_PJM2_soll);            
                grdata.push(row);
            }
        
            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) && this.props.ermebene.hrfJ_PJM1_haben !== undefined && this.props.ermebene.hrfJ_PJM1_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-1).toString()];
                row.push(this.props.ermebene.hrfJ_PJM1_haben);            
                row.push(this.props.ermebene.hrfJ_PJM1_soll);            
                grdata.push(row);
            }

            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr) >= 0) && this.props.ermebene.hrfJ_haben !== undefined && this.props.ermebene.hrfJ_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr).toString()];
                row.push(this.props.ermebene.hrfJ_haben);            
                row.push(this.props.ermebene.hrfJ_soll);            
                grdata.push(row);
            }
        }
        else if ( typ == 1 )
        {
            header.push("Erträge (EUR)");
            header.push("Aufwendungen (EUR)");               
            grdata.push(header);

            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 4) >= 0) && this.props.ermebene.hrfJ_PJM4_Erg_haben !== undefined && this.props.ermebene.hrfJ_PJM4_Erg_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-4).toString()];
                row.push(this.props.ermebene.hrfJ_PJM4_Erg_haben);            
                row.push(this.props.ermebene.hrfJ_PJM4_Erg_soll);            
                grdata.push(row);
            }

            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) && this.props.ermebene.hrfJ_PJM3_Erg_haben !== undefined && this.props.ermebene.hrfJ_PJM3_Erg_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-3).toString()];
                row.push(this.props.ermebene.hrfJ_PJM3_Erg_haben);            
                row.push(this.props.ermebene.hrfJ_PJM3_Erg_soll);            
                grdata.push(row);
            }

            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) && this.props.ermebene.hrfJ_PJM2_Erg_haben !== undefined && this.props.ermebene.hrfJ_PJM2_Erg_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-2).toString()];
                row.push(this.props.ermebene.hrfJ_PJM2_Erg_haben);            
                row.push(this.props.ermebene.hrfJ_PJM2_Erg_soll);            
                grdata.push(row);
            }
        
            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) && this.props.ermebene.hrfJ_PJM1_Erg_haben !== undefined && this.props.ermebene.hrfJ_PJM1_Erg_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr-1).toString()];
                row.push(this.props.ermebene.hrfJ_PJM1_Erg_haben);            
                row.push(this.props.ermebene.hrfJ_PJM1_Erg_soll);            
                grdata.push(row);
            }

            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr) >= 0) && this.props.ermebene.hrfJ_Erg_haben !== undefined && this.props.ermebene.hrfJ_Erg_soll !== undefined)
            {   
                var row: any[] = [(this.props.jahr).toString()];
                row.push(this.props.ermebene.hrfJ_Erg_haben);            
                row.push(this.props.ermebene.hrfJ_Erg_soll);            
                grdata.push(row);
            }
        }
        else if ( typ == 2 )
        {
            header.push("Aktiva (EUR)");
            header.push("Passiva (EUR)");               
            grdata.push(header);

            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 4) >= 0) && this.props.ermebene.hrfJ_PJM4_Bilanz_aktiva !== undefined && this.props.ermebene.hrfJ_PJM4_Bilanz_passiva !== undefined)
            {   
                var row: any[] = [(this.props.jahr-4).toString()];
                row.push(this.props.ermebene.hrfJ_PJM4_Bilanz_aktiva);            
                row.push(this.props.ermebene.hrfJ_PJM4_Bilanz_passiva);            
                grdata.push(row);
            }

            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) && this.props.ermebene.hrfJ_PJM3_Bilanz_aktiva !== undefined && this.props.ermebene.hrfJ_PJM3_Bilanz_passiva !== undefined)
            {   
                var row: any[] = [(this.props.jahr-3).toString()];
                row.push(this.props.ermebene.hrfJ_PJM3_Bilanz_aktiva);            
                row.push(this.props.ermebene.hrfJ_PJM3_Bilanz_passiva);            
                grdata.push(row);
            }

            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) && this.props.ermebene.hrfJ_PJM2_Bilanz_aktiva !== undefined && this.props.ermebene.hrfJ_PJM2_Bilanz_passiva !== undefined)
            {   
                var row: any[] = [(this.props.jahr-2).toString()];
                row.push(this.props.ermebene.hrfJ_PJM2_Bilanz_aktiva);            
                row.push(this.props.ermebene.hrfJ_PJM2_Bilanz_passiva);            
                grdata.push(row);
            }
        
            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) && this.props.ermebene.hrfJ_PJM1_Bilanz_aktiva !== undefined && this.props.ermebene.hrfJ_PJM1_Bilanz_passiva !== undefined)
            {   
                var row: any[] = [(this.props.jahr-1).toString()];
                row.push(this.props.ermebene.hrfJ_PJM1_Bilanz_aktiva);            
                row.push(this.props.ermebene.hrfJ_PJM1_Bilanz_passiva);            
                grdata.push(row);
            }

            if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr) >= 0) && this.props.ermebene.hrfJ_Bilanz_aktiva !== undefined && this.props.ermebene.hrfJ_Bilanz_passiva !== undefined)
            {   
                var row: any[] = [(this.props.jahr).toString()];
                row.push(this.props.ermebene.hrfJ_Bilanz_aktiva);            
                row.push(this.props.ermebene.hrfJ_Bilanz_passiva);            
                grdata.push(row);
            }
        }
                        
        return grdata;
    }


    createColumns = () => {
        var columns = [];


        var id = 1;
        /*
        columns.push({
            dataField: 'verbergen',
            text: "",
            headerStyle: (colum: any, colIndex: any) => {
                return { maxWidth: '20px', width: '20px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.KREbene) => {                                
                if ( row.isHHS || row.gesamtSumme  )
                    return null;
                
                
                if ( row.verbergen  )
                {
                    return (                                            
                        <div className="kslplus-row-sum">(+)</div>
                    );
                }
                else
                {
                    return (                                            
                        <div className="kslplus-row-sum">(-)</div>
                    );
                }
                

            }
        });

        
        columns.push({
            dataField: 'isBilanz',
            text: "Haushalt",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '75px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.ErmEbene) => {                                
                if ( row.gesamtSumme )
                {
                    return (                                            
                        <div className="kslplus-row-sum">Gesamt</div>
                    );
                }
                else
                {
                    return (                                            
                        <div className={row.isHHS ? "" : "kslplus-row-sum"}>{row.isBilanz ? "Bilanz" : "Ergebnishaushalt" }</div>
                    );
                }

            },            
            filter: selectFilter({
                options: {
                    false: 'Ergebnishaushalt',
                    true: 'Bilanz'
                },
                onFilter: (filterVal:string, data: KSLPlusAPI.ErmEbene[]) => {
                    
                    if ( filterVal === "" )
                        return data;                    
                    else if ( filterVal == "false" )
                        return data.filter(row => !row.gesamtSumme && !row.isBilanz);                    
                    else
                        return data.filter(row => !row.gesamtSumme && row.isBilanz);                    
                }    
            })  
        });
        
        id++;
        columns.push({
            dataField: 'istEinnahme',
            text: "Ert./Aufw./Akt./Pas.",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '85px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.ErmEbene) => {                                
                if ( row.isHHS )
                {
                    if ( row.isBilanz )
                        return (                                            
                            <div>{row.istEinnahme ? "Aktiva" : "Passiva" }</div>
                        );
                    else
                        return (                                            
                            <div>{row.istEinnahme ? "Ertrag" : "Aufwendung" }</div>
                        );
                }
                else
                {
                    if ( row.isBilanz )
                        return (                                            
                            <div className="kslplus-row-sum">{row.istEinnahme ? "Summe Aktiva" : "Summe Passiva" }</div>
                        );
                    else
                        return (                                            
                            <div className="kslplus-row-sum">{row.istEinnahme ? "Summe Erträge" : "Summe Aufwendungen" }</div>
                        );
                }

            },            
            filter: selectFilter({
                options: {
                    0: 'Erträge',
                    1: 'Aufwendungen',
                    2: 'Aktiva',
                    3: 'Passiva'
                
                },
                onFilter: (filterVal:string, data: KSLPlusAPI.ErmEbene[]) => {
                    
                    if ( filterVal === "" )
                        return data;                    
                    else if ( filterVal == "0" )
                        return data.filter(row => row.istEinnahme && !row.isBilanz);                    
                    else if ( filterVal == "1" )
                        return data.filter(row => !row.istEinnahme && !row.isBilanz);                    
                    else if ( filterVal == "2" )
                        return data.filter(row => row.istEinnahme && row.isBilanz);                    
                    else if ( filterVal == "3" )
                        return data.filter(row => !row.istEinnahme && row.isBilanz);                    
                }  
                
            })  
                      
        });
        */
        id++;
        columns.push({
            dataField: 'bezeichnung',
            text: "Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '120px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.ErmEbene) => {                                
                return (                                            
                    <div>{/*row.isHHS &&*/ row.bezeichnung !== undefined ? row.bezeichnung : ""}</div>
                );

            },            
            filter: textFilter(
                { onFilter: (filterVal:string, data: KSLPlusAPI.ErmEbene[]) => {
                    
                    if ( filterVal === "" )
                        return data;                    
                    else 
                        return data.filter(row =>  !row.bezeichnung || !row.isHHS || row.bezeichnung.toLowerCase().indexOf(filterVal.toLowerCase()) !== -1);                                        
                } } 
            )
        });

        id++;
        columns.push({
            dataField: 'produkt',
            text: "Produkt",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '60px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.ErmEbene) => {                                
                return (                                            
                    <div>{row.isHHS && row.produkt !== undefined ? Utilities.Num2Str(row.produkt, row.pStellen) : ""}</div>
                );

            },            
            filter: textFilter({
                onFilter: (filterVal: string, data: KSLPlusAPI.ErmEbene[]) => {                    
                    if (filterVal)
                        return data.filter(row => !row.isHHS || (row.produkt !== undefined && Utilities.Num2Str(row.produkt, row.pStellen).indexOf(filterVal) != -1));

                    return data;
                }

            })
        });
         
        
    
        id++;
        columns.push({
            dataField: 'konto',
            text: "Konto",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '60px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.ErmEbene) => {                                
                return (                                            
                    <div>{row.isHHS && row.konto !== undefined ? Utilities.Num2Str(row.konto, row.kStellen) : ""}</div>
                );

            },            
            filter: textFilter({
                onFilter: (filterVal: string, data: KSLPlusAPI.ErmEbene[]) => {                    
                    if (filterVal)
                        return data.filter(row => !row.isHHS || (row.konto !== undefined && Utilities.Num2Str(row.konto, row.kStellen).indexOf(filterVal) != -1));

                    return data;
                }

            })
        });

        if (this.props.auszahlungen) {
            id++;
            columns.push({
                dataField: 'investNr',
                text: "Maßnahme",
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '60px', textAlign: 'left' };
                },                                
                filter: textFilter(
                    {
                        onFilter: (filterVal: string, data: KSLPlusAPI.ErmEbene[]) => {

                            if (filterVal === "")
                                return data;
                            else
                                return data.filter(row => !row.investNr || !row.isHHS || row.investNr.toLowerCase().indexOf(filterVal.toLowerCase()) !== -1);
                        }
                    }
                )
            });
        }

        
        if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 4) >= 0)  )
        {   
            id++;
            columns.push({
                dataField: 'hrfj',
                text: (this.props.jahr-4).toString(),
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '100px', textAlign: 'right' };
                },            
                align: 'right',                       
                formatter: (cellContent: string, row: KSLPlusAPI.ErmEbene) => {                                
                    var text = "";
                    if ( row.istEinnahme && row.hrfJ_haben !== undefined )
                        text = Utilities.FormatCurrency(row.hrfJ_PJM4_haben, 2, 3, '.', ',')
                    else if (!row.istEinnahme && row.hrfJ_soll !== undefined )
                        text = Utilities.FormatCurrency(row.hrfJ_PJM4_soll, 2, 3, '.', ',')

                    return (                                            
                        <div className={row.isHHS ? "" : "kslplus-row-sum"}>{text}</div>
                    );

                }
            });
        }
        
        if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) )
        {   
            id++;
            columns.push({
                dataField: 'hrfj',
                text: (this.props.jahr-3).toString(),
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '100px', textAlign: 'right' };
                },            
                align: 'right',                       
                formatter: (cellContent: string, row: KSLPlusAPI.ErmEbene) => {                                
                    var text = "";
                    if ( row.istEinnahme && row.hrfJ_haben !== undefined )
                        text = Utilities.FormatCurrency(row.hrfJ_PJM3_haben, 2, 3, '.', ',')
                    else if (!row.istEinnahme && row.hrfJ_soll !== undefined )
                        text = Utilities.FormatCurrency(row.hrfJ_PJM3_soll, 2, 3, '.', ',')

                    return (                                            
                        <div className={row.isHHS ? "" : "kslplus-row-sum"}>{text}</div>
                    );

                }
            });
        }
        
        if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) )
        {   
            id++;
            columns.push({
                dataField: 'hrfj',
                text: (this.props.jahr-2).toString(),
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '100px', textAlign: 'right' };
                },            
                align: 'right',                       
                formatter: (cellContent: string, row: KSLPlusAPI.ErmEbene) => {                                
                    var text = "";
                    if ( row.istEinnahme && row.hrfJ_haben !== undefined )
                        text = Utilities.FormatCurrency(row.hrfJ_PJM2_haben, 2, 3, '.', ',')
                    else if (!row.istEinnahme && row.hrfJ_soll !== undefined )
                        text = Utilities.FormatCurrency(row.hrfJ_PJM2_soll, 2, 3, '.', ',')

                    return (                                            
                        <div className={row.isHHS ? "" : "kslplus-row-sum"}>{text}</div>
                    );

                }
            });
        }
        
        if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) )
        {   
            id++;
            columns.push({
                dataField: 'hrfj',
                text: (this.props.jahr-1).toString(),
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '100px', textAlign: 'right' };
                },            
                align: 'right',                       
                formatter: (cellContent: string, row: KSLPlusAPI.ErmEbene) => {                                
                    var text = "";
                    if ( row.istEinnahme && row.hrfJ_haben !== undefined )
                        text = Utilities.FormatCurrency(row.hrfJ_PJM1_haben, 2, 3, '.', ',')
                    else if (!row.istEinnahme && row.hrfJ_soll !== undefined )
                        text = Utilities.FormatCurrency(row.hrfJ_PJM1_soll, 2, 3, '.', ',')

                    return (                                            
                        <div className={row.isHHS ? "" : "kslplus-row-sum"}>{text}</div>
                    );

                }
            });
        }
        
        if ((!this.props.ermebene.jahre || this.props.ermebene.jahre.findIndex(d => d == this.props.jahr) >= 0) )
        {   
            id++;
            columns.push({
                dataField: 'hrfj',
                text: this.props.jahr.toString(),
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '100px', textAlign: 'right' };
                },            
                align: 'right',                       
                formatter: (cellContent: string, row: KSLPlusAPI.ErmEbene) => {                                
                    var text = "";
                    if ( row.istEinnahme && row.hrfJ_haben !== undefined )
                        text = Utilities.FormatCurrency(row.hrfJ_haben, 2, 3, '.', ',')
                    else if (!row.istEinnahme && row.hrfJ_soll !== undefined )
                        text = Utilities.FormatCurrency(row.hrfJ_soll, 2, 3, '.', ',')

                    return (                                            
                        <div className={row.isHHS ? "" : "kslplus-row-sum"}>{text}</div>
                    );

                }
            });
        }

        return columns;
    }
    
    hideRows = (rows: KSLPlusAPI.ErmEbene[]|undefined) => {
        if ( this.props.ermebene && this.props.ermebene.unterEbenen ) {
            const rows1 = this.props.ermebene.unterEbenen.filter(d => !rows || d.isHHS && (rows && rows.findIndex(z => d.istEinnahme === z.istEinnahme && d.isBilanz === z.isBilanz) >= 0 ) );
            rows1.forEach(r => r.verbergen = true);
            this.setState({ hiddenRows: this.props.ermebene.unterEbenen.filter(d => d.isHHS && d.verbergen).map(d => d.id) });            
        }
    }

    showRows = (rows: KSLPlusAPI.ErmEbene[]|undefined) => {
         if ( this.props.ermebene && this.props.ermebene.unterEbenen ) {
            const rows1 = this.props.ermebene.unterEbenen.filter(d => !rows || d.isHHS && (rows && rows.findIndex(z => d.istEinnahme === z.istEinnahme && d.isBilanz === z.isBilanz) >= 0 ) );
            rows1.forEach(r => r.verbergen = false);
            this.setState({ hiddenRows: this.props.ermebene.unterEbenen.filter(d => d.isHHS && d.verbergen).map(d => d.id) });            
        }
    }

    rowEvents = {
        onClick: (e:any, row: KSLPlusAPI.ErmEbene, rowIndex:number) => 
        {
            if ( this.props.ermebene && this.props.ermebene.unterEbenen && !row.isHHS && !row.gesamtSumme) {             
                const rows = this.props.ermebene.unterEbenen.filter(d => d.isHHS && (row.istEinnahme === d.istEinnahme && row.isBilanz === d.isBilanz) );              
                if (this.props.ermebene.unterEbenen.findIndex(d => rows.findIndex(z => d.istEinnahme === z.istEinnahme && d.isBilanz === z.isBilanz) >= 0 && d.verbergen) >= 0) {
                    row.verbergen = !row.verbergen;
                    this.showRows(rows);
                }                    
                else {
                    row.verbergen = !row.verbergen;
                    this.hideRows(rows);
                }            
            }
        }
    };

    componentDidUpdate(prevProps: Props) {        
        if ( prevProps.ermebene != this.props.ermebene && this.props.ermebene && this.props.ermebene.unterEbenen )
            this.setState({ hiddenRows: this.props.ermebene.unterEbenen.filter(d => d.isHHS && d.verbergen).map(d => d.id)  });
    }

    componentDidMount() {        
        if ( this.props.ermebene && this.props.ermebene.unterEbenen )
        {            
            this.setState({ hiddenRows: this.props.ermebene.unterEbenen.filter(d => d.isHHS && d.verbergen).map(d => d.id)  });
        }
    }

    render() {

        var jahrStart = "";
        if (this.props.ermebene.jahre && this.props.ermebene.jahre.length > 0)
            jahrStart = this.props.ermebene.jahre[0].toString();

        var jahrEnd = "";
        if (this.props.ermebene.jahre && this.props.ermebene.jahre.length > 0)
            jahrEnd = this.props.ermebene.jahre[this.props.ermebene.jahre.length - 1].toString();

        return (

            <div>
                <div className="kslplus-content">
                    {this.props.ermebene.beschreibung && this.props.ermebene.beschreibung.length &&
                        <Row>
                            <Col>
                                <div className="kslplus-headline-third">{this.props.ermebene.beschreibung}</div>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col>
                            <BootstrapTable hover={true} bordered={true} keyField='id' data={this.props.ermebene.unterEbenen} columns={this.createColumns()} filter={filterFactory()} filterPosition="top" hiddenRows={this.state.hiddenRows} rowEvents={this.rowEvents} />

                        </Col>
                    </Row>


                    


                </div>
            </div>
        );
    }

    render1() {         
        
        var jahrStart = "";
        if ( this.props.ermebene.jahre && this.props.ermebene.jahre.length > 0 )
            jahrStart = this.props.ermebene.jahre[0].toString();

        var jahrEnd = "";
        if ( this.props.ermebene.jahre && this.props.ermebene.jahre.length > 0 )
            jahrEnd = this.props.ermebene.jahre[this.props.ermebene.jahre.length-1].toString();

        return (
            
                <div>                                        
                    <div className="kslplus-content">                                                                                              
                        
                        <Row>
                            <Col>
                                <BootstrapTable hover={true} bordered={true} keyField='id' data={this.props.ermebene.unterEbenen} columns={this.createColumns()} filter={filterFactory()} filterPosition="top" hiddenRows={this.state.hiddenRows} rowEvents={ this.rowEvents }/>

                            </Col>
                        </Row>    
                        
                        
                        <Row>
                            <Col>
                                <div className="kslplus-headline-third">{"Entwicklung Ermächtigungen Ergebnishaushalt  " + jahrStart + " bis " + jahrEnd}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                    
                                <div className="ksl-grafik-container">
                                <Chart
                                    width="100%"
                                    height={'300px'}
                                    chartType= "ColumnChart"
                                    chartLanguage='de'
                                    loader={<div>Loading Chart</div>}
                                    data={this.createGraphData(1)}                                
                                    options={{                                      
                                        colors: [ '#264d99', '#dc3912'],
                                        vAxes: { 0: { title: "EUR", minValue: 0 } },                                
                                        hAxis: { title: 'Jahr', format: "####" },                                                         
                                        series: { 0: {  type: "bars", targetAxisIndex: 0 }}
                                        }}                            
                                    formatters={Utilities.createFormatters(2 , 2)}

                                    rootProps={{ 'data-testid': '2' }}
                                />
                            </div>
                         </Col>
                        </Row>
                              
                        <Row>
                            <Col>
                                <div className="kslplus-headline-third">{"Entwicklung Ermächtigungen Bilanz  " + jahrStart + " bis " + jahrEnd}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                    
                                <div className="ksl-grafik-container">
                                <Chart
                                    width="100%"
                                    height={'300px'}
                                    chartType= "ColumnChart"
                                    chartLanguage='de'
                                    loader={<div>Loading Chart</div>}
                                    data={this.createGraphData(2)}                                
                                    options={{                                      
                                        colors: [ '#264d99', '#dc3912'],
                                        vAxes: { 0: { title: "EUR", minValue: 0 } },
                                        hAxis: { title: 'Jahr', format: "####" },                                                         
                                        series: { 0: {  type: "bars", targetAxisIndex: 0 }}
                                        }}                            
                                    formatters={Utilities.createFormatters(2 , 2)}

                                    rootProps={{ 'data-testid': '2' }}
                                />
                            </div>
                         </Col>
                        </Row>
       
                                                
                    </div>
                </div>
        );
    }
}
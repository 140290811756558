import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, UncontrolledTooltip, Badge, Nav, NavItem, NavLink, TabContent, TabPane, Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import Chart from "react-google-charts";

import { Kennzahl } from './KennzahlComp';
import { KennzahlJR } from './KennzahlJRComp';


import * as Utilities from "./Utilities"

import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";



type State = {
    ready: boolean,
    activeMainTab: string,    
    isVisible: boolean
}

type Props = {        
    jahr: number,
    kunde: number,
    gemeinde: number,    
    plantyp: number,
    planstufe: number,
    bilanz: boolean,
    uhconfig: KSLPlusAPI.Uhconfig | undefined,
    kennzahlen: KSLPlusAPI.Kennzahlen[],
    settings: KSLPlusAPI.SettingsModel,
    isVisible: boolean    
}



export class Kennzahlen extends Component<Props, State> {
    constructor(props: any) {
        super(props);
                
    }

    node: any;    
    container: any = null;

    

    
    state: State = {
        ready: false,
        activeMainTab: "1",
        isVisible: false
    }

    redrawCharts = () => {                
    }
  
    componentDidUpdate(prevProps: Props) {
        if (prevProps.isVisible !== this.props.isVisible && this.props.isVisible !== this.state.isVisible)        
            this.setState({activeMainTab: "1"});        

    }   

    componentDidMount() {
        window.addEventListener('resize', this.redrawCharts, false);
        
        //this.readKennzahlen();
    }

    /*
    readKennzahlen = () => {
        var request = new KSLPlusAPI.JahrModel();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;


        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHousholdAdmin_Client(api);


        client.getKennzahlen("", request).then((kennzahlen) => {
            this.kennzahlen = kennzahlen;
            if ( kennzahlen.length > 0 )
                this.state.kennzahl = kennzahlen[0].bezeichnung;
            this.setState({ ready: true });
        }).catch(Reason => {

        });

    }
    */

    createKennzahlItems = (kennzahlen: KSLPlusAPI.Kennzahlen[]) => {        
        var mainContentItems: JSX.Element[] = [];
        var mainNavItems: JSX.Element[] = [];

        for (var i = 0; i < kennzahlen.length; i++) {
            const tabId = (i + 1).toString();
            const kennzahl = kennzahlen[i];
            mainNavItems.push(
                <NavItem key={"kenav" + tabId}>
                    <NavLink active={this.state.activeMainTab === tabId} onClick={() => this.setState({ activeMainTab: tabId })} >
                        {kennzahl.bezeichnung}
                    </NavLink>
                </NavItem>
            );
            if (this.props.plantyp == 3 || this.props.plantyp == 4)
            {
                mainContentItems.push(
                    <TabPane tabId={tabId} key={"ketab" + tabId}>
                        <KennzahlJR kennzahl={kennzahl} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} bilanz={this.props.bilanz} uhconfig={this.props.uhconfig} />
                    </TabPane>
                );
            }
            else
            {
                mainContentItems.push(
                    <TabPane tabId={tabId} key={"ketab" + tabId}>
                        <Kennzahl kennzahl={kennzahl} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} />
                    </TabPane>
                );
            }
        }

        return { navItems: mainNavItems, contentItems: mainContentItems };
    }

    

    
    render() {         

        var dataSetItems = this.createKennzahlItems(this.props.kennzahlen);

        return (
            
            <div className="kslplus-haushaltstab-wrapper-very-bottom">                
                <Nav tabs>                    
                    {dataSetItems.navItems}
                </Nav>
                <TabContent activeTab={this.state.activeMainTab}>
                    {dataSetItems.contentItems}
                </TabContent>
            </div>
        );
    }
}
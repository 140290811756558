import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import { Tooltip
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

type State = {
    username: string,    
}

type Props = {
    onGoBack: () => void,
    onSubmit: (username:string) => void
}

export class ForgotPW extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.handleUsernameChange = this.handleUsernameChange.bind(this);        
    }

    handleUsernameChange(event: ChangeEvent<HTMLInputElement>) {
        if ( event.target != null )
            this.setState({ username: event.target.value });
    }

    
    state: State = {
        username: ""    
    }

    handleSubmit = () => {        
        this.props.onSubmit(this.state.username);
    };

    render() {
        return (
                <form>
                <h3>KSLplus Passwort vergessen</h3>

                <div className="form-group">
                    <label>Nutzername</label>
                    <input type="string" className="form-control" placeholder="Nutzername" onChange={this.handleUsernameChange} value={this.state.username}/>
                </div>
                <div className="kslplus-model-button-container">
                    <button id="ksl-button-forgotpw-submit" type="button" className="btn btn-primary btn-block" onClick={() => this.handleSubmit()}>Übermitteln</button>
                    <button id="ksl-button-forgotpw-back" type="button" className="btn btn-primary btn-block" onClick={this.props.onGoBack}>Zurück</button>                
                </div>
                </form>
                
        );
    }
}
import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Badge
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

type State = {
    ready: boolean;
    
}

type Props = {    
    kunde: number | undefined,
    gemeinde: number | undefined,
    jahr: number | undefined, 
    modul: number   
}



export class Dokumente extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
        this.doktree = undefined;
    }

    node: any;
    doktree: KSLPlusAPI.DokTreeModel|undefined;

    state: State = {
        ready: false

    }

  
    componentDidMount() {
        this.readData();
    }

    readData = () => {
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        jahr = this.props.jahr;
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHoushold_Client(api);
        
        client.dokumenttree(kunde, gemeinde, jahr, this.props.modul, "").then((doktree) => {
            this.doktree = doktree;
            this.setState({ ready: true });            
        }).catch(Reason => {            
        });


    }

    createTreeItems = (doktree: KSLPlusAPI.DokTreeModel[], id: string) => {
        var items = Object.keys(doktree).map((key, index) => {
            const item = Number(key);
            const dtm = doktree[key as any];
            
            if ( !dtm.dokOrdner || !dtm.dokOrdner.bezeichnung )
                return null;

            const nid = id + "_" + item;            
            var subItems: (JSX.Element|null)[]  = [];
            
            if ( dtm.unterordner != null )
                subItems = this.createTreeItems(dtm.unterordner, nid);

            if ( dtm.dokumente != null )
                subItems = subItems.concat(this.createDocLinks(dtm.dokumente, nid));
                       
            return (
                <TreeItem nodeId={nid} label={dtm.dokOrdner.bezeichnung} key={key}>
                    {subItems.find(s => s!=null)?subItems:null}
                </TreeItem>
            );
        });
        return items;
    };

    createDocLinks = (dokumente: KSLPlusAPI.Dokumente[], id: string) => {
        var items = Object.keys(dokumente).map((key, index) => {
            const item = Number(key);
            const dok = dokumente[key as any];
            
            if ( !dok.url || !dok.bezeichnung )
                return null;

            const nid = id + "_" + item;            
                                               
            return (
                <TreeItem nodeId={nid} label={dok.bezeichnung} onClick={() => this.handleTreeClick(dok)} key={key}
                            icon={dok.typ == 0 ? <Badge className="kslplus-info-badge ">i</Badge>:null}
                        >
                    
                </TreeItem>
            );
        });
        return items;
    };

    handleTreeClick = (dok: KSLPlusAPI.Dokumente) => {
        window.open(dok.url, "_blank");
    }

    render() {
        if (!this.state.ready)
            return null;
        
        var treeitems = this.createTreeItems(this.doktree && this.doktree.unterordner ? this.doktree.unterordner : [], "1")
        var docitems = this.createDocLinks(this.doktree && this.doktree.dokumente ? this.doktree.dokumente : [], "1")
        return (
            <div >
                <div id="kslplus-headline1" className="kslplus-headline-main">Dokumente</div>
                <div className="kslplus-dokument-container">
                    <TreeView
                        className="kslplus-dokument-tree"
                        defaultCollapseIcon={<div className="kslplus-dokument-expand">-</div>}
                        defaultExpandIcon={<div className="kslplus-dokument-expand">+</div>}                        
                        
                        >                        
                        {treeitems}
                        {docitems}
                    </TreeView>
                    </div>
            </div>
        );
    }
}
import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, UncontrolledTooltip, Badge, Nav, NavItem, NavLink, TabContent, TabPane,  UncontrolledPopover, PopoverBody, PopoverHeader, Container
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
// @ts-ignore
import  filterFactory, { selectFilter, multiSelectFilter, textFilter  } from 'react-bootstrap-table2-filter';

import Chart from "react-google-charts";

import { Kennzahl } from './KennzahlComp';


import * as Utilities from "./Utilities"

import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";



type State = {
    ready: boolean,    
    activeMainTab: string,
    invests: KSLPlusAPI.InvestitionsModelDoppik[],
    expanded: number[];
}

type Props = {        
    kunde: number,
    gemeinde: number,
    jahr: number,
    plantyp: number,
    planstufe: number,    
    invests: KSLPlusAPI.InvestitionsModelDoppik[],
    investSettings: KSLPlusAPI.InvestSetting,
    settings: KSLPlusAPI.SettingsModel
}



export class InvestitionenDoppisch extends Component<Props, State> {
    constructor(props: any) {
        super(props);
                
    }

    node: any;    
    container: any = null;

    
    vermoegensarten: KSLPlusAPI.VermoegensartDoppisch[] = [];
    investitionsarten: KSLPlusAPI.Investitionsarten[] = [];      
    getFilters : {(filter:string|undefined):void}[] = [];      
    
    
    state: State = {
        ready: false,
        activeMainTab: "1",
        invests: [],
        expanded:  []
    }

    
    componentDidMount() {        
        this.readInvestitionsarten();
    }

    createColumns = (invests: KSLPlusAPI.InvestitionsModelDoppik[], spalten: string) => {            
        var columns: any[] = [];
        
        
        columns.push(
            {
                dataField: 'investition.bezeichnung',
                text: 'Bezeichnung',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "230px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "230px" };
                },
                filter: selectFilter(
                            {   options: this.getFilterOptions(this.state.invests, "bezeichnung"), 
                                placeholder: "Alle", 
                                className: "kslplus-tabellen-multiselect", 
                                style: { width: "220px" },
                                getFilter: (filter:any) => {                                    
                                    this.getFilters.push(filter);                                    
                                } } ),                                
                formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsModelDoppik) => {
                    const ve = (this.props.investSettings.veimmer === undefined || this.props.investSettings.veimmer) || (row.investition !== undefined && row.investition.veGesamt !== undefined && row.investition.veGesamt > 0);
                    const ga = (this.props.investSettings.gaimmer === undefined || this.props.investSettings.gaimmer) || (row.investition && row.investition.gesamtbedarf !== undefined && row.investition.gesamtzuwendungen !== undefined && row.investition.bisherVereinnahmt !== undefined && row.investition.bisherVerausgabt !== undefined ? Math.abs(row.investition.gesamtbedarf) + Math.abs(row.investition.gesamtzuwendungen) + Math.abs(row.investition.bisherVereinnahmt) + Math.abs(row.investition.bisherVerausgabt) > 0 : false);
                    var beschreibungen = this.createBeschreibungsItems(row.texte, ve, ga);
                    var werte = this.createWerteItems(row, ve, ga );
                    return (
                        <div>
                                <div className="kslplus-tabellen-bezeichnung">{row.investition && row.investition.bezeichnung ? row.investition.bezeichnung : ""}</div>                                                                                 
                                {werte.length > 0 || beschreibungen.length > 0 ? <Badge className="kslplus-info-badge " id={row.investition && row.investition.id ? "kslplus-info-text-" + row.investition.id : ""}>i</Badge> : null}
                                {werte.length > 0 || beschreibungen.length > 0 ?
                                    <UncontrolledPopover
                                        size="lg"
                                        placement="auto"
                                        target={row.investition && row.investition.id ? "kslplus-info-text-" + row.investition.id : ""}
                                        trigger="hover"
                                        className="kslplus-invest-popover">
                                        <PopoverBody className="kslplus-invest-popover-body">
                                            <Container className={(this.props.investSettings.veimmer === undefined || this.props.investSettings.veimmer) || (row.investition !== undefined && row.investition.veGesamt !== undefined && row.investition.veGesamt > 0) ? "kslplus-invest-popover-block" : "kslplus-invest-popover-block-small"}>
                                                <div className="kslplus-invest-popover-container-beschreibungen">
                                                    {beschreibungen}
                                                </div>
                                                <div className="kslplus-invest-popover-container-werte">
                                                    {werte}
                                                </div>
                                            </Container>
                                        </PopoverBody>
                                    </UncontrolledPopover> : null
                                }
                        </div>

                    );
                },
                sort: true,
                sortCaret: (order:string, column:any) => {
                    if (!order) return (<span className="kslplus-header-sort">&nbsp;&nbsp;&#8595;&#8593;</span>);
                    else if (order === 'asc') return (<span className="kslplus-header-sort-up">&nbsp;&nbsp;&#8593;</span>);
                    else if (order === 'desc') return (<span className="kslplus-header-sort-down">&nbsp;&nbsp;&#8595;</span>);
                    return null;
                }
                
                    
            }
        );
        columns.push(
            {
                dataField: 'investition.plz',
                text: 'PLZ',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                filter: selectFilter({ 
                    options: this.getFilterOptions(this.state.invests, "plz"),
                    placeholder: "Alle",
                    className: "kslplus-tabellen-multiselect",
                    style: { width: "85px" },
                    getFilter: (filter:any) => { this.getFilters.push(filter) }
                }),
                sort: true,
                sortCaret: (order: string, column: any) => {
                    if (!order) return (<span className="kslplus-header-sort">&nbsp;&nbsp;&#8595;&#8593;</span>);
                    else if (order === 'asc') return (<span className="kslplus-header-sort-up">&nbsp;&nbsp;&#8593;</span>);
                    else if (order === 'desc') return (<span className="kslplus-header-sort-down">&nbsp;&nbsp;&#8595;</span>);
                    return null;
                }
                
            }
        );

        columns.push(
            {
                dataField: 'investition.ort',
                text: 'Ort',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "150px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "150px" };
                },
                filter: selectFilter({ 
                    options: this.getFilterOptions(this.state.invests, "ort"),
                    placeholder: "Alle",
                    className: "kslplus-tabellen-multiselect",
                    style: { width: "135px" },
                    getFilter: (filter:any) => { this.getFilters.push(filter) }
                }),
                sort: true,
                sortCaret: (order: string, column: any) => {
                    if (!order) return (<span className="kslplus-header-sort">&nbsp;&nbsp;&#8595;&#8593;</span>);
                    else if (order === 'asc') return (<span className="kslplus-header-sort-up">&nbsp;&nbsp;&#8593;</span>);
                    else if (order === 'desc') return (<span className="kslplus-header-sort-down">&nbsp;&nbsp;&#8595;</span>);
                    return null;
                }
                
            }
        );
        columns.push(
            {
                dataField: 'investition.ortsteil',
                text: 'Ortsteil',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "150px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "150px" };
                },
                filter: selectFilter({ 
                    options: this.getFilterOptions(this.state.invests, "ortsteil"),
                    placeholder: "Alle",
                    className: "kslplus-tabellen-multiselect",
                    style: { width: "135px" },
                    getFilter: (filter:any) => { this.getFilters.push(filter) }
                }),
                sort: true,
                sortCaret: (order: string, column: any) => {
                    if (!order) return (<span className="kslplus-header-sort">&nbsp;&nbsp;&#8595;&#8593;</span>);
                    else if (order === 'asc') return (<span className="kslplus-header-sort-up">&nbsp;&nbsp;&#8593;</span>);
                    else if (order === 'desc') return (<span className="kslplus-header-sort-down">&nbsp;&nbsp;&#8595;</span>);
                    return null;
                }
            }
        );

        columns.push(
            {
                dataField: 'investition.strasse',
                text: "Strasse",
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "150px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "150px" };
                },
                headerFormatter: (column: any, colIndex: any, { sortElement, filterElement }: { sortElement: any, filterElement: any }) => {
                    return (
                        <div>Straße{sortElement}</div>
                    );
                },
                filter: selectFilter({ 
                    options: this.getFilterOptions(this.state.invests, "strasse"),
                    placeholder: "Alle",
                    className: "kslplus-tabellen-multiselect",
                    style: { width: "135px" },
                    getFilter: (filter:any) => { this.getFilters.push(filter) }
                }),
                sort: true,
                sortCaret: (order: string, column: any) => {
                    if (!order) return (<span className="kslplus-header-sort">&nbsp;&nbsp;&#8595;&#8593;</span>);
                    else if (order === 'asc') return (<span className="kslplus-header-sort-up">&nbsp;&nbsp;&#8593;</span>);
                    else if (order === 'desc') return (<span className="kslplus-header-sort-down">&nbsp;&nbsp;&#8595;</span>);
                    return null;
                }
            }
        );
        columns.push(
            {
                dataField: 'investition.hausnummer',
                text: 'Hausnr.',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "95px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "95px" };
                },
                filter: selectFilter({ 
                    options: this.getFilterOptions(this.state.invests, "hausnummer"),
                    placeholder: "Alle",
                    className: "kslplus-tabellen-multiselect",
                    style: { width: "80px" },
                    getFilter: (filter:any) => { this.getFilters.push(filter) }
                }),
                sort: true,
                sortCaret: (order: string, column: any) => {
                    if (!order) return (<span className="kslplus-header-sort">&nbsp;&nbsp;&#8595;&#8593;</span>);
                    else if (order === 'asc') return (<span className="kslplus-header-sort-up">&nbsp;&nbsp;&#8593;</span>);
                    else if (order === 'desc') return (<span className="kslplus-header-sort-down">&nbsp;&nbsp;&#8595;</span>);
                    return null;
                }
            }
        );
        columns.push(
            {
                dataField: 'investition.beginnJahr',
                text: 'Beginnjahr',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                filter: selectFilter({
                    options: this.getFilterOptions(this.state.invests, "beginnJahr"),
                    placeholder: "Alle",
                    className: "kslplus-tabellen-multiselect",
                    style: { width: "85px" },
                    getFilter: (filter: any) => { this.getFilters.push(filter) }
                }),
                sort: true,
                sortCaret: (order: string, column: any) => {
                    if (!order) return (<span className="kslplus-header-sort">&nbsp;&nbsp;&#8595;&#8593;</span>);
                    else if (order === 'asc') return (<span className="kslplus-header-sort-up">&nbsp;&nbsp;&#8593;</span>);
                    else if (order === 'desc') return (<span className="kslplus-header-sort-down">&nbsp;&nbsp;&#8595;</span>);
                    return null;
                }
            }
        );
        columns.push(
            {
                dataField: 'summenEbene.produkt',
                isDummyField: true,
                text: 'Produkt',
                formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsModelDoppik) => {
                    return (
                        <div>{Utilities.Num2Str(row.summenEbene && row.summenEbene.produkt !== undefined ? row.summenEbene.produkt : 0, row.summenEbene && row.summenEbene.produktStellen !== undefined ? row.summenEbene.produktStellen : 0)}</div>
                    );
                },
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                filter: selectFilter({ 
                    options: this.getProduktOptions(this.state.invests),
                    placeholder: "Alle",
                    className: "kslplus-tabellen-multiselect",
                    style: { width: "85px" },
                    getFilter: (filter:any) => { this.getFilters.push(filter) }
                }),
                sort: true,
                sortCaret: (order: string, column: any) => {
                    if (!order) return (<span className="kslplus-header-sort">&nbsp;&nbsp;&#8595;&#8593;</span>);
                    else if (order === 'asc') return (<span className="kslplus-header-sort-up">&nbsp;&nbsp;&#8593;</span>);
                    else if (order === 'desc') return (<span className="kslplus-header-sort-down">&nbsp;&nbsp;&#8595;</span>);
                    return null;
                },
                sortValue: (cell: any, row: KSLPlusAPI.InvestitionsModelDoppik) => {
                    return (
                        Utilities.Num2Str(row.summenEbene && row.summenEbene.produkt !== undefined ? row.summenEbene.produkt : 0, row.summenEbene && row.summenEbene.produktStellen !== undefined ? row.summenEbene.produktStellen : 0)
                    );
                }
            }
        );
        
        columns.push(
            {
                dataField: 'investition.investitionsart',
                text: 'Investitionsart',
                formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsModelDoppik) => {
                    var ia = this.investitionsarten.find(ia => row.investition && ia.id == row.investition.investitionsart);
                    
                    return (
                        <div>{ia != null ? ia.bezeichnung : ""}</div>
                    );
                },
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "120px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "120px" };
                },
                filter: selectFilter({
                    options: this.getInvestitionsartOptions(this.state.invests,
                    this.investitionsarten ? this.investitionsarten : []),
                    placeholder: "Alle", className: "kslplus-tabellen-multiselect",
                    style: { width: "115px" },
                    getFilter: (filter:any) => { this.getFilters.push(filter) }
                }),
                sort: true,
                sortCaret: (order: string, column: any) => {
                    if (!order) return (<span className="kslplus-header-sort">&nbsp;&nbsp;&#8595;&#8593;</span>);
                    else if (order === 'asc') return (<span className="kslplus-header-sort-up">&nbsp;&nbsp;&#8593;</span>);
                    else if (order === 'desc') return (<span className="kslplus-header-sort-down">&nbsp;&nbsp;&#8595;</span>);
                    return null;
                },
                sortValue: (cell: any, row: KSLPlusAPI.InvestitionsModelDoppik) => {
                    var ia = this.investitionsarten.find(ia => row.investition && ia.id == row.investition.investitionsart);

                    return (
                        ia != null ? ia.bezeichnung : ""
                    );
                }
            }
        );
        
        columns.push(
            {
                dataField: 'investition.vermoegensart',
                text: 'Vermoegensart',
                formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsModelDoppik) => {
                    var va = this.vermoegensarten.find(va => row.investition && va.id == row.investition.vermoegensart);
                
                    return (
                        <div>{va != null ? va.bezeichnung : ""}</div>
                    );
                },
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "150px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "150px" };                    
                },
                headerFormatter: (column:any, colIndex:any, { sortElement, filterElement } : { sortElement:any, filterElement:any}) => {
                  return (
                      <div>Vermögensart{sortElement}</div>
                  );
                },               
                filter: selectFilter({ 
                    options: this.getVermoegensartOptions(this.state.invests, this.vermoegensarten ? this.vermoegensarten : []),
                    placeholder: "Alle",
                    className: "kslplus-tabellen-multiselect",
                    style: { width: "145px" },
                    getFilter: (filter:any) => { this.getFilters.push(filter) }
                }),
                sort: true,
                sortCaret: (order: string, column: any) => {
                    if (!order) return (<span className="kslplus-header-sort">&nbsp;&nbsp;&#8595;&#8593;</span>);
                    else if (order === 'asc') return (<span className="kslplus-header-sort-up">&nbsp;&nbsp;&#8593;</span>);
                    else if (order === 'desc') return (<span className="kslplus-header-sort-down">&nbsp;&nbsp;&#8595;</span>);
                    return null;
                },
                sortValue: (cell: any, row: KSLPlusAPI.InvestitionsModelDoppik) => {
                    var va = this.vermoegensarten.find(va => row.investition && va.id == row.investition.vermoegensart);

                    return (
                        va != null ? va.bezeichnung : ""
                    );
                }
            }
        );
        columns.push(
            {
                dataField: 'investition.vermoegenstyp',
                text: 'Vermoegenstyp',
                formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsModelDoppik) => {
                    var va = this.vermoegensarten.find(va => row.investition && va.id == row.investition.vermoegensart);

                    var vt = undefined;
                    if (va && va.vermoegenstypen) {
                        vt = va.vermoegenstypen.find(vt => row.investition && vt.id == row.investition.vermoegenstyp);
                    }
                                
                    return (
                        <div>{vt != null ? vt.bezeichnung : ""}</div>
                    );
                },
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "150px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "150px" };
                },
                headerFormatter: (column:any, colIndex:any, { sortElement, filterElement } : { sortElement:any, filterElement:any}) => {
                  return (
                      <div>Vermögenstyp{sortElement}</div>
                  );
                },
                filter: selectFilter(
                    {
                        options: this.getVermoegenstypOptions(this.state.invests, this.vermoegensarten ? this.vermoegensarten : []),
                        placeholder: "Alle", className: "kslplus-tabellen-multiselect",
                        style: { width: "145px" },
                        getFilter: (filter:any) => { this.getFilters.push(filter) }
                    }
                ),
                sort: true,
                sortCaret: (order: string, column: any) => {
                    if (!order) return (<span className="kslplus-header-sort">&nbsp;&nbsp;&#8595;&#8593;</span>);
                    else if (order === 'asc') return (<span className="kslplus-header-sort-up">&nbsp;&nbsp;&#8593;</span>);
                    else if (order === 'desc') return (<span className="kslplus-header-sort-down">&nbsp;&nbsp;&#8595;</span>);
                    return null;
                },
                sortValue: (cell: any, row: KSLPlusAPI.InvestitionsModelDoppik) => {
                    var va = this.vermoegensarten.find(va => row.investition && va.id == row.investition.vermoegensart);

                    var vt = undefined;
                    if (va && va.vermoegenstypen) {
                        vt = va.vermoegenstypen.find(vt => row.investition && vt.id == row.investition.vermoegenstyp);
                    }

                    return (
                        vt != null ? vt.bezeichnung : ""
                    );
                }
            }
        );
        columns.push(
            {
                dataField: 'investition.wesentlich',
                text: 'Wesentlich',            
                formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsModelDoppik) => {
                    return (
                        <div>{row.investition && row.investition.wesentlich ? "ja" : "nein"}</div>);

                },
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "80px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "80px" };
                },
                filter: selectFilter({
                    options: this.boolSelectOptions,
                    placeholder: "Alle",
                    className: "kslplus-tabellen-multiselect",
                    style: { width: "65px" },
                    getFilter: (filter:any) => { this.getFilters.push(filter) }
                }),
                sort: true,
                sortCaret: (order: string, column: any) => {
                    if (!order) return (<span className="kslplus-header-sort">&nbsp;&nbsp;&#8595;&#8593;</span>);
                    else if (order === 'asc') return (<span className="kslplus-header-sort-up">&nbsp;&nbsp;&#8593;</span>);
                    else if (order === 'desc') return (<span className="kslplus-header-sort-down">&nbsp;&nbsp;&#8595;</span>);
                    return null;
                }
                ,
                sortValue: (cell: any, row: KSLPlusAPI.InvestitionsModelDoppik) => {
                    return (
                        row.investition && row.investition.wesentlich ? "ja" : "nein"
                    );
                }
            }
        );
        columns.push(
            {
                dataField: 'summenEbene.investNr',
                isDummyField: true,
                text: 'Investitions-Nr.',   
                formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsModelDoppik) => {
                    return (
                        <div>{row.summenEbene && row.summenEbene.investNr ? row.summenEbene.investNr : ""}</div>
                    );
                },
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                filter: selectFilter({
                    options: this.getInvestNrOptions(this.state.invests),
                    placeholder: "Alle",
                    className: "kslplus-tabellen-multiselect",
                    style: { width: "85px" },
                    getFilter: (filter: any) => { this.getFilters.push(filter) }
                }),
                sort: true,
                sortCaret: (order: string, column: any) => {
                    if (!order) return (<span className="kslplus-header-sort">&nbsp;&nbsp;&#8595;&#8593;</span>);
                    else if (order === 'asc') return (<span className="kslplus-header-sort-up">&nbsp;&nbsp;&#8593;</span>);
                    else if (order === 'desc') return (<span className="kslplus-header-sort-down">&nbsp;&nbsp;&#8595;</span>);
                    return null;
                }
            }
        );

        var columns1: any[] = [];
        
        var anzeigeSpalten = spalten.split(",");

        anzeigeSpalten.forEach(s => {
            var s1 = columns.filter(s2 => s2.text.replace(/\u00df/g, "ss").replace(/\u00f6/g, "oe") === s.replace(/\u00df/g, "ss").replace(/\u00f6/g, "oe"));
            if (s1.length > 0)
                columns1.push(s1[0]);
        });
        return columns1.length > 0 ? columns1 : columns;
    }

    createBeschreibungsItems = (texte: KSLPlusAPI.Texte[] | undefined, ve: boolean, ga: boolean) => {
        var items: JSX.Element[] = [];

        if (!texte)
            return items;

        for (var i = 0; i < texte.length; i++) {
            if (ve && ga) {
                items.push(
                    <Row key={"be" + i.toString()}>
                        <Col lg="3">
                            <div className="kslplus-invest-popover-bezeichnung">{texte[i].bezeichnung}:</div>
                        </Col>
                        <Col lg="9">
                            <div className="kslplus-invest-popover-beschreibung">{texte[i].text}</div>
                        </Col>

                    </Row>
                );
            }
            else {
                items.push(
                    <Row key={"be" + i.toString()}>
                        <Col lg="4">
                            <div className="kslplus-invest-popover-bezeichnung">{texte[i].bezeichnung}:</div>
                        </Col>
                        <Col lg="8">
                            <div className="kslplus-invest-popover-beschreibung">{texte[i].text}</div>
                        </Col>

                    </Row>
                );
            }
        }

        return items;

    }

    createWerteItems = (invest: KSLPlusAPI.InvestitionsModelDoppik, ve: boolean, ga: boolean) => {
        var items: JSX.Element[] = [];

        if (!invest.investition)
            return items;

        if (ve && ga) {
            items.push(
                <Row key="1">
                    <Col lg="3">
                        <div className="kslplus-invest-popover-bezeichnung">Gesamtausgabebedarf:</div>
                    </Col>
                    <Col lg="2" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.gesamtbedarf, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>


                    <Col lg="5">
                        <div className="kslplus-invest-popover-bezeichnung">Summe der Verpflichtungsermächtigungen:</div>
                    </Col>
                    <Col lg="2" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.veGesamt, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>



                </Row>
            );
            items.push(
                <Row key="2">
                    <Col lg="3">
                        <div className="kslplus-invest-popover-bezeichnung">Zuwendungen gesamt:</div>
                    </Col>
                    <Col lg="2" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.gesamtzuwendungen, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>

                    <Col lg="5">
                        <div className="kslplus-invest-popover-bezeichnung">{"davon " + (this.props.jahr + 1).toString() + ":"}</div>
                    </Col>
                    <Col lg="2" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.veFj1, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>


                </Row>
            );
            items.push(
                <Row key="3">
                    <Col lg="3">
                        <div className="kslplus-invest-popover-bezeichnung">Zuwendungsquote:</div>
                    </Col>
                    <Col lg="2" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.gesamtbedarf && invest.investition.gesamtzuwendungen ? invest.investition.gesamtzuwendungen * 100 / invest.investition.gesamtbedarf : 0, 2, 3, '.', ',') + " %"}</div>
                    </Col>

                    <Col lg="5">
                        <div className="kslplus-invest-popover-bezeichnung">{"davon " + (this.props.jahr + 2).toString() + ":"}</div>
                    </Col>
                    <Col lg="2" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.veFj2, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>


                </Row>
            );
            items.push(
                <Row key="4">
                    <Col lg="3">
                        <div className="kslplus-invest-popover-bezeichnung">Bisher vereinnahmt:</div>
                    </Col>
                    <Col lg="2" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.bisherVereinnahmt, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>


                    <Col lg="5">
                        <div className="kslplus-invest-popover-bezeichnung">{"davon " + (this.props.jahr + 3).toString() + ":"}</div>
                    </Col>
                    <Col lg="2" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.veFj3, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>


                </Row>
            );
            items.push(
                <Row key="5">
                    <Col lg="3">
                        <div className="kslplus-invest-popover-bezeichnung">Bisher verausgabt:</div>
                    </Col >
                    <Col lg="2" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.bisherVerausgabt, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>

                    <Col lg="5">

                    </Col>
                    <Col lg="2" className="kslplus-invest-popover-wert">

                    </Col>

                </Row>
            );
        }
        else if (ga) {
            items.push(
                <Row key="1">
                    <Col lg="7">
                        <div className="kslplus-invest-popover-bezeichnung">Gesamtausgabebedarf:</div>
                    </Col>
                    <Col lg="5" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.gesamtbedarf, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>
                </Row>
            );
            items.push(
                <Row key="2">
                    <Col lg="7">
                        <div className="kslplus-invest-popover-bezeichnung">Zuwendungen gesamt:</div>
                    </Col>
                    <Col lg="5" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.gesamtzuwendungen, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>
                </Row>
            );
            items.push(
                <Row key="3">
                    <Col lg="7">
                        <div className="kslplus-invest-popover-bezeichnung">Zuwendungsquote:</div>
                    </Col>
                    <Col lg="5" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.gesamtbedarf && invest.investition.gesamtzuwendungen ? invest.investition.gesamtzuwendungen * 100 / invest.investition.gesamtbedarf : 0, 2, 3, '.', ',') + " %"}</div>
                    </Col>
                </Row>
            );
            items.push(
                <Row key="4">
                    <Col lg="7">
                        <div className="kslplus-invest-popover-bezeichnung">Bisher vereinnahmt:</div>
                    </Col>
                    <Col lg="5" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.bisherVereinnahmt, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>
                </Row>
            );
            items.push(
                <Row key="5">
                    <Col lg="7">
                        <div className="kslplus-invest-popover-bezeichnung">Bisher verausgabt:</div>
                    </Col >
                    <Col lg="5" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.bisherVerausgabt, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>
                </Row>
            );
        }
        else if (ve) {
            items.push(
                <Row key="1">
                    <Col lg="5">
                        <div className="kslplus-invest-popover-bezeichnung">Summe der Verpflichtungsermächtigungen:</div>
                    </Col>
                    <Col lg="2" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.veGesamt, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>



                </Row>
            );
            items.push(
                <Row key="2">
                    <Col lg="5">
                        <div className="kslplus-invest-popover-bezeichnung">{"davon " + (this.props.jahr + 1).toString() + ":"}</div>
                    </Col>
                    <Col lg="2" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.veFj1, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>


                </Row>
            );
            items.push(
                <Row key="3">

                    <Col lg="5">
                        <div className="kslplus-invest-popover-bezeichnung">{"davon " + (this.props.jahr + 2).toString() + ":"}</div>
                    </Col>
                    <Col lg="2" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.veFj2, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>


                </Row>
            );
            items.push(
                <Row key="4">
                    <Col lg="5">
                        <div className="kslplus-invest-popover-bezeichnung">{"davon " + (this.props.jahr + 3).toString() + ":"}</div>
                    </Col>
                    <Col lg="2" className="kslplus-invest-popover-wert">
                        <div>{Utilities.FormatCurrency(invest.investition.veFj3, 2, 3, '.', ',') + " EUR"}</div>
                    </Col>


                </Row>
            );

        }
        return items;

    }


    createDataColumn = (field: string, jahr: number, footer: number, footer2: number, id: number, showFooter: boolean = true, showTotal: boolean, text: string = "") => {
        if (text == "") {
            text = "Ergebnis ";
            if (this.props.plantyp === 3 || jahr - this.props.jahr < -1)
                text = "Ergebnis ";
            else if (jahr - this.props.jahr > (this.props.settings.doppelhaushalt ? 1 : 0))
                text = "Finanzplan ";
            else
                text = "Ansatz ";
        }

        return {
            dataField: "ebene",
            text: text + jahr + " (EUR)",
            headerAlign: 'right',
            align: 'right',
            formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsEbene) => {                
                var ebene = row.ebene as { [key: string]: any };
                
                return (
                    <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(ebene[field + (row.ausgabe ? "_soll" : "_haben")], 2, 3, '.', ',')} </div>
                );

            }, 
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '18%' };
            },
            style: (colum: any, colIndex: any) => {
                return { width: '18%' };
            },                 
            footerClasses: (column: any, colIndex: number) => {              
              return "kslplus-footer-invest";
            },      
            footer: showFooter ? id : undefined,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                if ( showTotal  )
                {
                    var sumHaben = 0;
                    var sumSoll = 0;
                    this.state.invests.forEach( (row) => { 
                        if ( row.summenEbene )
                        {
                            var ebene = row.summenEbene as { [key: string]: any };
                            sumSoll += ebene[field + "_soll"];
                        
                            sumHaben += ebene[field + "_haben"];
                        }
                    } );
                    return (<div>   <div className="kslplus-tabellen-footer-invest">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')}</div>
                                    <div className="kslplus-tabellen-footer-invest">{Utilities.FormatCurrency(footer2, 2, 3, '.', ',')}</div> 
                                    <div className="kslplus-tabellen-footer-invest-border"><div className="kslplus-tabellen-footer-invest1">{Utilities.FormatCurrency(sumHaben, 2, 3, '.', ',')}</div></div> 
                                    <div className="kslplus-tabellen-footer-invest">{Utilities.FormatCurrency(sumSoll, 2, 3, '.', ',')} </div></div>);
                }
                if (footer2 !== undefined)
                    return (<div><div className="kslplus-tabellen-footer-invest">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')} </div><div className="kslplus-tabellen-footer-invest">{Utilities.FormatCurrency(footer2, 2, 3, '.', ',')} </div></div>);

                return (
                    <div className="kslplus-tabellen-footer-invest">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')} </div>
                );
            }
        };
    }

    handleOnExpand = (row:any, isExpand:boolean, rowIndex:number, e:any) => {
        if (isExpand) {
            this.state.expanded.push(row.investition.id);
            
        } else {
            this.state.expanded = this.state.expanded.filter(x => x !== row.investition.id);          
        }
      }

    createDataColumns = (data: KSLPlusAPI.HaushaltsEbeneDoppik | null | undefined, totalSum: KSLPlusAPI.HaushaltsEbeneDoppik | null | undefined, showTotal: boolean) => {
        var columns = [];
        
        var id = 1;
        columns.push(
            {
                dataField: 'ebene.bezeichnung',
                text: 'Bezeichnung',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "300px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "300px" };
                },
                footer: id,                         
                formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsEbeneDoppik) => {   
                    if ( !row.ebene )
                        return null;
                    return (
                        <div>{row.ebene.massnahme && row.ebene.massnahme.length > 0 ? row.ebene.massnahme : row.ebene.bezeichnung}</div>
                    );
                }
            }
            
        );
        id++;
        columns.push(
            {
                dataField: 'ebene.produkt',
                text: 'Produkt',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsEbeneDoppik) => {                   
                    return (
                        <div>{Utilities.Num2Str(row.ebene && row.ebene.produkt !== undefined ? row.ebene.produkt : 0, row.ebene && row.ebene.produktStellen !== undefined ? row.ebene.produktStellen : 0)}</div>
                    );
                },
                footer: id
            }
        );
        id++;
        columns.push(
            {
                dataField: 'ebene.konto',
                text: 'Konto',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsEbeneDoppik) => {
                    return (
                        <div>{Utilities.Num2Str(row.ebene && row.ebene.konto !== undefined ? row.ebene.konto : 0, row.ebene && row.ebene.kontoStellen !== undefined ? row.ebene.kontoStellen : 0)}</div>
                    );
                },
                footer: id
            }
        );
        id++;
        columns.push(    
            {
                dataField: 'ebene.investNr',
                text: 'Investitions-Nr.',                
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "150px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "150px" };
                },
                footer: id,
                formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsEbeneDoppik) => {
                    
                    if (!row.ebene)
                        return null;
                    return (
                        <div><div className="kslplus-tabellen-bezeichnung">{row.ebene.investNr}</div>
                            {row.ebene.bestandsKonto ? <div>< Badge className = "kslplus-info-badge " id = { row.ebene.id ? "kslplus-info-text-investNr" + row.ebene.id : "" } > i</Badge >
                                <UncontrolledPopover                                    
                                    placement="auto"
                                    target={row.ebene.id ? "kslplus-info-text-investNr" + row.ebene.id : ""}
                                    trigger="hover"
                                    >
                                    <PopoverBody >
                                        
                                            <div>
                                                {row.ebene.bestandsKonto +  " - " + row.ebene.bestandsKontoText}
                                            </div>                                                                                    
                                    </PopoverBody>
                                </UncontrolledPopover></div> :
                                null}
                        </div>
                    );
                }
                
            }
            
        );
        id++;
        columns.push(
        {
            dataField: 'ebene',
            text: 'Einzahlungen/Auszahlungen',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: "260px" };
            },
            style: (colum: any, colIndex: any) => {
                return { width: "260px" };
            },
            footerClasses: (column: any, colIndex: number) => {              
              return "kslplus-footer-invest";
            },  
            footerFormatter: (column: any, columnIndex: any) => {
                
                if ( showTotal && this.state.expanded.length === this.state.invests.length )     
                {
                    var ebenen: KSLPlusAPI.InvestitionsEbeneDoppik[] = [];
                    
                    this.state.invests.forEach( (row) => { 
                        if ( row.ebenen )
                        {
                            row.ebenen.forEach( (e) => { 
                                if ( !e.ausgabe )
                                {
                                    ebenen.push(e);
                                }                        
                            });
                        }
                    });


                    var row = this.state.invests[0];
                    
                    
                    if ( row )
                    {
                        return (
                            <div><  div className="kslplus-tabellen-footer-invest">Einzahlungen gesamt</div>
                                    <div className="kslplus-tabellen-footer-invest">Auszahlungen gesamt</div>                            
                                     <div className="kslplus-tabellen-footer-invest-border">
                                    <div className="kslplus-tabellen-footer-invest1">Einzahlungen über alle Investitionen
                                        {ebenen.length > 0 && <Badge className="kslplus-info-badge " id={"kslplus-invest-einnahemen-hhs"}>i</Badge>}
                                            {ebenen.length > 0 &&<UncontrolledPopover        
                                                size="lg"                                
                                                placement="auto"
                                                target="kslplus-invest-einnahemen-hhs"
                                                trigger="hover"
                                                className="kslplus-invest-popover">
                                                <Container className="kslplus-invest-popover-block">
                                        
                                                    <PopoverBody className="kslplus-invest-popover-body">
                                                        <BootstrapTable bordered={false} keyField='id' data={ebenen} columns={this.createEHHSColumns()} pagination={ebenen.length > 15 ? paginationFactory( [{text: '15', value: 15}, { text: '20', value: 20 }]) : undefined}   />                                                            
                                                    </PopoverBody>
                                                </Container>
                                            </UncontrolledPopover>
                                        }
                                    </div>
                                    </div>
                                    
                                <div className="kslplus-tabellen-footer-invest">Auszahlungen über alle Investitionen</div></div>
                        );
                    }
                }
            
                return (
                    <div><div className="kslplus-tabellen-footer-invest">Einzahlungen gesamt </div><div className="kslplus-tabellen-footer-invest">Auszahlungen gesamt</div></div>
                );
            },
            formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsEbene) => {                     
                return (
                    <div>{row.ausgabe ? "Auszahlung" : "Einzahlung"}</div>
                );
            },
            footer: id
        });
        
        
        if (this.props.plantyp == 3) {
            id++;
            columns.push(this.createDataColumn("ergebnisPJM4", this.props.jahr - 4, totalSum && totalSum.ergebnisPJM4_haben !== undefined ? totalSum.ergebnisPJM4_haben : 0, totalSum && totalSum.ergebnisPJM4_soll !== undefined ? totalSum.ergebnisPJM4_soll : 0, id, true, showTotal && this.state.expanded.length === this.state.invests.length));
            id++;
            columns.push(this.createDataColumn("ergebnisPJM3", this.props.jahr - 3, totalSum && totalSum.ergebnisPJM3_haben !== undefined ? totalSum.ergebnisPJM3_haben : 0, totalSum && totalSum.ergebnisPJM3_soll !== undefined ? totalSum.ergebnisPJM3_soll : 0, id, true, showTotal && this.state.expanded.length === this.state.invests.length));
            id++;
            columns.push(this.createDataColumn("ergebnisPJM2", this.props.jahr - 2, totalSum && totalSum.ergebnisPJM2_haben !== undefined ? totalSum.ergebnisPJM2_haben : 0, totalSum && totalSum.ergebnisPJM2_soll !== undefined ? totalSum.ergebnisPJM2_soll : 0, id, true, showTotal && this.state.expanded.length === this.state.invests.length));
            id++;
            columns.push(this.createDataColumn("ergebnisPJM1", this.props.jahr - 1, totalSum && totalSum.ergebnisPJM1_haben !== undefined ? totalSum.ergebnisPJM1_haben : 0, totalSum && totalSum.ergebnisPJM1_soll !== undefined ? totalSum.ergebnisPJM1_soll : 0, id, true, showTotal && this.state.expanded.length === this.state.invests.length));
            id++;
            columns.push(this.createDataColumn("ansatzPJfort", this.props.jahr, totalSum && totalSum.ansatzPJfort_haben !== undefined ? totalSum.ansatzPJfort_haben : 0, totalSum && totalSum.ansatzPJfort_soll !== undefined ? totalSum.ansatzPJfort_soll : 0, id, true, showTotal && this.state.expanded.length === this.state.invests.length, "Fortgeschriebener Ansatz "));
            id++;
            columns.push(this.createDataColumn("ergebnisPJ", this.props.jahr, totalSum && totalSum.ergebnisPJ_haben !== undefined ? totalSum.ergebnisPJ_haben : 0, totalSum && totalSum.ergebnisPJ_soll !== undefined ? totalSum.ergebnisPJ_soll : 0, id, true, showTotal && this.state.expanded.length === this.state.invests.length));
            id++;
        }
        else {
            id++;
            columns.push(this.createDataColumn("ergebnisVvJ", this.props.jahr - 2, totalSum && totalSum.ergebnisVvJ_haben !== undefined ? totalSum.ergebnisVvJ_haben : 0, totalSum && totalSum.ergebnisVvJ_soll !== undefined ? totalSum.ergebnisVvJ_soll : 0, id, true, showTotal && this.state.expanded.length === this.state.invests.length));
            id++;
            columns.push(this.createDataColumn("ansatzVJ", this.props.jahr - 1, totalSum && totalSum.ansatzVJ_haben !== undefined ? totalSum.ansatzVJ_haben : 0, totalSum && totalSum.ansatzVJ_soll !== undefined ? totalSum.ansatzVJ_soll : 0, id, true, showTotal && this.state.expanded.length === this.state.invests.length));
            id++;
            columns.push(this.createDataColumn("ansatzPj", this.props.jahr, totalSum && totalSum.ansatzPj_haben !== undefined ? totalSum.ansatzPj_haben : 0, totalSum && totalSum.ansatzPj_soll !== undefined ? totalSum.ansatzPj_soll : 0, id, true, showTotal && this.state.expanded.length === this.state.invests.length));
            id++;
            columns.push(this.createDataColumn("fp1", this.props.jahr + 1, totalSum && totalSum.fp1_haben !== undefined ? totalSum.fp1_haben : 0, totalSum && totalSum.fp1_soll !== undefined ? totalSum.fp1_soll : 0, id, true, showTotal && this.state.expanded.length === this.state.invests.length));
            id++;
            columns.push(this.createDataColumn("fp2", this.props.jahr + 2, totalSum && totalSum.fp2_haben !== undefined ? totalSum.fp2_haben : 0, totalSum && totalSum.fp2_soll !== undefined ? totalSum.fp2_soll : 0, id, true, showTotal && this.state.expanded.length === this.state.invests.length));
            id++;
            columns.push(this.createDataColumn("fp3", this.props.jahr + 3, totalSum && totalSum.fp3_haben !== undefined ? totalSum.fp3_haben : 0, totalSum && totalSum.fp3_soll !== undefined ? totalSum.fp3_soll : 0, id, true, showTotal && this.state.expanded.length === this.state.invests.length));
            id++;
            /*
            columns.push(this.createDataColumn("fp4", this.props.jahr + 4, totalSum && totalSum.fp4_haben !== undefined ? totalSum.fp4_haben : 0, totalSum && totalSum.fp4_soll !== undefined ? totalSum.fp4_soll : 0, id, true, showTotal && this.state.expanded.length === this.state.invests.length));
            id++;
            columns.push(this.createDataColumn("fp5", this.props.jahr + 5, totalSum && totalSum.fp5_haben !== undefined ? totalSum.fp5_haben : 0, totalSum && totalSum.fp5_soll !== undefined ? totalSum.fp5_soll : 0, id, true, showTotal && this.state.expanded.length === this.state.invests.length));
            id++:
            */
        }
        return columns;
    }


    createEHHSColumns = () => {
        var columns = [];
        
        var id = 1;
        columns.push(
            {
                dataField: 'ebene.bezeichnung',
                text: 'Bezeichnung',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "250px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "250px" };
                },                         
                formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsEbeneDoppik) => {   
                    if ( !row.ebene )
                        return null;
                    return (
                        <div>{row.ebene.massnahme && row.ebene.massnahme.length > 0 ? row.ebene.massnahme : row.ebene.bezeichnung}</div>
                    );
                }

            }
        );
        id++;
        columns.push(
            {
                dataField: 'ebene.produkt',
                text: 'Produkt',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsEbeneDoppik) => {                   
                    return (
                        <div>{Utilities.Num2Str(row.ebene && row.ebene.produkt !== undefined ? row.ebene.produkt : 0, row.ebene && row.ebene.produktStellen !== undefined ? row.ebene.produktStellen : 0)}</div>
                    );
                }
            }
        );
        id++;
        columns.push(
            {
                dataField: 'ebene.konto',
                text: 'Konto',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },
                formatter: (cellContent: string, row: KSLPlusAPI.InvestitionsEbeneDoppik) => {                   
                    return (
                        <div>{Utilities.Num2Str(row.ebene && row.ebene.konto !== undefined ? row.ebene.konto : 0, row.ebene && row.ebene.kontoStellen !== undefined ? row.ebene.kontoStellen : 0)}</div>
                    );
                }
            }
        );
        id++;
        columns.push(    
            {
                dataField: 'ebene.investNr',
                text: 'Investitions-Nr.',
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                },                
                style: (colum: any, colIndex: any) => {
                    return { width: "100px" };
                }
            }
            
        );
        
        id++;
        columns.push(this.createDataColumn("ansatzPj", this.props.jahr, 0, 0, id, false, false));
        
        return columns;
    }


    expandRowEA = {
        renderer: (row: KSLPlusAPI.InvestitionsModelDoppik) => (
            <BootstrapTable hover={true} bordered={false} keyField='ebene.id' data={row.ebenen ? row.ebenen : []} columns={this.createDataColumns(row.summenEbene, row.summenEbene, row === this.state.invests[this.state.invests.length-1])}   />                    
      ),
      className: 'kslplus-tabellen-expanded',
      showExpandColumn: true,
      expanded: this.state.expanded,
      onExpand: this.handleOnExpand,
        onExpandAll: (isExpandAll: boolean, rows: KSLPlusAPI.InvestitionsModelDoppik[], e: any) => {
        if ( !isExpandAll )
            this.state.expanded = [];
        else        
            this.state.expanded = rows.map(row => (row.investition && row.investition.id !== undefined) ? row.investition.id : -1);                                
      }              
    };

    boolSelectOptions = {
        false: 'nein',
        true: 'ja'
    };

    resetInvestFilters = () => {
        this.getFilters.forEach(fct => fct(""));
    }

    getFilterOptions = (invests: KSLPlusAPI.InvestitionsModelDoppik[], field: string) => {
        var items: {label: any, value: any}[] = [];
        
        for ( var i = 0; i < invests.length; i++ )
        {
            var invest = invests[i].investition as { [key: string]: any };            
            if ( !items.find( i => i.value === invest[field]) )
                items.push({value: invest[field], label: invest[field]});
        }
               
        return items;
    }

    getProduktOptions = (invests: KSLPlusAPI.InvestitionsModelDoppik[]) => {
        var items: {label: any, value: any}[] = [];
        
        for ( var i = 0; i < invests.length; i++ )
        {
            var se = invests[i].summenEbene;
            if ( !se || !se.produkt)
                continue;
            if (!items.find(inv => se && se.produkt && inv.value === se.produkt))
                items.push({ value: se.produkt, label: Utilities.Num2Str(se.produkt !== undefined ? se.produkt : 0, se.produktStellen !== undefined ? se.produktStellen : 0) });
        }
               
        return items;
    }

    getInvestNrOptions = (invests: KSLPlusAPI.InvestitionsModelDoppik[]) => {
        var items: { label: any, value: any }[] = [];

        for (var i = 0; i < invests.length; i++) {
            var se = invests[i].summenEbene;
            if (!se || !se.investNr)
                continue;
            if (!items.find(inv => se && se.investNr && inv.value === se.investNr))
                items.push({ value: se.investNr, label: se.investNr });
        }

        return items;
    }

    getInvestitionsartOptions = (invests: KSLPlusAPI.InvestitionsModelDoppik[], arten: KSLPlusAPI.Investitionsarten[]) => {
        var items: {label: any, value: any}[] = [];
        
        for ( var i = 0; i < arten.length; i++ )
        {
            if ( invests.find(inv => inv.investition && inv.investition.investitionsart && inv.investition.investitionsart == arten[i].id) )
            {

                if ( !items.find( it => it.value === arten[i].bezeichnung) )
                    items.push({value: arten[i].id, label: arten[i].bezeichnung});
            }
        }
               
        return items;
    }

    getVermoegensartOptions = (invests: KSLPlusAPI.InvestitionsModelDoppik[], arten: KSLPlusAPI.VermoegensartDoppisch[]) => {
        var items: {label: any, value: any}[] = [];
        
        for ( var i = 0; i < arten.length; i++ )
        {
            if ( invests.find(inv => inv.investition && inv.investition.vermoegensart && inv.investition.vermoegensart == arten[i].id) )
            {

                if ( !items.find( it => it.value === arten[i].bezeichnung) )
                    items.push({value: arten[i].id, label: arten[i].bezeichnung});
            }
        }
               
        return items;
    }

    getVermoegenstypOptions = (invests: KSLPlusAPI.InvestitionsModelDoppik[], arten: KSLPlusAPI.VermoegensartDoppisch[]) => {
        var items: {label: any, value: any}[] = [];
        
        for ( var i = 0; i < arten.length; i++ )
        {
            var va = arten[i];            
            if ( !va.vermoegenstypen )
                continue;

            for (var j = 0; j < va.vermoegenstypen.length; j++ )
            {   
                if (invests.find(inv => va.vermoegenstypen && inv.investition && inv.investition.vermoegensart && inv.investition.vermoegenstyp == va.vermoegenstypen[j].id) )
                {

                    if (!items.find(it => va.vermoegenstypen && it.value === va.vermoegenstypen[j].bezeichnung) )
                        items.push({value: va.vermoegenstypen[j].id, label: va.vermoegenstypen[j].bezeichnung});
                }
            }
        }
               
        return items;
    }

    readInvestitionsarten = () => {
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHousholdAdmin_Client(api);


        client.investitionsarten("").then(ias => {            
            this.investitionsarten = ias;
            this.readVermoegensarten();
            
        }).catch(Reason => {
            this.investitionsarten = [];
        });
    }

    readVermoegensarten = () => {
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHousholdDoppikAdmin_Client(api);


        client.vermoegensarten(this.props.kunde, "").then(vas => {
            this.vermoegensarten = vas;     
            // investition auf state übernehmen nachdem alles geladen wurde
            this.setState({invests: this.props.invests});                   
        }).catch(Reason => {
            this.vermoegensarten = [];
        });
    }
   
    
    
    render() {                         
        return (                                    
                <div className="kslplus-haushaltstab-wrapper-invest">
                    <button id="kslbutton-clear-filters" className={"kslplus-invest-delete-filteres"} onClick={() => {this.resetInvestFilters()}}>                                                                              
                        <img src= {env.REACT_APP_PUBLIC_URL + "4115230_cancel_close_delete_icon.png"}/>                                                    
                    </button>     
                    <UncontrolledTooltip delay={{show:1000, hide: 0}} className="kslplus-tooltip" placement="right" target="kslbutton-clear-filters">Filter zurücksetzen</UncontrolledTooltip>                                           
                    <BootstrapTable keyField='investition.id' data={this.state.invests} columns={this.createColumns(this.state.invests, this.props.investSettings.spalten ? this.props.investSettings.spalten : "")} expandRow={this.expandRowEA} filter={ filterFactory() } filterPosition="top" bordered={ false }/>
                </div>                
                                            
        );
    }
}
import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip, UncontrolledPopover, PopoverBody, Badge, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import Chart from "react-google-charts";


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as Utilities from "./Utilities"


import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

import { VBTab } from './VBTabComp';

type State = {
    ready: boolean;    
    activeTab1: string,
    activeTab2: string,
    activeTabs: string[]
    activeMainTab: string,
    dropDownOpen: boolean,
    tab: string,
    activeDataTabId: string,
    isVisible: boolean,
    alleJahre: boolean
}

type Props = {    
    kunde: number,
    gemeinde: number,
    jahr: number,
    plantyp: number,
    planstufe: number,
    verwaltungsansicht: boolean,
    uhconfig: KSLPlusAPI.UhConfigModel | undefined,
    settings: KSLPlusAPI.SettingsModel,
    isVisible: boolean   
}



export class Vorbericht extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.vwHH1 = null;
        this.vwHH2 = null;        
        this.E9_1 = null;
        this.E3_1 = null;        
        this.E9_2 = null;
        this.E3_2 = null;
        this.dataSets = []; 
        this.vbtabs = [];       

    }
    
    node1: any;
    node2: any;
    node3: any;
    node4: any;
    vwHH1: KSLPlusAPI.HaushaltsEbene | null;
    vwHH2: KSLPlusAPI.HaushaltsEbene | null;    
    E9_1: KSLPlusAPI.HaushaltsEbene | null | undefined;
    E3_1: KSLPlusAPI.HaushaltsEbene | null | undefined;
    E9_2: KSLPlusAPI.HaushaltsEbene | null | undefined;
    E3_2: KSLPlusAPI.HaushaltsEbene | null | undefined;
    dataSets: KSLPlusAPI.Datensaetze[];    
    vbtabs: KSLPlusAPI.Vbtab[];

    state: State = {
        ready: true,        
        activeTab1: "1",
        activeTab2: "1",
        activeTabs: [],
        activeMainTab: "1",
        dropDownOpen: false,
        tab: "",
        activeDataTabId: "",
        isVisible: false,
        alleJahre: false

    }    

    readHH1Data = (tries: number = 0) => {
        var request = new KSLPlusAPI.HaushaltRequest();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;   
        request.nurOberebenen = true;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;
        
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHoushold_Client(api);
        
        request.gruppen = [0,1,2,3,4,5,6,7,8,9];
        client.getHaushaltByGruppierung("", request).then((hh) => {            
            this.vwHH1 = hh;  
            if ( hh.unterEbenen !== undefined )
            {
                this.E9_1 = hh.unterEbenen.find(he => he.untergruppe == 9);
                if (this.E9_1)
                    this.E9_1.jahre = hh.jahre;
                this.E3_1 = hh.unterEbenen.find(he => he.untergruppe == 3);
                if (this.E3_1)
                    this.E3_1.jahre = hh.jahre;
            }       
            
            //this.readHH2Data();
            this.readDataSets();            
            this.setState({ ready: true });
        }).catch(Reason => {
            if (tries < 3)
                setTimeout(() => { this.readHH1Data(++tries) }, 2000);
            else
                alert("Fehler beim Lesen des Haushalts für Betrachtung nach Gruppierung!");
        });

    }

    readHH2Data = () => {
        var request = new KSLPlusAPI.HaushaltRequest();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0; 
        request.nurOberebenen = true;
        
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);
        
        request.gruppen = [0,1,2,3,4,5,6,7,8,9];
        request.ignorierteGruppen = [800, 801, 802, 803, 804, 805, 806, 807, 808, 86, 91, 201, 202, 203, 204, 205, 206, 207, 208, 28, 31];
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        client.getHaushaltByGruppierung("", request).then((hh) => {
            this.vwHH2 = hh;
            if ( hh.unterEbenen !== undefined )
            {
                this.E9_2 = hh.unterEbenen.find(he => he.untergruppe == 9);
                this.E3_2 = hh.unterEbenen.find(he => he.untergruppe == 3);
            }  
            this.setState({ ready: true });
            this.readDataSets();            
        }).catch(Reason => {
        });
    }


    readDataSets = () => {
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        client.datensaetze(this.props.kunde, this.props.gemeinde, this.props.jahr, -1, this.props.plantyp === 3 ? 1 : 0, "").then((sets) => {
            this.dataSets = sets;          
            this.readMainTabs();  
            this.setState({ ready: true });            
        }).catch(Reason => {
        });
    }

    readMainTabs = () => {
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        client.tabs(this.props.kunde, this.props.gemeinde, this.props.jahr, 1, this.props.plantyp == 3 ? 1 : (this.props.plantyp == 4 ? 2 : 0), "").then((tabs) => {
            this.vbtabs = tabs;         
            if ( tabs.length > 0 && tabs[0].bezeichnung)
            {
                this.state.tab = tabs[0].bezeichnung;   
                this.state.activeDataTabId = "tab0";
            }
            this.setState({ ready: true });
        }).catch(Reason => {
        });
    }

    readData = () => {
        this.readHH1Data();        
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.isVisible !== this.props.isVisible && this.props.isVisible !== this.state.isVisible)        
            this.setState({ activeMainTab: "1" });      

        if (prevProps.uhconfig !== this.props.uhconfig && this.props.plantyp === 4)
            this.readData();

    }

    componentDidMount() {
        this.readData();
    }

    createChangeColumn = (field1: string, field2: string, footer1: number, footer2: number, ausgaben: boolean, id: number, sumid: string | undefined, showFooter: boolean = true, text: string = "Vorjahr", pjFarben: boolean = false) => {
        return {
            dataField: "dummy" + id.toString(),
            text: "+/-",
            headerAlign: 'right',
            align: 'right',
            formatter: (cellContent: string, row: { [key: string]: number }) => {    
                            
                var res = Utilities.calcArrowRotAndColor(row[field1], row[field2], ausgaben, this.props.settings);


                var ttId = row.id ? "kslplus-label-" + id + "_" + row.id : "";
                ttId = ttId + (ausgaben ? "-a" : "-e");

                return (
                    <div>
                        <div id={ttId}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 156" width="100%">
                                <g transform={"rotate(" + res.rotation + ", 82, 78)"}>
                                    <path d="m0,86h36v70h92V86h36L82,0" fill={res.color} />
                                </g>
                            </svg>
                        </div>
                        <UncontrolledPopover
                            size="lg"
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-change-popover">
                            <PopoverBody className="kslplus-change-popover-body">
                                    <div className="kslplus-change-popover-headline">{"Gegenüber " + text + ": " + Utilities.FormatCurrency(res.changeA, 2, 3, '.', ',') + " EUR (" + Utilities.FormatCurrency((res.changeP-1)*100, 2, 0, '.', ',') + "  %)"}</div>                                                                                                                                                                                                            
                            </PopoverBody>
                        </UncontrolledPopover>  
                    </div>

                );

            },
            footerStyle: (colum: any, colIndex: any) => {
                if (pjFarben)
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };

                return {};
            },
            headerStyle: (colum: any, colIndex: any) => {
                if (pjFarben)
                    return { width: "40px", backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };


                return { width: "40px" };
            },
            style: (colum: any, colIndex: any) => {
                if (pjFarben)
                    return { width: "40px", backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };

                return { width: "40px" };
            },
            footer: showFooter ? id : undefined,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: number) => {
                var res = Utilities.calcArrowRotAndColor(footer1, footer2, ausgaben, this.props.settings);

                var ttId = "kslplus-label-column-" + sumid + "-" + (ausgaben ? "a-" : "e-") + columnIndex;

                return (
                    <div>
                        <div id={ttId}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 156" width="100%">
                                <g transform={"rotate(" + res.rotation + ", 82, 78)"}>
                                    <path d="m0,86h36v70h92V86h36L82,0" fill={res.color} />
                                </g>
                            </svg>
                        </div>
                        <UncontrolledPopover
                            size="lg"
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-change-popover">
                            <PopoverBody className="kslplus-change-popover-body">
                                    <div className="kslplus-change-popover-headline">{"Gegenüber " + text + ": " + Utilities.FormatCurrency(res.changeA, 2, 3, '.', ',') + " EUR (" + Utilities.FormatCurrency((res.changeP-1)*100, 2, 0, '.', ',') + "  %)"}</div>                                                                                                                                                                                                            
                            </PopoverBody>
                        </UncontrolledPopover>  
                    </div>

                );
            }
        };
    }

    createDataColumnUH = (field: string, jahr: number, footer: number, footer2: number | undefined, id: number, text: string, hidden: boolean = false, ttE: boolean = false, ttF: boolean = false, testfield: string = "", komfield: string = "", pkTip: boolean = false, gsTip: boolean = false, ausgefuehrtTip: boolean = false) => {
        return {
            dataField: field,
            text: text + " " + jahr + " (EUR)",
            headerAlign: 'right',
            align: 'right',
            hidden: hidden,
            headerFormatter: (column: any, columnIndex: any) => {
                if (ausgefuehrtTip) {

                    var ttId = "header-tt-gl-erg-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>

                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Ausgeführt errechnet sich wie folgt:</div></div>
                                        <br />

                                        {this.props.uhconfig && this.props.uhconfig.verfuegtSpaltenDic &&
                                            Utilities.createAusgefuehrtTooltipItems(this.props.uhconfig.verfuegtSpaltenDic, this.props.settings.importFormat)
                                        }


                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else if (gsTip) {

                    var ttId = "header-tt-gl-erg-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>

                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Das Gesamtsoll errechnet sich wie folgt:</div></div>
                                        <br />

                                        {this.props.uhconfig && this.props.uhconfig.fortAnsatzSpaltenDic &&
                                            Utilities.createGSTooltipItems(this.props.uhconfig.fortAnsatzSpaltenDic, this.props.settings.importFormat)
                                        }


                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else if (ttE) {

                    var ttId = "header-tt-gl-erg-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-data">{text + " " + jahr + " (EUR)"} </div>

                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Das Ergebnis (Rechnungsergebnis) errechnet sich wie folgt:</div></div>
                                        <br />
                                        <div className="kslplus-header-tt-block"><div className="kslplus-label">Ausgeführtes Soll auf lfd. Ansatz</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang auf Kassenreste aus Vj.</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Neue Haushaltsreste</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang Haushaltsreste aus Vj.</div></div>


                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else if (ttF) {

                    var ttId = "header-tt-gl-fort-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-data">{text + " " + jahr + " (EUR)"} </div>
                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Der fortgeschrieben Ansatz errechnet sich wie folgt:</div></div>
                                        <br />
                                        <div className="kslplus-header-tt-block"><div className="kslplus-label">Ansatz</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Nachtragswerte</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Sollveränderungen</div></div>
                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else {
                    return (
                        <div className="kslplus-tabellen-data">{text + " " + jahr + " (EUR)"} </div>
                    );
                }
            },
            formatter: (cellContent: string, row: { [key: string]: number }) => {
                return (
                    <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field], 2, 3, '.', ',')} </div>
                );

            },
            footer: id,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                if (footer2 !== undefined)
                    return (<div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')} </div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer2, 2, 3, '.', ',')} </div></div>);

                return (
                    <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')} </div>
                );
            },
            style: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if (testfield != "" && row[testfield])
                    return { backgroundColor: this.props.uhconfig && this.props.uhconfig.hfarbeProgMan ? this.props.uhconfig.hfarbeProgMan : 'lightcoral', color: this.props.uhconfig && this.props.uhconfig.vfarbeProgMan ? this.props.uhconfig.vfarbeProgMan : 'black' }
            }
        };
    }


    createDataColumnJR = (field: string, jahr: number, footer: number, footer2: number|undefined, id: number, text: string, ttE: boolean = false, ttF: boolean = false) => {        
        return {
            dataField: field,
            text: text + " "+ jahr + " (EUR)",
            headerAlign: 'right',
            align: 'right',   
            headerFormatter: (column: any, columnIndex: any) => {
                if ( ttE )    
                {
                    
                    var ttId = "header-tt-gl-erg-" + field;
                
                    return (  
                        <div>                                          
                            <div id={ttId} className="kslplus-tabellen-data">{text + " "+ jahr + " (EUR)"} </div>
                    
                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>                                          
                                            <div><div className="kslplus-header-tt-block1">Das Ergebnis (Rechnungsergebnis) errechnet sich wie folgt:</div></div>
                                            <br/>                                        
                                        <div className="kslplus-header-tt-block"><div className="kslplus-label">Ausgeführtes Soll auf lfd. Ansatz</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang auf Kassenreste aus Vj.</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Neue Haushaltsreste</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang Haushaltsreste aus Vj.</div></div>
                                        

                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>                    
                        </div>
                        ); 
                }
                else if ( ttF )    
                {
                    
                    var ttId = "header-tt-gl-fort-" + field;
                
                    return (             
                            <div>                               
                                <div id={ttId} className="kslplus-tabellen-data">{text + " "+ jahr + " (EUR)"} </div>                    
                                <UncontrolledPopover
                                    size="xl"
                                    placement="right"
                                    target={ttId}
                                    trigger="hover"
                                    className="kslplus-header-popover">
                                    <PopoverBody className="kslplus-header-popover-body">
                                        <Container>                                          
                                             <div><div className="kslplus-header-tt-block1">Der fortgeschrieben Ansatz errechnet sich wie folgt:</div></div>
                                             <br/>                                        
                                            <div className="kslplus-header-tt-block"><div className="kslplus-label">Ansatz</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Nachtragswerte</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Sollveränderungen</div></div>                                                                                    
                                        </Container>
                                    </PopoverBody>
                                </UncontrolledPopover>                    
                            </div>
                        ); 
                }
                else
                {
                    return (                                            
                        <div className="kslplus-tabellen-data">{text + " "+ jahr + " (EUR)"} </div>
                    );
                }
            },                    
            formatter: (cellContent: string, row: {[key: string]: number}) => {                
                return (
                    <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field], 2, 3, '.', ',')} </div>
                );
                
            },
            footer: id,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                if ( footer2 !== undefined )
                    return (<div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')} </div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer2, 2, 3, '.', ',')} </div></div>);
            
                return (                    
                        <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')} </div>
                    );
            }
        };
    }

    createDataColumn = (field: string, jahr: number, footer: number, footer2: number|undefined, id: number) => {
        var text = "";
        if (jahr - this.props.jahr == -2 && this.props.plantyp < 3 && this.props.settings.vvjVorl)
            text = "vorl. Ergebnis ";
        else if (  jahr - this.props.jahr < -1 )
            text = "Ergebnis ";
        else if ( jahr - this.props.jahr > (this.props.settings.doppelhaushalt ? 1 : 0) )
            text = "Finanzplan ";
        else
            text = "Ansatz "; 

        return {
            dataField: field,
            text: text + jahr + " (EUR)",
            headerAlign: 'right',
            align: 'right',    
            footerStyle: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr === 0 || this.props.settings.doppelhaushalt && jahr - this.props.jahr === 1)) {
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };
                }
                return {};

            },
            formatter: (cellContent: string, row: { [key: string]: number }) => {      
                var nk = 2;
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr > -2) && this.props.settings.ansatzOhneDS) {
                    nk = 0;
                }

                return (
                    <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field], nk, 3, '.', ',')} </div>
                );
                
            },
            footer: id,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                var nk = 2;
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr > -2) && this.props.settings.ansatzOhneDS) {
                    nk = 0;
                }


                if ( footer2 !== undefined )
                    return (<div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, nk, 3, '.', ',')} </div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer2, nk, 3, '.', ',')} </div></div>);
            
                return (                    
                        <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, nk, 3, '.', ',')} </div>
                    );
            },
            style: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr === 0 || this.props.settings.doppelhaushalt && jahr - this.props.jahr === 1)) {
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };
                }

                //return { width: '15%' };
            },
            headerStyle: (colum: any, colIndex: any) => {
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr === 0 || this.props.settings.doppelhaushalt && jahr - this.props.jahr === 1)) {
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };
                }
                else
                    return {};
            }
        };
    }

    createDataPercentColumn = (field: string, field1: string, jahr: number, id: number, text: string = "", hidden: boolean = false, ttE: boolean = false, ttF: boolean = false, footVal: number, footVal1: number) => {    
        if (text == "") {
            if (jahr - this.props.jahr < -1)
                text = "Ergebnis";
            else if (jahr - this.props.jahr > (this.props.settings.doppelhaushalt ? 1 : 0))
                text = "Finanzplan";
            else
                text = "Ansatz";
        }
        return {
            dataField: field,
            text: text + " " + jahr + " (%)",
            headerAlign: 'right',
            align: 'right',
            hidden: hidden,
            headerFormatter: (column: any, columnIndex: any) => {
                return (
                    <div className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(%)</div></div>
                );
            },
            formatter: (cellContent: string, row: { [key: string]: number }) => {
                if (Math.round(row[field] * 100) / 100 === 0)
                    return (
                        <div className="kslplus-tabellen-data">0,00</div>
                    );
                else if (row[field1] === 0 && row[field] < 0) {
                    return (<div className="kslplus-tabellen-data">-Ꝏ</div>);
                }
                else if (row[field1] === 0) {

                    return (<div className="kslplus-tabellen-data">Ꝏ</div>);
                }
                else
                    return (
                        <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field] / row[field1] * 100.0, 2, 3, '.', ',')}</div>
                    );

            },
            footer: id,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                if (Math.round(footVal * 100) / 100 === 0)
                    return (
                        <div className="kslplus-tabellen-footer">0,00</div>
                    );
                else if (footVal1 === 0 && footVal < 0) {
                    return (<div className="kslplus-tabellen-footer">-Ꝏ</div>);
                }
                else if (footVal1 === 0) {

                    return (<div className="kslplus-tabellen-footer">Ꝏ</div>);
                }
                return (
                    <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footVal / footVal1 * 100.0, 2, 3, '.', ',')} </div>
                );
            }
        };
    }

    createColumnsJR = (data: KSLPlusAPI.HaushaltsEbene | null, totalSum:  KSLPlusAPI.HaushaltsEbene, totalSub: KSLPlusAPI.HaushaltsEbene | null | undefined, totalHaben:  KSLPlusAPI.HaushaltsEbene | null, totalHabenSub: KSLPlusAPI.HaushaltsEbene | null, ausgaben: boolean) =>  {        
        var columns = [];
        columns.push({
            dataField: 'bezeichnung',
            text: ausgaben?"Ausgaben/Bezeichnung":"Einnahmen" + "/Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '180px', textAlign: 'left' };
            },
            
            style: (colum: any, colIndex: any) => {
                return { width: '180px' };
            },
            footer: "1",
            footerFormatter: (column: any, columnIndex: any) => {
                if ( ausgaben )
                    return (<div><div>Ausgaben gesamt</div><div>Ergebnis E-A</div></div>);
                    
                return <div>Einnahmen gesamt</div>;
            }
        });

        if ( data == null )
            return columns;

        if (!data.jahre)
            return columns;

        var id = 2;
        if (ausgaben) {
            var fieldPrev: string | undefined;
            var sumPrev = 0;

            
            if (data.jahre.findIndex(d => d == this.props.jahr - 4) >= 0 && totalSum.ergebnisPJM4_haben != null && totalSum.ergebnisPJM4_soll != null && totalSum.ergebnisPJM4_soll != 0 && data.ergebnisPJM4_haben != null && data.ergebnisPJM4_soll != null && data.ergebnisPJM4_soll != 0 && totalHaben != null && totalHaben.ergebnisPJM4_haben != null && totalHaben.ergebnisPJM4_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_haben != undefined ? totalSub.ergebnisPJM4_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_soll != undefined ? totalSub.ergebnisPJM4_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM4_haben != undefined ? totalHabenSub.ergebnisPJM4_haben : 0);                
                columns.push(this.createDataColumnJR("ergebnisPJM4_soll", this.props.jahr - 4, totalSum.ergebnisPJM4_soll - subsoll, totalHaben.ergebnisPJM4_haben - totalSum.ergebnisPJM4_soll - subErgebnis + subsoll, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4_soll", sumPrev, totalSum.ergebnisPJM4_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "ergebnisPJM4_soll";
                sumPrev = totalSum.ergebnisPJM4_soll - subsoll;                
            }
            else {
                fieldPrev = undefined;
            }
            

            if (data.jahre.findIndex(d => d == this.props.jahr - 3) >= 0 && totalSum.ergebnisPJM3_haben != null && totalSum.ergebnisPJM3_soll != null && totalSum.ergebnisPJM3_soll != 0 && data.ergebnisPJM3_haben != null && data.ergebnisPJM3_soll != null && data.ergebnisPJM3_soll != 0 && totalHaben != null && totalHaben.ergebnisPJM3_haben != null && totalHaben.ergebnisPJM3_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_haben != undefined ? totalSub.ergebnisPJM3_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_soll != undefined ? totalSub.ergebnisPJM3_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM3_haben != undefined ? totalHabenSub.ergebnisPJM3_haben : 0);                
                columns.push(this.createDataColumnJR("ergebnisPJM3_soll", this.props.jahr - 3, totalSum.ergebnisPJM3_soll - subsoll, totalHaben.ergebnisPJM3_haben - totalSum.ergebnisPJM3_soll - subErgebnis + subsoll, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3_soll", sumPrev, totalSum.ergebnisPJM3_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "ergebnisPJM3_soll";
                sumPrev = totalSum.ergebnisPJM3_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisPJM2_haben != null && totalSum.ergebnisPJM2_soll != null && totalSum.ergebnisPJM2_soll != 0 && data.ergebnisPJM2_haben != null && data.ergebnisPJM2_soll != null && data.ergebnisPJM2_soll != 0 && totalHaben != null && totalHaben.ergebnisPJM2_haben != null && totalHaben.ergebnisPJM2_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_haben != undefined ? totalSub.ergebnisPJM2_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_soll != undefined ? totalSub.ergebnisPJM2_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM2_haben != undefined ? totalHabenSub.ergebnisPJM2_haben : 0);               
                columns.push(this.createDataColumnJR("ergebnisPJM2_soll", this.props.jahr - 2, totalSum.ergebnisPJM2_soll - subsoll, totalHaben.ergebnisPJM2_haben - totalSum.ergebnisPJM2_soll - subErgebnis + subsoll, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2_soll", sumPrev, totalSum.ergebnisPJM2_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }                
                fieldPrev = "ergebnisPJM2_soll";
                sumPrev = totalSum.ergebnisPJM2_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ergebnisPJM1_haben != null && totalSum.ergebnisPJM1_soll != null && totalSum.ergebnisPJM1_soll != 0 && data.ergebnisPJM1_haben != null && data.ergebnisPJM1_soll != null && data.ergebnisPJM1_soll != 0 && totalHaben != null && totalHaben.ergebnisPJM1_haben != null && totalHaben.ergebnisPJM1_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_haben != undefined ? totalSub.ergebnisPJM1_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_soll != undefined ? totalSub.ergebnisPJM1_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM1_haben != undefined ? totalHabenSub.ergebnisPJM1_haben : 0);                
                columns.push(this.createDataColumnJR("ergebnisPJM1_soll", this.props.jahr - 1, totalSum.ergebnisPJM1_soll - subsoll, totalHaben.ergebnisPJM1_haben - totalSum.ergebnisPJM1_soll - subErgebnis + subsoll, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1_soll", sumPrev, totalSum.ergebnisPJM1_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "ergebnisPJM1_soll";
                sumPrev = totalSum.ergebnisPJM1_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }
            
            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPJfort_haben != null && totalSum.ansatzPJfort_soll != null && totalSum.ansatzPJfort_soll != 0 && data.ansatzPJfort_haben != null && data.ansatzPJfort_soll != null && data.ansatzPJfort_soll != 0 && totalHaben != null && totalHaben.ansatzPJfort_haben != null && totalHaben.ansatzPJfort_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_haben != undefined ? totalSub.ansatzPJfort_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_soll != undefined ? totalSub.ansatzPJfort_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ansatzPJfort_haben != undefined ? totalHabenSub.ansatzPJfort_haben : 0);                
                columns.push(this.createDataColumnJR("ansatzPJfort_soll", this.props.jahr, totalSum.ansatzPJfort_soll - subsoll, totalHaben.ansatzPJfort_haben - totalSum.ansatzPJfort_soll - subErgebnis + subsoll, id, "Fortgeschriebener Ansatz", false, true));
                id++;
                
                fieldPrev = "ansatzPJfort_soll";
                sumPrev = totalSum.ansatzPJfort_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ergebnisPJ_haben != null && totalSum.ergebnisPJ_soll != null && totalSum.ergebnisPJ_soll != 0 && data.ergebnisPJ_haben != null && data.ergebnisPJ_soll != null && data.ergebnisPJ_soll != 0 && totalHaben != null && totalHaben.ergebnisPJ_haben != null && totalHaben.ergebnisPJ_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJ_haben != undefined ? totalSub.ergebnisPJ_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJ_soll != undefined ? totalSub.ergebnisPJ_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJ_haben != undefined ? totalHabenSub.ergebnisPJ_haben : 0);                
                columns.push(this.createDataColumnJR("ergebnisPJ_soll", this.props.jahr, totalSum.ergebnisPJ_soll - subsoll, totalHaben.ergebnisPJ_haben - totalSum.ergebnisPJ_soll - subErgebnis + subsoll, id, "Ergebnis", true));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJ_soll", sumPrev, totalSum.ergebnisPJ_soll - subsoll, true, id, totalSum.id, true, "fortgeschriebenen Ansatz"));
                    id++;
                }
                fieldPrev = "ergebnisPJ_soll";
                sumPrev = totalSum.ergebnisPJ_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }                        

        }
        else {
            var fieldPrev: string | undefined;
            var sumPrev = 0;

            if (data.jahre.findIndex(d => d == this.props.jahr - 4) >= 0 && totalSum.ergebnisPJM4_haben != null && totalSum.ergebnisPJM4_haben != 0)
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_haben != undefined ? totalSub.ergebnisPJM4_haben : 0);                            
                columns.push(this.createDataColumnJR("ergebnisPJM4_haben", this.props.jahr - 4, totalSum.ergebnisPJM4_haben - subhaben, undefined, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4_haben", sumPrev, totalSum.ergebnisPJM4_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "ergebnisPJM4_haben";
                sumPrev = totalSum.ergebnisPJM4_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr - 3) >= 0 && totalSum.ergebnisPJM3_haben != null && totalSum.ergebnisPJM3_haben != 0)
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_haben != undefined ? totalSub.ergebnisPJM3_haben : 0);
                columns.push(this.createDataColumnJR("ergebnisPJM3_haben", this.props.jahr - 3, totalSum.ergebnisPJM3_haben - subhaben, undefined, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3_haben", sumPrev, totalSum.ergebnisPJM3_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "ergebnisPJM3_haben";
                sumPrev = totalSum.ergebnisPJM3_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisPJM2_haben != null && totalSum.ergebnisPJM2_haben != 0)
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_haben != undefined ? totalSub.ergebnisPJM2_haben : 0);
                columns.push(this.createDataColumnJR("ergebnisPJM2_haben", this.props.jahr - 2, totalSum.ergebnisPJM2_haben - subhaben, undefined, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2_haben", sumPrev, totalSum.ergebnisPJM2_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "ergebnisPJM2_haben";
                sumPrev = totalSum.ergebnisPJM2_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ergebnisPJM1_haben != null && totalSum.ergebnisPJM1_haben != 0)
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_haben != undefined ? totalSub.ergebnisPJM1_haben : 0);
                columns.push(this.createDataColumnJR("ergebnisPJM1_haben", this.props.jahr - 1, totalSum.ergebnisPJM1_haben - subhaben, undefined, id, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1_haben", sumPrev, totalSum.ergebnisPJM1_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "ergebnisPJM1_haben";
                sumPrev = totalSum.ergebnisPJM1_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }
            
            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPJfort_haben != null && totalSum.ansatzPJfort_haben != 0)
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_haben != undefined ? totalSub.ansatzPJfort_haben : 0);
                columns.push(this.createDataColumnJR("ansatzPJfort_haben", this.props.jahr, totalSum.ansatzPJfort_haben - subhaben, undefined, id, "Fortgeschriebener Ansatz", false, true));
                id++;                
                fieldPrev = "ansatzPJfort_haben";
                sumPrev = totalSum.ansatzPJfort_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ergebnisPJ_haben != null && totalSum.ergebnisPJ_haben != 0)
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJ_haben != undefined ? totalSub.ergebnisPJ_haben : 0);
                columns.push(this.createDataColumnJR("ergebnisPJ_haben", this.props.jahr, totalSum.ergebnisPJ_haben - subhaben, undefined, id, "Ergebnis", true));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJ_haben", sumPrev, totalSum.ergebnisPJ_haben - subhaben, false, id, totalSum.id, true, "fortgeschriebenen Ansatz"));
                    id++;
                }
                fieldPrev = "ergebnisPJ_haben";
                sumPrev = totalSum.ergebnisPJ_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }                        

        }
        return columns;
    }


    createColumnsUH = (data: KSLPlusAPI.HaushaltsEbene | null, totalSum: KSLPlusAPI.HaushaltsEbene, totalSub: KSLPlusAPI.HaushaltsEbene | null | undefined, totalHaben: KSLPlusAPI.HaushaltsEbene | null, totalHabenSub: KSLPlusAPI.HaushaltsEbene | null, ausgaben: boolean) => {        
        var columns = [];
        columns.push({
            dataField: 'bezeichnung',
            text: ausgaben ? "Ausgaben/Bezeichnung" : "Einnahmen" + "/Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '180px', textAlign: 'left' };
            },

            style: (colum: any, colIndex: any) => {
                return { width: '180px' };
            },
            footer: "1",
            footerFormatter: (column: any, columnIndex: any) => {
                if (ausgaben)
                    return (<div><div>Ausgaben gesamt</div><div>Ergebnis E-A</div></div>);

                return <div>Einnahmen gesamt</div>;
            }
        });


        

        if (data == null)
            return columns;
        

        if (!data.jahre)
            return columns;
            
        if (data.jahre.findIndex(d => d == this.props.jahr - 3) != -1 || data.jahre.findIndex(d => d == this.props.jahr - 4) != -1) {
            columns.push({
                dataField: '',
                isDummyField: true,
                headerAlign: 'middle',
                headerFormatter: (column: any, columnIndex: any) => {
                    var ttId = "kslplus-tt-pjcol" + (ausgaben ? "a" : "e");
                    return (<div>
                            <div id={ttId}>{this.state.alleJahre ? "-" : "+"}</div >
                                <UncontrolledPopover                                    
                                    placement="right"
                                    target={ttId}
                                    trigger="hover"
                                    className="kslplus-header-popover">
                                    <PopoverBody className="kslplus-header-popover-body">
                                        <Container>
                                            <div>
                                                {"Zusätzliche Jahre " + (this.state.alleJahre ? "verbergen" : "anzeigen")}
                                            </div>
                                        </Container>
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div >);

                },
                text: this.state.alleJahre ? "-" : "+",
                headerStyle: (colum: any, colIndex: any) => {
                    return { fontWeight: 900, width: '30px', textAlign: 'left' };
                },

                style: (colum: any, colIndex: any) => {
                    return { width: '30px' };
                },
                footer: -1,                
                headerEvents: {
                    onClick: (e: any, column: any, columnIndex: any) => { this.setState({alleJahre: !this.state.alleJahre}) }
                }
            });
        }
        
        var id = 2;
        if (ausgaben) {
            
            var fieldPrev: string | undefined;
            var sumPrev = 0;

            
            if (data.jahre.findIndex(d => d == this.props.jahr - 4) >= 0 && totalSum.ergebnisPJM4_haben != null && totalSum.ergebnisPJM4_soll != null && totalSum.ergebnisPJM4_soll != 0 && data.ergebnisPJM4_haben != null && data.ergebnisPJM4_soll != null && data.ergebnisPJM4_soll != 0 && totalHaben != null && totalHaben.ergebnisPJM4_haben != null && totalHaben.ergebnisPJM4_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_haben != undefined ? totalSub.ergebnisPJM4_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_soll != undefined ? totalSub.ergebnisPJM4_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM4_haben != undefined ? totalHabenSub.ergebnisPJM4_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM4_soll", this.props.jahr - 4, totalSum.ergebnisPJM4_soll - subsoll, totalHaben.ergebnisPJM4_haben - totalSum.ergebnisPJM4_soll - subErgebnis + subsoll, id, "Ergebnis", !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4_soll", sumPrev, totalSum.ergebnisPJM4_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM4_soll";
                //sumPrev = totalSum.ergebnisPJM4_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }


            if (data.jahre.findIndex(d => d == this.props.jahr - 3) >= 0 && totalSum.ergebnisPJM3_haben != null && totalSum.ergebnisPJM3_soll != null && totalSum.ergebnisPJM3_soll != 0 && data.ergebnisPJM3_haben != null && data.ergebnisPJM3_soll != null && data.ergebnisPJM3_soll != 0 && totalHaben != null && totalHaben.ergebnisPJM3_haben != null && totalHaben.ergebnisPJM3_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_haben != undefined ? totalSub.ergebnisPJM3_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_soll != undefined ? totalSub.ergebnisPJM3_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM3_haben != undefined ? totalHabenSub.ergebnisPJM3_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM3_soll", this.props.jahr - 3, totalSum.ergebnisPJM3_soll - subsoll, totalHaben.ergebnisPJM3_haben - totalSum.ergebnisPJM3_soll - subErgebnis + subsoll, id, "Ergebnis", !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3_soll", sumPrev, totalSum.ergebnisPJM3_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM3_soll";
                //sumPrev = totalSum.ergebnisPJM3_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }
            

            if (data.jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisPJM2_haben != null && totalSum.ergebnisPJM2_soll != null && totalSum.ergebnisPJM2_soll != 0 && data.ergebnisPJM2_haben != null && data.ergebnisPJM2_soll != null && data.ergebnisPJM2_soll != 0 && totalHaben != null && totalHaben.ergebnisPJM2_haben != null && totalHaben.ergebnisPJM2_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_haben != undefined ? totalSub.ergebnisPJM2_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_soll != undefined ? totalSub.ergebnisPJM2_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM2_haben != undefined ? totalHabenSub.ergebnisPJM2_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM2_soll", this.props.jahr - 2, totalSum.ergebnisPJM2_soll - subsoll, totalHaben.ergebnisPJM2_haben - totalSum.ergebnisPJM2_soll - subErgebnis + subsoll, id, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2_soll", sumPrev, totalSum.ergebnisPJM2_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM2_soll";
                //sumPrev = totalSum.ergebnisPJM2_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ergebnisPJM1_haben != null && totalSum.ergebnisPJM1_soll != null && totalSum.ergebnisPJM1_soll != 0 && data.ergebnisPJM1_haben != null && data.ergebnisPJM1_soll != null && data.ergebnisPJM1_soll != 0 && totalHaben != null && totalHaben.ergebnisPJM1_haben != null && totalHaben.ergebnisPJM1_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_haben != undefined ? totalSub.ergebnisPJM1_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_soll != undefined ? totalSub.ergebnisPJM1_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM1_haben != undefined ? totalHabenSub.ergebnisPJM1_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM1_soll", this.props.jahr - 1, totalSum.ergebnisPJM1_soll - subsoll, totalHaben.ergebnisPJM1_haben - totalSum.ergebnisPJM1_soll - subErgebnis + subsoll, id, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1_soll", sumPrev, totalSum.ergebnisPJM1_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM1_soll";
                //sumPrev = totalSum.ergebnisPJM1_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPJfort_haben != null && totalSum.ansatzPJfort_soll != null && totalSum.ansatzPJfort_soll != 0 && data.ansatzPJfort_haben != null && data.ansatzPJfort_soll != null && data.ansatzPJfort_soll != 0 && totalHaben != null && totalHaben.ansatzPJfort_haben != null && totalHaben.ansatzPJfort_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_haben != undefined ? totalSub.ansatzPJfort_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_soll != undefined ? totalSub.ansatzPJfort_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ansatzPJfort_haben != undefined ? totalHabenSub.ansatzPJfort_haben : 0);
                columns.push(this.createDataColumnUH("ansatzPJfort_soll", this.props.jahr, totalSum.ansatzPJfort_soll - subsoll, totalHaben.ansatzPJfort_haben - totalSum.ansatzPJfort_soll - subErgebnis + subsoll, id, "Gesamtsoll", false, false, false, "", "", false, true));
                id++;

                fieldPrev = "ansatzPJfort_soll";
                sumPrev = totalSum.ansatzPJfort_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }
            
            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.verfuegt_haben != null && totalSum.verfuegt_soll != null && totalSum.verfuegt_soll != 0 && data.verfuegt_haben != null && data.verfuegt_soll != null && data.verfuegt_soll != 0 && totalHaben != null && totalHaben.verfuegt_haben != null && totalHaben.verfuegt_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.verfuegt_haben != undefined ? totalSub.verfuegt_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.verfuegt_soll != undefined ? totalSub.verfuegt_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.verfuegt_soll != undefined ? totalHabenSub.verfuegt_soll : 0);
                columns.push(this.createDataColumnUH("verfuegt_soll", this.props.jahr, totalSum.verfuegt_soll - subsoll, totalHaben.verfuegt_haben - totalSum.verfuegt_soll - subErgebnis + subsoll, id, "Ausgeführt", false, false, false, "", "", false, false, true));
                id++;

                fieldPrev = "verfuegt_soll";
                //sumPrev = totalSum.verfuegt_soll - subsoll;

                columns.push(this.createDataPercentColumn("verfuegt_soll", "ansatzPJfort_soll", this.props.jahr, id, "Ausgeführt", this.state.alleJahre, false, false, totalSum.verfuegt_soll - subsoll, sumPrev ));
                id++;
                fieldPrev = undefined;
            }
            else {
                fieldPrev = undefined;
            }

            /*
            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                columns.push(this.createDataPercentColumn("verfuegt_soll", "ansatzPJfort_soll", this.props.jahr, id, "Ausgeführt", false, false));
                id++;
                fieldPrev = undefined;

            }
            else {
                fieldPrev = undefined;
            }
            */

            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.verfuegbar_haben != null && totalSum.verfuegbar_soll != null && totalSum.verfuegbar_soll != 0 && data.verfuegbar_haben != null && data.verfuegbar_soll != null && data.verfuegbar_soll != 0 && totalHaben != null && totalHaben.verfuegbar_haben != null && totalHaben.verfuegbar_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.verfuegbar_haben != undefined ? totalSub.verfuegbar_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.verfuegbar_soll != undefined ? totalSub.verfuegbar_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.verfuegbar_soll != undefined ? totalHabenSub.verfuegbar_soll : 0);
                columns.push(this.createDataColumnUH("verfuegbar_soll", this.props.jahr, totalSum.verfuegbar_soll - subsoll, totalHaben.verfuegbar_haben - totalSum.verfuegbar_soll - subErgebnis + subsoll, id, "Verfügbar", false, false, false));
                id++;

                fieldPrev = "verfuegbar_soll";
                //sumPrev = totalSum.verfuegbar_soll - subsoll;

                columns.push(this.createDataPercentColumn("verfuegbar_soll", "ansatzPJfort_soll", this.props.jahr, id, "Verfügbar", this.state.alleJahre, false, false, totalSum.verfuegbar_soll - subsoll, sumPrev ));
                id++;
                fieldPrev = undefined;
            }
            else {
                fieldPrev = undefined;
            }

            /*
            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                columns.push(this.createDataPercentColumn("verfuegbar_soll", "ansatzPJfort_soll", this.props.jahr, id, "Einzunehmen / Verfügbar", false, false));
                id++;
                fieldPrev = undefined;

            }
            else {
                fieldPrev = undefined;
            }
            */

            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.progAuto_haben != null && totalSum.progAuto_soll != null && totalSum.progAuto_soll != 0 && data.progAuto_haben != null && data.progAuto_soll != null && data.progAuto_soll != 0 && totalHaben != null && totalHaben.progAuto_haben != null && totalHaben.progAuto_haben !== undefined) {                        
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.progAuto_haben != undefined ? totalSub.progAuto_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.progAuto_soll != undefined ? totalSub.progAuto_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.progAuto_soll != undefined ? totalHabenSub.progAuto_soll : 0);
                columns.push(this.createDataColumnUH("progAuto_soll", this.props.jahr, totalSum.progAuto_soll - subsoll, totalHaben.progAuto_haben - totalSum.progAuto_soll - subErgebnis + subsoll, id, "Prognose errechnet", false, false, false));
                id++;

                fieldPrev = "progAuto_soll";
                //sumPrev = totalSum.progAuto_soll - subsoll;
                
                columns.push(this.createChangeColumn("ansatzPJfort_soll", "progAuto_soll", sumPrev, totalSum.progAuto_soll - subsoll, true, id, totalSum.id, true, "Gesamtsoll"));
                
                id++;
                
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.progMan_haben != null && totalSum.progMan_soll != null && totalSum.progMan_soll != 0 && data.progMan_haben != null && data.progMan_soll != null && data.progMan_soll != 0 && totalHaben != null && totalHaben.progMan_haben != null && totalHaben.progMan_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.progMan_haben != undefined ? totalSub.progMan_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.progMan_soll != undefined ? totalSub.progMan_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.progMan_soll != undefined ? totalHabenSub.progMan_soll : 0);
                columns.push(this.createDataColumnUH("progMan_soll", this.props.jahr, totalSum.progMan_soll - subsoll, totalHaben.progMan_haben - totalSum.progMan_soll - subErgebnis + subsoll, id, "Prognose manuell", false, false, false, "hasProgMan"));
                id++;

                fieldPrev = "progMan_soll";
                //sumPrev = totalSum.progMan_soll - subsoll;

                columns.push(this.createChangeColumn("ansatzPJfort_soll", "progMan_soll", sumPrev, totalSum.progMan_soll - subsoll, true, id, totalSum.id, true, "Gesamtsoll"));

                id++;

            }
            else {
                fieldPrev = undefined;
            }


            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.istAo_haben != null && totalSum.istAo_soll != null && totalSum.istAo_soll != 0 && data.istAo_haben != null && data.istAo_soll != null && data.istAo_soll != 0 && totalHaben != null && totalHaben.istAo_haben != null && totalHaben.istAo_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.istAo_haben != undefined ? totalSub.istAo_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.istAo_soll != undefined ? totalSub.istAo_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.istAo_haben != undefined ? totalHabenSub.istAo_haben : 0);
                columns.push(this.createDataColumnUH("istAo_soll", this.props.jahr, totalSum.istAo_soll - subsoll, totalHaben.istAo_haben - totalSum.istAo_soll - subErgebnis + subsoll, id, "Ist AO", false, false, false));
                id++;

                fieldPrev = "istAo_soll";
                sumPrev = totalSum.istAo_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

        }
        else {
            var fieldPrev: string | undefined;
            var sumPrev = 0;

            
            if (data.jahre.findIndex(d => d == this.props.jahr - 4) >= 0 && totalSum.ergebnisPJM4_haben != null && totalSum.ergebnisPJM4_haben != 0) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_haben != undefined ? totalSub.ergebnisPJM4_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM4_haben", this.props.jahr - 4, totalSum.ergebnisPJM4_haben - subhaben, undefined, id, "Ergebnis", !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4_haben", sumPrev, totalSum.ergebnisPJM4_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM4_haben";
                //sumPrev = totalSum.ergebnisPJM4_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr - 3) >= 0 && totalSum.ergebnisPJM3_haben != null && totalSum.ergebnisPJM3_haben != 0) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_haben != undefined ? totalSub.ergebnisPJM3_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM3_haben", this.props.jahr - 3, totalSum.ergebnisPJM3_haben - subhaben, undefined, id, "Ergebnis", !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3_haben", sumPrev, totalSum.ergebnisPJM3_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM3_haben";
                //sumPrev = totalSum.ergebnisPJM3_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }
            

            if (data.jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisPJM2_haben != null && totalSum.ergebnisPJM2_haben != 0) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_haben != undefined ? totalSub.ergebnisPJM2_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM2_haben", this.props.jahr - 2, totalSum.ergebnisPJM2_haben - subhaben, undefined, id, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2_haben", sumPrev, totalSum.ergebnisPJM2_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM2_haben";
                //sumPrev = totalSum.ergebnisPJM2_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ergebnisPJM1_haben != null && totalSum.ergebnisPJM1_haben != 0) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_haben != undefined ? totalSub.ergebnisPJM1_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM1_haben", this.props.jahr - 1, totalSum.ergebnisPJM1_haben - subhaben, undefined, id, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1_haben", sumPrev, totalSum.ergebnisPJM1_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM1_haben";
                //sumPrev = totalSum.ergebnisPJM1_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPJfort_haben != null && totalSum.ansatzPJfort_haben != 0) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_haben != undefined ? totalSub.ansatzPJfort_haben : 0);
                columns.push(this.createDataColumnUH("ansatzPJfort_haben", this.props.jahr, totalSum.ansatzPJfort_haben - subhaben, undefined, id, "Gesamtsoll", false, false, false, "", "", false, true ));
                id++;
                fieldPrev = "ansatzPJfort_haben";
                sumPrev = totalSum.ansatzPJfort_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }


            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.verfuegt_haben != null && totalSum.verfuegt_haben != 0) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.verfuegt_haben != undefined ? totalSub.verfuegt_haben : 0);
                columns.push(this.createDataColumnUH("verfuegt_haben", this.props.jahr, totalSum.verfuegt_haben - subhaben, undefined, id, "Eingenommen", false, false, false, "", "", false, false, true));
                id++;
                fieldPrev = "verfuegt_haben";
                //sumPrev = totalSum.verfuegt_haben - subhaben;

                columns.push(this.createDataPercentColumn("verfuegt_haben", "ansatzPJfort_haben", this.props.jahr, id, "Eingenommen", this.state.alleJahre, false, false, totalSum.verfuegt_haben - subhaben, sumPrev ));
                id++;
                fieldPrev = undefined;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.verfuegbar_haben != null && totalSum.verfuegbar_haben != 0) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.verfuegbar_haben != undefined ? totalSub.verfuegbar_haben : 0);
                columns.push(this.createDataColumnUH("verfuegbar_haben", this.props.jahr, totalSum.verfuegbar_haben - subhaben, undefined, id, "Einzunehmen", false, false, false));
                id++;
                fieldPrev = "verfuegbar_haben";
                //sumPrev = totalSum.verfuegbar_haben - subhaben;

                columns.push(this.createDataPercentColumn("verfuegbar_haben", "ansatzPJfort_haben", this.props.jahr, id, "Einzunehmen", this.state.alleJahre, false, false, totalSum.verfuegbar_haben - subhaben, sumPrev ));
                id++;
                fieldPrev = undefined;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.progAuto_haben != null && totalSum.progAuto_haben != 0) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.progAuto_haben != undefined ? totalSub.progAuto_haben : 0);
                columns.push(this.createDataColumnUH("progAuto_haben", this.props.jahr, totalSum.progAuto_haben - subhaben, undefined, id, "Prognose errechnet", false, false, false));
                id++;
                fieldPrev = "progAuto_haben";
                //sumPrev = totalSum.progAuto_haben - subhaben;

                columns.push(this.createChangeColumn("ansatzPJfort_haben", "progAuto_haben", sumPrev, totalSum.progAuto_haben - subhaben, false, id, totalSum.id, true, "Gesamtsoll"));

                id++;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.progMan_haben != null && totalSum.progMan_haben != 0) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.progMan_haben != undefined ? totalSub.progMan_haben : 0);
                columns.push(this.createDataColumnUH("progMan_haben", this.props.jahr, totalSum.progMan_haben - subhaben, undefined, id, "Prognose manuell", false, false, false, "hasProgMan"));
                id++;
                fieldPrev = "progMan_haben";
                //sumPrev = totalSum.progMan_haben - subhaben;

                columns.push(this.createChangeColumn("ansatzPJfort_haben", "progMan_haben", sumPrev, totalSum.progMan_haben - subhaben, false, id, totalSum.id, true, "Gesamtsoll"));

                id++;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.istAo_haben != null && totalSum.istAo_haben != 0) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.istAo_haben != undefined ? totalSub.istAo_haben : 0);
                columns.push(this.createDataColumnUH("istAo_haben", this.props.jahr, totalSum.istAo_haben - subhaben, undefined, id, "Ist AO"));
                id++;
                
            }
            else {
                fieldPrev = undefined;
            }

        }
        return columns;
    }


    createColumns = (data: KSLPlusAPI.HaushaltsEbene | null, totalSum:  KSLPlusAPI.HaushaltsEbene, totalSub: KSLPlusAPI.HaushaltsEbene | null | undefined, totalHaben:  KSLPlusAPI.HaushaltsEbene | null, totalHabenSub: KSLPlusAPI.HaushaltsEbene | null, ausgaben: boolean) =>  {
        if ( this.props.plantyp == 3 )
            return this.createColumnsJR(data, totalSum, totalSub, totalHaben, totalHabenSub, ausgaben);

        if (this.props.plantyp == 4)
            return this.createColumnsUH(data, totalSum, totalSub, totalHaben, totalHabenSub, ausgaben);

        var columns = [];
        columns.push({
            dataField: 'bezeichnung',
            text: ausgaben?"Ausgaben/Bezeichnung":"Einnahmen" + "/Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '180px', textAlign: 'left' };
            },
            
            style: (colum: any, colIndex: any) => {
                return { width: '180px' };
            },
            footer: "1",
            footerFormatter: (column: any, columnIndex: any) => {
                if ( ausgaben )
                    return (<div><div>Ausgaben gesamt</div><div>Ergebnis E-A</div></div>);
                    
                return <div>Einnahmen gesamt</div>;
            }
        });

        if ( data == null )
            return columns;

        var id = 2;

        if ( !data.jahre )
            return columns;

        if (ausgaben) {
            var fieldPrev: string | undefined;
            var sumPrev = 0;
            /*
            if ( totalSum.ergebnisVvvJ_haben != null && totalSum.ergebnisVvvJ_soll != null && data.ergebnisVvvJ_haben != null && data.ergebnisVvvJ_soll != null && totalHaben != null && totalHaben.ergebnisVvvJ_haben != null && totalHaben.ergebnisVvvJ_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvvJ_haben != undefined ? totalSub.ergebnisVvvJ_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvvJ_soll != undefined ? totalSub.ergebnisVvvJ_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisVvvJ_haben != undefined ? totalHabenSub.ergebnisVvvJ_haben : 0);                
                columns.push(this.createDataColumn("ergebnisVvvJ_soll", this.props.jahr - 3, totalSum.ergebnisVvvJ_soll - subsoll, totalHaben.ergebnisVvvJ_haben - totalSum.ergebnisVvvJ_soll - subErgebnis + subsoll, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvvJ_soll", sumPrev, totalSum.ergebnisVvvJ_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "ergebnisVvvJ_soll";
                sumPrev = totalSum.ergebnisVvvJ_soll - subsoll;                
            }
            else {
                fieldPrev = undefined;
            }
            */
            if (data.jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisVvJ_haben != null && totalSum.ergebnisVvJ_soll != null && data.ergebnisVvJ_haben != null && data.ergebnisVvJ_soll != null && totalHaben != null && totalHaben.ergebnisVvJ_haben != null && totalHaben.ergebnisVvJ_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvJ_haben != undefined ? totalSub.ergebnisVvJ_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvJ_soll != undefined ? totalSub.ergebnisVvJ_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisVvJ_haben != undefined ? totalHabenSub.ergebnisVvJ_haben : 0);                
                columns.push(this.createDataColumn("ergebnisVvJ_soll", this.props.jahr - 2, totalSum.ergebnisVvJ_soll - subsoll, totalHaben.ergebnisVvJ_haben - totalSum.ergebnisVvJ_soll - subErgebnis + subsoll, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvJ_soll", sumPrev, totalSum.ergebnisVvJ_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "ergebnisVvJ_soll";
                sumPrev = totalSum.ergebnisVvJ_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ansatzVJ_haben != null && totalSum.ansatzVJ_soll != null && data.ansatzVJ_haben != null && data.ansatzVJ_soll != null && totalHaben != null && totalHaben.ansatzVJ_haben != null && totalHaben.ansatzVJ_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzVJ_haben != undefined ? totalSub.ansatzVJ_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ansatzVJ_soll != undefined ? totalSub.ansatzVJ_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ansatzVJ_haben != undefined ? totalHabenSub.ansatzVJ_haben : 0);               
                columns.push(this.createDataColumn("ansatzVJ_soll", this.props.jahr - 1, totalSum.ansatzVJ_soll - subsoll, totalHaben.ansatzVJ_haben - totalSum.ansatzVJ_soll - subErgebnis + subsoll, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzVJ_soll", sumPrev, totalSum.ansatzVJ_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }                
                fieldPrev = "ansatzVJ_soll";
                sumPrev = totalSum.ansatzVJ_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPj_haben != null && totalSum.ansatzPj_soll != null && data.ansatzPj_haben != null && data.ansatzPj_soll != null && totalHaben != null && totalHaben.ansatzPj_haben != null && totalHaben.ansatzPj_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPj_haben != undefined ? totalSub.ansatzPj_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ansatzPj_soll != undefined ? totalSub.ansatzPj_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ansatzPj_haben != undefined ? totalHabenSub.ansatzPj_haben : 0);                
                columns.push(this.createDataColumn("ansatzPj_soll", this.props.jahr, totalSum.ansatzPj_soll - subsoll, totalHaben.ansatzPj_haben - totalSum.ansatzPj_soll - subErgebnis + subsoll, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzPj_soll", sumPrev, totalSum.ansatzPj_soll - subsoll, true, id, totalSum.id, true, "Vorjahr", true));
                    id++;
                }
                fieldPrev = "ansatzPj_soll";
                sumPrev = totalSum.ansatzPj_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }
            
            if (data.jahre.findIndex(d => d == this.props.jahr + 1) >= 0 && totalSum.fp1_haben != null && totalSum.fp1_soll != null && data.fp1_haben != null && data.fp1_soll != null && totalHaben != null && totalHaben.fp1_haben != null && totalHaben.fp1_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp1_haben != undefined ? totalSub.fp1_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.fp1_soll != undefined ? totalSub.fp1_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.fp1_haben != undefined ? totalHabenSub.fp1_haben : 0);                
                columns.push(this.createDataColumn("fp1_soll", this.props.jahr + 1, totalSum.fp1_soll - subsoll, totalHaben.fp1_haben - totalSum.fp1_soll - subErgebnis + subsoll, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp1_soll", sumPrev, totalSum.fp1_soll - subsoll, true, id, totalSum.id, true, "Vorjahr", this.props.settings.doppelhaushalt ? true : false));
                    id++;
                }
                fieldPrev = "fp1_soll";
                sumPrev = totalSum.fp1_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr + 2) >= 0 && totalSum.fp2_haben != null && totalSum.fp2_soll != null && data.fp2_haben != null && data.fp2_soll != null && totalHaben != null && totalHaben.fp2_haben != null && totalHaben.fp2_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp2_haben != undefined ? totalSub.fp2_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.fp2_soll != undefined ? totalSub.fp2_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.fp2_haben != undefined ? totalHabenSub.fp2_haben : 0);                
                columns.push(this.createDataColumn("fp2_soll", this.props.jahr + 2, totalSum.fp2_soll - subsoll, totalHaben.fp2_haben - totalSum.fp2_soll - subErgebnis + subsoll, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp2_soll", sumPrev, totalSum.fp2_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "fp2_soll";
                sumPrev = totalSum.fp2_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr + 3) >= 0 && totalSum.fp3_haben != null && totalSum.fp3_soll != null && data.fp3_haben != null && data.fp3_soll != null && totalHaben != null && totalHaben.fp3_haben != null && totalHaben.fp3_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp3_haben != undefined ? totalSub.fp3_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.fp3_soll != undefined ? totalSub.fp3_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.fp3_haben != undefined ? totalHabenSub.fp3_haben : 0);                
                columns.push(this.createDataColumn("fp3_soll", this.props.jahr + 3, totalSum.fp3_soll - subsoll, totalHaben.fp3_haben - totalSum.fp3_soll - subErgebnis + subsoll, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp3_soll", sumPrev, totalSum.fp3_soll - subsoll, true, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "fp3_soll";
                sumPrev = totalSum.fp3_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }
            

        }
        else {
            var fieldPrev: string | undefined;
            var sumPrev = 0;

            /*
            if ( totalSum.ergebnisVvvJ_haben != null )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvvJ_haben != undefined ? totalSub.ergebnisVvvJ_haben : 0);                            
                columns.push(this.createDataColumn("ergebnisVvvJ_haben", this.props.jahr - 3, totalSum.ergebnisVvvJ_haben - subhaben, undefined, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvvJ_haben", sumPrev, totalSum.ergebnisVvvJ_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "ergebnisVvvJ_haben";
                sumPrev = totalSum.ergebnisVvvJ_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }
            */
            if (data.jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisVvJ_haben != null )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvJ_haben != undefined ? totalSub.ergebnisVvJ_haben : 0);
                columns.push(this.createDataColumn("ergebnisVvJ_haben", this.props.jahr-2, totalSum.ergebnisVvJ_haben - subhaben, undefined, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvJ_haben", sumPrev, totalSum.ergebnisVvJ_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "ergebnisVvJ_haben";
                sumPrev = totalSum.ergebnisVvJ_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ansatzVJ_haben != null )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzVJ_haben != undefined ? totalSub.ansatzVJ_haben : 0);
                columns.push(this.createDataColumn("ansatzVJ_haben", this.props.jahr-1, totalSum.ansatzVJ_haben - subhaben, undefined, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzVJ_haben", sumPrev, totalSum.ansatzVJ_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "ansatzVJ_haben";
                sumPrev = totalSum.ansatzVJ_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPj_haben != null )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPj_haben != undefined ? totalSub.ansatzPj_haben : 0);
                columns.push(this.createDataColumn("ansatzPj_haben", this.props.jahr, totalSum.ansatzPj_haben - subhaben, undefined, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzPj_haben", sumPrev, totalSum.ansatzPj_haben - subhaben, false, id, totalSum.id, true, "Vorjahr", true));
                    id++;
                }
                fieldPrev = "ansatzPj_haben";
                sumPrev = totalSum.ansatzPj_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }
            
            if (data.jahre.findIndex(d => d == this.props.jahr + 1) >= 0 && totalSum.fp1_haben != null )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp1_haben != undefined ? totalSub.fp1_haben : 0);
                columns.push(this.createDataColumn("fp1_haben", this.props.jahr+1, totalSum.fp1_haben - subhaben, undefined, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp1_haben", sumPrev, totalSum.fp1_haben - subhaben, false, id, totalSum.id, true, "Vorjahr", this.props.settings.doppelhaushalt ? true : false));
                    id++;
                }
                fieldPrev = "fp1_haben";
                sumPrev = totalSum.fp1_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr + 2) >= 0 && totalSum.fp2_haben != null )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp2_haben != undefined ? totalSub.fp2_haben : 0);
                columns.push(this.createDataColumn("fp2_haben", this.props.jahr+2, totalSum.fp2_haben - subhaben, undefined, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp2_haben", sumPrev, totalSum.fp2_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "fp2_haben";
                sumPrev = totalSum.fp2_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (data.jahre.findIndex(d => d == this.props.jahr + 3) >= 0 && totalSum.fp3_haben != null )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp3_haben != undefined ? totalSub.fp3_haben : 0);
                columns.push(this.createDataColumn("fp3_haben", this.props.jahr+3, totalSum.fp3_haben - subhaben, undefined, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp3_haben", sumPrev, totalSum.fp3_haben - subhaben, false, id, totalSum.id, true));
                    id++;
                }
                fieldPrev = "fp3_haben";
                sumPrev = totalSum.fp3_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }
            

        }
        return columns;
    }
        
    setActiveTab = (tabIndex: number, tabValue: string) => {
        this.state.activeTabs[tabIndex] = tabValue;
        this.setState({ ready: true });
    }


    
    
    getActiveTab = (tabIndex: number) => {
        return this.state.activeTabs[tabIndex];
    }

    createTabItems = (tabs: KSLPlusAPI.Vbtab[]) => {
        var mainContentItems: JSX.Element[] = [];
        var mainNavItems: JSX.Element[] = [];   

        for (var i = 0; i < tabs.length; i++) {
            const tabId = "tab" + i.toString();
            const tab = tabs[i];
            mainNavItems.push(
                <NavItem key={"kenav" + tabId}>
                    <NavLink active={this.state.activeMainTab === tabId} onClick={() => this.setState({ activeMainTab: tabId })} >
                        <div className="kslplus-reiter-text-hauptzeile">{tab.bezeichnung}</div>
                    </NavLink>
                </NavItem>
            );
            mainContentItems.push(
                <TabPane tabId={tabId} key={"ketab" + tabId}>
                    <VBTab modul={this.props.plantyp == 3 ? 1 : 0} tab={tabs[i]} datasets={this.dataSets} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} settings={this.props.settings} className="kslplus-haushaltstab-wrapper-bottom" isVisible={this.state.activeMainTab === tabId} />                  
                </TabPane>
            );
        }

        return { navItems: mainNavItems, contentItems: mainContentItems };        
    }

    createTabItemsDropDown = (tabs: KSLPlusAPI.Vbtab[]) => {
        var mainContentItems: JSX.Element[] = [];
        var mainNavItems: JSX.Element[] = [];   

        for (var i = 0; i < tabs.length; i++) {
            const tabId = "tab" + i.toString();
            const tab = tabs[i];
            mainNavItems.push(
                <DropdownItem active={this.state.activeMainTab === tabId} key={"datanav" + tabId} onClick={() => this.setState({ activeMainTab: tabId, dropDownOpen: false, tab: tab.bezeichnung?tab.bezeichnung:"", activeDataTabId: tabId })}>                         
                            {tab.bezeichnung}                        
                </DropdownItem>

            );
            mainContentItems.push(
                <TabPane tabId={tabId} key={"ketab" + tabId}>  
                    <VBTab modul={this.props.plantyp == 3 ? 1 : 0} tab={tabs[i]} datasets={this.dataSets} kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} settings={this.props.settings} className="kslplus-haushaltstab-wrapper-bottom" isVisible={this.state.activeMainTab === tabId}/>                  
                </TabPane>
            );
        }

        return { navItems: mainNavItems, contentItems: mainContentItems };        
    }

    toggleDataDropDown = () => {        
        if ( !this.state.dropDownOpen && this.state.activeMainTab !== this.state.activeDataTabId  ) 
            this.setState({activeMainTab: this.state.activeDataTabId});
        else 
            this.setState({dropDownOpen: !this.state.dropDownOpen}); 
    }
            


    render() {
        
        if (!this.state.ready )
            return null;
        
        var barColors = ['#264d99', '#264d99', '#264d99', '#dc3912' ,'#dc3912', '#dc3912'];
        var tabItems = this.createTabItems(this.vbtabs);

        var agr: {data: any[], colors: any[]} = {data:[], colors: []};
        var egr: { data: any[], colors: any[] } = { data: [], colors: [] };
        var max: any[] = [-Number.MAX_VALUE, -Number.MAX_VALUE];
        var min: any[] = [Number.MAX_VALUE, Number.MAX_VALUE];
        var graphOptAGR: { minG: number | undefined, maxG: number | undefined, min0: number | undefined, max0: number | undefined, min1: number | undefined, max1: number | undefined } = { minG: undefined, maxG: undefined, min0: undefined, max0: undefined, min1: undefined, max1: undefined };
        var graphOptEGR: { minG: number | undefined, maxG: number | undefined, min0: number | undefined, max0: number | undefined, min1: number | undefined, max1: number | undefined } = { minG: undefined, maxG: undefined, min0: undefined, max0: undefined, min1: undefined, max1: undefined };


        if (this.props.plantyp == 3 || this.props.plantyp == 4)
        {
            agr = Utilities.createBarDataJR(this.vwHH1, [4, 5, 6, 7, 8], true, this.props.jahr, min, max, this.props.plantyp == 4);
            graphOptAGR = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);
            egr = Utilities.createBarDataJR(this.vwHH1, [0, 1, 2], false, this.props.jahr, min, max, this.props.plantyp == 4);
            graphOptEGR = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);
        }

        var max: any[] = [-Number.MAX_VALUE, -Number.MAX_VALUE];
        var min: any[] = [Number.MAX_VALUE, Number.MAX_VALUE];
        var grDataVM: any[] = [];
        if (this.E3_1 && this.E9_1)
            grDataVM = this.props.plantyp == 3 ? Utilities.createGraphDataJR(this.E3_1, this.E9_1, null, null, this.props.jahr, min, max) : (this.props.plantyp == 4 ? Utilities.createGraphDataUH(this.E3_1, this.E9_1, null, null, this.props.jahr, min, max) : Utilities.createGraphData(this.E3_1, this.E9_1, null, null, this.props.jahr, min, max)); 
        var graphOptVM = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);
        max = [-Number.MAX_VALUE, -Number.MAX_VALUE];
        min = [Number.MAX_VALUE, Number.MAX_VALUE];
        var grDataVW: any[] = [];
        if ( this.E3_1 && this.E9_1 )
            grDataVW = this.props.plantyp == 3 ? Utilities.createGraphDataJR(this.vwHH1, this.vwHH1, this.E3_1, this.E9_1, this.props.jahr, min, max) : (this.props.plantyp == 4 ? Utilities.createGraphDataUH(this.vwHH1, this.vwHH1, this.E3_1, this.E9_1, this.props.jahr, min, max) : Utilities.createGraphData(this.vwHH1, this.vwHH1, this.E3_1, this.E9_1, this.props.jahr, min, max));
        var graphOptVW = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);

        var options = {
            backgroundColor: 'none',
            is3D: this.props.settings.tD3d,
            pieSliceText: this.props.settings.tdText,
            legend: { position: this.props.settings.tdLegend, labeledValueText: this.props.settings.tdText == "value" ? "percentage" : "value" },
            pieSliceTextStyle: { fontSize: this.props.settings.tdFontSize != -1 ? this.props.settings.tdFontSize : undefined },
            sliceVisibilityThreshold: 0.00001                
        };

        return (            
            <div className="kslplus-haushaltstab-wrapper-middle">  
                <Nav tabs>                                    
                    <NavItem id="kslplus-tab-verwaltungshaushalt-gesamt">
                        <NavLink  active={this.state.activeMainTab === "1"}   onClick={() => this.setState({activeMainTab: "1"})} >
                            <div className="kslplus-reiter-text-hauptzeile">Verwaltungshaushalt gesamt</div>
                        </NavLink>
                    </NavItem>
                    {Utilities.createTTItem(this.props.settings, "verwaltungshaushalt-gesamt")}
                    <NavItem id="kslplus-tab-vermögenshaushalt-gesamt">
                        <NavLink active={this.state.activeMainTab === "2"}   onClick={() => this.setState({activeMainTab: "2"})} >
                            <div className="kslplus-reiter-text-hauptzeile">Vermögenshaushalt gesamt</div>
                                                
                        </NavLink>
                    </NavItem>           
                    {Utilities.createTTItem(this.props.settings, "vermögenshaushalt-gesamt")}
                   {tabItems.navItems}                    
                </Nav>
                {this.vwHH1 != null &&  this.E3_1 !== undefined && this.E3_1 != null && this.E9_1 !== undefined && this.E9_1 != null ? 
                <TabContent activeTab={this.state.activeMainTab}>                    
                        
                        <TabPane tabId="1">     
                            <div className="kslplus-vbhaushalts-wrapper">                                        
                                {this.state.alleJahre ?
                                    <Row>
                                        <div></div>
                                        <Col>
                                            <BootstrapTable ref={(n: any) => this.node1 = n} keyField='untergruppe' data={this.vwHH1 != null ? this.vwHH1.unterEbenen : []} columns={this.createColumns(this.vwHH1, this.vwHH1, this.E3_1, null, null, false)} hiddenRows={[3, 4, 5, 6, 7, 8, 9]} />
                                        </Col>

                                    </Row>
                                    :
                                    <Row>
                                        <Col>
                                            <BootstrapTable ref={(n: any) => this.node1 = n} keyField='untergruppe' data={this.vwHH1 != null ? this.vwHH1.unterEbenen : []} columns={this.createColumns(this.vwHH1, this.vwHH1, this.E3_1, null, null, false)} hiddenRows={[3, 4, 5, 6, 7, 8, 9]} />
                                        </Col>

                                    </Row>}
                                {this.state.alleJahre ?
                                    <Row>
                                        <div></div>
                                        <Col>
                                            <BootstrapTable ref={(n: any) => this.node2 = n} keyField='untergruppe' data={this.vwHH1 != null ? this.vwHH1.unterEbenen : []} columns={this.createColumns(this.vwHH1, this.vwHH1, this.E9_1, this.vwHH1, this.E3_1, true)} hiddenRows={[0, 1, 2, 3, 9]} />
                                        </Col>                                        
                                    </Row>
                                    :
                                    <Row>                                        
                                        <Col>
                                            <BootstrapTable ref={(n: any) => this.node2 = n} keyField='untergruppe' data={this.vwHH1 != null ? this.vwHH1.unterEbenen : []} columns={this.createColumns(this.vwHH1, this.vwHH1, this.E9_1, this.vwHH1, this.E3_1, true)} hiddenRows={[0, 1, 2, 3, 9]} />
                                        </Col>

                                    </Row>
                                }
                                {this.props.plantyp == 3 ?
                                    <Row>
                                        <Col>                                
                                            <div className="kslplus-headline-third">{"Einnnahmen und Ausgaben des VWHH " + this.props.jahr.toString() + " - Abweichungen zwischen Ansatz und Ergebnis"}</div>                                                                                
                                        </Col>                                                
                                    </Row>:null
                                }                         
                                {this.props.plantyp == 3 ?       
                                    <Row>
                                            <Col>
                                                <div className="ksl-grafik-container">

                                                    <Chart
                                                        width="100%"
                                                        height={'400px'}
                                                        chartType="BarChart"
                                                        chartLanguage='de'
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createBarDataAEAJR(this.vwHH1, this.E9_1, this.E3_1, barColors)}                                                    
                                                        options={{
                                                          
                                                              bar: { groupWidth: "50%" },
                                                              legend: { position: "none"},
                                                              chartArea: {width: '80%', left: '20%'},
                                                              hAxis: { gridlines: { count: 10 }}
                                                        
                                                        }}
                                                    
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                </div>
                                            </Col>
                                    </Row>
                                    : null
                                }
                                {this.props.plantyp == 4 ?
                                    <Row>
                                        <Col>
                                            <div className="kslplus-headline-third">{"Einnnahmen und Ausgaben des VWHH " + this.props.jahr.toString() + " - Abweichungen zwischen Gesamtsoll und Prognosewert (s. Spalte Prognose manuell)"}</div>
                                        </Col>
                                    </Row> : null
                                }
                                {this.props.plantyp == 4 ?
                                    <Row>
                                        <Col>
                                            <div className="ksl-grafik-container">

                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createBarDataAEAUH(this.vwHH1, this.E9_1, this.E3_1, barColors)}
                                                    options={{

                                                        bar: { groupWidth: "50%" },
                                                        legend: { position: "none" },
                                                        chartArea: { width: '80%', left: '20%' },
                                                        hAxis: { gridlines: { count: 10 } }

                                                    }}

                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    : null
                                }
                                {this.props.plantyp == 3 ?
                                    <Row>
                                        <Col>
                                            <div className="kslplus-headline-third">{"Abweichungen der Einnahmen zwischen Ansatz und Ergebnis nach Hauptgruppen"}</div>
                                            <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                            
                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'                                                            
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createAenderungsBarDataJR(this.vwHH1, [0,1,2], false )}
                                                    options={{                                                                                                      
                                                        legend: { position: "none"},
                                                        chartArea: {width: '70%', left:'30%' },
                                                        hAxis: { gridlines: { count: 10 }}
                                                    }}
                                                    formatters={[
                                                                {
                                                                    type: 'NumberFormat',
                                                                    column: 1,
                                                                    options: {
                                                                    suffix: ' EUR'
                                                                    },
                                                                }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                                </div>
                                        </Col>
                                        
                                    
                                    </Row> : null
                                }
                                {this.props.plantyp == 3 ?
                                    <Row>                                        
                                        <Col>
                                            <div className="kslplus-headline-third">{"Abweichungen der Ausgaben zwischen Ansatz und Ergebnis nach Hauptgruppen"}</div>
                                            <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                            
                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'                                                            
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createAenderungsBarDataJR(this.vwHH1, [4,5,6,7,8], true )}
                                                    options={{                                                                                                       
                                                        legend: { position: "none"},
                                                        chartArea: {width: '70%', left:'30%' },
                                                        hAxis: { gridlines: { count: 10 }}
                                                    }}
                                                    formatters={[
                                                                {
                                                                    type: 'NumberFormat',
                                                                    column: 1,
                                                                    options: {
                                                                    suffix: ' EUR'
                                                                    },
                                                                }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                                </div>
                                        </Col>
                                    
                                    </Row> : null
                                }
                                {this.props.plantyp == 4 ?
                                    <Row>
                                        <Col>
                                            <div className="kslplus-headline-third">{"Abweichungen der Einnahmen zwischen Gesamtsoll und Prognosewert (s. Spalte Prognose manuell) nach Hauptgruppen"}</div>
                                            <div className="ksl-grafik-container  ksl-grafik-container-piechart">

                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createAenderungsBarDataUH(this.vwHH1, [0, 1, 2], false)}
                                                    options={{
                                                        legend: { position: "none" },
                                                        chartArea: { width: '70%', left: '30%' },
                                                        hAxis: { gridlines: { count: 10 } }
                                                    }}
                                                    formatters={[
                                                        {
                                                            type: 'NumberFormat',
                                                            column: 1,
                                                            options: {
                                                                suffix: ' EUR'
                                                            },
                                                        }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>


                                    </Row> : null
                                }
                                {this.props.plantyp == 4 ?
                                    <Row>
                                        <Col>
                                            <div className="kslplus-headline-third">{"Abweichungen der Ausgaben zwischen Gesamtsoll und Prognosewert (s. Spalte Prognose manuell) nach Hauptgruppen"}</div>
                                            <div className="ksl-grafik-container  ksl-grafik-container-piechart">

                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createAenderungsBarDataUH(this.vwHH1, [4, 5, 6, 7, 8], true)}
                                                    options={{
                                                        legend: { position: "none" },
                                                        chartArea: { width: '70%', left: '30%' },
                                                        hAxis: { gridlines: { count: 10 } }
                                                    }}
                                                    formatters={[
                                                        {
                                                            type: 'NumberFormat',
                                                            column: 1,
                                                            options: {
                                                                suffix: ' EUR'
                                                            },
                                                        }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>

                                    </Row> : null
                                }

                                <Row>
                                    <Col>                                                                        
                                        <div className="kslplus-headline-third">{this.props.plantyp == 3 ? "Entwicklung der Ergebnisse der Einnahmen und Ausgaben des VWHH" : "Entwicklung der Einnahmen und Ausgaben des VWHH" }</div>                                                                                
                                    </Col>                                            
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="ksl-grafik-container">
                                            <Chart
                                                width="100%"
                                                height={'300px'}
                                                chartType="ComboChart"
                                                chartLanguage='de'                                                            
                                                loader={<div>Loading Chart</div>}
                                                data={grDataVW}
                                                options={{                                                
                                                colors: ['#264d99', '#dc3912' ,'#ADD8E6', '#FF7F7F'],
                                                    vAxes: { 0: { title: 'EUR', minValue: graphOptVW.minG, maxValue: graphOptVW.maxG, viewWindow: { max: graphOptVW.max0, min: graphOptVW.min0 } }, 1: { title: '%', minValue: graphOptVW.minG, maxValue: graphOptVW.maxG, viewWindow: { max: graphOptVW.max1, min: graphOptVW.min1 } } },
                                                hAxis: { title: 'Jahr' },
                                                seriesType: 'bars',
                                                series: { 0: {targetAxisIndex: 0}, 1: {targetAxisIndex: 0}, 2: { type: 'line', targetAxisIndex: 1 }, 3: { type: 'line', targetAxisIndex: 1 } },
                                                }}    
                                                formatters={Utilities.createFormatters(4, 2)}                                                       
                                                rootProps={{ 'data-testid': '1' }}
                                            />
                                        </div>
                                    </Col>                                            
                                </Row>
                                <Row>
                                    <Col>                                
                                        <div className="kslplus-headline-third">{this.props.plantyp == 3 ?"Entwicklung der Ergebnisse der Einnahmen nach Hauptgruppen":"Entwicklung der Einnahmen des VWHH nach Hauptgruppen"}</div>
                                    </Col>                                            
                                </Row>
                                
                                {this.props.plantyp == 3 || (this.props.plantyp == 4 && this.vwHH1.jahre && this.vwHH1.jahre.length > 3) ?
                                    <div>
                                        <Row>
                                            <Col className="ksl-container-piechart3">
                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-4).toString()}</div>
                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                            
                                                    <Chart
                                                        width="100%"
                                                        height={'300px'}
                                                        chartType="PieChart"
                                                        chartLanguage='de'                                                            
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createPieDataJR(this.vwHH1, -4, [0,1,2], false )}
                                                        options={options}
                                                        formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                    </div>
                                            </Col>
                                            <Col className="ksl-container-piechart3">
                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-3).toString()}</div>
                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                    <Chart
                                                        width="100%"
                                                        height={'300px'}
                                                        chartType="PieChart"
                                                        chartLanguage='de'                                                            
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createPieDataJR(this.vwHH1, -3, [0,1,2], false )}
                                                        options={options}
                                                        formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                    </div>
                                            </Col>
                                            <Col className="ksl-container-piechart3">
                                                    <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-2).toString()}</div>
                                                    <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                            
                                                    <Chart
                                                        width="100%"
                                                        height={'300px'}
                                                        chartType="PieChart"
                                                        chartLanguage='de'                                                            
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createPieDataJR(this.vwHH1, -2, [0,1,2], false )}
                                                        options={options}
                                                        formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                    </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="ksl-container-piechart3">
                                                    <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-1).toString()}</div>
                                                    <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                            
                                                    <Chart
                                                        width="100%"
                                                        height={'300px'}
                                                        chartType="PieChart"
                                                        chartLanguage='de'                                                            
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createPieDataJR(this.vwHH1, -1, [0,1,2], false )}
                                                        options={options}
                                                        formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                    </div>
                                            </Col>
                                            <Col className="ksl-container-piechart3">
                                                <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ergebnis ") +(this.props.jahr).toString()}</div>
                                                    <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                
                                                    <Chart
                                                        width="100%"
                                                        height={'300px'}
                                                        chartType="PieChart"
                                                        chartLanguage='de'                                                            
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createPieDataJR(this.vwHH1, 0, [0, 1, 2], false, this.props.plantyp === 4 )}
                                                        options={options}
                                                        formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                    </div>
                                            </Col>
                                    
                                        </Row> 
                                        <Row>                                            
                                            <Col>
                                                <div className="ksl-grafik-container">
                                                    <Chart
                                                        width="100%"
                                                        height={'300px'}                                                                    
                                                        chartType="ComboChart"
                                                        chartLanguage='de'                                                            
                                                        loader={<div>Loading Chart</div>}
                                                        data={egr.data}
                                                        options={{    
                                                            color: egr.colors,                                                                                                            
                                                            chartArea: {width: '70%', left:'10%' },
                                                            vAxes: { 0: { title: 'EUR', minValue: graphOptEGR.minG, maxValue: graphOptEGR.maxG, viewWindow: { max: graphOptEGR.max0, min: graphOptEGR.min0 } } },
                                                            hAxis: { title: 'Jahr' },
                                                            seriesType: 'bars'
                                                        }}
                                                        formatters={Utilities.createFormatters(4, 2)}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                    </div>
                                            </Col>
                                        </Row>  
                                     </div>
                                    :
                                    <div>
                                        <Row>
                                            <Col className="ksl-container-piechart3">
                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-2).toString()}</div>
                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                    <Chart
                                                        width="100%"
                                                        height={'300px'}
                                                        chartType="PieChart"
                                                        chartLanguage='de'                                                            
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createPieData(this.vwHH1, -2, [0,1,2], false, this.props.plantyp === 4 )}
                                                        options={options}
                                                        formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                    </div>
                                            </Col>
                                            <Col className="ksl-container-piechart3">
                                                <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Ergebnis " : "Ansatz ") +(this.props.jahr-1).toString()}</div>
                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                    <Chart
                                                        width="100%"
                                                        height={'300px'}
                                                        chartType="PieChart"
                                                        chartLanguage='de'                                                            
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createPieData(this.vwHH1, -1, [0, 1, 2], false, this.props.plantyp === 4 )}
                                                        options={options}
                                                        formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                    </div>
                                            </Col>
                                            <Col className="ksl-container-piechart3">
                                                <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ansatz ") +(this.props.jahr).toString()}</div>
                                                    <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                    <Chart
                                                        width="100%"
                                                        height={'300px'}
                                                        chartType="PieChart"
                                                        chartLanguage='de'                                                            
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createPieData(this.vwHH1, 0, [0, 1, 2], false, this.props.plantyp === 4 )}
                                                        options={options}
                                                        formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                    </div>
                                            </Col>
                                    
                                        </Row> 
                                        {(this.props.plantyp == 3 || this.props.plantyp == 4) &&
                                            <Row>
                                                <Col>
                                                    <div className="ksl-grafik-container">
                                                        <Chart
                                                            width="100%"
                                                            height={'300px'}
                                                            chartType="ComboChart"
                                                            chartLanguage='de'
                                                            loader={<div>Loading Chart</div>}
                                                            data={egr.data}
                                                            options={{
                                                                color: egr.colors,
                                                                chartArea: { width: '70%', left: '10%' },
                                                                vAxes: { 0: { title: 'EUR', minValue: graphOptEGR.minG, maxValue: graphOptEGR.maxG, viewWindow: { max: graphOptEGR.max0, min: graphOptEGR.min0 } } },
                                                                hAxis: { title: 'Jahr' },
                                                                seriesType: 'bars'
                                                            }}
                                                            formatters={Utilities.createFormatters(4, 2)}
                                                            rootProps={{ 'data-testid': '2' }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>  
                                        }
                                     </div>
                                }
                                                
                                <Row>
                                    <Col>                                
                                        <div className="kslplus-headline-third">{this.props.plantyp == 3 ?"Entwicklung der Ergebnisse der Ausgaben nach Hauptgruppen": "Entwicklung der Ausgaben des VWHH nach Hauptgruppen"}</div>
                                    </Col>                                            
                                </Row>
                                
                                {this.props.plantyp == 3 || (this.props.plantyp == 4 && this.vwHH1.jahre && this.vwHH1.jahre.length > 3) ?
                                    <div>
                                        <Row>                                    
                                            <Col className="ksl-container-piechart3">
                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-4).toString()}</div>
                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                    <Chart
                                                        width="100%"
                                                        height={'300px'}
                                                        chartType="PieChart"
                                                        chartLanguage='de'                                                            
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createPieDataJR(this.vwHH1, -4, [4,5,6,7,8], true )}
                                                        options={options}
                                                        formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                    </div>
                                            </Col>
                                            <Col className="ksl-container-piechart3">
                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-3).toString()}</div>
                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                    <Chart
                                                        width="100%"
                                                        height={'300px'}
                                                        chartType="PieChart"
                                                        chartLanguage='de'                                                            
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createPieDataJR(this.vwHH1, -3, [4,5,6,7,8], true )}
                                                        options={options}
                                                        formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                    </div>
                                            </Col>
                                            <Col className="ksl-container-piechart3">
                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-2).toString()}</div>
                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                    <Chart
                                                        width="100%"
                                                        height={'300px'}
                                                        chartType="PieChart"
                                                        chartLanguage='de'                                                            
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createPieDataJR(this.vwHH1, -2, [4,5,6,7,8], true )}
                                                        options={options}
                                                        formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                    </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                                <Col className="ksl-container-piechart3">
                                                    <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-1).toString()}</div>
                                                    <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                        <Chart
                                                            width="100%"
                                                            height={'300px'}
                                                            chartType="PieChart"
                                                            chartLanguage='de'                                                            
                                                            loader={<div>Loading Chart</div>}
                                                            data={Utilities.createPieDataJR(this.vwHH1, -1, [4,5,6,7,8], true )}
                                                        options={options}
                                                            formatters={[
                                                                        {
                                                                            type: 'NumberFormat',
                                                                            column: 1,
                                                                            options: {
                                                                                fractionDigits: 0,
                                                                                suffix: ' TEUR'
                                                                            },
                                                                        }]}
                                                            rootProps={{ 'data-testid': '2' }}
                                                        />
                                                        </div>
                                                </Col>
                                                <Col className="ksl-container-piechart3">
                                                    <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ergebnis ") + (this.props.jahr).toString()}</div>
                                                    <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                        <Chart
                                                            width="100%"
                                                            height={'300px'}
                                                            chartType="PieChart"
                                                            chartLanguage='de'                                                            
                                                            loader={<div>Loading Chart</div>}
                                                            data={Utilities.createPieDataJR(this.vwHH1, 0, [4, 5, 6, 7, 8], true, this.props.plantyp === 4)}
                                                            options={options}
                                                            formatters={[
                                                                        {
                                                                            type: 'NumberFormat',
                                                                            column: 1,
                                                                            options: {
                                                                                fractionDigits: 0,
                                                                                suffix: ' TEUR'
                                                                            },
                                                                        }]}
                                                            rootProps={{ 'data-testid': '2' }}
                                                        />
                                                        </div>
                                                </Col>
                                            </Row>
                                            <Row>                                            
                                                <Col>
                                                    <div className="ksl-grafik-container">
                                                        <Chart
                                                            width="100%"
                                                            height={'300px'}                                                                    
                                                            chartType="ComboChart"
                                                            chartLanguage='de'                                                            
                                                            loader={<div>Loading Chart</div>}
                                                            data={agr.data}
                                                            options={{                                                                           
                                                                colors: agr.colors,                                     
                                                                chartArea: {width: '70%', left:'10%' },
                                                                vAxes: { 0: { title: 'EUR', minValue: graphOptAGR.minG, maxValue: graphOptAGR.maxG, viewWindow: { max: graphOptAGR.max0, min: graphOptAGR.min0 } } },
                                                                hAxis: { title: 'Jahr' },
                                                                seriesType: 'bars'
                                                            }}
                                                            formatters={Utilities.createFormatters(4, 2)}
                                                            rootProps={{ 'data-testid': '2' }}
                                                        />
                                                        </div>
                                                </Col>
                                            </Row>  
                                        </div>
                                    :
                                    <div>
                                        <Row>                                    
                                        <Col className="ksl-container-piechart3">
                                            <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-2).toString()}</div>
                                            <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                <Chart
                                                    width="100%"
                                                    height={'300px'}
                                                    chartType="PieChart"
                                                    chartLanguage='de'                                                            
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createPieData(this.vwHH1, -2, [4, 5, 6, 7, 8], true, this.props.plantyp === 4 )}
                                                    options={options}
                                                    formatters={[
                                                                {
                                                                    type: 'NumberFormat',
                                                                    column: 1,
                                                                    options: {
                                                                        fractionDigits: 0,
                                                                        suffix: ' TEUR'
                                                                    },
                                                                }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                                </div>
                                        </Col>
                                            <Col className="ksl-container-piechart3">
                                            <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Ergebnis " : "Ansatz ") +(this.props.jahr-1).toString()}</div>
                                            <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                <Chart
                                                    width="100%"
                                                    height={'300px'}
                                                    chartType="PieChart"
                                                    chartLanguage='de'                                                            
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createPieData(this.vwHH1, -1, [4, 5, 6, 7, 8], true, this.props.plantyp === 4 )}
                                                    options={options}
                                                    formatters={[
                                                                {
                                                                    type: 'NumberFormat',
                                                                    column: 1,
                                                                    options: {
                                                                        fractionDigits: 0,
                                                                        suffix: ' TEUR'
                                                                    },
                                                                }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                                </div>
                                        </Col>
                                        <Col className="ksl-container-piechart3">
                                                <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ansatz ") +(this.props.jahr).toString()}</div>
                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                <Chart
                                                    width="100%"
                                                    height={'300px'}
                                                    chartType="PieChart"
                                                    chartLanguage='de'                                                            
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createPieData(this.vwHH1, 0, [4, 5, 6, 7, 8], true, this.props.plantyp === 4 )}
                                                    options={options}
                                                    formatters={[
                                                                {
                                                                    type: 'NumberFormat',
                                                                    column: 1,
                                                                    options: {
                                                                        fractionDigits: 0,
                                                                        suffix: ' TEUR'
                                                                    },
                                                                }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                                </div>
                                        </Col>
                                    
                                        </Row> 
                                        {(this.props.plantyp == 3 || this.props.plantyp == 4) &&
                                            <Row>
                                                <Col>
                                                    <div className="ksl-grafik-container">
                                                        <Chart
                                                            width="100%"
                                                            height={'300px'}
                                                            chartType="ComboChart"
                                                            chartLanguage='de'
                                                            loader={<div>Loading Chart</div>}
                                                            data={agr.data}
                                                            options={{
                                                                colors: agr.colors,
                                                                chartArea: { width: '70%', left: '10%' },
                                                                vAxes: { 0: { title: 'EUR', minValue: graphOptAGR.minG, maxValue: graphOptAGR.maxG, viewWindow: { max: graphOptAGR.max0, min: graphOptAGR.min0 } } },
                                                                hAxis: { title: 'Jahr' },
                                                                seriesType: 'bars'
                                                            }}
                                                            formatters={Utilities.createFormatters(4, 2)}
                                                            rootProps={{ 'data-testid': '2' }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                     </div>
                            } 
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="kslplus-vbhaushalts-wrapper">
                                {this.state.alleJahre ? 
                                    <Row>                                                
                                        <div></div>
                                        <Col>                                                    
                                            <BootstrapTable ref={(n: any) => this.node3 = n} keyField='untergruppe' data={this.vwHH1 != null ? this.vwHH1.unterEbenen : []} columns={this.createColumns(this.vwHH1, this.E3_1, null, null, null, false)} hiddenRows={[0,1,2,4,5,6,7,8,9]}/>                                                         
                                        </Col>
                                    </Row>
                                    :
                                    <Row>
                                        <Col>
                                            <BootstrapTable ref={(n: any) => this.node3 = n} keyField='untergruppe' data={this.vwHH1 != null ? this.vwHH1.unterEbenen : []} columns={this.createColumns(this.vwHH1, this.E3_1, null, null, null, false)} hiddenRows={[0, 1, 2, 4, 5, 6, 7, 8, 9]} />
                                        </Col>
                                    </Row>
                                }
                                {this.state.alleJahre ?
                                    <Row>
                                    <div></div>
                                        <Col>
                                            <BootstrapTable ref={(n: any) => this.node4 = n} keyField='untergruppe' data={this.vwHH1 != null ? this.vwHH1.unterEbenen : []} columns={this.createColumns(this.vwHH1, this.E9_1, null, this.E3_1, null, true)} hiddenRows={[0, 1, 2, 3, 4, 5, 6, 7, 8]} />
                                        </Col>
                                    </Row>
                                    :
                                    <Row>
                                        <Col>
                                            <BootstrapTable ref={(n: any) => this.node4 = n} keyField='untergruppe' data={this.vwHH1 != null ? this.vwHH1.unterEbenen : []} columns={this.createColumns(this.vwHH1, this.E9_1, null, this.E3_1, null, true)} hiddenRows={[0, 1, 2, 3, 4, 5, 6, 7, 8]} />
                                        </Col>
                                    </Row>
                                }
                                
                                {this.props.plantyp == 3 ?
                                    <Row>
                                        <Col>                                
                                            <div className="kslplus-headline-third">{"Einnnahmen und Ausgaben des VMHH " + this.props.jahr.toString() + " - Abweichungen zwischen Ansatz und Ergebnis"}</div>                                                                                
                                        </Col>                                                
                                    </Row>:null
                                }                         
                                {this.props.plantyp == 3 ?       
                                    <Row>
                                            <Col>
                                                <div className="ksl-grafik-container">

                                                    <Chart
                                                        width="100%"
                                                        height={'400px'}
                                                        chartType="BarChart"
                                                        chartLanguage='de'
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createBarDataVMAEAJR(this.E3_1, this.E9_1, barColors)}                                                    
                                                        options={{
                                                          
                                                              bar: { groupWidth: "50%" },
                                                              legend: { position: "none"},
                                                              chartArea: {width: '80%', left: '20%'}
                                                        
                                                        }}
                                                    
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                </div>
                                            </Col>
                                    </Row>
                                    : null
                                }
                                {this.props.plantyp == 4 ?
                                    <Row>
                                        <Col>
                                            <div className="kslplus-headline-third">{"Einnnahmen und Ausgaben des VMHH " + this.props.jahr.toString() + " - Abweichungen zwischen Gesamtsoll und Prognosewert (s. Spalte Prognose manuell)"}</div>
                                        </Col>
                                    </Row> : null
                                }
                                {this.props.plantyp == 4 ?
                                    <Row>
                                        <Col>
                                            <div className="ksl-grafik-container">

                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createBarDataVMAEAUH(this.E3_1, this.E9_1, barColors)}
                                                    options={{

                                                        bar: { groupWidth: "50%" },
                                                        legend: { position: "none" },
                                                        chartArea: { width: '80%', left: '20%' }

                                                    }}

                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    : null
                                }
                                <Row>                                           
                                    <Col>
                                        <div className="kslplus-headline-third">{this.props.plantyp == 3 ? "Entwicklung der Ergebnisse der Einnahmen und Ausgaben des VMHH" : "Entwicklung der Einnahmen und Ausgaben des VMHH"}</div>
                                    </Col>
                                </Row>
                                <Row>                                            
                                    <Col>
                                        <div className="ksl-grafik-container">
                                            <Chart
                                                width="100%"
                                                height={'300px'}                                                                    
                                                chartType="ComboChart"
                                                chartLanguage='de'                                                            
                                                loader={<div>Loading Chart</div>}
                                                data={grDataVM}
                                                options={{                                                                                                                
                                                    colors: ['#264d99', '#dc3912' ,'#ADD8E6', '#FF7F7F'],
                                                    vAxes: { 0: { title: 'EUR', minValue: graphOptVM.minG, maxValue: graphOptVM.maxG, viewWindow: { max: graphOptVM.max0, min: graphOptVM.min0 } }, 1: { title: '%', minValue: graphOptVM.minG, maxValue: graphOptVM.maxG, viewWindow: { max: graphOptVM.max1, min: graphOptVM.min1 } } },
                                                    hAxis: { title: 'Jahr' },
                                                    seriesType: 'bars',
                                                    series: { 0: {targetAxisIndex: 0}, 1: {targetAxisIndex: 0}, 2: { type: 'line', targetAxisIndex: 1 }, 3: { type: 'line', targetAxisIndex: 1 } },
                                                }}
                                                formatters={Utilities.createFormatters(4, 2)}
                                                rootProps={{ 'data-testid': '2' }}
                                            />
                                            </div>
                                    </Col>
                                </Row>
                            </div>
                            
                        </TabPane>                                                                                                                    
                        {tabItems.contentItems}
                    </TabContent> : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>}
                               
            </div>
        );
    }
}
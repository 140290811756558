import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip, UncontrolledPopover, PopoverBody, Badge, Container
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import Chart from "react-google-charts";


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as Utilities from "./Utilities"

import { VWHaushalt } from './VWHaushaltsComp';
import { VMHaushalt } from './VMHaushaltsComp';
import { WeitereFinanzdaten } from './WeitereFinanzdatenComp';

import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

import { VBTab } from './VBTabComp';

type State = {
    ready: boolean;        
    activeMainTab: string,
    selectedGLEbene: string;    
    isVisible: boolean;
}

type Props = {    
    kunde: number,
    gemeinde: number,
    jahr: number,
    plantyp: number,
    planstufe: number,
    verwaltungsansicht: boolean,
    uhconfig: KSLPlusAPI.UhConfigModel | undefined,
    massnahmen: KSLPlusAPI.Massnahman[],
    settings: KSLPlusAPI.SettingsModel,
    isVisible: boolean
}



export class Finanzdaten extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        
        this.dataSets = []; 
        this.vbtabs = [];       

    }

    node: any;
    
    dataSets: KSLPlusAPI.Datensaetze[];    
    vbtabs: KSLPlusAPI.Vbtab[];

    state: State = {
        ready: true,        
        activeMainTab: "1",
        selectedGLEbene: "",
        isVisible: false
    }    

    handleSelectHHEbene = (ebenenNum: string) => {                
        this.setState({selectedGLEbene: ebenenNum} );        
    } 

    componentDidUpdate(prevProps: Props) {
        if (prevProps.isVisible !== this.props.isVisible && this.props.isVisible !== this.state.isVisible)        
            this.setState({activeMainTab: "1"});        

    }

    
    componentDidMount() {
        
    }
                         
    render() {
                

        return (
            <div className="kslplus-haushaltstab-wrapper-middle">  
                <Nav tabs>                                    
                    <NavItem id="kslplus-tab-verwaltungshaushalt-detailliert">
                        <NavLink  active={this.state.activeMainTab === "1"}   onClick={() => this.setState({activeMainTab: "1"})} >
                            <div className="kslplus-reiter-text-hauptzeile">Verwaltungshaushalt detailliert</div>
                        </NavLink>                        
                    </NavItem>
                    {Utilities.createTTItem(this.props.settings, "verwaltungshaushalt-detailliert")}
                    <NavItem id="kslplus-tab-vermögenshaushalt-detailliert">
                        <NavLink active={this.state.activeMainTab === "2"}   onClick={() => this.setState({activeMainTab: "2"})} >
                            <div className="kslplus-reiter-text-hauptzeile">Vermögenshaushalt detailliert</div>
                                                
                        </NavLink>
                    </NavItem>    
                    {Utilities.createTTItem(this.props.settings, "vermögenshaushalt-detailliert")}
                    <NavItem id="kslplus-tab-weitere-finanzdaten">
                        <NavLink active={this.state.activeMainTab === "3"}   onClick={() => this.setState({activeMainTab: "3"})} >
                            <div className="kslplus-reiter-text-hauptzeile">Weitere Finanzdaten</div>                                                
                        </NavLink>
                    </NavItem>         
                    {Utilities.createTTItem(this.props.settings, "weitere-finanzdaten")}
                </Nav>
                
                <TabContent activeTab={this.state.activeMainTab}>                    
                                                                
                    <TabPane tabId="1">

                        <VWHaushalt kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} uhconfig={this.props.uhconfig} settings={this.props.settings} onSelGLChange={this.handleSelectHHEbene} selectedGL={this.state.selectedGLEbene} massnahmen={this.props.massnahmen} isVisible={this.props.isVisible && this.state.activeMainTab === "1"} verwaltungsansicht={this.props.verwaltungsansicht} />
                                        
                    </TabPane>
                    <TabPane tabId="2">
                        <VMHaushalt kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} uhconfig={this.props.uhconfig} settings={this.props.settings} onSelGLChange={this.handleSelectHHEbene} selectedGL={this.state.selectedGLEbene} massnahmen={this.props.massnahmen} isVisible={this.props.isVisible && this.state.activeMainTab === "2"} verwaltungsansicht={this.props.verwaltungsansicht} />
                            
                    </TabPane>
                    <TabPane tabId="3">
                        <WeitereFinanzdaten kunde={this.props.kunde} gemeinde={this.props.gemeinde} jahr={this.props.jahr} plantyp={this.props.plantyp} planstufe={this.props.planstufe} settings={this.props.settings} massnahmen={this.props.massnahmen} isVisible={this.props.isVisible && this.state.activeMainTab === "3"} uhconfig={this.props.uhconfig} verwaltungsansicht={this.props.verwaltungsansicht} />                            
                    </TabPane>                    
                </TabContent>
                               
            </div>
        );
    }
}
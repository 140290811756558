import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, UncontrolledTooltip, Badge, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import Chart from "react-google-charts";

import { Kennzahl } from './KennzahlComp';

import { HHSummiertDoppisch } from './HHSummiertDoppischComp';
import { ProduktSpezHaushalt } from './ProduktSpezHaushaltComp';


import * as Utilities from "./Utilities"

import { env } from '../env';

import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";
import { GLHaushalt } from "./GLHaushaltComp";



type State = {
    ready: boolean,
    hhPr: KSLPlusAPI.HaushaltSummiert | null,
    hhPRModal: boolean,
    expanded: number[],
    hhPrs: { [key: string]: KSLPlusAPI.HaushaltSummiert }
    hhGL: KSLPlusAPI.HaushaltsEbene | null;
}

type Props = {  
    buchungsart: number;         
    abc: KSLPlusAPI.ABCResult;
    jahr: number;
    kunde: number;
    gemeinde: number;
    plantyp: number;
    planstufe: number;
    verwaltungsansicht: boolean;
    uhconfig: KSLPlusAPI.UhConfigModel | undefined,
    settings: KSLPlusAPI.SettingsModel;    
}



export class ABCAnalyse extends Component<Props, State> {
    constructor(props: any) {
        super(props);


    }

    node: any;    
    container: any = null;

    

    
    state: State = {
        ready: false,
        hhPr: null,
        hhPRModal: false,
        expanded: [],
        hhPrs: {},
        hhGL: null
    }

    readHHGLData = () => {
        var request = new KSLPlusAPI.HaushaltRequest();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";

        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        request.gruppen = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        request.ignorierteGruppen = [3, 9];
        //request.ignorierteGruppen = [800, 801, 802, 803, 804, 805, 806, 807, 808, 86, 201, 202, 203, 204, 205, 206, 207, 208, 28, 3, 9];
        client.getHaushaltByGliederung("", request).then((hh) => {
            
            this.setState({ ready: true, hhGL: hh });
        }).catch(Reason => {
        });
        /*        
        request.gruppen = [0,1,2];
        client.getHaushaltByGliederung( "", request ).then((hh) => {
            this.hhGL_Einnahmen = hh;                          
            this.setState({ ready: true });
        }).catch(Reason => {
            alert("failed");
        });

        request.gruppen = [4, 5, 6, 7, 8];
        client.getHaushaltByGliederung("", request).then((hh) => {
            this.hhGL_Einnahmen = hh;
            this.readHHGRData();
            this.setState({ ready: true });
        }).catch(Reason => {
            alert("failed");
        });
        */

    }
    
    componentDidMount() {
        if (this.props.buchungsart == 1)
            this.readHHGLData();
    }

    rowStyle = (row: KSLPlusAPI.ABCResultEbene, rowIdx: number) => {
        const style:any = {};
        if (row.klassifikation === 'A') {            
            style.backgroundColor = this.props.abc.farbeA ? this.props.abc.farbeA : '#ffb09c';
        }
        else if (row.klassifikation === 'B') {
            style.backgroundColor = this.props.abc.farbeB ? this.props.abc.farbeB : '#FFFBC8';
        }
        else if ( row.klassifikation === 'C' ) {
            style.backgroundColor = this.props.abc.farbeC ? this.props.abc.farbeC : '#D8FCD8';
        }
        else {
            style.backgroundColor = this.props.abc.farbePos ? this.props.abc.farbePos : 'white';
        }
        

        return style;
    }

    rowClasses = (row: KSLPlusAPI.ABCResultEbene, rowIdx: number) => {
        if (rowIdx > 0 && this.props.abc.negResults && this.props.abc.negResults[rowIdx].klassifikation !== this.props.abc.negResults[rowIdx - 1].klassifikation)
            return "kslplus-abcanlysis-rowborder";

        return "kslplus-abcanlysis-row";
    }
    
    createNegColumns = () => {
        var columns = [];


        var id = 1;
        columns.push({
            dataField: 'uaFmt',
            text: this.props.buchungsart === 2 ? "Produkt" : "Unterabschnitt",
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '50px', textAlign: 'right' };
            },
            footer: 0,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                return null;
            }
        });
        
        columns.push({
            dataField: 'uaText',
            text: "Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '250px', textAlign: 'left' };
            },
            footer: 1,            
            footerAlign: 'left',
            footerFormatter: (column: any, columnIndex: any) => {                
                return (
                    <div className="kslplus-tabellen-footer">Negativer Deckungsbeitrag insgesamt</div>
                );
            }            
        });
        
        columns.push({
            dataField: 'resultAbs',
            text: "Deckungsbeitrag",
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '75px', textAlign: 'right' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.ABCResultEbene) => {                
                return (
                    <div>{Utilities.FormatCurrency(row.resultAbs, 2, 3, '.', ',')  + " EUR"}</div>
                );

            },
            footer: 2,            
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                return (
                    <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.abc.negResultAbs, 2, 3, '.', ',') + " EUR"}</div>
                );
            }
        });

        columns.push({
            dataField: 'resultRel',
            text: "Anteil",
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '30px', textAlign: 'right' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.ABCResultEbene) => {
                return (
                    <div>{Utilities.FormatCurrency(row.resultRel, 2, 3, '.', ',') + " %"}</div>
                );

            },
            footer: 3,          
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                return (
                    <div className="kslplus-tabellen-footer">100,00 %</div>
                );
            }
        });

        columns.push({
            dataField: 'resultRelKumuliert',
            text: "kum. Anteil",
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '30px', textAlign: 'right' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.ABCResultEbene) => {
                return (
                    <div>{Utilities.FormatCurrency(row.resultRelKumuliert, 2, 3, '.', ',') + " %"}</div>
                );

            },
            footer: 4,            
            footerFormatter: (column: any, columnIndex: any) => {
                return null;
            }
        });

        columns.push({
            dataField: 'klassifikation',
            text: "Klasse",
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '30px', textAlign: 'right' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.ABCResultEbene) => {
                return (
                    <div>{row.klassifikation}</div>
                );

            },
            footer: 5,            
            footerFormatter: (column: any, columnIndex: any) => {
                return null;
            }
        });

        return columns;
    }

    createPosColumns = () => {
        var columns = [];


        var id = 1;
        columns.push({
            dataField: 'uaFmt',
            text: this.props.buchungsart === 2 ? "Produkt" : "Unterabschnitt",
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '50px', textAlign: 'right' };
            },
            footer: 0,            
            footerFormatter: (column: any, columnIndex: any) => {
                return null;
            }
        });

        columns.push({
            dataField: 'uaText',
            text: "Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '250px', textAlign: 'left' };
            },
            footer: 1,            
            footerAlign: 'left',
            footerFormatter: (column: any, columnIndex: any) => {
                return (
                    <div>
                        <div className="kslplus-tabellen-footer">Positiver Deckungsbeitrag insgesamt</div>
                        <div className="kslplus-tabellen-footer">Negativer Deckungsbeitrag insgesamt</div>
                        <div className="kslplus-tabellen-footer">Gesamtergebnis</div>
                    </div>
                );
            }
        });

        columns.push({
            dataField: 'resultAbs',
            text: "Deckungsbeitrag",
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '75px', textAlign: 'right' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.ABCResultEbene) => {
                return (
                    <div>{Utilities.FormatCurrency(row.resultAbs, 2, 3, '.', ',') + " EUR"}</div>
                );

            },
            footer: 2,            
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                return (
                    <div>
                        <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.abc.posResultAbs, 2, 3, '.', ',') + " EUR"}</div>
                        <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(this.props.abc.negResultAbs, 2, 3, '.', ',') + " EUR"}</div>
                        <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency((this.props.abc.posResultAbs !== undefined ? this.props.abc.posResultAbs : 0) + (this.props.abc.negResultAbs !== undefined ? this.props.abc.negResultAbs : 0), 2, 3, '.', ',') + " EUR"}</div>
                    </div>
                );
            }
        });

        columns.push({
            dataField: 'resultRel',
            text: "Anteil",
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '30px', textAlign: 'right' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.ABCResultEbene) => {
                return (
                    <div>{Utilities.FormatCurrency(row.resultRel, 2, 3, '.', ',') + " %"}</div>
                );

            },
            footer: 3,            
            footerFormatter: (column: any, columnIndex: any) => {
                return null;
            }
        });

        columns.push({
            dataField: 'resultRelKumuliert',
            text: "kum. Anteil",
            align: 'right',
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '30px', textAlign: 'right' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.ABCResultEbene) => {                
                return (
                    <div>{Utilities.FormatCurrency(row.resultRelKumuliert, 2, 3, '.', ',') + " %"}</div>
                );

            },
            footer: 4,            
            footerFormatter: (column: any, columnIndex: any) => {
                return null;
            }
        });
        

        return columns;
    }



    expandRow = {
        /*
        renderer: (row: KSLPlusAPI.ABCResultEbene) => (
            this.state.hhPrs[row.uaFmt ? row.uaFmt : ""] != null ? <HHSummiertDoppisch hh={this.state.hhPrs[row.uaFmt ? row.uaFmt : ""]} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={true} settings={this.props.settings} summenTyp={3} uhconfig={this.props.uhconfig} callerID={row.uaFmt ? row.uaFmt : ""} />
                : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
        ),
        */
        renderer: (row: KSLPlusAPI.ABCResultEbene) => (
            this.props.buchungsart == 2 ?
                <ProduktSpezHaushalt kunde={this.props.kunde} gemeinde={this.props.gemeinde} produkt={row.uaFmt ? row.uaFmt : ""} plantyp={this.props.plantyp} planstufe={this.props.planstufe} jahr={this.props.jahr} isVisible={true} settings={this.props.settings} summenTyp={3} uhconfig={this.props.uhconfig} verwaltungsansicht={this.props.verwaltungsansicht} />
                :
                this.state.hhGL != null ?
                    <GLHaushalt hh={this.state.hhGL} onSelChange={(ebene: string) => { }} isvmhh={false} massnahmen={[]} kunde={this.props.kunde} gemeinde={this.props.gemeinde} selected={row.uaFmt ? row.uaFmt : ""} plantyp={this.props.plantyp} planstufe={this.props.planstufe} jahr={this.props.jahr} settings={this.props.settings} uhconfig={this.props.uhconfig} verwaltungsansicht={this.props.verwaltungsansicht} hideNav={true} isVisible={true} />
                    :
                    null
        ),
        className: 'kslplus-tabellen-expanded',
        //expanded: this.state.expanded,
        //onExpand: this.handleOnExpand,
        showExpandColumn: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }: { isAnyExpands: boolean }) => {
            return null;
        }     
    };


    

    render() {                 
        return (
            
            <div className="kslplus-abcanlysis">
                <Row>
                    <Col className="kslplus-beschreibung-content">
                        <div className="content" dangerouslySetInnerHTML={{ __html: this.props.abc.textOben ? this.props.abc.textOben : "" }}></div>
                    </Col>                                        
                </Row>
                <Row>
                    <Col>
                        <BootstrapTable hover={false} bordered={false} keyField='id' data={this.props.abc.negResults} columns={this.createNegColumns()} rowStyle={this.rowStyle} rowClasses={this.rowClasses} expandRow={this.expandRow} />
                    </Col>
                </Row>
                <Row>
                    <Col className="kslplus-beschreibung-content">
                        <div className="content" dangerouslySetInnerHTML={{ __html: this.props.abc.textMitte ? this.props.abc.textMitte : "" }}></div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BootstrapTable hover={false} bordered={false} keyField='id' data={this.props.abc.posResults} columns={this.createPosColumns()} rowStyle={this.rowStyle} expandRow={this.expandRow} />
                    </Col>
                </Row>
                <Row>
                    <Col className="kslplus-beschreibung-content">
                        <div className="content" dangerouslySetInnerHTML={{ __html: this.props.abc.textUnten ? this.props.abc.textUnten : "" }}></div>

                    </Col>
                </Row>
                <Modal id="ksl-modal-hh-doppisch" size="xl" contentClassName="ksl-modal-drilldown" isOpen={this.state.hhPRModal} toggle={() => this.setState({ hhPRModal: false })} backdrop="static">
                    <ModalHeader toggle={() => this.setState({ hhPRModal: false })}>Haushaltsdaten</ModalHeader>
                    <ModalBody>
                        {this.state.hhPr != null ?
                            <HHSummiertDoppisch hh={this.state.hhPr} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={true} settings={this.props.settings} summenTyp={3} uhconfig={this.props.uhconfig} callerID={"3-abc"} />
                            : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                        }
                    </ModalBody>                    
                </Modal>
                
            </div>
        );
    }
}
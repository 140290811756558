import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, UncontrolledTooltip, UncontrolledPopover, PopoverBody, Badge, Container
} from "reactstrap";

import Collapse from "@kunukn/react-collapse";


import * as KSLPlusAPI from "../services/KSLPlusAPI"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import Chart from "react-google-charts";

import TrackVisibility from 'react-on-screen';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import Drawer from '@material-ui/core/Drawer';

//import { HaushaltsNav, EbenenTyp } from './HaushaltsNavComp';
import { ProduktNavTree, EbenenTyp } from './ProduktNavTreeComp';
import { HHSummiertDoppisch } from './HHSummiertDoppischComp';

import * as Utilities from "./Utilities"

import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

const paginationoptions = {
    sizePerPageList: [{
        text: '10', value: 10
    },{
        text: '15', value: 15
    }, {
        text: '20', value: 20
    }] // A numeric array is also available. the purpose of above example is custom the text
};

type State = {
    ready: boolean,
    ebene: KSLPlusAPI.HaushaltsHiearchieDoppik | undefined,
    expandedItem: string,
    openBeschreibungen: boolean[]
}

type Props = {    
    hh: KSLPlusAPI.HaushaltsHiearchieDoppik,
    jahr: number,
    kunde: number,
    gemeinde: number,    
    plantyp: number,
    planstufe: number,  
    verwaltungsansicht: boolean,
    settings: KSLPlusAPI.SettingsModel,
    uhconfig: KSLPlusAPI.UhConfigModel | undefined,
    onSelChange: (ebeneId: string) => void,
    selected: string,
    summenTyp: number,
    isVisible: boolean
}


export class ProduktHaushalt extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
        
    }

    static defaultProps = {
        summenTyp: 1        
    }

    node: any;    
    container: any = null;

    state: State = {
        ready: false,
        expandedItem: "",
        ebene: this.props.hh,
        openBeschreibungen: []
    }

    redrawCharts = () => {        
        if (this.state.ebene )
            this.handleSelectHHEbene(this.state.ebene);
    }

    componentDidUpdate(prevProps: Props) {
        
        if (prevProps.uhconfig !== this.props.uhconfig && this.state.ebene)
            this.readSummenHH(this.state.ebene);
    }
  
    componentDidMount() {
        window.addEventListener('resize', this.redrawCharts, false);
        if ( this.state.ebene )
            this.readSummenHH(this.state.ebene);
    }

    

    readFiltered = (ebene: KSLPlusAPI.HaushaltsHiearchieDoppik) => {
        this.setState({ ebene: ebene });
        /*
        var request = new KSLPlusAPI.HaushaltSummenRequest();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;


        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        if (this.props.isvmhh) {
            request.gruppen = [3, 9];
            request.ignorierteGruppen = [0, 1, 2, 4, 5, 6, 7, 8];
        }
        else {
            request.gruppen = [0, 1, 2, 4, 5, 6, 7, 8];
            request.ignorierteGruppen = [3, 9];
        }
        request.uaStellen = ebene.uaStellen;
        request.unterabschnitt = ebene.unterabschnitt;
        //request.ignorierteGruppen = [800, 801, 802, 803, 804, 805, 806, 807, 808, 86, 91, 201, 202, 203, 204, 205, 206, 207, 208, 28, 31];
        client.getFilteredHaushaltByGruppierung("", request).then((hh) => {
            ebene.filter = hh;            
            this.setState({ ebene: ebene });
            //this.setState({ ready: true });
        }).catch(Reason => {
        });
        */
    }


    readSummenHH = (ebene: KSLPlusAPI.HaushaltsHiearchieDoppik, tries: number = 0) => {
        
        
        
        var request = new KSLPlusAPI.HaushaltSummenRequest();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.summenTyp = this.props.summenTyp;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);
        
        if (ebene && ebene.produkt !== undefined && ebene.produktStellen)
            request.produkt = Utilities.Num2Str(ebene.produkt, ebene.produktStellen);
                
        client.getHaushaltsSummeByProdukt("", request).then((hh) => {
            ebene.summenHH = hh;                        
            this.setState({ ebene: ebene });
            //this.setState({ ready: true });
        }).catch(Reason => {
            if (tries < 3)
                setTimeout(() => { this.readSummenHH(ebene, ++tries) }, 2000);
            else
                alert("Fehler beim Lesen des Haushalts für Betrachtung nach Produkten!");
        });
        
    }

    

    handleSelectHHEbene = (ebene: KSLPlusAPI.HaushaltsHiearchieDoppik) => {                
        this.setState({ ebene: undefined });
        if (!ebene.summenHH && ebene.unterEbenen )
            this.readSummenHH(ebene);
        else
            setTimeout(() => {this.setState({ ebene: ebene })}, 10);

        
        if ( ebene.id === this.props.hh.id || (this.props.hh.filter && ebene.id === this.props.hh.filter.id))
        {
            this.props.onSelChange("");            
        }
        else if ( ebene.produkt !== undefined && ebene.produktStellen !== undefined )
        {
            const num = Utilities.Num2Str(ebene.produkt, ebene.produktStellen);
            this.props.onSelChange(num);
            
        }
        
    }

    toggleBeschreibung = (idx: number) => {        
        this.state.openBeschreibungen[idx] = !this.state.openBeschreibungen[idx];
        this.setState({ready: true});
    }
    createBeschreibungsItems = (texte: KSLPlusAPI.Texte[]|undefined) => { 
        var items: JSX.Element[] = [];
        
        
        if ( !texte )
            return items;

        var numInRow = texte.length > 4 || texte.length == 3 ? 3 : 2;
        
        for ( var i = 0; i < texte.length ; i = i + numInRow )
        {
            const idx = i / numInRow;            
            this.state.openBeschreibungen.push(false);
            var spanItems : JSX.Element[] = [];
            var colItems : JSX.Element[] = [];
            for ( var j = i; j < i+numInRow && j < texte.length; j++ )
            {
                spanItems.push(
                    <span className="kslplus-beschreibung-text" key={"bespan" + j.toString()}>{texte[j].bezeichnung}</span>
                );
                colItems.push(
                    <Col className="kslplus-beschreibung-content" key={"becol" + j.toString()}>
                            {texte[j].text}                                                      
                    </Col>
                );
            }
            items.push(
                <button
                          className={"kslplus-beschreibung-toggle" + (this.state.openBeschreibungen[idx]?" active":"")}
                                                      onClick={() => this.toggleBeschreibung(idx)}
                            key={"beb" + idx.toString()}
                        >
                          
                          {spanItems}
                          <div className="rotate90">
                            <svg
                              className={"kslplus-beschreibung-icon" + (this.state.openBeschreibungen[idx]?" expanded":"")}
                              viewBox="6 0 12 24"
                            >
                              <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12"  />
                            </svg>
                          </div>
                            
                        </button>
            );
            items.push(
                <Collapse
                            isOpen={this.state.openBeschreibungen[idx]}
                            className={"kslplus-beschreibung-collapse-gradient " + + (this.state.openBeschreibungen[idx]?" active":"")}
                            key={"bec" + idx.toString()}
                        >
                        <Row>   
                        {colItems}
                        </Row>
                </Collapse>
            );
        }

        return items;
        
    }


    render() {        

        
        var numSelected = "";
        if ( this.state.ebene == this.props.hh )
            numSelected = "";
        else if (this.state.ebene && this.state.ebene.produkt !== undefined && this.state.ebene.produktStellen !== undefined)
            numSelected = Utilities.Num2Str(this.state.ebene.produkt, this.state.ebene.produktStellen);
        
        var num = this.props.selected;
        if ( numSelected !== num && this.props.hh.unterEbenen )
        {
            /*
            var ebene = this.findEbene( this.props.selected, this.props.hh.unterEbenen );
            if ( ebene )
            {
                this.readFiltered(ebene);                
                
            }
            */
        }
        /*
        const einnahmen = this.state.ebene !== undefined && Utilities.hatEinnahmen(this.state.ebene.filter);
        const ausgaben = this.state.ebene !== undefined && Utilities.hatAusgaben(this.state.ebene.filter);
        */

        
        if ( this.state.ebene == this.props.hh )
            num = "";
        else if ( !num.length && this.state.ebene && this.state.ebene.produkt !== undefined && this.state.ebene.produktStellen !== undefined)
            num = Utilities.Num2Str(this.state.ebene.produkt, this.state.ebene.produktStellen);
        

        
        var beschreibungen = this.createBeschreibungsItems(this.state.ebene ? this.state.ebene.texte : undefined);
        
        return (    
            
            <div>
                
                    
              <ProduktNavTree hh={this.props.hh} onSelChange={this.handleSelectHHEbene} useUAText={true} showNumber={true} selected={num} typ={EbenenTyp.Produkt} auswahlText="Auswahl Produktebene od. Suche per Eingabe"/>
            
            <div className="kslplus-hhcomp-content">  
            
                    <div className="kslplus-content">
                        {beschreibungen}
                                                                        
                    </div>
                    {this.state.ebene != null && this.state.ebene.summenHH ?
                        <HHSummiertDoppisch hh={this.state.ebene.summenHH} plantyp={this.props.plantyp} jahr={this.props.jahr} isVisible={this.props.isVisible} settings={this.props.settings} summenTyp={this.props.summenTyp} uhconfig={this.props.uhconfig} callerID={this.props.summenTyp.toString()} />
                        : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                    }
            </div>
            </div>                      
        );
    }
}


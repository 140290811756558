import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import { Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Col, Row, Nav, TabContent, TabPane, Badge, UncontrolledPopover, PopoverBody, Spinner, UncontrolledTooltip } from 'reactstrap';
import * as KSLPlusAPI from "../services/KSLPlusAPI"
import { ReactGoogleChartProps } from "react-google-charts/dist/types";

var _colors = ['#3366CC','#DC3912', '#FF9900','#109618', '#990099','#3B3EAC', '#0099C6','#DD4477', '#66AA00','#B82E2E', '#316395','#994499', '#22AA99','#AAAA11', '#6633CC','#E67300', '#8B0707','#329262', '#5574A6','#3B3EAC'];

export function Num2Str(num: number|undefined, size: number|undefined): string {
    if ( num === undefined || size === undefined )
        return "";

    var num1 = num.toString();
    while (num1.length < size) num1 = "0" + num1;
    return num1;
}

export function FormatCurrency(i: number | undefined, n: number, x: number, s: string, c: string): string {
    if (i === undefined)
        return "";

    if (i === Infinity)
        return "Ꝏ";
    else if (i === -Infinity)
        return "-Ꝏ";

    

    const fac = Math.pow(10, n);
    i = i * fac;
    i = Math.round(i);
    i = i / fac;

    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = i.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
}


export function calcArrowRotAndColor(val1: number, val2: number, ausgaben: boolean, settings: KSLPlusAPI.SettingsModel)
{
    // Werte auf zwei Dezimalstellen runden
    val1 = val1 * 100;
    val1 = Math.round(val1);
    val1 = val1 / 100;

    val2 = val2 * 100;
    val2 = Math.round(val2);
    val2 = val2 / 100;

    var rotation = 90;
    var color = "#fa0";

    var highColor = ausgaben ? "#c00" : "#0c0";
    var highValue = 1.03;
    var medhighColor: string | undefined;
    var medhighValue = 1.01;
    var medlowColor: string | undefined;
    var medlowValue = 0.99;
    var lowColor = ausgaben ? "#0c0" : "#c00";
    var lowValue = 0.97;

    const s = settings.changeSettings;

    if (s && s.highColor)
        highColor = s.highColor;
    if (ausgaben && s && s.highColorA)
        highColor = s.highColorA;
    if (s && s.highValue !== null && s.highValue !== undefined)
        highValue = 1 + s.highValue / 100;
    if (ausgaben && s && s.highValueA !== null && s.highValueA !== undefined)
        highValue = 1 + s.highValueA / 100;
    if (s && s.medHighColor)
        medhighColor = s.medHighColor;
    if (ausgaben && s && s.medHighColorA)
        medhighColor = s.medHighColorA;
    if (s && s.medHighValue !== null && s.medHighValue !== undefined)
        medhighValue = 1 + s.medHighValue / 100;
    if (ausgaben && s && s.medHighValueA !== null && s.medHighValueA !== undefined)
        medhighValue = 1 + s.medHighValueA / 100;
    if (s && s.evenColor)
        color = s.evenColor;
    if (ausgaben && s && s.evenColorA)
        color = s.evenColorA;
    if (s && s.medLowColor)
        medlowColor = s.medLowColor;
    if (ausgaben && s && s.medLowColorA)
        medlowColor = s.medLowColorA;
    if (s && s.medLowValue !== null && s.medLowValue !== undefined)
        medlowValue = 1 + s.medLowValue / 100;
    if (ausgaben && s && s.medLowValueA !== null && s.medLowValueA !== undefined)
        medlowValue = 1 + s.medLowValueA / 100;
    if (s && s.lowColor)
        lowColor = s.lowColor;
    if (ausgaben && s && s.lowColorA)
        lowColor = s.lowColorA;
    if (s && s.lowValue !== null && s.lowValue !== undefined)
        lowValue = 1 + s.lowValue / 100;
    if (ausgaben && s && s.lowValueA !== null && s.lowValueA !== undefined)
        lowValue = 1 + s.lowValueA / 100;

    
    var changeP = val2 == val1 ? 1 : 0;
    //if (val1 == 0 && val2 > 0)
      //  changeP = 11;
    //else if (val1 == 0 && val2 < 0)
        //changeP = -9;
    //else if (val1)
      //  changeP = val2 / val1;
    if (val2 != val1)
        changeP = val2 / val1;
        
    var changeA = val2 - val1;


    //if (val1 <= 0 || val2 < 0) {

    if (val1 == 0) {
        if (changeA > 0) {
            color = highColor;
            rotation = 0;
        }
        else if (changeA < 0) {
            color = lowColor;
            rotation = 180;
        }

    }
    else {
        if (Math.sign(changeP-1) != Math.sign(changeA)) 
        {

            if (changeP < lowValue) {
                rotation = 0;
                color = highColor;
            }
            else if (medlowColor && medhighColor && changeP < medlowValue) {
                rotation = 45;
                color = medhighColor;
            }
            else if (changeP > highValue) {
                rotation = 180;
                color = lowColor;
            }
            else if (medlowColor && medhighColor && changeP > medhighValue) {
                rotation = 135;
                color = medlowColor;
            }
        }
        else
        {
            if (changeP < lowValue) {
                rotation = 180;
                color = lowColor;
            }
            else if (medlowColor && medhighColor && changeP < medlowValue) {
                rotation = 135;
                color = medlowColor;
            }
            else if (changeP > highValue) {
                rotation = 0;
                color = highColor;
            }
            else if (medlowColor && medhighColor && changeP > medhighValue) {
                rotation = 45;
                color = medhighColor;
            }
        }

    }

    /*
    if (val1 == 0) {
        if (changeA > 0) {
            color = ausgaben ? lowColor : highColor;
            rotation = 0;
        }
        else if (changeA < 0) {
            color = ausgaben ? highColor : lowColor;
            rotation = 180;
        }

    }
    else {
        if (Math.sign(changeP-1) != Math.sign(changeA)) 
        {

            if (changeP < lowValue) {
                rotation = 0;
                color = ausgaben ? lowColor : highColor;
            }
            else if (medlowColor && medhighColor && changeP < medlowValue) {
                rotation = 45;
                color = ausgaben ? medlowColor : medhighColor;
            }
            else if (changeP > highValue) {
                rotation = 180;
                color = ausgaben ? highColor : lowColor;
            }
            else if (medlowColor && medhighColor && changeP > medhighValue) {
                rotation = 135;
                color = ausgaben ? medhighColor : medlowColor;
            }
        }
        else
        {
            if (changeP < lowValue) {
                rotation = 180;
                color = ausgaben ? highColor : lowColor;
            }
            else if (medlowColor && medhighColor && changeP < medlowValue) {
                rotation = 135;
                color = ausgaben ? medhighColor : medlowColor;
            }
            else if (changeP > highValue) {
                rotation = 0;
                color = ausgaben ? lowColor : highColor;
            }
            else if (medlowColor && medhighColor && changeP > medhighValue) {
                rotation = 45;
                color = ausgaben ? medlowColor : medhighColor;
            }
        }

    }
    */
    var ret = { changeA: changeA, changeP: changeP, color: color, rotation: rotation }
    return ret;
}

    
export function createGraphDataByHHSumme(data: KSLPlusAPI.HaushaltsSummeDoppik, jahr: number, bezeichnung: string, plantyp: number, jahre: number[], min: any[], max: any[]) {
    var grdata = [];
    grdata.push(['Jahr', bezeichnung + ' (EUR)',  'Änderung ' + bezeichnung + ' (%)']);

    
    
    var basis = null;

    if (plantyp == 3) {
        if (data.ergebnisPJM4) {
            grdata.push([(jahr - 4).toString(), data.ergebnisPJM4, basis != null ? (data.ergebnisPJM4 / basis - 1) * 100 : 100]);
            basis = data.ergebnisPJM4;
        }

        if (data.ergebnisPJM3) {
            grdata.push([(jahr - 3).toString(), data.ergebnisPJM3, basis != null ? (data.ergebnisPJM3 / basis - 1) * 100 : 100]);
            basis = data.ergebnisPJM3;
        }

        if (data.ergebnisPJM2) {
            grdata.push([(jahr - 2).toString(), data.ergebnisPJM2, basis != null ? (data.ergebnisPJM2 / basis - 1) * 100 : 100]);
            basis = data.ergebnisPJM2;
        }

        if (data.ergebnisPJM1) {
            grdata.push([(jahr - 1).toString(), data.ergebnisPJM1, basis != null ? (data.ergebnisPJM1 / basis - 1) * 100 : 100]);
            basis = data.ergebnisPJM1;
        }

        if (data.ergebnisPJ) {
            grdata.push([(jahr).toString(), data.ergebnisPJ, basis != null ? (data.ergebnisPJ / basis - 1) * 100 : 100]);
            basis = data.ergebnisPJ;
        }        
    }
    else if (plantyp == 4) {
        if (data.ergebnisPJM4) {
            grdata.push([(jahr - 4).toString(), data.ergebnisPJM4, basis != null ? (data.ergebnisPJM4 / basis - 1) * 100 : 100]);
            basis = data.ergebnisPJM4;
        }

        if (data.ergebnisPJM3) {
            grdata.push([(jahr - 3).toString(), data.ergebnisPJM3, basis != null ? (data.ergebnisPJM3 / basis - 1) * 100 : 100]);
            basis = data.ergebnisPJM3;
        }

        if (data.ergebnisPJM2) {
            grdata.push([(jahr - 2).toString(), data.ergebnisPJM2, basis != null ? (data.ergebnisPJM2 / basis - 1) * 100 : 100]);
            basis = data.ergebnisPJM2;
        }

        if (data.ergebnisPJM1) {
            grdata.push([(jahr - 1).toString(), data.ergebnisPJM1, basis != null ? (data.ergebnisPJM1 / basis - 1) * 100 : 100]);
            basis = data.ergebnisPJM1;
        }

        if (data.progMan) {
            grdata.push([(jahr).toString(), data.progMan, basis != null ? (data.progMan / basis - 1) * 100 : 100]);
            basis = data.ergebnisPJ;
        }
    }
    else
    {
        if (data.ergebnisVvJ != undefined) {
            grdata.push([(jahr - 2).toString(), data.ergebnisVvJ, basis != null ? (data.ergebnisVvJ / basis - 1) * 100 : 100]);
            basis = data.ergebnisVvJ;
        }

        if (data.ansatzVJ !== undefined) {
            grdata.push([(jahr - 1).toString(), data.ansatzVJ, basis != null ? (data.ansatzVJ / basis - 1) * 100 : 100]);
            basis = data.ansatzVJ;
        }

        if (data.ansatzPj !== undefined) {
            grdata.push([(jahr).toString(), data.ansatzPj, basis != null ? (data.ansatzPj / basis - 1) * 100 : 100]);
            basis = data.ansatzPj;
        }

        if (data.fp1 !== undefined) {
            grdata.push([(jahr + 1).toString(), data.fp1, basis != null ? (data.fp1 / basis - 1) * 100 : 100]);
            basis = data.fp1;
        }

        if (data.fp2 !== undefined) {
            grdata.push([(jahr + 2).toString(), data.fp2, basis != null ? (data.fp2 / basis - 1) * 100 : 100]);
            basis = data.fp2;
        }

        if (data.fp3 !== undefined) {
            grdata.push([(jahr + 3).toString(), data.fp3, basis != null ? (data.fp3 / basis - 1) * 100 : 100]);
            basis = data.fp3;
        }

        if (data.fp4 !== undefined) {
            grdata.push([(jahr + 4).toString(), data.fp4, basis != null ? (data.fp4 / basis - 1) * 100 : 100]);
            basis = data.fp4;
        }

    }
    
    
    for (var i=1; i < grdata.length ; i++) {
        if (grdata[i][1] < min[0])
            min[0] = grdata[i][1];
        if (grdata[i][1] > max[0])
            max[0] = grdata[i][1];
        if (grdata[i][2] < min[1])
            min[1] = grdata[i][2];
        if (grdata[i][2] > max[1])
            max[1] = grdata[i][2];
    }
    return grdata;
}

export function createGraphData(dataHaben: KSLPlusAPI.HaushaltsEbene | null | undefined, dataSoll: KSLPlusAPI.HaushaltsEbene | null | undefined, subHaben: KSLPlusAPI.HaushaltsEbene | null, subSoll: KSLPlusAPI.HaushaltsEbene | null, jahr: number, min: any[], max: any[]) {
    var grdata = [];
    grdata.push(['Jahr', 'Einnahmen (EUR)', 'Ausgaben (EUR)', 'Änderung Einnahmen (%)', 'Änderung Ausgaben (%)']);

    if (dataHaben == null || dataSoll == null)
        return grdata;

    var basis_haben = null;
    var basis_soll = null;

    
    /*
    if (dataHaben.ergebnisVvvJ_haben != null && dataSoll.ergebnisVvvJ_soll != null && dataSoll.ergebnisVvvJ_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisVvvJ_haben != undefined ? subHaben.ergebnisVvvJ_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisVvvJ_soll != undefined ? subSoll.ergebnisVvvJ_soll : 0);
        grdata.push([(jahr - 3).toString(), dataHaben.ergebnisVvvJ_haben - subhaben, dataSoll.ergebnisVvvJ_soll - subsoll, 100, 100]);
        basis_haben = dataHaben.ergebnisVvvJ_haben - subhaben;
        basis_soll = dataSoll.ergebnisVvvJ_soll - subsoll;
    }
    */

    if (!dataHaben.jahre) {        
        return grdata;
    }


    if (dataHaben.jahre.findIndex(d => d == jahr - 2) >= 0 && dataHaben.ergebnisVvJ_haben != null && dataSoll.ergebnisVvJ_soll != null && dataSoll.ergebnisVvJ_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisVvJ_haben != undefined ? subHaben.ergebnisVvJ_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisVvJ_soll != undefined ? subSoll.ergebnisVvJ_soll : 0);

        grdata.push([(jahr - 2).toString(), dataHaben.ergebnisVvJ_haben - subhaben, dataSoll.ergebnisVvJ_soll - subsoll, basis_haben != null ? ((dataHaben.ergebnisVvJ_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.ergebnisVvJ_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisVvJ_haben - subhaben;
        basis_soll = dataSoll.ergebnisVvJ_soll - subsoll;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr - 1) >= 0 && dataHaben.ansatzVJ_haben != null && dataSoll.ansatzVJ_soll != null && dataSoll.ansatzVJ_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ansatzVJ_haben != undefined ? subHaben.ansatzVJ_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ansatzVJ_soll != undefined ? subSoll.ansatzVJ_soll : 0);

        grdata.push([(jahr - 1).toString(), dataHaben.ansatzVJ_haben - subhaben, dataSoll.ansatzVJ_soll - subsoll, basis_haben != null ? ((dataHaben.ansatzVJ_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.ansatzVJ_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.ansatzVJ_haben - subhaben;
        basis_soll = dataSoll.ansatzVJ_soll - subsoll;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr) >= 0 && dataHaben.ansatzPj_haben != null && dataSoll.ansatzPj_soll != null && dataSoll.ansatzPj_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ansatzPj_haben != undefined ? subHaben.ansatzPj_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ansatzPj_soll != undefined ? subSoll.ansatzPj_soll : 0);

        grdata.push([jahr.toString(), dataHaben.ansatzPj_haben - subhaben, dataSoll.ansatzPj_soll - subsoll, basis_haben != null ? ((dataHaben.ansatzPj_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.ansatzPj_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.ansatzPj_haben - subhaben;
        basis_soll = dataSoll.ansatzPj_soll - subsoll;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr + 1) >= 0 && dataHaben.fp1_haben != null && dataSoll.fp1_soll != null && dataSoll.fp1_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.fp1_haben != undefined ? subHaben.fp1_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.fp1_soll != undefined ? subSoll.fp1_soll : 0);

        grdata.push([(jahr + 1).toString(), dataHaben.fp1_haben - subhaben, dataSoll.fp1_soll - subsoll, basis_haben != null ? ((dataHaben.fp1_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.fp1_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.fp1_haben - subhaben;
        basis_soll = dataSoll.fp1_soll - subsoll;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr + 2) >= 0 && dataHaben.fp2_haben != null && dataSoll.fp2_soll != null && dataSoll.fp2_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.fp2_haben != undefined ? subHaben.fp2_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.fp2_soll != undefined ? subSoll.fp2_soll : 0);

        grdata.push([(jahr + 2).toString(), dataHaben.fp2_haben - subhaben, dataSoll.fp2_soll - subsoll, basis_haben != null ? ((dataHaben.fp2_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.fp2_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.fp2_haben - subhaben;
        basis_soll = dataSoll.fp2_soll - subsoll;
    }


    if (dataHaben.jahre.findIndex(d => d == jahr + 3) >= 0 &&  dataHaben.fp3_haben != null && dataSoll.fp3_soll != null && dataSoll.fp3_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.fp3_haben != undefined ? subHaben.fp3_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.fp3_soll != undefined ? subSoll.fp3_soll : 0);        
        grdata.push([(jahr + 3).toString(), dataHaben.fp3_haben - subhaben, dataSoll.fp3_soll - subsoll, basis_haben != null ? ((dataHaben.fp3_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.fp3_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.fp3_haben - subhaben;
        basis_soll = dataSoll.fp3_soll - subsoll;
    }
    for (var i = 1; i < grdata.length; i++) {
        if (grdata[i][1] < min[0])
            min[0] = grdata[i][1];
        if (grdata[i][1] > max[0])
            max[0] = grdata[i][1];
        if (grdata[i][2] < min[1])
            min[1] = grdata[i][2];
        if (grdata[i] > max[1])
            max[1] = grdata[i][2];
        if (grdata[i][3] < min[0])
            min[0] = grdata[i][3];
        if (grdata[i][3] > max[0])
            max[0] = grdata[i][3];
        if (grdata[i][4] < min[1])
            min[1] = grdata[i][4];
        if (grdata[i][4] > max[1])
            max[1] = grdata[i][4];
    }
    
    return grdata;
}

export function createGraphDataJR(dataHaben: KSLPlusAPI.HaushaltsEbene | null | undefined, dataSoll: KSLPlusAPI.HaushaltsEbene | null | undefined, subHaben: KSLPlusAPI.HaushaltsEbene | null, subSoll: KSLPlusAPI.HaushaltsEbene | null, jahr: number, min: any[], max: any[]) {
    var grdata = [];
    grdata.push(['Jahr', 'Einnahmen (EUR)', 'Ausgaben (EUR)', 'Änderung Einnahmen (%)', 'Änderung Ausgaben (%)']);

    if (dataHaben == null || dataSoll == null)
        return grdata;

    var basis_haben = null;
    var basis_soll = null;

    if (!dataHaben.jahre) {
        return grdata;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr - 4) >= 0 && dataHaben.ergebnisPJM4_haben != null && dataSoll.ergebnisPJM4_soll != null && dataSoll.ergebnisPJM4_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM4_haben != undefined ? subHaben.ergebnisPJM4_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM4_soll != undefined ? subSoll.ergebnisPJM4_soll : 0);

        grdata.push([(jahr - 4).toString(), dataHaben.ergebnisPJM4_haben - subhaben, dataSoll.ergebnisPJM4_soll - subsoll, basis_haben != null ? ((dataHaben.ergebnisPJM4_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.ergebnisPJM4_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM4_haben - subhaben;
        basis_soll = dataSoll.ergebnisPJM4_soll - subsoll;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr - 3) >= 0 && dataHaben.ergebnisPJM3_haben != null && dataSoll.ergebnisPJM3_soll != null && dataSoll.ergebnisPJM3_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM3_haben != undefined ? subHaben.ergebnisPJM3_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM3_soll != undefined ? subSoll.ergebnisPJM3_soll : 0);

        grdata.push([(jahr - 3).toString(), dataHaben.ergebnisPJM3_haben - subhaben, dataSoll.ergebnisPJM3_soll - subsoll, basis_haben != null ? ((dataHaben.ergebnisPJM3_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.ergebnisPJM3_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM3_haben - subhaben;
        basis_soll = dataSoll.ergebnisPJM3_soll - subsoll;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr - 2) >= 0 && dataHaben.ergebnisPJM2_haben != null && dataSoll.ergebnisPJM2_soll != null && dataSoll.ergebnisPJM2_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM2_haben != undefined ? subHaben.ergebnisPJM2_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM2_soll != undefined ? subSoll.ergebnisPJM2_soll : 0);

        grdata.push([(jahr - 2).toString(), dataHaben.ergebnisPJM2_haben - subhaben, dataSoll.ergebnisPJM2_soll - subsoll, basis_haben != null ? ((dataHaben.ergebnisPJM2_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.ergebnisPJM2_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM2_haben - subhaben;
        basis_soll = dataSoll.ergebnisPJM2_soll - subsoll;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr - 1) >= 0 && dataHaben.ergebnisPJM1_haben != null && dataSoll.ergebnisPJM1_soll != null && dataSoll.ergebnisPJM1_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM1_haben != undefined ? subHaben.ergebnisPJM1_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM1_soll != undefined ? subSoll.ergebnisPJM1_soll : 0);

        grdata.push([(jahr - 1).toString(), dataHaben.ergebnisPJM1_haben - subhaben, dataSoll.ergebnisPJM1_soll - subsoll, basis_haben != null ? ((dataHaben.ergebnisPJM1_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.ergebnisPJM1_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM1_haben - subhaben;
        basis_soll = dataSoll.ergebnisPJM1_soll - subsoll;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr) >= 0 && dataHaben.ergebnisPJ_haben != null && dataSoll.ergebnisPJ_soll != null && dataSoll.ergebnisPJ_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJ_haben != undefined ? subHaben.ergebnisPJ_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJ_soll != undefined ? subSoll.ergebnisPJ_soll : 0);

        grdata.push([jahr.toString(), dataHaben.ergebnisPJ_haben - subhaben, dataSoll.ergebnisPJ_soll - subsoll, basis_haben != null ? ((dataHaben.ergebnisPJ_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.ergebnisPJ_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJ_haben - subhaben;
        basis_soll = dataSoll.ergebnisPJ_soll - subsoll;
    }
    for (var i = 1; i < grdata.length; i++) {
        if (grdata[i][1] < min[0])
            min[0] = grdata[i][1];
        if (grdata[i][1] > max[0])
            max[0] = grdata[i][1];
        if (grdata[i][2] < min[1])
            min[1] = grdata[i][2];
        if (grdata[i] > max[1])
            max[1] = grdata[i][2];
        if (grdata[i][3] < min[0])
            min[0] = grdata[i][3];
        if (grdata[i][3] > max[0])
            max[0] = grdata[i][3];
        if (grdata[i][4] < min[1])
            min[1] = grdata[i][4];
        if (grdata[i][4] > max[1])
            max[1] = grdata[i][4];
    }
    
    return grdata;
}

export function createGraphDataUH(dataHaben: KSLPlusAPI.HaushaltsEbene | null | undefined, dataSoll: KSLPlusAPI.HaushaltsEbene | null | undefined, subHaben: KSLPlusAPI.HaushaltsEbene | null, subSoll: KSLPlusAPI.HaushaltsEbene | null, jahr: number, min: any[], max: any[]) {
    var grdata = [];
    grdata.push(['Jahr', 'Einnahmen (EUR)', 'Ausgaben (EUR)', 'Änderung Einnahmen (%)', 'Änderung Ausgaben (%)']);

    if (dataHaben == null || dataSoll == null)
        return grdata;

    var basis_haben = null;
    var basis_soll = null;

    if (!dataHaben.jahre) {
        return grdata;
    }
    
    if (dataHaben.jahre.findIndex(d => d == jahr - 4) >= 0 && dataHaben.ergebnisPJM4_haben != null && dataSoll.ergebnisPJM4_soll != null && dataSoll.ergebnisPJM4_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM4_haben != undefined ? subHaben.ergebnisPJM4_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM4_soll != undefined ? subSoll.ergebnisPJM4_soll : 0);

        grdata.push([(jahr - 4).toString(), dataHaben.ergebnisPJM4_haben - subhaben, dataSoll.ergebnisPJM4_soll - subsoll, basis_haben != null ? ((dataHaben.ergebnisPJM4_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.ergebnisPJM4_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM4_haben - subhaben;
        basis_soll = dataSoll.ergebnisPJM4_soll - subsoll;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr - 3) >= 0 && dataHaben.ergebnisPJM3_haben != null && dataSoll.ergebnisPJM3_soll != null && dataSoll.ergebnisPJM3_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM3_haben != undefined ? subHaben.ergebnisPJM3_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM3_soll != undefined ? subSoll.ergebnisPJM3_soll : 0);

        grdata.push([(jahr - 3).toString(), dataHaben.ergebnisPJM3_haben - subhaben, dataSoll.ergebnisPJM3_soll - subsoll, basis_haben != null ? ((dataHaben.ergebnisPJM3_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.ergebnisPJM3_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM3_haben - subhaben;
        basis_soll = dataSoll.ergebnisPJM3_soll - subsoll;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr - 2) >= 0 && dataHaben.ergebnisPJM2_haben != null && dataSoll.ergebnisPJM2_soll != null && dataSoll.ergebnisPJM2_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM2_haben != undefined ? subHaben.ergebnisPJM2_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM2_soll != undefined ? subSoll.ergebnisPJM2_soll : 0);

        grdata.push([(jahr - 2).toString(), dataHaben.ergebnisPJM2_haben - subhaben, dataSoll.ergebnisPJM2_soll - subsoll, basis_haben != null ? ((dataHaben.ergebnisPJM2_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.ergebnisPJM2_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM2_haben - subhaben;
        basis_soll = dataSoll.ergebnisPJM2_soll - subsoll;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr - 1) >= 0 && dataHaben.ergebnisPJM1_haben != null && dataSoll.ergebnisPJM1_soll != null && dataSoll.ergebnisPJM1_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM1_haben != undefined ? subHaben.ergebnisPJM1_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM1_soll != undefined ? subSoll.ergebnisPJM1_soll : 0);

        grdata.push([(jahr - 1).toString(), dataHaben.ergebnisPJM1_haben - subhaben, dataSoll.ergebnisPJM1_soll - subsoll, basis_haben != null ? ((dataHaben.ergebnisPJM1_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.ergebnisPJM1_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM1_haben - subhaben;
        basis_soll = dataSoll.ergebnisPJM1_soll - subsoll;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr) >= 0 && dataHaben.progMan_haben != null && dataSoll.progMan_soll != null && dataSoll.progMan_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.progMan_haben != undefined ? subHaben.progMan_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.progMan_soll != undefined ? subSoll.progMan_soll : 0);

        grdata.push([jahr.toString(), dataHaben.progMan_haben - subhaben, dataSoll.progMan_soll - subsoll, basis_haben != null ? ((dataHaben.progMan_haben - subhaben) / basis_haben - 1) * 100 : 100, basis_soll != null ? ((dataSoll.progMan_soll - subsoll) / basis_soll - 1) * 100 : 100]);
        basis_haben = dataHaben.progMan_haben - subhaben;
        basis_soll = dataSoll.progMan_soll - subsoll;
    }
    for (var i = 1; i < grdata.length; i++) {
        if (grdata[i][1] < min[0])
            min[0] = grdata[i][1];
        if (grdata[i][1] > max[0])
            max[0] = grdata[i][1];
        if (grdata[i][2] < min[0])
            min[0] = grdata[i][2];
        if (grdata[i] > max[0])
            max[0] = grdata[i][2];
        if (grdata[i][3] < min[1])
            min[1] = grdata[i][3];
        if (grdata[i][3] > max[1])
            max[1] = grdata[i][3];
        if (grdata[i][4] < min[1])
            min[1] = grdata[i][4];
        if (grdata[i][4] > max[1])
            max[1] = grdata[i][4];
    }

    return grdata;
}


export function createGraphDataAusgabenJR(dataSoll: KSLPlusAPI.HaushaltsEbene | null | undefined, subSoll: KSLPlusAPI.HaushaltsEbene | null, jahr: number, min: any[], max: any[]) {
    var grdata = [];
    grdata.push(['Jahr', 'Ausgaben (EUR)', 'Änderung Ausgaben (%)']);

    if (dataSoll == null)
        return grdata;

    if (!dataSoll.jahre) {
        return grdata;
    }
    
    var basis_soll = null;

    if (dataSoll.jahre.findIndex(d => d == jahr - 4) >= 0 && dataSoll.ergebnisPJM4_soll != null ) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM4_soll != undefined ? subSoll.ergebnisPJM4_soll : 0);

        grdata.push([(jahr - 4).toString(), dataSoll.ergebnisPJM4_soll - subsoll, basis_soll != null ? (dataSoll.ergebnisPJM4_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.ergebnisPJM4_soll - subsoll;
    }

    if (dataSoll.jahre.findIndex(d => d == jahr - 3) >= 0 && dataSoll.ergebnisPJM3_soll != null ) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM3_soll != undefined ? subSoll.ergebnisPJM3_soll : 0);

        grdata.push([(jahr - 3).toString(), dataSoll.ergebnisPJM3_soll - subsoll, basis_soll != null ? (dataSoll.ergebnisPJM3_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.ergebnisPJM3_soll - subsoll;
    }

    if (dataSoll.jahre.findIndex(d => d == jahr - 2) >= 0 && dataSoll.ergebnisPJM2_soll != null ) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM2_soll != undefined ? subSoll.ergebnisPJM2_soll : 0);

        grdata.push([(jahr - 2).toString(), dataSoll.ergebnisPJM2_soll - subsoll, basis_soll != null ? (dataSoll.ergebnisPJM2_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.ergebnisPJM2_soll - subsoll;
    }

    if (dataSoll.jahre.findIndex(d => d == jahr - 1) >= 0 && dataSoll.ergebnisPJM1_soll != null ) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM1_soll != undefined ? subSoll.ergebnisPJM1_soll : 0);

        grdata.push([(jahr - 1).toString(), dataSoll.ergebnisPJM1_soll - subsoll, basis_soll != null ? (dataSoll.ergebnisPJM1_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.ergebnisPJM1_soll - subsoll;
    }

    if (dataSoll.jahre.findIndex(d => d == jahr) >= 0 && dataSoll.ergebnisPJ_soll != null ) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJ_soll != undefined ? subSoll.ergebnisPJ_soll : 0);

        grdata.push([jahr.toString(), dataSoll.ergebnisPJ_soll - subsoll, basis_soll != null ? (dataSoll.ergebnisPJ_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.ergebnisPJ_soll - subsoll;
    }
    for (var i = 1; i < grdata.length; i++) {
        if (grdata[i][1] < min[0])
            min[0] = grdata[i][1];
        if (grdata[i][1] > max[0])
            max[0] = grdata[i][1];
        if (grdata[i][2] < min[1])
            min[1] = grdata[i][2];
        if (grdata[i][2] > max[1])
            max[1] = grdata[i][2];
    }

    return grdata;
}


export function createGraphDataAusgabenUH(dataSoll: KSLPlusAPI.HaushaltsEbene | null | undefined, subSoll: KSLPlusAPI.HaushaltsEbene | null, jahr: number, min: any[], max: any[]) {
    var grdata = [];
    grdata.push(['Jahr', 'Ausgaben (EUR)', 'Änderung Ausgaben (%)']);

    if (dataSoll == null)
        return grdata;

    var basis_soll = null;

    if (!dataSoll.jahre) {
        return grdata;
    }

    if (dataSoll.jahre.findIndex(d => d == jahr - 4) >= 0 && dataSoll.ergebnisPJM4_soll != null) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM4_soll != undefined ? subSoll.ergebnisPJM4_soll : 0);

        grdata.push([(jahr - 4).toString(), dataSoll.ergebnisPJM4_soll - subsoll, basis_soll != null ? (dataSoll.ergebnisPJM4_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.ergebnisPJM4_soll - subsoll;
    }

    if (dataSoll.jahre.findIndex(d => d == jahr - 3) >= 0 && dataSoll.ergebnisPJM3_soll != null) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM3_soll != undefined ? subSoll.ergebnisPJM3_soll : 0);

        grdata.push([(jahr - 3).toString(), dataSoll.ergebnisPJM3_soll - subsoll, basis_soll != null ? (dataSoll.ergebnisPJM3_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.ergebnisPJM3_soll - subsoll;
    }

    if (dataSoll.jahre.findIndex(d => d == jahr - 2) >= 0 && dataSoll.ergebnisPJM2_soll != null) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM2_soll != undefined ? subSoll.ergebnisPJM2_soll : 0);

        grdata.push([(jahr - 2).toString(), dataSoll.ergebnisPJM2_soll - subsoll, basis_soll != null ? (dataSoll.ergebnisPJM2_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.ergebnisPJM2_soll - subsoll;
    }

    if (dataSoll.jahre.findIndex(d => d == jahr - 1) >= 0 && dataSoll.ergebnisPJM1_soll != null) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisPJM1_soll != undefined ? subSoll.ergebnisPJM1_soll : 0);

        grdata.push([(jahr - 1).toString(), dataSoll.ergebnisPJM1_soll - subsoll, basis_soll != null ? (dataSoll.ergebnisPJM1_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.ergebnisPJM1_soll - subsoll;
    }

    if (dataSoll.jahre.findIndex(d => d == jahr) >= 0 && dataSoll.progMan_soll != null) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.progMan_soll != undefined ? subSoll.progMan_soll : 0);

        grdata.push([jahr.toString(), dataSoll.progMan_soll - subsoll, basis_soll != null ? (dataSoll.progMan_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.progMan_soll - subsoll;
    }
    for (var i = 1; i < grdata.length; i++) {
        if (grdata[i][1] < min[0])
            min[0] = grdata[i][1];
        if (grdata[i][1] > max[0])
            max[0] = grdata[i][1];
        if (grdata[i][2] < min[1])
            min[1] = grdata[i][2];
        if (grdata[i][2] > max[1])
            max[1] = grdata[i][2];
    }

    return grdata;
}

export function createGraphDataEinnahmenJR(dataHaben: KSLPlusAPI.HaushaltsEbene | null | undefined, subHaben: KSLPlusAPI.HaushaltsEbene | null, jahr: number, min: any[], max: any[]) {
    var grdata = [];
    grdata.push(['Jahr', 'Einnahmen (EUR)', 'Änderung Einnahmen (%)']);

    if (dataHaben == null )
        return grdata;

    if (!dataHaben.jahre) {
        return grdata;
    }

    var basis_haben = null;
    
    if (dataHaben.jahre.findIndex(d => d == jahr - 4) >= 0 && dataHaben.ergebnisPJM4_haben != null ) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM4_haben != undefined ? subHaben.ergebnisPJM4_haben : 0);
        
        grdata.push([(jahr - 4).toString(), dataHaben.ergebnisPJM4_haben - subhaben, basis_haben != null ? (dataHaben.ergebnisPJM4_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM4_haben - subhaben;        
    }
    

    if (dataHaben.jahre.findIndex(d => d == jahr - 3) >= 0 && dataHaben.ergebnisPJM3_haben != null ) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM3_haben != undefined ? subHaben.ergebnisPJM3_haben : 0);
        
        grdata.push([(jahr - 3).toString(), dataHaben.ergebnisPJM3_haben - subhaben, basis_haben != null ? (dataHaben.ergebnisPJM3_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM3_haben - subhaben;        
    }

    if (dataHaben.jahre.findIndex(d => d == jahr - 2) >= 0 && dataHaben.ergebnisPJM2_haben != null ) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM2_haben != undefined ? subHaben.ergebnisPJM2_haben : 0);
        
        grdata.push([(jahr - 2).toString(), dataHaben.ergebnisPJM2_haben - subhaben, basis_haben != null ? (dataHaben.ergebnisPJM2_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM2_haben - subhaben;        
    }

    if (dataHaben.jahre.findIndex(d => d == jahr - 1) >= 0 && dataHaben.ergebnisPJM1_haben != null ) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM1_haben != undefined ? subHaben.ergebnisPJM1_haben : 0);
        
        grdata.push([(jahr - 1).toString(), dataHaben.ergebnisPJM1_haben - subhaben, basis_haben != null ? (dataHaben.ergebnisPJM1_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM1_haben - subhaben;        
    }

    if (dataHaben.jahre.findIndex(d => d == jahr) >= 0 && dataHaben.ergebnisPJ_haben != null ) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJ_haben != undefined ? subHaben.ergebnisPJ_haben : 0);
        
        grdata.push([jahr.toString(), dataHaben.ergebnisPJ_haben - subhaben, basis_haben != null ? (dataHaben.ergebnisPJ_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJ_haben - subhaben;        
    }
    for (var i = 1; i < grdata.length; i++) {
        if (grdata[i][1] < min[0])
            min[0] = grdata[i][1];
        if (grdata[i][1] > max[0])
            max[0] = grdata[i][1];
        if (grdata[i][2] < min[1])
            min[1] = grdata[i][2];
        if (grdata[i][2] > max[1])
            max[1] = grdata[i][2];
    }
    

    
    return grdata;
}


export function createGraphDataEinnahmenUH(dataHaben: KSLPlusAPI.HaushaltsEbene | null | undefined, subHaben: KSLPlusAPI.HaushaltsEbene | null, jahr: number, min: any[], max: any[]) {
    var grdata = [];
    grdata.push(['Jahr', 'Einnahmen (EUR)', 'Änderung Einnahmen (%)']);

    if (dataHaben == null)
        return grdata;

    if (!dataHaben.jahre) {
        return grdata;
    }

    var basis_haben = null;

    if (dataHaben.jahre.findIndex(d => d == jahr - 4) >= 0 && dataHaben.ergebnisPJM4_haben != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM4_haben != undefined ? subHaben.ergebnisPJM4_haben : 0);

        grdata.push([(jahr - 4).toString(), dataHaben.ergebnisPJM4_haben - subhaben, basis_haben != null ? (dataHaben.ergebnisPJM4_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM4_haben - subhaben;
    }


    if (dataHaben.jahre.findIndex(d => d == jahr - 3) >= 0 && dataHaben.ergebnisPJM3_haben != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM3_haben != undefined ? subHaben.ergebnisPJM3_haben : 0);

        grdata.push([(jahr - 3).toString(), dataHaben.ergebnisPJM3_haben - subhaben, basis_haben != null ? (dataHaben.ergebnisPJM3_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM3_haben - subhaben;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr - 2) >= 0 && dataHaben.ergebnisPJM2_haben != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM2_haben != undefined ? subHaben.ergebnisPJM2_haben : 0);

        grdata.push([(jahr - 2).toString(), dataHaben.ergebnisPJM2_haben - subhaben, basis_haben != null ? (dataHaben.ergebnisPJM2_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM2_haben - subhaben;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr - 1) >= 0 && dataHaben.ergebnisPJM1_haben != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisPJM1_haben != undefined ? subHaben.ergebnisPJM1_haben : 0);

        grdata.push([(jahr - 1).toString(), dataHaben.ergebnisPJM1_haben - subhaben, basis_haben != null ? (dataHaben.ergebnisPJM1_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisPJM1_haben - subhaben;
    }

    if (dataHaben.jahre.findIndex(d => d == jahr) >= 0 && dataHaben.progMan_haben != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.progMan_haben != undefined ? subHaben.progMan_haben : 0);

        grdata.push([jahr.toString(), dataHaben.progMan_haben - subhaben, basis_haben != null ? (dataHaben.progMan_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.progMan_haben - subhaben;
    }
    for (var i = 1; i < grdata.length; i++) {
        if (grdata[i][1] < min[0])
            min[0] = grdata[i][1];
        if (grdata[i][1] > max[0])
            max[0] = grdata[i][1];
        if (grdata[i][2] < min[1])
            min[1] = grdata[i][2];
        if (grdata[i][2] > max[1])
            max[1] = grdata[i][2];
    }



    return grdata;
}

export function createBarDataAEAJRBySummenDoppisch(z: KSLPlusAPI.HaushaltsSummeDoppik|undefined, bezeichnung: string) {
    var grdata = [];
    grdata.push(['Bezeichnung', '', { role: "style" }, {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
    }]);
            
    if (z) {

        if (z.ansatzPJfort != null)
            grdata.push([bezeichnung + " Ansatz (EUR)", z.ansatzPJfort, z.ansatzPJfort > 0 ? "#264d99" : "#DC3912", null]);
        if (z.ergebnisPJ != null)
            grdata.push([bezeichnung + " Ergebnis (EUR)", z.ergebnisPJ, z.ergebnisPJ > 0 ? "#264d99" : "#DC3912", null]);
        if (z.aenderungPJ != null)
            grdata.push([bezeichnung + " Abweichung (EUR)", z.aenderungPJ, z.aenderungPJ > 0 ? "#264d99" : "#DC3912", null]);

    }            
    
        
    return grdata;
}

export function createBarDataAPAUHBySummenDoppisch(z: KSLPlusAPI.HaushaltsSummeDoppik | undefined, bezeichnung: string) {
    var grdata = [];
    grdata.push(['Bezeichnung', '', { role: "style" }, {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
    }]);

    if (z) {

        if (z.ansatzPJfort != null)
            grdata.push([bezeichnung + " Ansatz (EUR)", z.ansatzPJfort, z.ansatzPJfort > 0 ? "#264d99" : "#DC3912", null]);
        if (z.progMan != null)
            grdata.push([bezeichnung + " Prognose (EUR)", z.progMan, z.progMan > 0 ? "#264d99" : "#DC3912", null]);
        if (z.aenderungPJ != null)
            grdata.push([bezeichnung + " Abweichung (EUR)", z.aenderungPJ, z.aenderungPJ > 0 ? "#264d99" : "#DC3912", null]);

    }


    return grdata;
}

export function createAenderungsBarDataJRBySummenDoppisch(zeilen: KSLPlusAPI.HaushaltsSummeDoppik[]) {
    var grdata = [];
    grdata.push(['Bezeichnung', '', { role: "style" }, {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
    }]);




    zeilen.forEach(z => {                    
        if (z) {
            if (z.aenderungPJ != null && z.aenderungPJ != 0)
                grdata.push([z.bezeichnung + " (EUR)", z.aenderungPJ, z.aenderungPJ > 0 ? "#264d99" : "#DC3912", null]);
        }
            
    });
    

    return grdata;
}



export function createBarDataAEAJRdoppisch(zeilen: KSLPlusAPI.HaushaltsZeile[], g: KSLPlusAPI.HaushaltsGrafik, useProgMan: boolean = false) {
    var grdata = [];
    grdata.push(['Bezeichnung', '', { role: "style" }, {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
    }]);

   
    

    if (g.reihen) {

        g.reihen.forEach(r => {
            if (r.zeile) {
                const z = zeilen.find(z => z.zeile == r.zeile);
                if (z) {
                        
                        if (z.ansatzPJfort != null)
                            grdata.push([(r.bezeichnung ? r.bezeichnung : z.bezeichnung) + " Ansatz (EUR)", z.ansatzPJfort, z.ansatzPJfort > 0 ? r.farbePos : r.farbeNeg, null]);
                        if (useProgMan && z.progMan != null)
                            grdata.push([(r.bezeichnung ? r.bezeichnung : z.bezeichnung) + " Prognose (EUR)", z.progMan, z.progMan > 0 ? r.farbePos : r.farbeNeg, null]);
                        else if (!useProgMan && z.ergebnisPJ != null)
                            grdata.push([(r.bezeichnung ? r.bezeichnung : z.bezeichnung) + " Ergebnis (EUR)", z.ergebnisPJ, z.ergebnisPJ > 0 ? r.farbePos : r.farbeNeg, null]);
                        if (z.aenderungPJ != null)
                            grdata.push([(r.bezeichnung ? r.bezeichnung : z.bezeichnung) + " Abweichung (EUR)", z.aenderungPJ, z.aenderungPJ > 0 ? r.farbePos : r.farbeNeg, null]);
                    
                }
            }
        });
    }
    /*
    if (ertraege) {
        if (ertraege.ansatzPJfort != null)
            grdata.push([ertraege.bezeichnung + " Ansatz (EUR)", ertraege.ansatzPJfort, "#264d99", null]);
        if (ertraege.ergebnisPJ != null)
            grdata.push([ertraege.bezeichnung + " Ergebnis (EUR)", ertraege.ergebnisPJ, "#264d99", null]);
        if (ertraege.aenderungPJ != null)
            grdata.push([ertraege.bezeichnung + " Abweichung (EUR)", ertraege.aenderungPJ, ertraege.aenderungPJ > 0 ? "#264d99" : "#DC3912", null]);
    }
    if (aufwendungen) {
        if (aufwendungen.ansatzPJfort != null)
            grdata.push([aufwendungen.bezeichnung + " Ansatz (EUR)", aufwendungen.ansatzPJfort, "#DC3912", null]);
        if (aufwendungen.ergebnisPJ != null)
            grdata.push([aufwendungen.bezeichnung + " Ergebnis (EUR)", aufwendungen.ergebnisPJ, "#DC3912", null]);
        if (aufwendungen.aenderungPJ != null)
            grdata.push([aufwendungen.bezeichnung + " Abweichung (EUR)", aufwendungen.aenderungPJ, aufwendungen.aenderungPJ < 0 ? "#264d99" : "#DC3912", null]);
    }
    */
    return grdata;
}

export function createAenderungsBarDataJRdoppisch(zeilen: KSLPlusAPI.HaushaltsZeile[], g: KSLPlusAPI.HaushaltsGrafik) {
    var grdata = [];
    grdata.push(['Bezeichnung', '', { role: "style" }, {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
    }]);




    if (g.reihen) {

        g.reihen.forEach(r => {
            if (r.zeile) {
                const z = zeilen.find(z => z.zeile == r.zeile);
                if (z) {                    
                    if (z.aenderungPJ != null && z.aenderungPJ != 0)
                        grdata.push([(r.bezeichnung ? r.bezeichnung : z.bezeichnung) + " (EUR)", z.aenderungPJ, z.aenderungPJ > 0 ? r.farbePos : r.farbeNeg, null]);
                }
            }
        });
    }
    
    return grdata;
}

export function createBarDataAEAJR(data: KSLPlusAPI.HaushaltsEbene | null | undefined, subSoll: KSLPlusAPI.HaushaltsEbene | null | undefined, subHaben: KSLPlusAPI.HaushaltsEbene | null | undefined, colors: string[], ausgaben: boolean = true, einnahmen: boolean = true ) {
    var grdata = [];
    grdata.push(['Bezeichnung', '', { role: "style" }, {
      sourceColumn: 0,      
      role: "annotation",
      type: "string",
      calc: "stringify",
    }]);


    if (data == null || data == undefined)
        return grdata;

/*
    if (subSoll == null || subSoll == undefined )
        return grdata;

    if (subHaben == null || subHaben == undefined )
        return grdata;  
*/
    var style = "color: <color>; fill-color: <fillcolor>; stroke-width: 3";
    if (subSoll == null || subSoll == undefined || subHaben == null || subHaben == undefined)
    {
        if ( einnahmen )
        {
            if ( data.ansatzPJfort_haben != null  )
                grdata.push(["Einnahmen Ansatz (EUR)", data.ansatzPJfort_haben, "#264d99", null]);
            if ( data.ergebnisPJ_haben != null  )
                grdata.push(["Einnahmen Ergebnis (EUR)", data.ergebnisPJ_haben, "#264d99", null]);
            if ( data.aenderungPJ_haben != null  )    
                grdata.push(["Einnahmen Abweichung (EUR)", data.aenderungPJ_haben, data.aenderungPJ_haben > 0 ? "#264d99" : "#DC3912", null]);
        }
        if ( ausgaben )
        {
            if ( data.ansatzPJfort_soll != null )
                grdata.push(["Ausgaben Ansatz (EUR)", data.ansatzPJfort_soll, "#DC3912", null]);
            if ( data.ergebnisPJ_soll != null )
                grdata.push(["Ausgaben Ergebnis (EUR)", data.ergebnisPJ_soll, "#DC3912", null]);
            if ( data.aenderungPJ_soll != null )
                grdata.push(["Ausgaben Abweichung (EUR)", data.aenderungPJ_soll, data.aenderungPJ_soll < 0 ? "#264d99" : "#DC3912", null]);
        }
    }
    else
    {
        if ( einnahmen )
        {
            if ( data.ansatzPJfort_haben != null && subHaben.ansatzPJfort_haben != null )
                grdata.push(["Einnahmen Ansatz (EUR)", data.ansatzPJfort_haben - subHaben.ansatzPJfort_haben, "#264d99", null]);
            if ( data.ergebnisPJ_haben != null && subHaben.ergebnisPJ_haben != null )
                grdata.push(["Einnahmen Ergebnis (EUR)", data.ergebnisPJ_haben - subHaben.ergebnisPJ_haben, "#264d99", null]);
            if ( data.aenderungPJ_haben != null && subHaben.aenderungPJ_haben != null )    
                grdata.push(["Einnahmen Abweichung (EUR)", data.aenderungPJ_haben - subHaben.aenderungPJ_haben, (data.aenderungPJ_haben - subHaben.aenderungPJ_haben) > 0 ? "#264d99" : "#DC3912", null]);
        }
        if ( ausgaben )
        {
            if ( data.ansatzPJfort_soll != null && subSoll.ansatzPJfort_soll != null )
                grdata.push(["Ausgaben Ansatz (EUR)", data.ansatzPJfort_soll - subSoll.ansatzPJfort_soll, "#DC3912", null]);

            if ( data.ergebnisPJ_soll != null && subSoll.ergebnisPJ_soll != null )
                grdata.push(["Ausgaben Ergebnis (EUR)", data.ergebnisPJ_soll - subSoll.ergebnisPJ_soll, "#DC3912", null]);
            if ( data.aenderungPJ_soll != null && subSoll.aenderungPJ_soll != null )
                grdata.push(["Ausgaben Abweichung (EUR)", data.aenderungPJ_soll - subSoll.aenderungPJ_soll, (data.aenderungPJ_soll - subSoll.aenderungPJ_soll) < 0 ? "#264d99" : "#DC3912", null]);
        }
    }
  
    return grdata;
}

export function createBarDataAEAUH(data: KSLPlusAPI.HaushaltsEbene | null | undefined, subSoll: KSLPlusAPI.HaushaltsEbene | null | undefined, subHaben: KSLPlusAPI.HaushaltsEbene | null | undefined, colors: string[], ausgaben: boolean = true, einnahmen: boolean = true) {
    var grdata = [];
    grdata.push(['Bezeichnung', '', { role: "style" }, {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
    }]);


    if (data == null || data == undefined)
        return grdata;

    /*
        if (subSoll == null || subSoll == undefined )
            return grdata;
    
        if (subHaben == null || subHaben == undefined )
            return grdata;  
    */
    var style = "color: <color>; fill-color: <fillcolor>; stroke-width: 3";
    if (subSoll == null || subSoll == undefined || subHaben == null || subHaben == undefined) {
        if (einnahmen) {
            if (data.ansatzPJfort_haben != null)
                grdata.push(["Einnahmen Ansatz (EUR)", data.ansatzPJfort_haben, "#264d99", null]);
            if (data.progMan_haben != null)
                grdata.push(["Einnahmen Prognose (EUR)", data.progMan_haben, "#264d99", null]);
            if (data.aenderungPJ_haben != null)
                grdata.push(["Einnahmen Abweichung (EUR)", data.aenderungPJ_haben, data.aenderungPJ_haben > 0 ? "#264d99" : "#DC3912", null]);
        }
        if (ausgaben) {
            if (data.ansatzPJfort_soll != null)
                grdata.push(["Ausgaben Ansatz (EUR)", data.ansatzPJfort_soll, "#DC3912", null]);
            if (data.progMan_soll != null)
                grdata.push(["Ausgaben Prognose (EUR)", data.progMan_soll, "#DC3912", null]);
            if (data.aenderungPJ_soll != null)
                grdata.push(["Ausgaben Abweichung (EUR)", data.aenderungPJ_soll, data.aenderungPJ_soll < 0 ? "#264d99" : "#DC3912", null]);
        }
    }
    else {
        if (einnahmen) {
            if (data.ansatzPJfort_haben != null && subHaben.ansatzPJfort_haben != null)
                grdata.push(["Einnahmen Ansatz (EUR)", data.ansatzPJfort_haben - subHaben.ansatzPJfort_haben, "#264d99", null]);
            if (data.progAuto_haben != null && subHaben.progAuto_haben != null)
                grdata.push(["Einnahmen Prognose (EUR)", data.progAuto_haben - subHaben.progAuto_haben, "#264d99", null]);
            if (data.aenderungPJ_haben != null && subHaben.aenderungPJ_haben != null)
                grdata.push(["Einnahmen Abweichung (EUR)", data.aenderungPJ_haben - subHaben.aenderungPJ_haben, (data.aenderungPJ_haben - subHaben.aenderungPJ_haben) > 0 ? "#264d99" : "#DC3912", null]);
        }
        if (ausgaben) {
            if (data.ansatzPJfort_soll != null && subSoll.ansatzPJfort_soll != null)
                grdata.push(["Ausgaben Ansatz (EUR)", data.ansatzPJfort_soll - subSoll.ansatzPJfort_soll, "#DC3912", null]);

            if (data.progMan_soll != null && subSoll.progMan_soll != null)
                grdata.push(["Ausgaben Prognose (EUR)", data.progMan_soll - subSoll.progMan_soll, "#DC3912", null]);
            if (data.aenderungPJ_soll != null && subSoll.aenderungPJ_soll != null)
                grdata.push(["Ausgaben Abweichung (EUR)", data.aenderungPJ_soll - subSoll.aenderungPJ_soll, (data.aenderungPJ_soll - subSoll.aenderungPJ_soll) < 0 ? "#264d99" : "#DC3912", null]);
        }
    }

    return grdata;
}

export function createAenderungsBarDataJR (data: KSLPlusAPI.HaushaltsEbene | null | undefined, gruppen: number[] | undefined, ausgaben: boolean) {
    var grdata = [];
    grdata.push(['Bezeichnung', '', { role: "style" }, {
      sourceColumn: 0,      
      role: "annotation",
      type: "string",
      calc: "stringify",
    }]);

    //var colors = ["#264d99", "pink", "magenta", "purple", "lightblue", "orange", "lightgreen", "#dc3912", "yellow", "brown"];
    var colors = ["brown", "pink", "magenta", "purple", "lightblue", "orange", "lightgreen", "#dc3912", "yellow", "darkgray"];

    
    if (data == null || data == undefined || data.unterEbenen === undefined)
        return grdata;
    var j = 0;
    for (var i = 0; i < data.unterEbenen.length; i++) {
        var ebene = data.unterEbenen[i];

        if (gruppen === undefined || gruppen.find(g => g == ebene.untergruppe) !== undefined) {
            var val: number | undefined;            
            val = ausgaben ? ebene.aenderungPJ_soll : ebene.aenderungPJ_haben;            
            var style = "color: <color>; fill-color: <fillcolor>; stroke-width: 3";
            if (ausgaben)
                style = val !== undefined && val < 0 ? "#264d99" : "#DC3912";
            else
                style = val !== undefined && val > 0 ? "#264d99" : "#DC3912";

            if ( ebene.containsData  )
                grdata.push([ebene.bezeichnung, val, style, null]);
        }
        j++;
    }

    return grdata;
}


export function createAenderungsBarDataUH(data: KSLPlusAPI.HaushaltsEbene | null | undefined, gruppen: number[] | undefined, ausgaben: boolean) {
    var grdata = [];
    grdata.push(['Bezeichnung', '', { role: "style" }, {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
    }]);

    //var colors = ["#264d99", "pink", "magenta", "purple", "lightblue", "orange", "lightgreen", "#dc3912", "yellow", "brown"];
    var colors = ["brown", "pink", "magenta", "purple", "lightblue", "orange", "lightgreen", "#dc3912", "yellow", "darkgray"];


    if (data == null || data == undefined || data.unterEbenen === undefined)
        return grdata;
    var j = 0;
    for (var i = 0; i < data.unterEbenen.length; i++) {
        var ebene = data.unterEbenen[i];

        if (gruppen === undefined || gruppen.find(g => g == ebene.untergruppe) !== undefined) {
            var val: number | undefined;
            val = ausgaben ? ebene.aenderungPJ_soll : ebene.aenderungPJ_haben;
            var style = "color: <color>; fill-color: <fillcolor>; stroke-width: 3";
            if (ausgaben)
                style = val !== undefined && val < 0 ? "#264d99" : "#DC3912";
            else
                style = val !== undefined && val > 0 ? "#264d99" : "#DC3912";

            if (ebene.containsData)
                grdata.push([ebene.bezeichnung, val, style, null]);
        }
        j++;
    }

    return grdata;
}

export function createBarDataVMAEAJR(dataE: KSLPlusAPI.HaushaltsEbene | null | undefined, dataA: KSLPlusAPI.HaushaltsEbene | null | undefined, colors: string[]) {
    var grdata = [];
    grdata.push(['Bezeichnung', '', { role: "style" }, {
      sourceColumn: 0,      
      role: "annotation",
      type: "string",
      calc: "stringify",
    }]);


    if (dataE == null || dataE == undefined )
        return grdata;

    if (dataA == null || dataA == undefined )
        return grdata;

    if ( dataE.ansatzPJfort_haben != null  )
        grdata.push(["Einnahmen Ansatz (EUR)", dataE.ansatzPJfort_haben, colors[0], null]);
    if ( dataE.ergebnisPJ_haben != null )
        grdata.push(["Einnahmen Ergebnis (EUR)", dataE.ergebnisPJ_haben, colors[1], null]);
    if ( dataE.aenderungPJ_haben != null )    
        grdata.push(["Einnahmen Abweichung (EUR)", dataE.aenderungPJ_haben, dataE.aenderungPJ_haben, dataE.aenderungPJ_haben > 0 ? "#264d99" : "#DC3912", null]);
    if ( dataA.ansatzPJfort_soll != null  )
        grdata.push(["Ausgaben Ansatz (EUR)", dataA.ansatzPJfort_soll , colors[3], null]);
    if ( dataA.ergebnisPJ_soll != null  )
        grdata.push(["Ausgaben Ergebnis (EUR)", dataA.ergebnisPJ_soll , colors[4], null]);
    if ( dataA.aenderungPJ_soll != null  )
        grdata.push(["Ausgaben Abweichung (EUR)", dataA.aenderungPJ_soll, dataA.aenderungPJ_soll > 0 ? "#DC3912" : "#264d99", null]);

  
    return grdata;
}

export function createBarDataVMAEAUH(dataE: KSLPlusAPI.HaushaltsEbene | null | undefined, dataA: KSLPlusAPI.HaushaltsEbene | null | undefined, colors: string[]) {
    var grdata = [];
    grdata.push(['Bezeichnung', '', { role: "style" }, {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
    }]);


    if (dataE == null || dataE == undefined)
        return grdata;

    if (dataA == null || dataA == undefined)
        return grdata;

    if (dataE.ansatzPJfort_haben != null)
        grdata.push(["Einnahmen Ansatz (EUR)", dataE.ansatzPJfort_haben, colors[0], null]);
    if (dataE.ergebnisPJ_haben != null)
        grdata.push(["Einnahmen Prognose (EUR)", dataE.progMan_haben, colors[1], null]);
    if (dataE.aenderungPJ_haben != null)
        grdata.push(["Einnahmen Abweichung (EUR)", dataE.aenderungPJ_haben, dataE.aenderungPJ_haben > 0 ? "#264d99" : "#DC3912", null]);
    if (dataA.ansatzPJfort_soll != null)
        grdata.push(["Ausgaben Ansatz (EUR)", dataA.ansatzPJfort_soll, colors[3], null]);
    if (dataA.ergebnisPJ_soll != null)
        grdata.push(["Ausgaben Prognose (EUR)", dataA.progMan_soll, colors[4], null]);
    if (dataA.aenderungPJ_soll != null)
        grdata.push(["Ausgaben Abweichung (EUR)", dataA.aenderungPJ_soll, dataA.aenderungPJ_soll > 0 ? "#DC3912" : "#264d99", null]);


    return grdata;
}

export function createGraphDataEinnahmen(dataHaben: KSLPlusAPI.HaushaltsEbene | null | undefined, subHaben: KSLPlusAPI.HaushaltsEbene | null, jahr: number, min: any[], max: any[]) {
    var grdata = [];
    grdata.push(['Jahr', 'Einnahmen (EUR)', 'Änderung Einnahmen (%)']);

    if (dataHaben == null )
        return grdata;

    var basis_haben = null;    

    /*
    if (dataHaben.ergebnisVvvJ_haben != null && dataSoll.ergebnisVvvJ_soll != null && dataSoll.ergebnisVvvJ_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisVvvJ_haben != undefined ? subHaben.ergebnisVvvJ_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisVvvJ_soll != undefined ? subSoll.ergebnisVvvJ_soll : 0);
        grdata.push([(jahr - 3).toString(), dataHaben.ergebnisVvvJ_haben - subhaben, dataSoll.ergebnisVvvJ_soll - subsoll, 100, 100]);
        basis_haben = dataHaben.ergebnisVvvJ_haben - subhaben;
        basis_soll = dataSoll.ergebnisVvvJ_soll - subsoll;
    }
    */

    if (dataHaben.ergebnisVvJ_haben != null ) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisVvJ_haben != undefined ? subHaben.ergebnisVvJ_haben : 0);
        
        grdata.push([(jahr - 2).toString(), dataHaben.ergebnisVvJ_haben - subhaben, basis_haben != null ? (dataHaben.ergebnisVvJ_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.ergebnisVvJ_haben - subhaben;        
    }

    if (dataHaben.ansatzVJ_haben != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ansatzVJ_haben != undefined ? subHaben.ansatzVJ_haben : 0);
        
        grdata.push([(jahr - 1).toString(), dataHaben.ansatzVJ_haben - subhaben, basis_haben != null ? (dataHaben.ansatzVJ_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.ansatzVJ_haben - subhaben;        
    }

    if (dataHaben.ansatzPj_haben != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ansatzPj_haben != undefined ? subHaben.ansatzPj_haben : 0);
       
        grdata.push([jahr.toString(), dataHaben.ansatzPj_haben - subhaben, basis_haben != null ? (dataHaben.ansatzPj_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.ansatzPj_haben - subhaben;        
    }

    if (dataHaben.fp1_haben != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.fp1_haben != undefined ? subHaben.fp1_haben : 0);
        
        grdata.push([(jahr + 1).toString(), dataHaben.fp1_haben - subhaben, basis_haben != null ? (dataHaben.fp1_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.fp1_haben - subhaben;        
    }

    if (dataHaben.fp2_haben != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.fp2_haben != undefined ? subHaben.fp2_haben : 0);

        grdata.push([(jahr + 2).toString(), dataHaben.fp2_haben - subhaben, basis_haben != null ? (dataHaben.fp2_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.fp2_haben - subhaben;
    }


    if (dataHaben.fp3_haben != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.fp3_haben != undefined ? subHaben.fp3_haben : 0);
        
        grdata.push([(jahr + 3).toString(), dataHaben.fp3_haben - subhaben, basis_haben != null ? (dataHaben.fp3_haben / basis_haben - 1) * 100 : 100]);
        basis_haben = dataHaben.fp3_haben - subhaben;
    }
    for (var i = 1; i < grdata.length; i++) {
        if (grdata[i][1] < min[0])
            min[0] = grdata[i][1];
        if (grdata[i][1] > max[0])
            max[0] = grdata[i][1];
        if (grdata[i][2] < min[1])
            min[1] = grdata[i][2];
        if (grdata[i][2] > max[1])
            max[1] = grdata[i][2];
    }

    return grdata;
}

export function createGraphDataAusgaben(dataSoll: KSLPlusAPI.HaushaltsEbene | null | undefined, subSoll: KSLPlusAPI.HaushaltsEbene | null, jahr: number, min: any[], max: any[]) {
    var grdata = [];
    grdata.push(['Jahr', 'Ausgaben (EUR)', 'Änderung Ausgaben (%)']);

    if (dataSoll == null)
        return grdata;
    
    var basis_soll = null;

    /*
    if (dataHaben.ergebnisVvvJ_haben != null && dataSoll.ergebnisVvvJ_soll != null && dataSoll.ergebnisVvvJ_soll != null) {
        var subhaben = (subHaben != null && subHaben !== undefined && subHaben.ergebnisVvvJ_haben != undefined ? subHaben.ergebnisVvvJ_haben : 0);
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisVvvJ_soll != undefined ? subSoll.ergebnisVvvJ_soll : 0);
        grdata.push([(jahr - 3).toString(), dataHaben.ergebnisVvvJ_haben - subhaben, dataSoll.ergebnisVvvJ_soll - subsoll, 100, 100]);
        basis_haben = dataHaben.ergebnisVvvJ_haben - subhaben;
        basis_soll = dataSoll.ergebnisVvvJ_soll - subsoll;
    }
    */

    if (dataSoll.ergebnisVvJ_soll != null && dataSoll.ergebnisVvJ_soll != null) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ergebnisVvJ_soll != undefined ? subSoll.ergebnisVvJ_soll : 0);

        grdata.push([(jahr - 2).toString(), dataSoll.ergebnisVvJ_soll - subsoll, basis_soll != null ? (dataSoll.ergebnisVvJ_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.ergebnisVvJ_soll - subsoll;
    }

    if (dataSoll.ansatzVJ_soll != null && dataSoll.ansatzVJ_soll != null) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ansatzVJ_soll != undefined ? subSoll.ansatzVJ_soll : 0);

        grdata.push([(jahr - 1).toString(), dataSoll.ansatzVJ_soll - subsoll, basis_soll != null ? (dataSoll.ansatzVJ_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.ansatzVJ_soll - subsoll;
    }

    if (dataSoll.ansatzPj_soll != null && dataSoll.ansatzPj_soll != null) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.ansatzPj_soll != undefined ? subSoll.ansatzPj_soll : 0);

        grdata.push([jahr.toString(), dataSoll.ansatzPj_soll - subsoll, basis_soll != null ? (dataSoll.ansatzPj_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.ansatzPj_soll - subsoll;
    }

    if (dataSoll.fp1_soll != null && dataSoll.fp1_soll != null) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.fp1_soll != undefined ? subSoll.fp1_soll : 0);

        grdata.push([(jahr + 1).toString(), dataSoll.fp1_soll - subsoll, basis_soll != null ? (dataSoll.fp1_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.fp1_soll - subsoll;
    }

    if (dataSoll.fp2_soll != null && dataSoll.fp2_soll != null) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.fp2_soll != undefined ? subSoll.fp2_soll : 0);

        grdata.push([(jahr + 2).toString(), dataSoll.fp2_soll - subsoll, basis_soll != null ? (dataSoll.fp2_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.fp2_soll - subsoll;
    }


    if (dataSoll.fp3_soll != null && dataSoll.fp3_soll != null) {
        var subsoll = (subSoll != null && subSoll !== undefined && subSoll.fp3_soll != undefined ? subSoll.fp3_soll : 0);

        grdata.push([(jahr + 3).toString(), dataSoll.fp3_soll - subsoll, basis_soll != null ? (dataSoll.fp3_soll / basis_soll - 1) * 100 : 100]);
        basis_soll = dataSoll.fp3_soll - subsoll;
    }
    for (var i = 1; i < grdata.length; i++) {
        if (grdata[i][1] < min[0])
            min[0] = grdata[i][1];
        if (grdata[i][1] > max[0])
            max[0] = grdata[i][1];
        if (grdata[i][2] < min[1])
            min[1] = grdata[i][2];
        if (grdata[i][2] > max[1])
            max[1] = grdata[i][2];
    }

    return grdata;
}

export function createPieData1 (data: KSLPlusAPI.HaushaltsEbene | null | undefined, jahr: number, ausgaben: boolean) {
    var grdata = [];
    grdata.push(['Gruppe', 'Wert']);


    if (data == null || data == undefined || data.unterEbenen === undefined)
        return grdata;

    for (var i = 0; i < data.unterEbenen.length; i++) {
        var ebene = data.unterEbenen[i];

        var val: number | undefined;
        switch (jahr) {
            case -3:
                val = ausgaben ? ebene.ergebnisVvvJ_soll : ebene.ergebnisVvvJ_haben;                    
                break;

            case -2:
                val = ausgaben ? ebene.ergebnisVvJ_soll : ebene.ergebnisVvJ_haben;                    
                break;

            case -1:
                val = ausgaben ? ebene.ansatzVJ_soll : ebene.ansatzVJ_haben;                
                break;

            case 0:
                val = ausgaben ? ebene.ansatzPj_soll : ebene.ansatzPj_haben;                    
                break;

            case 1:
                val = ausgaben ? ebene.fp1_soll : ebene.fp1_haben;                   
                break;

            case 2:
                val = ausgaben ? ebene.fp2_soll : ebene.fp2_haben;
                break;

            case 3:
                val = ausgaben ? ebene.fp3_soll : ebene.fp3_haben;
                break;

        }
        if ( val !== undefined && val >= 0 )
            grdata.push([ebene.bezeichnung, val]);
    }

    return grdata;
}

export function createBarDataByHHSumme(data: KSLPlusAPI.HaushaltsSummeDoppik | null | undefined, jahr: number, colors: string[], plantyp: number) {
    var grdata = [];
    grdata.push(['Produktbereich', 'Betrag [EUR]', { role: "style" }, {
      sourceColumn: 0,      
      role: "annotation",
      type: "string",
      calc: "stringify",
    }]);


    if (data == null || data == undefined || data.unterEbenen === undefined)
        return grdata;

    for (var i = 0; i < data.unterEbenen.length; i++) {
        
        var ebene = data.unterEbenen[i];    
        
        
        var val: number | undefined;
        if (plantyp === 3) {
            switch (jahr) {
                case -4:
                    val = ebene.ergebnisPJM4;
                    break;

                case -3:
                    val = ebene.ergebnisPJM3;
                    break;

                case -2:
                    val = ebene.ergebnisPJM2;
                    break;

                case -1:
                    val = ebene.ergebnisPJM1;
                    break;

                case 0:
                    val = ebene.ergebnisPJ;
                    break;                
            }
        }
        else if (plantyp === 4) {
            switch (jahr) {
                case -4:
                    val = ebene.ergebnisPJM4;
                    break;

                case -3:
                    val = ebene.ergebnisPJM3;
                    break;

                case -2:
                    val = ebene.ergebnisPJM2;
                    break;

                case -1:
                    val = ebene.ergebnisPJM1;
                    break;

                case 0:
                    val = ebene.progMan;
                    break;
            }
        }
        else {
            switch (jahr) {
                case -3:
                    val = ebene.ergebnisVvvJ;
                    break;

                case -2:
                    val = ebene.ergebnisVvJ;
                    break;

                case -1:
                    val = ebene.ansatzVJ;
                    break;

                case 0:
                    val = ebene.ansatzPj;
                    break;

                case 1:
                    val = ebene.fp1;
                    break;

                case 2:
                    val = ebene.fp2;
                    break;

                case 3:
                    val = ebene.fp3;
                    break;

            }
        }
        if (val !== undefined)
            grdata.push([ebene.bezeichnung, val, colors[i], null]);
        

    }
    
    return grdata;
}

export function createPieDataByHHSumme(data: KSLPlusAPI.HaushaltsSummeDoppik | null | undefined, jahr: number, plantyp: number   ) {
    var grdata = [];
    grdata.push(['Produktbereich', 'Wert']);


    if (data == null || data == undefined || data.unterEbenen === undefined)
        return grdata;

    for (var i = 0; i < data.unterEbenen.length; i++) {
        var ebene = data.unterEbenen[i];

        
        var val: number | undefined;
        if (plantyp == 3) {
            switch (jahr) {
                case -4:
                    val = ebene.ergebnisPJM4;
                    break;

                case -3:
                    val = ebene.ergebnisPJM3;
                    break;

                case -2:
                    val = ebene.ergebnisPJM2;
                    break;

                case -1:
                    val = ebene.ergebnisPJM1;
                    break;

                case 0:
                    val = ebene.ergebnisPJ;
                    break;
            }
        }
        else if (plantyp == 4) {
            switch (jahr) {
                case -4:
                    val = ebene.ergebnisPJM4;
                    break;

                case -3:
                    val = ebene.ergebnisPJM3;
                    break;

                case -2:
                    val = ebene.ergebnisPJM2;
                    break;

                case -1:
                    val = ebene.ergebnisPJM1;
                    break;

                case 0:
                    val = ebene.progMan;
                    break;
            }
        }
        else {
            switch (jahr) {
                case -3:
                    val = ebene.ergebnisVvvJ;
                    break;

                case -2:
                    val = ebene.ergebnisVvJ;
                    break;

                case -1:
                    val = ebene.ansatzVJ;
                    break;

                case 0:
                    val = ebene.ansatzPj;
                    break;

                case 1:
                    val = ebene.fp1;
                    break;

                case 2:
                    val = ebene.fp2;
                    break;

                case 3:
                    val = ebene.fp3;
                    break;
            }
        }

        if (val !== undefined && val >= 0)
            grdata.push([ebene.bezeichnung, val/1000]);
        

    }
    
    return grdata;
}

export function createBarDataJR(data: KSLPlusAPI.HaushaltsEbene | null | undefined, gruppen: number[] | undefined, ausgaben: boolean, planjahr: number, min: any[], max: any[], ubw: boolean = false): { data: any[], colors: any[] } {
    var grdata = [];           

    if (data == null || data == undefined || data.unterEbenen === undefined)
        return { data: [], colors: [] };

    var header: any[] = [];
    header.push('Jahr');
    
    
    var grwithdata: number[] = [];

    // Spalten mit Daten bestimmen
    for (var jahr = -4; jahr < 1; jahr++) {

        if (data.jahre && data.jahre.findIndex(d => d == planjahr + jahr) == -1)
            continue;
        for (var i = 0; i < data.unterEbenen.length; i++) {
            var ebene = data.unterEbenen[i];

            if (gruppen === undefined || gruppen.find(g => g == ebene.untergruppe) !== undefined) {
                var val: number | undefined;
                switch (jahr) {
                    case -4:
                        val = ausgaben ? ebene.ergebnisPJM4_soll : ebene.ergebnisPJM4_haben;
                        break;

                    case -3:
                        val = ausgaben ? ebene.ergebnisPJM3_soll : ebene.ergebnisPJM3_haben;
                        break;

                    case -2:
                        val = ausgaben ? ebene.ergebnisPJM2_soll : ebene.ergebnisPJM2_haben;
                        break;

                    case -1:
                        val = ausgaben ? ebene.ergebnisPJM1_soll : ebene.ergebnisPJM1_haben;
                        break;

                    case 0:
                        val = ubw ? (ausgaben ? ebene.progMan_soll : ebene.progMan_haben) : (ausgaben ? ebene.ergebnisPJ_soll : ebene.ergebnisPJ_haben);
                        break;

                }
                if (val !== undefined && val != 0) {
                    if (ebene.untergruppe !== undefined && !grwithdata.find(g => g == ebene.untergruppe)) {
                        grwithdata.push(ebene.untergruppe);
                    }                    
                }
            }
        }        
    }

    // Header-Zeile erzeugen, wobei nur Spalten mit daten beachtet werden
    var colors = [];
    for (var i = 0; i < data.unterEbenen.length; i++) {
        var ebene = data.unterEbenen[i];

        if (grwithdata.find(g => g == ebene.untergruppe) !== undefined) {
            header.push(ebene.bezeichnung + " (EUR)");
            if (gruppen !== undefined && ebene.untergruppe !== undefined)
                colors.push(_colors[ebene.untergruppe - gruppen[0]]);
        }
    }
    grdata.push(header);

    // Daten einfügen, wobei nur Splaten mit Daten beachtet werden
    for (var jahr = -4; jahr < 1; jahr++) {
        var row = [];

        if (data.jahre && data.jahre.findIndex(d => d == planjahr + jahr) == -1)
            continue;

        row.push((planjahr + jahr).toString());

        for (var i = 0; i < data.unterEbenen.length; i++) {
            var ebene = data.unterEbenen[i];

            if (gruppen === undefined || grwithdata.find(g => g == ebene.untergruppe) !== undefined) {
                var val: number | undefined;
                switch (jahr) {                    
                    case -4:
                        val = ausgaben ? ebene.ergebnisPJM4_soll : ebene.ergebnisPJM4_haben;
                        break;

                    case -3:
                        val = ausgaben ? ebene.ergebnisPJM3_soll : ebene.ergebnisPJM3_haben;
                        break;

                    case -2:
                        val = ausgaben ? ebene.ergebnisPJM2_soll : ebene.ergebnisPJM2_haben;
                        break;

                    case -1:
                        val = ausgaben ? ebene.ergebnisPJM1_soll : ebene.ergebnisPJM1_haben;
                        break;

                    case 0:
                        val = ubw ? (ausgaben ? ebene.progMan_soll : ebene.progMan_haben) : (ausgaben ? ebene.ergebnisPJ_soll : ebene.ergebnisPJ_haben);                        
                        break;

                }
                                
                row.push(val);                                    
            }
        }
        if ( row.length > 1 )
            grdata.push(row);
    }
    for (var i = 1; i < grdata.length; i++) {
        for (var j = 1; j < grdata[i].length; j++) {
            if (grdata[i][j] < min[0])
                min[0] = grdata[i][j];
            if (grdata[i][j] > max[0])
                max[0] = grdata[i][j];
        }
        
    }
    

    return { data: grdata, colors: colors };
}

export function createPieDataJR(data: KSLPlusAPI.HaushaltsEbene | null | undefined, jahr: number, gruppen: number[] | undefined, ausgaben: boolean, ubw: boolean = false) {
    var grdata = [];
    grdata.push(['Gruppe', 'Wert']);


    if (data == null || data == undefined || data.unterEbenen === undefined)
        return grdata;

    for (var i = 0; i < data.unterEbenen.length; i++) {
        var ebene = data.unterEbenen[i];

        if (gruppen === undefined || gruppen.find(g => g == ebene.untergruppe) !== undefined) {
            var val: number | undefined;
            switch (jahr) {
                case -999:
                    val = ausgaben ? ebene.aenderungPJ_soll : ebene.aenderungPJ_haben;
                    break;

                case -4:
                    val = ausgaben ? ebene.ergebnisPJM4_soll : ebene.ergebnisPJM4_haben;
                    break;

                case -3:
                    val = ausgaben ? ebene.ergebnisPJM3_soll : ebene.ergebnisPJM3_haben;
                    break;

                case -2:
                    val = ausgaben ? ebene.ergebnisPJM2_soll : ebene.ergebnisPJM2_haben;
                    break;

                case -1:
                    val = ausgaben ? ebene.ergebnisPJM1_soll : ebene.ergebnisPJM1_haben;
                    break;

                case 0:
                    val = ubw ? (ausgaben ? ebene.progMan_soll : ebene.progMan_haben): (ausgaben ? ebene.ergebnisPJ_soll : ebene.ergebnisPJ_haben);
                    break;

            }
            if (val !== undefined && val >= 0)
                grdata.push([ebene.bezeichnung, val/1000]);
        }

    }

    return grdata;
}

export function createPieData(data: KSLPlusAPI.HaushaltsEbene | null | undefined, jahr: number, gruppen: number[] | undefined, ausgaben: boolean, ubw: boolean = false) {
    var grdata = [];
    grdata.push(['Gruppe', 'Wert']);


    if (data == null || data == undefined || data.unterEbenen === undefined)
        return grdata;

    for (var i = 0; i < data.unterEbenen.length; i++) {
        var ebene = data.unterEbenen[i];

        if (gruppen === undefined || gruppen.find(g => g == ebene.untergruppe) !== undefined) {
            var val: number | undefined;
            if (!ubw) {
                switch (jahr) {
                    
                    case -3:
                        val = ausgaben ? ebene.ergebnisVvvJ_soll : ebene.ergebnisVvvJ_haben;
                        break;

                    case -2:
                        val = ausgaben ? ebene.ergebnisVvJ_soll : ebene.ergebnisVvJ_haben;
                        break;

                    case -1:
                        val = ausgaben ? ebene.ansatzVJ_soll : ebene.ansatzVJ_haben;
                        break;

                    case 0:
                        val = ausgaben ? ebene.ansatzPj_soll : ebene.ansatzPj_haben;
                        break;

                    case 1:
                        val = ausgaben ? ebene.fp1_soll : ebene.fp1_haben;
                        break;

                    case 2:
                        val = ausgaben ? ebene.fp2_soll : ebene.fp2_haben;
                        break;

                    case 3:
                        val = ausgaben ? ebene.fp3_soll : ebene.fp3_haben;
                        break;

                }
            }
            else {
                switch (jahr) {
                    case -4:
                        val = ausgaben ? ebene.ergebnisPJM4_soll : ebene.ergebnisPJM4_haben;
                        break;

                    case -3:
                        val = ausgaben ? ebene.ergebnisPJM3_soll : ebene.ergebnisPJM3_haben;
                        break;

                    case -2:
                        val = ausgaben ? ebene.ergebnisPJM2_soll : ebene.ergebnisPJM2_haben;
                        break;

                    case -1:
                        val = ausgaben ? ebene.ergebnisPJM1_soll : ebene.ergebnisPJM1_haben;
                        break;

                    case 0:
                        val = ausgaben ? ebene.progMan_soll : ebene.progMan_haben;
                        break;
                    

                }
            }
            if (val !== undefined && val >= 0)
                grdata.push([ebene.bezeichnung, val/1000]);
        }

    }

    return grdata;
}


export function createGSTooltipItems(spalten: { [key: string]: boolean }, importformat: number|undefined ) {
    var items = [];


    var sign = false;

    if (spalten["hhrest"]) {
        items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label"> Reste aus Vorjahren</div></div>);
        sign = true;
    }
        
    if (spalten["abg_hhrest"]) {
        items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">- Abgang auf Reste aus Vorjahren</div></div>);
        sign = true;
    }

    if (importformat != 0 && importformat != 1) {
        if (spalten["ansatz"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">{sign ? "+ " : " "}Ansatz lfd. Jahr</div></div>);
            sign = true;
        }
    }
    else {
        if (spalten["ansatz"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">{sign ? "+ " : " "}Ansatz lfd. Jahr (inkl. aller Nachträge)</div></div>);
            sign = true;
        }
    }

    if (importformat != 0 && importformat != 1) {
        if (spalten["nachtrag"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">+/- aus Nachtrag</div></div>);
            sign = true;
        }
    }

    if (spalten["sollueber"]) {
        items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">+/- aus Sollveränderungen</div></div>);
        sign = true;
    }

    if (spalten["uepl_apl"]) {
        items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">+/- aus über-/außerplanmäßigen Bewilligungen:</div></div>);
        sign = true;
    }

    return items;
}


export function createAusgefuehrtTooltipItems(spalten: { [key: string]: boolean }, importformat: number | undefined) {
    var items = [];


    var sign = false;

    if (importformat != 0 && importformat != 1) {
        if (spalten["se_ao_hhre"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label"> Mittelreservierung auf Reste aus VJ</div></div>);
            sign = true;
        }
    }

    if (importformat != 0 && importformat != 1) {
        if (spalten["auftrag_hr"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">{sign ? "+ " : " "}Aufträge auf Reste aus Vj</div></div>);
            sign = true;
        }
    }
    else {
        if (spalten["auftrag_hr"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">{sign ? "+ " : " "}Aufträge</div></div>);
            sign = true;
        }
    }

    if (importformat != 0 && importformat != 1) {
        if (spalten["aosoll_vj"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">{sign ? "+ " : ""}Ausgeführtes Soll auf Reste aus Vj</div></div>);
            sign = true;
        }
    }
    else {
        if (spalten["aosoll_vj"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">{sign ? "+ " : ""}Ausgeführtes Soll</div></div>);
            sign = true;
        }
    }

    if (importformat != 0 && importformat != 1) {
        if (spalten["ao_hr_hu"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">{sign ? "+ " : ""}Vorgemerktes Soll auf Reste aus Vj</div></div>);
            sign = true;
        }
    }
    else {
        if (spalten["ao_hr_hu"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">{sign ? "+ " : ""}Vorgemerktes Soll</div></div>);
            sign = true;
        }
    }

    if (importformat != 0 && importformat != 1) {
        if (spalten["se_ao_soll"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">{sign ? "+ " : ""}Mittelreservierung auf lfd. Ansatz</div></div>);
            sign = true;
        }
    }
    else {
        if (spalten["se_ao_soll"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">{sign ? "+ " : ""}Mittelreservierung / gesperrt</div></div>);
            sign = true;
        }
    }


    if (importformat != 0 && importformat != 1) {
        if (spalten["auftraeg"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">{sign ? "+ " : ""}Aufträge auf lfd. Ansatz</div></div>);
            sign = true;
        }
    }

    if (importformat != 0 && importformat != 1) {
        if (spalten["aosoll_lfd"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">{sign ? "+ " : ""}Ausgeführtes Soll auf lfd. Ansatz</div></div>);
            sign = true;
        }
    }

    if (importformat != 0 && importformat != 1) {
        if (spalten["ao_soll_hu"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">{sign ? "+ " : ""}Vorgemerktes Soll auf lfd. Ansatz</div></div>);
            sign = true;
        }
    }

    if (importformat == 0 || importformat == 1) {
        if (spalten["dauerbelege"]) {
            items.push(<div className="kslplus-header-tt-block"><div className="kslplus-label">{sign ? "+ " : ""}Mittelbindung durch Dauerbelege</div></div>);
            sign = true;
        }
    }

    return items;
}


export function hatAusgaben (ebene: KSLPlusAPI.HaushaltsEbene | null | undefined) {
    if ( !ebene || (!ebene.ergebnisVvJ_soll && !ebene.ansatzVJ_soll && !ebene.ansatzPj_soll && !ebene.ansatzPj_soll && !ebene.fp2_soll && !ebene.fp3_soll && !ebene.ergebnisPJ_soll && !ebene.ergebnisPJM1_soll && !ebene.ergebnisPJM2_soll && !ebene.ergebnisPJM3_soll && !ebene.ergebnisPJM4_soll) )
        return false;
    
    return true;            
}

export function hatEinnahmen (ebene: KSLPlusAPI.HaushaltsEbene | null | undefined) {
    if ( !ebene || (!ebene.ergebnisVvJ_haben && !ebene.ansatzVJ_haben && !ebene.ansatzPj_haben && !ebene.ansatzPj_haben && !ebene.fp2_haben && !ebene.fp3_haben && !ebene.ergebnisPJ_haben && !ebene.ergebnisPJM1_haben && !ebene.ergebnisPJM2_haben && !ebene.ergebnisPJM3_haben && !ebene.ergebnisPJM4_haben ) )
        return false;

    return true;
}

export function createFormatters(length:number, decimalPlaces: number) {
    var formatters : ReactGoogleChartProps["formatters"] = [];
    for ( var i = 0; i < length; i++ )
    {
        formatters.push({
                            type: 'NumberFormat',
                            column: i+1,
                            options: {
                                fractionDigits: decimalPlaces
                            }
                        });
    }
    return formatters;
}

export function createFormatters1(length:number, sets: KSLPlusAPI.Datensaetze[]) {
    var formatters : ReactGoogleChartProps["formatters"] = [];
    for ( var i = 0; i < length; i++ )
    {
        formatters.push({
                            type: 'NumberFormat',
                            column: i+1,
                            options: {
                                fractionDigits: sets[i].nachkommastellen
                            }
                        });
    }
    return formatters;
}

export function calcGrapOptions(min: any[], max: any[], showNullAxis: boolean|undefined) {
    var min0: number | undefined;
    var max0: number | undefined;
    var min1: number | undefined;
    var max1: number | undefined;
    var minG: number | undefined = 0;
    var maxG: number | undefined = 0;

    if (!showNullAxis) {
        minG = undefined;
        maxG = undefined;
        min0 = min[0];
        max0 = max[0];
        min1 = min[1];
        max1 = max[1];

        if (min0 !== undefined && min0 < 0)
            min0 *= 1.1;
        else if (min0 !== undefined && min0 > 0)
            min0 *= 0.9;
        if (min1 !== undefined && min1 < 0)
            min1 *= 1.1;
        else if (min1 !== undefined && min1 > 0)
            min1 *= 0.9;

        if (max0 !== undefined && max0 > 0)
            max0 *= 1.1;
        else if (max0 !== undefined && max0 < 0)
            max0 *= 0.9;
        if (max1 !== undefined && max1 > 0)
            max1 *= 1.1;
        else if (max1 !== undefined && max1 < 0)
            max1 *= 0.9;

        if (max0 != undefined && max0 >= 0 && min0 != undefined && min0 >= 0)
            max0 = undefined;
        if (max1 != undefined && max1 >= 0 && min1 != undefined && min1 >= 0)
            max1 = undefined;

        if (max0 != undefined && max0 <= 0 && min0 != undefined && min0 <= 0)
            min0 = undefined;
        if (max1 != undefined && max1 <= 0 && min1 != undefined && min1 <= 0)
            min1 = undefined;

        if (max0 != undefined && min0 != undefined && min0 * max0 < 0) {
            min0 = undefined;
            max0 = undefined;
        }

        if (max1 != undefined && min1 != undefined && min1 * max1 < 0) {
            min1 = undefined;
            max1 = undefined;
        }
    }

    return { minG: minG, maxG: maxG, min0: min0, max0: max0, min1: min1, max1: max1 };
}

export function createTTItem(settings: KSLPlusAPI.SettingsModel, bezeichnung: string) {
    //var items: JSX.Element[] = [];

    if (!settings.ttTexte)
        return null;



    var texte = settings.ttTexte.find(tt => tt.bezeichnung == bezeichnung);

    if ( !texte )
        return null;
    
    var id = "kslplus-tab-" + bezeichnung;      
    
    return (<UncontrolledPopover
                size="lg"
                placement="top"
                target={id}
                delay={{ show: 1000, hide: 0 }}
                trigger="hover"
                key={"kslplus-menu-tooltip-" + bezeichnung}
                className="kslplus-menu-popover">
                <PopoverBody className="kslplus-menu-popover-body">
                    {texte.text}
                </PopoverBody>
            </UncontrolledPopover>
            );
        

}

export default class Utilities { }
import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import { Tooltip
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

type State = {
    username: string,
    password: string;
    rememberme: boolean;
}

type Props = {
    failed: boolean,
    onSubmit: (username: string, password: string) => void,
    onForgotPW: () => void;
}

export class Login extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRememberMeClick = this.handleRememberMeClick.bind(this);

        var remember = localStorage.getItem("KSLPlusAdmin_RememberMe");
        var user = localStorage.getItem("KSLPlusAdmin_UserName");

        if (remember === "true" && user !== null) {
            this.state.username = user;
            this.state.rememberme = true;
        }

    }

    handleUsernameChange(event: ChangeEvent<HTMLInputElement>) {
        if ( event.target != null )
            this.setState({ username: event.target.value });
    }

    handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
        if (event.target != null)
            this.setState({ password: event.target.value });
    }

    handleRememberMeClick(event: ChangeEvent<HTMLInputElement>) {
        this.setState({ rememberme: !this.state.rememberme });
    }

    state: State = {
        username: "",
        password: "",
        rememberme:false
    }

    handleSubmit = () => {
        if (this.state.rememberme) {
            localStorage.setItem("KSLPlusAdmin_UserName", this.state.username);
            localStorage.setItem("KSLPlusAdmin_RememberMe", this.state.rememberme?"true":"false");
        } else {
        
            localStorage.removeItem("KSLPlusAdmin_RememberMe");
            localStorage.removeItem("KSLPlusAdmin_UserName");
        }

    this.props.onSubmit(this.state.username, this.state.password);
    };

    render() {
        return (
                <form>
                <h3>KSLplus Login</h3>

                <div className="form-group">
                    <label>Nutzername</label>
                    <input type="string" className="form-control" placeholder="Nutzername" onChange={this.handleUsernameChange} value={this.state.username}/>
                </div>

                <div className="form-group">
                    <label>Passwort</label>
                    <input type="password" className="form-control" placeholder="Passwort" onChange={this.handlePasswordChange} />
                </div>

                <div className="form-group">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" onChange={this.handleRememberMeClick} checked={this.state.rememberme}/>
                        <label className="custom-control-label" htmlFor="customCheck1">Angemeldet bleiben</label>
                    </div>
                </div>

                <button id="ksl-button-login" type="button" className="btn btn-primary btn-block" onClick={() => this.handleSubmit()}>Anmelden</button>
                <Tooltip placement="right" target="ksl-button-login" isOpen={this.props.failed} >Falscher Nutzername oder Passwort</Tooltip>
                <p className="forgot-password text-right">
                    <div id="ksl-plus-forgotpw" onClick={this.props.onForgotPW}>Passwort vergessen?</div> 
                </p>
                    </form>
                
        );
    }
}
import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import Chart from "react-google-charts";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
// @ts-ignore
import  filterFactory, { selectFilter, multiSelectFilter, textFilter  } from 'react-bootstrap-table2-filter';


import { GRHaushalt } from './GRHaushaltComp';
import { GLHaushalt } from './GLHaushaltComp';

import { EbenenHaushalt } from './EbenenHaushaltComp';


import * as Utilities from "./Utilities"

import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";



type State = {
    ready: boolean;    
    activeTabs: string[],    
    activeMainTab: string,
    selectedGLEbene: string,
    isVisible: boolean
}

type Props = {    
    kunde: number,
    gemeinde: number,
    jahr: number,
    plantyp: number,
    planstufe: number,
    verwaltungsansicht: boolean,
    uhconfig: KSLPlusAPI.Uhconfig | undefined,
    settings: KSLPlusAPI.SettingsModel,
    selectedGL:string,
    onSelGLChange: (ebeneId: string) => void,
    massnahmen: KSLPlusAPI.Massnahman[],
    isVisible: boolean
}



export class VMHaushalt extends Component<Props, State> {
    constructor(props: any) {
        super(props);



    }

    node: any;
    hhGL: KSLPlusAPI.HaushaltsEbene | null = null;
    //hhGL_Ausgaben: KSLPlusAPI.HaushaltsEbene | null;
    hhGR: KSLPlusAPI.HaushaltsEbene | null = null;
    //hhGR_Ausgaben: KSLPlusAPI.HaushaltsEbene | null;
    //hhEbenen_Einnahmen: KSLPlusAPI.HaushaltsEbene[];
    hhEbenen: KSLPlusAPI.HaushaltsEbene[] = [];
    
    

    state: State = {
        ready: true,
        activeTabs: [],
        activeMainTab: "1",
        selectedGLEbene: "",
        isVisible: false
    }

    
    

    readHHGLData = () => {
        var request = new KSLPlusAPI.HaushaltRequest();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;   
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;
        
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
       
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        request.gruppen = [3,9];
        request.ignorierteGruppen = [0,1,2,4,5,6,7,8];
        //request.ignorierteGruppen = [800, 801, 802, 803, 804, 805, 806, 807, 808, 86, 201, 202, 203, 204, 205, 206, 207, 208, 28, 3, 9];
        client.getHaushaltByGliederung("", request).then((hh) => {            
            this.hhGL = hh;
            this.readHHGRData();            
            this.setState({ ready: true });
        }).catch(Reason => {
        });
        /*        
        request.gruppen = [0,1,2];
        client.getHaushaltByGliederung( "", request ).then((hh) => {
            this.hhGL_Einnahmen = hh;                          
            this.setState({ ready: true });
        }).catch(Reason => {
            alert("failed");
        });

        request.gruppen = [4, 5, 6, 7, 8];
        client.getHaushaltByGliederung("", request).then((hh) => {
            this.hhGL_Einnahmen = hh;
            this.readHHGRData();
            this.setState({ ready: true });
        }).catch(Reason => {
            alert("failed");
        });
        */

    }

    readHHGRData = () => {
        var request = new KSLPlusAPI.HaushaltRequest();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;   
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;
        
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

                
        request.gruppen = [3,9];
        //request.ignorierteGruppen = [3, 9];
        //request.ignorierteGruppen = [800, 801, 802, 803, 804, 805, 806, 807, 808, 86, 91, 201, 202, 203, 204, 205, 206, 207, 208, 28, 31, 3, 9];
        //request.ignorierteGruppen = [800, 801, 802, 803, 804, 805, 806, 807, 808, 86, 91, 201, 202, 203, 204, 205, 206, 207, 208, 28, 31];
        client.getHaushaltByGruppierung("", request).then((hh) => {
            this.hhGR = hh;
            this.readEbenen();
            this.setState({ ready: true });
        }).catch(Reason => {
        });
        /*
        request.gruppen = [0,1,2];
        //request.ignorierteGruppen = [800, 801, 802, 803, 804, 805, 806, 807, 808, 86, 91, 201, 202, 203, 204, 205, 206, 207, 208, 28, 31];
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        client.getHaushaltByGliederung("", request).then((hh) => {
            this.hhGR_Einnahmen = hh;            
            this.setState({ ready: true });            
        }).catch(Reason => {
        });

        request.gruppen = [4, 5, 6, 7, 8];
        client.getHaushaltByGliederung("", request).then((hh) => {
            this.hhGR_Ausgaben = hh;
            this.readEbenen();
            this.setState({ ready: true });
        }).catch(Reason => {
        });
        */
    }

    readEbenenData = (ebene: string) => {
        var request = new KSLPlusAPI.HaushaltRequest();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;   
        request.ebene = ebene;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        request.gruppen = [0, 1, 2];
        
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);
        
        request.gruppen = [3,9];
        request.ignorierteGruppen = [0,1,2,4,5,6,7,8];
        //request.ignorierteGruppen = [800, 801, 802, 803, 804, 805, 806, 807, 808, 86, 91, 201, 202, 203, 204, 205, 206, 207, 208, 28, 31];
        client.getHaushaltByEbene("", request).then((hh) => {
            this.hhEbenen.push(hh);            
            this.setState({ ready: true });
        }).catch(Reason => {           
        });
    }

    readEbenen = () => {
        this.hhEbenen = [];
        var request = new KSLPlusAPI.JahrModel();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;
        switch (this.props.plantyp) {
            case 3:
                request.modul = 1;
                break;

            case 4:
                request.modul = 2;
                break;

            default:
                request.modul = 0;
                break;
        }
        

        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        client.toplevelebenen("", request).then((ebenen) => {
            for (var i = 0; i < ebenen.length; i++) {
                var ebene = ebenen[i];
                if ( ebene.ebenenId )
                    this.readEbenenData(ebene.ebenenId);
            }
            this.setState({ ready: true });
        }).catch(Reason => {
        });
    }
    
    
    readData = () => {
        this.readHHGLData();        
    }


    componentDidUpdate(prevProps: Props) {
        if (prevProps.isVisible !== this.props.isVisible && this.props.isVisible !== this.state.isVisible)        
            this.setState({ activeMainTab: "1" });     

        if (prevProps.uhconfig !== this.props.uhconfig && this.props.plantyp === 4)
            this.readData();

    }    

    componentDidMount() {
        this.readData();
        
    }

    isEbeneVisible = (ebene: string) => {
        return this.props.isVisible && this.state.activeMainTab === ebene;
    }


    createEbeneItems = (ebenen: KSLPlusAPI.HaushaltsEbene[]) => {
        var navItems: JSX.Element[] = [];
        var contentItems: JSX.Element[] = [];


        if ( !ebenen.length )
            return {navItems: navItems, contentItems: contentItems};

        var tabIdx = 0;
        for (var i = 0; i < ebenen.length; i++) {
            const tabId = (i+3).toString();
            var bezeichnung = "Betrachtung nach " + ebenen[i].bezeichnung;
            navItems.push(                
                <NavItem id={"kslplus-tab-" + bezeichnung.toLowerCase().replace(/ /gi, "-") + "-vermögenshaushalt"} key={"mnav" + tabId+toString()}>
                    <NavLink active={this.state.activeMainTab === tabId}   onClick={() => this.setState({activeMainTab: tabId})} >
                        {bezeichnung}
                    </NavLink>
                </NavItem>                      
            );
            
            var tt = Utilities.createTTItem(this.props.settings, bezeichnung.toLowerCase().replace(/ /gi, "-") + "-vermögenshaushalt");            
            if (tt) {
                navItems.push(
                    tt
                );
            }
            contentItems.push(
                <TabPane tabId={tabId} key={"mtab" + tabId+toString()}>                    
                    <div className="kslplus-haushaltstab-wrapper" >
                        <EbenenHaushalt hh={ebenen[i]} kunde={this.props.kunde} gemeinde={this.props.gemeinde} planstufe={this.props.planstufe} plantyp={this.props.plantyp} uhconfig={this.props.uhconfig} jahr={this.props.jahr} settings={this.props.settings} isvmhh={true} massnahmen={this.props.massnahmen} verwaltungsansicht={this.props.verwaltungsansicht} isVisible={this.props.isVisible && this.state.activeMainTab === tabId} />
                    </div>
                    
                </TabPane> 
            );  
            
        }
        

        return {navItems: navItems, contentItems: contentItems};
    }
    
    
    handleSelectHHEbene = (ebenenNum: string) => {        
        this.state.selectedGLEbene = ebenenNum;
        this.props.onSelGLChange(ebenenNum);
    }

    render() {
        if (!this.state.ready )
            return null;
        
        var ebeneItems = this.createEbeneItems(this.hhEbenen);
        

        return (
            <div className="kslplus-haushaltstab-wrapper-bottom">   
                <Nav tabs>                                    
                    <NavItem id="kslplus-tab-betrachtung-nach-aufgaben-vermögenshaushalt">
                        <NavLink active={this.state.activeMainTab === "1"}   onClick={() => this.setState({activeMainTab: "1"})} >
                            Betrachtung nach Aufgaben
                        </NavLink>
                    </NavItem>
                    {Utilities.createTTItem(this.props.settings, "betrachtung-nach-aufgaben-vermögenshaushalt")}
                
                    <NavItem id="kslplus-tab-betrachtung-nach-einnahme-ausgabearten-vermögenshaushalt">
                        <NavLink active={this.state.activeMainTab === "2"}   onClick={() => this.setState({activeMainTab: "2"})} >
                            Betrachtung nach Einnahme-/Ausgabearten 
                        </NavLink>
                    </NavItem>    
                    {Utilities.createTTItem(this.props.settings, "betrachtung-nach-einnahme-ausgabearten-vermögenshaushalt")}
                    {ebeneItems.navItems}                    
                </Nav>     
                <TabContent activeTab={this.state.activeMainTab}>    
                    <TabPane tabId="1">   
                        {this.hhGL ? 
                            <div className="kslplus-haushaltstab-wrapper" >
                                <GLHaushalt hh={this.hhGL} jahr={this.props.jahr} kunde={this.props.kunde} gemeinde={this.props.gemeinde} planstufe={this.props.planstufe} plantyp={this.props.plantyp} uhconfig={this.props.uhconfig} settings={this.props.settings} isvmhh={true} selected={this.props.selectedGL} onSelChange={this.handleSelectHHEbene} massnahmen={this.props.massnahmen} verwaltungsansicht={this.props.verwaltungsansicht} isVisible={this.props.isVisible && this.state.activeMainTab == "1"} />
                                </div>
                                : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>}
                    </TabPane>
                    <TabPane tabId="2">   
                        {this.hhGR ?
                                <div className="kslplus-haushaltstab-wrapper">
                                <GRHaushalt hh={this.hhGR} jahr={this.props.jahr} kunde={this.props.kunde} gemeinde={this.props.gemeinde} planstufe={this.props.planstufe} plantyp={this.props.plantyp} uhconfig={this.props.uhconfig} settings={this.props.settings} isvmhh={true} massnahmen={this.props.massnahmen} verwaltungsansicht={this.props.verwaltungsansicht} isVisible={this.props.isVisible && this.state.activeMainTab == "2"} />
                                </div>
                                : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>}
                    </TabPane>
                    {ebeneItems.contentItems}                                      
                </TabContent>                                
            </div>             
                           
            
            
        );
    }
}
import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, UncontrolledTooltip, UncontrolledPopover, PopoverBody, Badge, Container
} from "reactstrap";

import Collapse from "@kunukn/react-collapse";


import * as KSLPlusAPI from "../services/KSLPlusAPI"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import Chart from "react-google-charts";

import TrackVisibility from 'react-on-screen';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import Drawer from '@material-ui/core/Drawer';

//import { HaushaltsNav, EbenenTyp } from './HaushaltsNavComp';
import { KontenNavTree, EbenenTyp } from './KontenNavTreeComp';
//import { HHSummiertDoppisch } from './HHSummiertDoppischComp';

import * as Utilities from "./Utilities"

import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

const paginationoptions = {
    sizePerPageList: [{
        text: '10', value: 10
    },{
        text: '15', value: 15
    }, {
        text: '20', value: 20
    }] // A numeric array is also available. the purpose of above example is custom the text
};

type State = {
    ready: boolean,
    ebene: KSLPlusAPI.HaushaltsHiearchieDoppik | undefined,    
    expandedItem: string,
    openBeschreibungen: boolean[],
    alleJahre: boolean;
}

type Props = {    
    hh: KSLPlusAPI.HaushaltsHiearchieDoppik,
    jahr: number,
    kunde: number,
    gemeinde: number,    
    plantyp: number,
    planstufe: number,
    verwaltungsansicht: boolean,
    uhconfig: KSLPlusAPI.UhConfigModel | undefined,
    settings: KSLPlusAPI.SettingsModel,        
    onSelChange: (ebeneId: string) => void,
    selected: string,
    summenTyp: number,
    isVisible: boolean
}


export class KontenHaushalt extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
        
    }

    static defaultProps = {
        summenTyp: 1        
    }

    node: any;    
    container: any = null;

    state: State = {
        ready: false,
        expandedItem: "",
        ebene: undefined,
        openBeschreibungen: [],
        alleJahre: false
    }

    redrawCharts = () => {        
        if (this.state.ebene )
            this.handleSelectHHEbene(this.state.ebene);
    }
  
    componentDidMount() {
        window.addEventListener('resize', this.redrawCharts, false);
        if ( this.state.ebene )
            this.readFilteredHH(this.state.ebene);
        
    }

    componentDidUpdate(prevProps: Props) {        
        if (this.state.ebene  && prevProps.uhconfig !== this.props.uhconfig)
            this.readFilteredHH(this.state.ebene);

    }


    readFilteredHH = (ebene: KSLPlusAPI.HaushaltsHiearchieDoppik, tries: number = 0) => {
                
        ebene.filter = undefined;                        
        this.setState({ ready: true });

        var request = new KSLPlusAPI.HaushaltFilterRequestDoppik();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        
        request.inkludierteKonten = ebene.inkludierteKonten;
        request.exkludierteKonten = ebene.exkludierteKonten;
        request.subtraktionsKonten = ebene.subtraktionsKonten;
        request.ausgaben = ebene.ausgaben;
        request.ergebnis = ebene.ergebnis;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;
      
        
        
        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHousholdDoppik_Client(api);
                        
        client.getFilteredHaushaltByProdukt("", request).then((hh) => {            
            ebene.filter = hh;                        
            this.setState({ ebene: ebene });
            //this.setState({ ready: true });
        }).catch(Reason => {
            if (tries < 3)
                setTimeout(() => { this.readFilteredHH(ebene, tries++) }, 2000);
            else
                alert("Fehler beim Lesen des Haushalts für Betrachtung nach Konten!");
        });
        
    }

    

    handleSelectHHEbene = (ebene: KSLPlusAPI.HaushaltsHiearchieDoppik) => {                
        this.setState({ ebene: undefined });
        if (!ebene.filter && ebene.unterEbenen )
            this.readFilteredHH(ebene);
        else
            setTimeout(() => {this.setState({ ebene: ebene })}, 10);

        
        if ( ebene.id === this.props.hh.id || (this.props.hh.filter && ebene.id === this.props.hh.filter.id))
        {
            this.props.onSelChange("");            
        }
        else 
        {            
            this.props.onSelChange(ebene.id ? ebene.id : "");
            
        }
        
    }

    getInitialSelected = (ebenen: KSLPlusAPI.HaushaltsHiearchieDoppik[]): KSLPlusAPI.HaushaltsHiearchieDoppik | undefined => {        
        for (var i = 0; i < ebenen.length; i++) {
            var ebene = ebenen[i];
            if (!ebene || !ebene.unterEbenen)
                continue;
            //if (ebene.unterEbenen.length > 0 && !ebene.unterEbenen[0].unterEbenen) {
            if (!ebene.verbergen) {
                return ebene;
            }
            else
                return this.getInitialSelected(ebene.unterEbenen);
        }
    }
    createHighDifItems = (rows: KSLPlusAPI.HaushaltsSummeDoppik[], field1: string, field2: string) => {
        var items = [];

        for (let i = 0; i < Math.min(3, rows.length); i++) {
            const rowAsArray = rows[i] as unknown as { [key: string]: number };
            if (rowAsArray[field2] - rowAsArray[field1] != 0) {
                {
                    if (rows[i].kontoStellen)
                        items.push(<div key={i.toString()}><Row><Col lg="8"><div className="kslplus-change-popover-bezeichnung">{Utilities.Num2Str(rows[i].produkt, rows[i].produktStellen) + "." + Utilities.Num2Str(rows[i].konto, rows[i].kontoStellen) + " " + rows[i].bezeichnung}</div></Col><Col><div className="kslplus-change-popover-wert">{Utilities.FormatCurrency(rowAsArray[field2] - rowAsArray[field1], 2, 0, '.', ',') + " EUR"}</div></Col></Row></div>);
                    else
                        items.push(<div key={i.toString()}><Row><Col lg="8"><div className="kslplus-change-popover-bezeichnung">{Utilities.Num2Str(rows[i].produkt, rows[i].produktStellen) + " " + rows[i].bezeichnung}</div></Col><Col><div className="kslplus-change-popover-wert">{Utilities.FormatCurrency(rowAsArray[field2] - rowAsArray[field1], 2, 0, '.', ',') + " EUR"}</div></Col></Row></div>);
                }

            }
        }
        return items;
    }

    createExpandRow = (rows: KSLPlusAPI.HaushaltsSummeDoppik[]) => {
        return {
            renderer: (row: KSLPlusAPI.HaushaltsSummeDoppik) => (
                row.unterEbenen ?
                    <BootstrapTable hover={true} bordered={false} headerClasses="kslplus-hidden" keyField='id' data={row.unterEbenen} columns={this.createColumns(row, false)} hiddenRows={this.createHiddenRowKeys(row.unterEbenen)}  expandRow={row.unterEbenen && row.unterEbenen.find(e => e.unterEbenen && e.unterEbenen.length) ? this.createExpandRow(row.unterEbenen) : undefined} rowClasses="kslplus-row-sum-0" />
                    :
                    null

            ),
            className: 'kslplus-tabellen-expanded',
            showExpandColumn: true,
            expandColumnPosition: 'left',

            expandHeaderColumnRenderer: ({ isAnyExpands }: { isAnyExpands: boolean }) => {
                return null;
            },
            nonExpandable: rows.filter(r => !r.unterEbenen || !r.unterEbenen.length).map(d => d.id)

        }
    }

    expandRow = {
      renderer: (row: KSLPlusAPI.HaushaltsSummeDoppik) => (
            row.unterEbenen ?                                  
                <BootstrapTable hover={true} bordered={false} headerClasses="kslplus-hidden" keyField='id' data={row.unterEbenen} columns={this.createColumns(row, false)} hiddenRows={this.createHiddenRowKeys(row.unterEbenen)}  expandRow={row.unterEbenen && row.unterEbenen.find(e => e.unterEbenen && e.unterEbenen.length) ? this.createExpandRow(row.unterEbenen) : undefined} rowClasses="kslplus-row-sum-0" />
            :
                null
        
      ),
      className: 'kslplus-tabellen-expanded',
      showExpandColumn: true,
      expandColumnPosition: 'left',
      
      expandHeaderColumnRenderer: ({ isAnyExpands } : { isAnyExpands: boolean}) => {
        return null;        
      },
      expandColumnRenderer: ({ expanded } : { expanded: boolean}) => {
        if (expanded) {
          return (
            <div className="kslplus-expand-column">(-)</div>
          );
        }
        return (
          <div className="kslplus-expand-column">(+)</div>
        );
      }      
    };

    createChangeColumn = (field1: string, field2: string, id: number, text: string = "Vorjahr", ausgaben: boolean = false, showFooter: boolean = true, pjFarben: boolean = false) => {
        return {
            dataField: "dummy" + id.toString(),
            text: "+/-",
            headerAlign: 'right',
            align: 'right',
            formatter: (cellContent: string, row: { [key: string]: number }) => {
                var items: JSX.Element[] = [];
                {

                    let sorted: KSLPlusAPI.HaushaltsSummeDoppik[] = [];
                    var unterEbenen: KSLPlusAPI.HaushaltsSummeDoppik[] = [];


                    if (row['unterEbenen'] !== undefined)
                        unterEbenen = row['unterEbenen'] as unknown as KSLPlusAPI.HaushaltsSummeDoppik[];
                    else if (row['details'] !== undefined) {
                        const details = row['details'] as unknown as KSLPlusAPI.HaushaltsSummeDoppik;
                        if (details.unterEbenen !== undefined)
                            unterEbenen = details.unterEbenen
                    }


                    //if (details.unterEbenen && details.kontoStellen && details.kontoStellen < 8) {
                    sorted = unterEbenen.slice();
                    //}
                    sorted = sorted.sort((r1, r2) => {
                        const row1 = r1 as unknown as { [key: string]: number };
                        const row2 = r2 as unknown as { [key: string]: number };

                        return (Math.abs(row2[field1] - row2[field2]) - Math.abs(row1[field1] - row1[field2]))

                    });

                    items = this.createHighDifItems(sorted, field1, field2);
                }

                var res = Utilities.calcArrowRotAndColor(row[field1], row[field2], row["ausgaben"] > 0, this.props.settings);


                var ttId = row.id ? "kslplus-label-" + id + "_" + row.id : "";
                ttId = ttId + ((row["ausgaben"] > 0) ? "-a-" : "-e-" + this.props.summenTyp.toString());

                return (
                    <div>
                        <div id={ttId}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 156" width="100%">
                                <g transform={"rotate(" + res.rotation + ", 82, 78)"}>
                                    <path d="m0,86h36v70h92V86h36L82,0" fill={res.color} />
                                </g>
                            </svg>
                        </div>
                        <UncontrolledPopover
                            size="lg"
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-change-popover">
                            <PopoverBody className={items.length > 0 ? "kslplus-change-popover-doppisch-detailiert-body" : "kslplus-change-popover-body"}>
                                <div className="kslplus-change-popover-headline">{"Gegenüber " + text + ": " + Utilities.FormatCurrency(res.changeA, 2, 3, '.', ',') + " EUR (" + Utilities.FormatCurrency((res.changeP - 1) * 100, 2, 0, '.', ',') + "  %)"}</div>
                                {items.length > 0 && <br />}
                                {items.length > 0 && <div className="kslplus-change-popover-headline">Davon wesentlich:</div>}
                                {items.length > 0 &&
                                    <div className="kslplus-change-popover-container-werte">
                                        {items}
                                    </div>
                                }
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>

                );

            },
            footerStyle: (colum: any, colIndex: any) => {
                if (pjFarben)
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };

                return { };
            },
            headerStyle: (colum: any, colIndex: any) => {
                if (pjFarben)
                    return { width: "40px", backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };

                return { width: "40px" };
            },
            style: (colum: any, colIndex: any) => {
                if (pjFarben)
                    return { width: "40px", backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };

                return { width: "40px" };
            },
            footer: showFooter ? id : undefined,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: number) => {

                if ( !this.state.ebene || !this.state.ebene.filter )
                    return null;

                var main:{[key: string]: number} =  this.state.ebene.filter as unknown as {[key: string]: number};

                var res = Utilities.calcArrowRotAndColor(main[field1], main[field2], main["ausgaben"] as unknown as boolean, this.props.settings);
                
                var ttId = "kslplus-label-change-k-" +  "-" + (ausgaben ? "a-" : "e-") + columnIndex + this.props.summenTyp.toString();
                
                return (
                    <div>
                        <div id={ttId}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 156" width="100%">
                                <g transform={"rotate(" + res.rotation + ", 82, 78)"}>
                                    <path d="m0,86h36v70h92V86h36L82,0" fill={res.color} />
                                </g>
                            </svg>
                        </div>
                        <UncontrolledPopover
                            size="lg"
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-change-popover">
                            <PopoverBody className="kslplus-change-popover-body">
                                    <div className="kslplus-change-popover-headline">{"Gegenüber " + text + ": " + Utilities.FormatCurrency(res.changeA, 2, 3, '.', ',') + " EUR (" + Utilities.FormatCurrency((res.changeP-1)*100, 2, 0, '.', ',') + "  %)"}</div>                                                                                                                                                                                                            
                            </PopoverBody>
                        </UncontrolledPopover>     
                        
                    </div>
                    
                );
            }
        };
    }

    createDataColumn = (field: string, jahr: number, id: number, showFooter: boolean = true, text: string = "", ttE: boolean = false, ttF: boolean = false, hidden: boolean = false, testfield: string = "", komfield: string = "", pkTip: boolean = false, gsTip: boolean = false, ausgefuehrtTip: boolean = false) => {
        if ( text == "" )
        {
            if (jahr - this.props.jahr == -2 && this.props.plantyp < 3 && this.props.settings.vvjVorl)
                text = "vorl. Ergebnis ";
            else if (  jahr - this.props.jahr < -1 )
                text = "Ergebnis";
            else if ( jahr - this.props.jahr > (this.props.settings.doppelhaushalt ? 1 : 0) )
                text = "Finanzplan";
            else
                text = "Ansatz"; 
        }

        return {
            dataField: field,
            text: text + " " + jahr + " (EUR)",
            headerAlign: 'right',
            align: 'right',
            hidden: hidden,
            /*
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '15%' };
            },*/
            footerStyle: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr === 0 || this.props.settings.doppelhaushalt && jahr - this.props.jahr === 1)) {
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };
                }
                return {};
                
            },
            style: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if (testfield != "" && row[testfield])
                    return { backgroundColor: this.props.uhconfig && this.props.uhconfig.hfarbeProgMan ? this.props.uhconfig.hfarbeProgMan : 'lightcoral', color: this.props.uhconfig && this.props.uhconfig.vfarbeProgMan ? this.props.uhconfig.vfarbeProgMan : 'black' };
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr === 0 || this.props.settings.doppelhaushalt && jahr - this.props.jahr === 1)) {
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };
                }
                
                //return { width: '15%' };
            },
            headerStyle: (colum: any, colIndex: any) => {
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr === 0 || this.props.settings.doppelhaushalt && jahr - this.props.jahr === 1)) {
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };
                }
                else
                    return {};
            },
            headerFormatter: (column: any, columnIndex: any) => {
                if (ausgefuehrtTip) {

                    var ttId = "header-tt-gl-erg-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>

                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Ausgeführt errechnet sich wie folgt:</div></div>
                                        <br />

                                        {this.props.uhconfig && this.props.uhconfig.verfuegtSpaltenDic &&
                                            Utilities.createAusgefuehrtTooltipItems(this.props.uhconfig.verfuegtSpaltenDic, this.props.settings.importFormat)
                                        }


                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else if (gsTip) {

                    var ttId = "header-tt-gl-erg-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>

                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Das Gesamtsoll errechnet sich wie folgt:</div></div>
                                        <br />

                                        {this.props.uhconfig && this.props.uhconfig.fortAnsatzSpaltenDic &&
                                            Utilities.createGSTooltipItems(this.props.uhconfig.fortAnsatzSpaltenDic, this.props.settings.importFormat)
                                        }


                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else if ( ttE )    
                {
                    
                    var ttId = "header-tt-gl-erg-" + field;
                
                    return (  
                        <div>                                          
                            <div id={ttId} className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>                                                         
                    
                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>                                          
                                            <div><div className="kslplus-header-tt-block1">Das Ergebnis (Rechnungsergebnis) errechnet sich wie folgt:</div></div>
                                            <br/>                                        
                                        <div className="kslplus-header-tt-block"><div className="kslplus-label">Ausgeführtes Soll auf lfd. Ansatz</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang auf Kassenreste aus Vj.</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Neue Haushaltsreste</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang Haushaltsreste aus Vj.</div></div>
                                        

                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>                    
                        </div>
                        ); 
                }
                else if ( ttF )    
                {
                    
                    var ttId = "header-tt-gl-fort-" + field;
                
                    return (             
                            <div>      
                                <div id={ttId} className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>                                                         
                                <UncontrolledPopover
                                    size="xl"
                                    placement="right"
                                    target={ttId}
                                    trigger="hover"
                                    className="kslplus-header-popover">
                                    <PopoverBody className="kslplus-header-popover-body">
                                        <Container>                                          
                                             <div><div className="kslplus-header-tt-block1">Der fortgeschrieben Ansatz errechnet sich wie folgt:</div></div>
                                             <br/>                                        
                                            <div className="kslplus-header-tt-block"><div className="kslplus-label">Ansatz</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Nachtrag</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Sollveränderungen</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Übertragungen aus Vj.</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">über-/außerplanmäßige Bewilligungen</div></div>
                                        </Container>
                                    </PopoverBody>
                                </UncontrolledPopover>                    
                            </div>
                        ); 
                }
                else
                {
                    return (                                                                    
                        <div className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>
                    );
                }
            },            
            formatter: (cellContent: string, row: { [key: string]: any }) => {
                var nk = 2;
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr > -2) && this.props.settings.ansatzOhneDS) {
                    nk = 0;
                }

                if (pkTip && row["pkTip"]) {
                    return (<div className="kslplus-tabellen-data" >{row["pkTip"] ? <div className="kslplus-info-progkom-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-progtip-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="left" target={row.id ? "kslplus-info-progtip-" + row.id : ""}>{row["pkTip"]}</UncontrolledTooltip></div> : null}{Utilities.FormatCurrency(row[field] as number, nk, 3, '.', ',')}</div>);
                }
                if (komfield !== "") {
                    return (<div className="kslplus-tabellen-data" >{row[komfield] ? <div className="kslplus-info-progkom-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-progkomm-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="left" target={row.id ? "kslplus-info-progkomm-" + row.id : ""}>{row[komfield]}</UncontrolledTooltip></div> : null}{Utilities.FormatCurrency(row[field] as number, nk, 3, '.', ',')}</div>);
                }
                return (
                    <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field] as number, nk, 3, '.', ',')} </div>
                );

            },                       
            footer: showFooter ? id : undefined,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                if ( !this.state.ebene || !this.state.ebene.filter )
                    return null;

                var main: { [key: string]: number } = this.state.ebene.filter as unknown as { [key: string]: number };

                var nk = 2;
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr > -2) && this.props.settings.ansatzOhneDS) {
                    nk = 0;
                }
                
                return (
                    <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(main[field], nk, 3, '.', ',')} </div>
                );
            }
        };
    }


    createDataPercentColumn = (field: string, field1: string, jahr: number, id: number, text: string = "", hidden: boolean = false, ttE: boolean = false, ttF: boolean = false, showFooter = true) => {
        if (text == "") {
            if (jahr - this.props.jahr < -1)
                text = "Ergebnis";
            else if (jahr - this.props.jahr > (this.props.settings.doppelhaushalt ? 1 : 0))
                text = "Finanzplan";
            else
                text = "Ansatz";
        }
        return {
            dataField: field + "_per",
            text: text + " " + jahr + " (%)",
            headerAlign: 'right',
            align: 'right',
            hidden: hidden,
            headerFormatter: (column: any, columnIndex: any) => {
                return (
                    <div className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(%)</div></div>
                );
            },
            formatter: (cellContent: string, row: { [key: string]: number }) => {
                if (Math.round(row[field] * 100) / 100 === 0)
                    return (
                        <div className="kslplus-tabellen-data">0,00</div>
                    );
                else if (row[field1] === 0 && row[field] < 0) {
                    return (<div className="kslplus-tabellen-data">-Ꝏ</div>);
                }
                else if (row[field1] === 0) {

                    return (<div className="kslplus-tabellen-data">Ꝏ</div>);
                }
                else
                    return (
                        <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field] / row[field1] * 100.0, 2, 3, '.', ',')}</div>
                    );

            },
            footer: showFooter ? id : undefined,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                if (!this.state.ebene || !this.state.ebene.filter)
                    return null;

                var main: { [key: string]: number } = this.state.ebene.filter as unknown as { [key: string]: number };

                if (Math.round(main[field] * 100) / 100 === 0)
                    return (
                        <div className="kslplus-tabellen-data">0,00</div>
                    );
                else if (main[field1] === 0 && main[field] < 0) {
                    return (<div className="kslplus-tabellen-data">-Ꝏ</div>);
                }
                else if (main[field1] === 0) {

                    return (<div className="kslplus-tabellen-data">Ꝏ</div>);
                }
                else
                    return (
                        <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(main[field] / main[field1] * 100.0, 2, 3, '.', ',')}</div>
                    );
                                
            }
        };
    }

    createColumns = (data: KSLPlusAPI.HaushaltsSummeDoppik, showFooter: boolean) => {
        var columns = [];
        
         
        if ( data && data.unterEbenen && !data.unterEbenen.find(e => e.unterEbenen && e.unterEbenen.length) ) {        
            columns.push({            
                dataField: 'df1',
                text: "",
                isDummyField: true,
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '35px', textAlign: 'left' };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: '35px', textAlign: 'left' };
                },
                footer: showFooter ? "1" : undefined,
                
                
            });
        }
        columns.push({
            dataField: 'bezeichnung',
            text: "Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '400px', textAlign: 'left' };
            },

            style: (colum: any, colIndex: any) => {
                return { width: '400px' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsSummeDoppik) => {
                var label: JSX.Element[] = [];
                var toolTips: JSX.Element[] = [];
      
                if ( row.produktStellen )
                {
                    if (row.kontoStellen)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-op" + row.id}>{row.ausgaben?"- " : "+ " }</span>);

                    label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-prod" + row.id} key={"kslplus-label-prod" + row.id}>{(row.produktStellen ? (Utilities.Num2Str(row.produkt, row.produktStellen)) : "")}</span>);

                    if (row.kontoStellen) {
                        if (row.investNr)
                            label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-konto" + row.id} key={"kslplus-label-konto" + row.id}>{"." + Utilities.Num2Str(row.konto, row.kontoStellen) + "-" + row.investNr.toString()}</span>);
                        else
                            label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-konto" + row.id} key={"kslplus-label-konto" + row.id}>{"." + Utilities.Num2Str(row.konto, row.kontoStellen)}</span>);
                    }
                   
                    label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id}>{" - " + row.bezeichnung}</span>);

                    if ( row.kontoText )
                        toolTips.push(<UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-konto" + row.id : ""} key={row.id ? "kslplus-label-prod" + row.id : ""}>{row.kontoText}</UncontrolledTooltip>);
                }
                
                //<div className="kslplus-tabellen-bezeichnung" >{label} {row.beschreibung != null && row.beschreibung.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-text-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-info-text-" + row.id : ""}>{row.beschreibung}</UncontrolledTooltip></div> : null}</div>{tooltip.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id : ""}>{tooltip}</UncontrolledTooltip> : null}
                //    <div className="kslplus-tabellen-data">{(row.produktStellen ? (Utilities.Num2Str(row.produkt, row.produktStellen) + ".") : "") + Utilities.Num2Str(row.konto, row.kontoStellen) + " - " + row.bezeichnung}</div>

                
                return (
                    
                    <div className="kslplus-tabellen-bezeichnung" >{label}{row.beschreibung != null && row.beschreibung.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-text-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-info-text-" + row.id : ""}>{row.beschreibung}</UncontrolledTooltip></div> : null}{toolTips}</div>
                    
                );                

                //return (
                  //  <div className="kslplus-tabellen-data">{row.bezeichnung}</div>
                //  );

            },
            footer: showFooter ? "2" : undefined,
            footerFormatter: (column: any, columnIndex: any) => {
                return <div>Gesamt</div>;
            }
        });

        

        if (data == null || data.unterEbenen == null || !this.props.hh.jahre)
            return columns;

        var id = 2;

        if (this.props.plantyp == 4 && (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) != -1 || this.props.hh.jahre.findIndex(d => d == this.props.jahr - 4) != -1)) {
            columns.push({
                dataField: '',
                isDummyField: true,
                headerAlign: 'middle',
                headerFormatter: (column: any, columnIndex: any) => {
                    var ttId = "kslplus-tt-pjcol-kh";
                    return (<div>
                        <div id={ttId}>{this.state.alleJahre ? "-" : "+"}</div >
                        <UncontrolledPopover
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-header-popover">
                            <PopoverBody className="kslplus-header-popover-body">
                                <Container>
                                    <div>
                                        {"Zusätzliche Jahre " + (this.state.alleJahre ? "verbergen" : "anzeigen")}
                                    </div>
                                </Container>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div >);

                },
                footer: showFooter ? -1 : undefined,
                text: this.state.alleJahre ? "-" : "+",
                headerStyle: (colum: any, colIndex: any) => {
                    return { fontWeight: 900, width: '30px', textAlign: 'left' };
                },

                style: (colum: any, colIndex: any) => {
                    return { width: '30px' };
                },
                headerEvents: {
                    onClick: (e: any, column: any, columnIndex: any) => { this.setState({ alleJahre: !this.state.alleJahre }) }
                }
            });
        }





        var fieldPrev: string | undefined;

        
        if ( this.props.plantyp === 3 )
        {            
            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 4) >= 0)
            {                
                columns.push(this.createDataColumn("ergebnisPJM4", this.props.jahr - 4, id, true, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4", id));
                    id++;
                }
                fieldPrev = "ergebnisPJM4";     

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) >= 0)
            {                
                columns.push(this.createDataColumn("ergebnisPJM3", this.props.jahr - 3, id, true, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3", id));
                    id++;
                }
                fieldPrev = "ergebnisPJM3";     

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 2) >= 0)
            {                
                columns.push(this.createDataColumn("ergebnisPJM2", this.props.jahr - 2, id, true, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2", id));
                    id++;
                }
                fieldPrev = "ergebnisPJM2";     

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 1) >= 0)
            {                
                columns.push(this.createDataColumn("ergebnisPJM1", this.props.jahr - 1, id, true, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1", id));
                    id++;
                }
                fieldPrev = "ergebnisPJM1";     

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0)
            {                
                columns.push(this.createDataColumn("ansatzPJfort", this.props.jahr, id, true, "Fortgeschriebener Ansatz", false, true));
                id++;                
                fieldPrev = "ansatzPJfort";     

            }
            else {
                fieldPrev = undefined;
            }
            
            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0)
            {                
                columns.push(this.createDataColumn("ergebnisPJ", this.props.jahr, id, true, "Ergebnis", true));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJ", id, "fortgeschriebenen Ansatz"));
                    id++;
                }
                fieldPrev = "ergebnisPJ";     

            }
            else {
                fieldPrev = undefined;
            }
             
        }
        else if (this.props.plantyp === 4) {            
            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 4) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM4", this.props.jahr - 4, id, true, "Ergebnis", false, false, !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4", id));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM4";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM3", this.props.jahr - 3, id, true, "Ergebnis", false, false, !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3", id));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM3";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM2", this.props.jahr - 2, id, true, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2", id));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM2";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) {
                columns.push(this.createDataColumn("ergebnisPJM1", this.props.jahr - 1, id, true, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1", id));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM1";

            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                columns.push(this.createDataColumn("ansatzPJfort", this.props.jahr, id, true, "Gesamtsoll", false, false, false, "", "", false, true));
                id++;
                fieldPrev = "ansatzPJfort";

            }
            else {
                fieldPrev = undefined;
            }

            
            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("verfuegt", this.props.jahr, id, true, "Ausgeführt", false, false, false, "", "", false, false, true));
                    id++;
                    fieldPrev = "verfuegt";

                }
                else {
                    fieldPrev = undefined;
                }
            }

            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataPercentColumn("verfuegt", "ansatzPJfort", this.props.jahr, id, "Ausgeführt", false, false, this.state.alleJahre));
                    id++;
                    fieldPrev = undefined;

                }
                else {
                    fieldPrev = undefined;
                }
            }


            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("verfuegbar", this.props.jahr, id, true, "Einzunehmen / Verfügbar", false, false));
                    id++;
                    fieldPrev = "verfuegbar";

                }
                else {
                    fieldPrev = undefined;
                }
            }


            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataPercentColumn("verfuegbar", "ansatzPJfort", this.props.jahr, id, "Einzunehmen / Verfügbar", false, false, this.state.alleJahre));
                    id++;
                    fieldPrev = undefined;

                }
                else {
                    fieldPrev = undefined;
                }
            }

            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("progAuto", this.props.jahr, id, true, "Prognose errechnet", false, false, false, "", "", true));
                    id++;
                    fieldPrev = "progAuto";

                    columns.push(this.createChangeColumn("ansatzPJfort", "progAuto", id, "Gesamtsoll"));
                    id++;

                }
                else {
                    fieldPrev = undefined;
                }
            }


            if (this.props.summenTyp < 11 || this.props.summenTyp > 16) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("progMan", this.props.jahr, id, true, "Prognose manuell", false, false, false, "hasProgMan", "progKomm"));
                    id++;
                    fieldPrev = "progMan";

                    columns.push(this.createChangeColumn("ansatzPJfort", "progMan", id, "Gesamtsoll"));
                    id++;

                }
                else {
                    fieldPrev = undefined;
                }
            }


            if (this.props.settings.importFormat !== 0 && this.props.settings.importFormat !== 1 && (this.props.summenTyp < 11 || this.props.summenTyp > 16)) {
                if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                    columns.push(this.createDataColumn("istAo", this.props.jahr, id, true, "Ist AO", false, false));
                    id++;
                    fieldPrev = "istAo";


                }
                else {
                    fieldPrev = undefined;
                }
            }

        }
        else
        {
            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 3) >= 0) {                        
                columns.push(this.createDataColumn("ergebnisVvvJ", this.props.jahr - 3, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvvJ", id));
                    id++;
                }
                fieldPrev = "ergebnisVvvJ";
            }
            else {
                fieldPrev = undefined;
            }

            //if (this.state.ebene.filter.unterEbenen.findIndex(d => d.ergebnisVvJ !== 0) >= 0) {
            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 2) >= 0) {
                columns.push(this.createDataColumn("ergebnisVvJ", this.props.jahr - 2, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvJ", id));
                    id++;
                }
                fieldPrev = "ergebnisVvJ";
            }
            else {
                fieldPrev = undefined;
            }


            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr - 1) >= 0) {
                columns.push(this.createDataColumn("ansatzVJ", this.props.jahr - 1, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzVJ", id));
                    id++;
                }
                fieldPrev = "ansatzVJ";
            }
            else {
                fieldPrev = undefined;
            }


            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr) >= 0) {
                columns.push(this.createDataColumn("ansatzPj", this.props.jahr, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzPj", id, "Vorjahr", false, true, true));
                    id++;
                }
                fieldPrev = "ansatzPj";
            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 1) >= 0) {
                columns.push(this.createDataColumn("fp1", this.props.jahr + 1, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp1", id, "Vorjahr", false, true, this.props.settings.doppelhaushalt ? true : false));
                    id++;
                }
                fieldPrev = "fp1";
            }
            else {
                fieldPrev = undefined;
            }


            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 2) >= 0) {
                columns.push(this.createDataColumn("fp2", this.props.jahr + 2, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp2", id));
                    id++;
                }
                fieldPrev = "fp2";
            }
            else {
                fieldPrev = undefined;
            }


            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 3) >= 0) {
                columns.push(this.createDataColumn("fp3", this.props.jahr + 3, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp3", id));
                    id++;
                }
                fieldPrev = "fp3";
            }
            else {
                fieldPrev = undefined;
            }

            if (this.props.hh.jahre.findIndex(d => d == this.props.jahr + 4) >= 0) {
                columns.push(this.createDataColumn("fp4", this.props.jahr + 4, id));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "fp4", id));
                    id++;
                }
                fieldPrev = "fp4";
            }
            else {
                fieldPrev = undefined;
            }
        }





        return columns;
    }


    createHiddenRowKeys = (ebenen: KSLPlusAPI.HaushaltsEbeneDoppik[]) => {
        var hidden: string[] = [];
        for (var i = 0; i < ebenen.length; i++) {
            const ebene = ebenen[i];
            if (ebene.konto === undefined)
                continue;


            if (!ebene.containsData) {
                hidden.push(ebene.id ? ebene.id : "");
            }
            
        }

        return hidden;
    }


    render() {        

        if (!this.state.ebene && this.props.hh.unterEbenen)
            this.state.ebene = this.getInitialSelected(this.props.hh.unterEbenen);

        var numSelected = "";
        if ( this.state.ebene == this.props.hh )
            numSelected = "";
        else if (this.state.ebene && this.state.ebene.id)
            numSelected = this.state.ebene.id;
        
        var num = this.props.selected;
        if ( numSelected !== num && this.props.hh.unterEbenen )
        {
            /*
            var ebene = this.findEbene( this.props.selected, this.props.hh.unterEbenen );
            if ( ebene )
            {
                this.readFiltered(ebene);                
                
            }
            */
        }
        /*
        const einnahmen = this.state.ebene !== undefined && Utilities.hatEinnahmen(this.state.ebene.filter);
        const ausgaben = this.state.ebene !== undefined && Utilities.hatAusgaben(this.state.ebene.filter);
        */

        
        if ( this.state.ebene == this.props.hh )
            num = "";
        else if (!num.length && this.state.ebene && this.state.ebene.id)
            num = this.state.ebene.id;

        var max: any[] = [-Number.MAX_VALUE, -Number.MAX_VALUE];
        var min: any[] = [Number.MAX_VALUE, Number.MAX_VALUE];
        var graphData: (string | number)[][] = [];  
        if (this.state.ebene && this.state.ebene.filter && (this.props.summenTyp == 3 || this.props.summenTyp == 9 || this.props.summenTyp == 17 || this.props.summenTyp == 19)) // Ergebnishaushalt
            graphData = Utilities.createGraphDataByHHSumme(this.state.ebene.filter, this.props.jahr, this.state.ebene.ergebnis ? "Ergebnis" : (this.state.ebene.ausgaben ? "Aufwendungen" : "Erträge"), this.props.plantyp, this.props.hh.jahre ? this.props.hh.jahre : [], min, max);
        if (this.state.ebene && this.state.ebene.filter && (this.props.summenTyp == 4 || this.props.summenTyp == 10 || this.props.summenTyp == 18 || this.props.summenTyp == 20)) // Finanzhaushalt
            graphData = Utilities.createGraphDataByHHSumme(this.state.ebene.filter, this.props.jahr, this.state.ebene.ergebnis ? "Saldo" : (this.state.ebene.ausgaben ? "Auszahlungen" : "Einzahlungen"), this.props.plantyp, this.props.hh.jahre ? this.props.hh.jahre : [], min, max);
        var colors = ['#264d99', '#ADD8E6'];
        var series1 = { 0: { targetAxisIndex: 0 }, 1: { type: 'line', targetAxisIndex: 1 } };   
        var barColors = ["blue", "pink", "magenta", "purple", "lightblue", "orange"];
        var bezWerte = this.state.ebene ? this.state.ebene.bezeichnung : "";
        /*
        if ( this.state.ebene && this.state.ebene.ergebnis )
            bezWerte = this.props.summenTyp == 3 ? "Ergebnisse" : "Salden";
        else if ( this.state.ebene && this.state.ebene.ausgaben )
            bezWerte = this.props.summenTyp == 3 ? "Auwendungen" : "Auszahlungen";
        else if ( this.state.ebene )
            bezWerte = this.props.summenTyp == 3 ? "Eräge" : "Einzahlungen";
        */

        
        var graphOpt = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);

        var options = {
            backgroundColor: 'none',
            is3D: this.props.settings.tD3d,
            pieSliceText: this.props.settings.tdText,            
            legend: { position: this.props.settings.tdLegend, labeledValueText: this.props.settings.tdText == "value" ? "percentage" : "value" },
            pieSliceTextStyle: { fontSize: this.props.settings.tdFontSize != -1 ? this.props.settings.tdFontSize : undefined },
            sliceVisibilityThreshold: 0.00001                
        };

        return (    
            

            <div>
                
                    
                <KontenNavTree hh={this.props.hh} onSelChange={this.handleSelectHHEbene} useUAText={true} showNumber={true} selected={num} typ={EbenenTyp.Produkt} auswahlText="Auswahl Kontenebene od. Suche per Eingabe"/>
            
                <div className="kslplus-content kslplus-hhcomp-content">  
                    {this.state.ebene && (!this.state.ebene.unterEbenen || (this.state.ebene.filter && this.state.ebene.filter.unterEbenen)) ?
                        <div>
                            {this.state.alleJahre ?
                                <Row>
                                    <div></div>
                                    <Col className={"ksl-plus-table" + (this.props.plantyp === 4 ? "-ubw" : "")}>
                                        {
                                            /*
                                            this.state.ebene && this.state.ebene.unterEbenen ?
                                            <BootstrapTable ref={(n: any) => this.node = n} keyField='unterasbchnitt' data={this.state.ebene.unterEbenen} columns={this.createColumns(this.state.ebene, this.state.ebene, null, null, null, false)}  pagination={paginationFactory(paginationoptions)} /> :
                                            null*/
                                            this.state.ebene.filter && this.state.ebene.filter.unterEbenen ?
                                                <BootstrapTable hover={true} bordered={true} keyField='id' data={this.state.ebene.filter.unterEbenen} columns={this.createColumns(this.state.ebene.filter, true)} hiddenRows={this.createHiddenRowKeys(this.state.ebene.filter.unterEbenen)}  expandRow={this.createExpandRow(this.state.ebene.filter.unterEbenen)} rowClasses="kslplus-row-sum-0" />
                                                : null

                                        }
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col className={"ksl-plus-table" + (this.props.plantyp === 4 ? "-ubw" : "")}>
                                        {
                                            /*
                                            this.state.ebene && this.state.ebene.unterEbenen ?
                                            <BootstrapTable ref={(n: any) => this.node = n} keyField='unterasbchnitt' data={this.state.ebene.unterEbenen} columns={this.createColumns(this.state.ebene, this.state.ebene, null, null, null, false)}  pagination={paginationFactory(paginationoptions)} /> :
                                            null*/
                                            this.state.ebene.filter && this.state.ebene.filter.unterEbenen ?
                                                <BootstrapTable hover={true} bordered={true} keyField='id' data={this.state.ebene.filter.unterEbenen} columns={this.createColumns(this.state.ebene.filter, true)} hiddenRows={this.createHiddenRowKeys(this.state.ebene.filter.unterEbenen)}  expandRow={this.createExpandRow(this.state.ebene.filter.unterEbenen)} rowClasses="kslplus-row-sum-0" />
                                                : null

                                        }
                                    </Col>
                                </Row>
                            }
                            {this.props.plantyp === 3 ?
                                <div>
                                    <Row>
                                        <Col>
                                                <div className="kslplus-headline-third">{bezWerte + " der " + ((this.props.summenTyp == 3 || this.props.summenTyp == 9) ? "Ergebnisrechnung" : "Finanzrechnung") +" - Abweichung zwischen Ansatz und Ergebnis "}</div>
                                        </Col>
                                    </Row>                                    
                                    <Row>
                                        <Col>
                                            <Chart
                                                width="100%"
                                                height={'400px'}
                                                chartType="BarChart"
                                                chartLanguage='de'
                                                loader={<div>Loading Chart</div>}
                                                data={Utilities.createBarDataAEAJRBySummenDoppisch(this.state.ebene.filter, bezWerte ? bezWerte : "")}                                                    
                                                options={{
                                                          
                                                        bar: { groupWidth: "50%" },
                                                        legend: { position: "none"},
                                                        chartArea: {width: '75%', left: '25%'},
                                                        hAxis: { gridlines: { count: 10 }}
                                                        
                                                }}
                                                    
                                                rootProps={{ 'data-testid': '2' }}
                                            />
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                        <Col>
                                                <div className="kslplus-headline-third">{bezWerte + " - Abweichungen zwischen Ansatz und Ergebnis nach Produktbereichen"}</div>
                                        </Col>
                                    </Row>                                    
                                    <Row>
                                        <Col>
                                            <Chart
                                                width="100%"
                                                height={'400px'}
                                                chartType="BarChart"
                                                chartLanguage='de'
                                                loader={<div>Loading Chart</div>}
                                                data={Utilities.createAenderungsBarDataJRBySummenDoppisch(this.state.ebene.filter && this.state.ebene.filter.unterEbenen ? this.state.ebene.filter.unterEbenen : [])}                                                    
                                                options={{
                                                          
                                                        bar: { groupWidth: "50%" },
                                                        legend: { position: "none"},
                                                        chartArea: {width: '75%', left: '25%'},
                                                        hAxis: { gridlines: { count: 10 }}
                                                        
                                                }}
                                                    
                                                rootProps={{ 'data-testid': '2' }}
                                            />
                                        </Col>
                                        
                                    </Row>
                                    </div>
                                : <div/>
                            }
                            {this.props.plantyp === 4 ?
                                <div>
                                    <Row>
                                        <Col>
                                            <div className="kslplus-headline-third">{bezWerte + " der " + ((this.props.summenTyp == 17 || this.props.summenTyp == 19) ? "Ergebnisrechnung" : "Finanzrechnung") + " - Abweichung zwischen Gesamtsoll und Prognose (s. Spalte Prognose manuell)"}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Chart
                                                width="100%"
                                                height={'400px'}
                                                chartType="BarChart"
                                                chartLanguage='de'
                                                loader={<div>Loading Chart</div>}
                                                data={Utilities.createBarDataAPAUHBySummenDoppisch(this.state.ebene.filter, bezWerte ? bezWerte : "")}
                                                options={{

                                                    bar: { groupWidth: "50%" },
                                                    legend: { position: "none" },
                                                    chartArea: { width: '75%', left: '25%' },
                                                    hAxis: { gridlines: { count: 10 } }

                                                }}

                                                rootProps={{ 'data-testid': '2' }}
                                            />
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="kslplus-headline-third">{bezWerte + " - Abweichungen zwischen Gesamtsoll und Prognose (s. Spalte Prognose manuell)  nach Produktbereichen"}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Chart
                                                width="100%"
                                                height={'400px'}
                                                chartType="BarChart"
                                                chartLanguage='de'
                                                loader={<div>Loading Chart</div>}
                                                data={Utilities.createAenderungsBarDataJRBySummenDoppisch(this.state.ebene.filter && this.state.ebene.filter.unterEbenen ? this.state.ebene.filter.unterEbenen : [])}
                                                options={{

                                                    bar: { groupWidth: "50%" },
                                                    legend: { position: "none" },
                                                    chartArea: { width: '75%', left: '25%' },
                                                    hAxis: { gridlines: { count: 10 } }

                                                }}

                                                rootProps={{ 'data-testid': '2' }}
                                            />
                                        </Col>

                                    </Row>
                                </div>
                                : <div />
                            }
                            <Row>
                                <Col>
                                        <div className="kslplus-headline-third">Entwicklung {this.state.ebene.bezeichnung}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="ksl-grafik-container">
                                        <Chart
                                            width="100%"
                                            height={'300px'}
                                            chartType="ComboChart"
                                            chartLanguage='de'
                                            loader={<div>Loading Chart</div>}
                                                data={graphData}
                                            options={{
                                                colors: colors,                                                
                                                vAxes: { 0: { title: 'EUR', minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max0, min: graphOpt.min0 } }, 1: { title: '%', minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max1, min: graphOpt.min1 } } },
                                                hAxis: { title: 'Jahr' },
                                                seriesType: 'bars',
                                                series: series1,
                                            }}                                                    
                                            formatters={Utilities.createFormatters(2, 2)}
                                            rootProps={{ 'data-testid': '1' }}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            
                            {((this.props.plantyp !== 3 && this.props.plantyp !== 4) || (this.props.plantyp === 4 && this.props.hh.jahre && this.props.hh.jahre.length < 4)) && (this.state.ebene.ergebnis ?
                                <div>
                                    <Row>
                                        <Col>
                                                <div className="kslplus-headline-third">{"Entwicklung " + bezWerte + " nach Produktbereichen"}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="kslplus-headline-third">{"Ergebnis " + (this.props.jahr - 2).toString()}</div>
                                        </Col>
                                        <Col>
                                            <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Ergebnis " : "Ansatz ") + (this.props.jahr - 1).toString()}</div>
                                        </Col>
                                        <Col>
                                            <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ansatz ") + (this.props.jahr).toString()}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="ksl-grafik-container">

                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createBarDataByHHSumme(this.state.ebene.filter, -2, barColors, this.props.plantyp)}                                                    
                                                    options={{
                                                          
                                                          bar: { groupWidth: "50%" },
                                                          legend: { position: "none"},
                                                          chartArea: {width: '50%'}
                                                        
                                                    }}
                                                    //formatters={Utilities.createFormatters(this.state.ebene.filter.unterEbenen ? this.state.ebene.filter.unterEbenen.length : 10, 2)}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="ksl-grafik-container">

                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createBarDataByHHSumme(this.state.ebene.filter, -1, barColors, this.props.plantyp)}                                                    
                                                    options={{
                                                          
                                                          bar: { groupWidth: "50%" },
                                                          legend: { position: "none"},
                                                          chartArea: {width: '50%'}
                                                        
                                                    }}
                                                    
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="ksl-grafik-container">

                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createBarDataByHHSumme(this.state.ebene.filter, 0, barColors, this.props.plantyp)}                                                    
                                                    options={{
                                                          
                                                          bar: { groupWidth: "50%" },
                                                          legend: { position: "none"},
                                                          chartArea: {width: '50%'}
                                                        
                                                    }}
                                                    
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>

                                    </Row>
                                    </div>
                                : 
                                
                                
                                <div>
                                    <Row>
                                        <Col>
                                                <div className="kslplus-headline-third">{"Entwicklung " + bezWerte + " nach Produktbereichen"}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="kslplus-headline-third">{"Ergebnis " + (this.props.jahr - 2).toString()}</div>
                                        </Col>
                                        <Col>
                                            <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Ergebnis " : "Ansatz ") + (this.props.jahr - 1).toString()}</div>
                                        </Col>
                                        <Col>
                                            <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ansatz ") + (this.props.jahr).toString()}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="ksl-grafik-container ksl-grafik-container-piechart">

                                                <Chart
                                                    width="100%"
                                                    height={'300px'}
                                                    chartType="PieChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createPieDataByHHSumme(this.state.ebene.filter, -2, this.props.plantyp)}
                                                    options={options}
                                                    formatters={[
                                                        {
                                                            type: 'NumberFormat',
                                                            column: 1,
                                                            options: {
                                                                fractionDigits: 0,
                                                                suffix: ' TEUR'
                                                            },
                                                        }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="ksl-grafik-container ksl-grafik-container-piechart">

                                                <Chart
                                                    width="100%"
                                                    height={'300px'}
                                                    chartType="PieChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createPieDataByHHSumme(this.state.ebene.filter, -1, this.props.plantyp)}
                                                    options={options}
                                                    formatters={[
                                                        {
                                                            type: 'NumberFormat',
                                                            column: 1,
                                                            options: {
                                                                fractionDigits: 0,
                                                                suffix: ' TEUR'
                                                            },
                                                        }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="ksl-grafik-container ksl-grafik-container-piechart">

                                                <Chart
                                                    width="100%"
                                                    height={'300px'}
                                                    chartType="PieChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createPieDataByHHSumme(this.state.ebene.filter, 0, this.props.plantyp)}    
                                                    options={options}
                                                    formatters={[
                                                        {
                                                            type: 'NumberFormat',
                                                            column: 1,
                                                            options: {
                                                                fractionDigits: 0,
                                                                suffix: ' TEUR'
                                                            },
                                                        }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>

                                    </Row>
                                    </div>)
                                }

                                {(this.props.plantyp === 3 || (this.props.plantyp === 4 && this.props.hh.jahre && this.props.hh.jahre.length > 3)) && (this.state.ebene.ergebnis ?
                                <div>
                                    <Row>
                                        <Col>
                                                <div className="kslplus-headline-third">{"Entwicklung " + bezWerte + " nach Produktbereichen"}</div>
                                        </Col>
                                    </Row>                                    
                                    <Row>
                                        <Col className="ksl-container-barchart3">
                                            <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-4).toString()}</div>
                                            <div className="ksl-grafik-container ksl-grafik-container-piechart">

                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createBarDataByHHSumme(this.state.ebene.filter, -4, barColors, this.props.plantyp)}                                                    
                                                    options={{
                                                          
                                                          bar: { groupWidth: "50%" },
                                                          legend: { position: "none"},
                                                          chartArea: {width: '50%'}
                                                        
                                                    }}
                                                    //formatters={Utilities.createFormatters(this.state.ebene.filter.unterEbenen ? this.state.ebene.filter.unterEbenen.length : 10, 2)}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="ksl-container-barchart3">
                                            <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-3).toString()}</div>
                                            <div className="ksl-grafik-container ksl-grafik-container-piechart">

                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createBarDataByHHSumme(this.state.ebene.filter, -3, barColors, this.props.plantyp)}                                                    
                                                    options={{
                                                          
                                                          bar: { groupWidth: "50%" },
                                                          legend: { position: "none"},
                                                          chartArea: {width: '50%'}
                                                        
                                                    }}
                                                    
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="ksl-container-barchart3">
                                            <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-2).toString()}</div>
                                            <div className="ksl-grafik-container ksl-grafik-container-piechart">

                                                <Chart
                                                    width="100%"
                                                    height={'400px'}
                                                    chartType="BarChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createBarDataByHHSumme(this.state.ebene.filter, -2, barColors, this.props.plantyp)}                                                    
                                                    options={{
                                                          
                                                          bar: { groupWidth: "50%" },
                                                          legend: { position: "none"},
                                                          chartArea: {width: '50%'}
                                                        
                                                    }}
                                                    
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col className="ksl-container-barchart2">
                                            <div className="ksl-grafik-container-barchart">
                                                <div className="kslplus-headline-third ksl-grafik-container-piechart">{"Ergebnis " +(this.props.jahr-1 ).toString()}</div>
                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">

                                                    <Chart
                                                        width="100%"
                                                        height={'400px'}
                                                        chartType="BarChart"
                                                        chartLanguage='de'
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createBarDataByHHSumme(this.state.ebene.filter, -1, barColors, this.props.plantyp)}                                                    
                                                        options={{
                                                          
                                                              bar: { groupWidth: "50%" },
                                                              legend: { position: "none"},
                                                              chartArea: {width: '50%'}
                                                        
                                                        }}
                                                        //formatters={Utilities.createFormatters(this.state.ebene.filter.unterEbenen ? this.state.ebene.filter.unterEbenen.length : 10, 2)}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="ksl-container-barchart2">
                                            <div className="ksl-grafik-container-barchart">
                                                <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ergebnis ") + (this.props.jahr).toString()}</div>
                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">

                                                    <Chart
                                                        width="100%"
                                                        height={'400px'}
                                                        chartType="BarChart"
                                                        chartLanguage='de'
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createBarDataByHHSumme(this.state.ebene.filter, 0, barColors, this.props.plantyp)}                                                    
                                                        options={{
                                                          
                                                              bar: { groupWidth: "50%" },
                                                              legend: { position: "none"},
                                                              chartArea: {width: '50%'}
                                                        
                                                        }}
                                                    
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        

                                    </Row>
                                    </div>
                                : 
                                
                                
                                <div>
                                    <Row>
                                        <Col>
                                                <div className="kslplus-headline-third">{"Entwicklung " + bezWerte + " nach Produktbereichen"}</div>
                                        </Col>
                                    </Row>                                    
                                    <Row>
                                        <Col className="ksl-container-piechart3">
                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-4).toString()}</div>
                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">

                                                <Chart
                                                    width="100%"
                                                    height={'300px'}
                                                    chartType="PieChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createPieDataByHHSumme(this.state.ebene.filter, -4, this.props.plantyp)}
                                                    options={options}
                                                    formatters={[
                                                        {
                                                            type: 'NumberFormat',
                                                            column: 1,
                                                            options: {
                                                                fractionDigits: 0,
                                                                suffix: ' TEUR'
                                                            },
                                                        }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="ksl-container-piechart3">
                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-3).toString()}</div>
                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">

                                                <Chart
                                                    width="100%"
                                                    height={'300px'}
                                                    chartType="PieChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createPieDataByHHSumme(this.state.ebene.filter, -3, this.props.plantyp)}
                                                    options={options}
                                                    formatters={[
                                                        {
                                                            type: 'NumberFormat',
                                                            column: 1,
                                                            options: {
                                                                fractionDigits: 0,
                                                                suffix: ' TEUR'
                                                            },
                                                        }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="ksl-container-piechart3">
                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-2).toString()}</div>
                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">

                                                <Chart
                                                    width="100%"
                                                    height={'300px'}
                                                    chartType="PieChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createPieDataByHHSumme(this.state.ebene.filter, -2, this.props.plantyp)}    
                                                    options={options}
                                                    formatters={[
                                                        {
                                                            type: 'NumberFormat',
                                                            column: 1,
                                                            options: {
                                                                fractionDigits: 0,
                                                                suffix: ' TEUR'
                                                            },
                                                        }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col className="ksl-container-piechart3">                                            
                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-1).toString()}</div>
                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">

                                                <Chart
                                                    width="100%"
                                                    height={'360px'}
                                                    chartType="PieChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createPieDataByHHSumme(this.state.ebene.filter, -1, this.props.plantyp)}
                                                    options={options}
                                                    formatters={[
                                                        {
                                                            type: 'NumberFormat',
                                                            column: 1,
                                                            options: {
                                                                fractionDigits: 0,
                                                                suffix: ' TEUR'
                                                            },
                                                        }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="ksl-container-piechart3">
                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr).toString()}</div>
                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">

                                                <Chart
                                                    width="100%"
                                                    height={'360px'}
                                                    chartType="PieChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                    data={Utilities.createPieDataByHHSumme(this.state.ebene.filter, 0, this.props.plantyp)}
                                                    options={options}
                                                    formatters={[
                                                        {
                                                            type: 'NumberFormat',
                                                            column: 1,
                                                            options: {
                                                                fractionDigits: 0,
                                                                suffix: ' TEUR'
                                                            },
                                                        }]}
                                                    rootProps={{ 'data-testid': '2' }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    </div>)
                                }
                            
                        </div> :
                        <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                    }
                    
                </div>
            </div>                      
        );
    }
}


import React, { Component, KeyboardEvent, ChangeEvent, MouseEvent } from "react";
import {
    Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, UncontrolledTooltip, UncontrolledPopover, PopoverBody, Badge, Container
} from "reactstrap";
import * as KSLPlusAPI from "../services/KSLPlusAPI"

import Chart from "react-google-charts";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// @ts-ignores
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import Drawer from '@material-ui/core/Drawer';

//import { HaushaltsNav, EbenenTyp } from './HaushaltsNavComp';
import { HaushaltsNavTree, EbenenTyp } from './HaushaltsNavTreeComp';


import * as Utilities from "./Utilities"

import { env } from '../env';
import '../custom.css';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";

const paginationoptions = {
    sizePerPageList: [{
        text: '10', value: 10
    },{
        text: '15', value: 15
    }, {
        text: '20', value: 20
    }] // A numeric array is also available. the purpose of above example is custom the text
};

type State = {
    ready: boolean,
    ebene: KSLPlusAPI.HaushaltsEbene | undefined,
    expandedItem: string
    alleJahre: boolean
}

type Props = {    
    hh: KSLPlusAPI.HaushaltsEbene,
    jahr: number,
    kunde: number,
    gemeinde: number,
    plantyp: number,
    planstufe: number,
    verwaltungsansicht: boolean,
    uhconfig: KSLPlusAPI.UhConfigModel | undefined,
    massnahmen: KSLPlusAPI.Massnahman[],
    settings: KSLPlusAPI.SettingsModel,
    isvmhh: boolean,
    isVisible: boolean
    
}



export class GRHaushalt extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        
        this.dokumente=  [];
    }

    node: any;
    dokumente: KSLPlusAPI.Dokumente[];
    container: any = null;

    state: State = {
        ready: false,
        expandedItem: "",
        ebene: this.props.hh,
        alleJahre: false
    }

    redrawCharts = () => {        
        if (this.state.ebene )
            this.handleSelectHHEbene(this.state.ebene);
    }
      
    expandRowEinnahmen = {
      renderer: (row: KSLPlusAPI.HaushaltsEbene) => (
            row.unterEbenen ?                                  
                <BootstrapTable hover={true} bordered={false} headerClasses="kslplus-hidden" keyField='id' data={row.unterEbenen} columns={this.createColumns(row, row, null, null, null, false, false, true, this.props.hh.jahre ? this.props.hh.jahre : [])} hiddenRows={this.createHiddenRowKeys(row.unterEbenen, false)} expandRow={row.unterEbenen && row.unterEbenen.find(e => e.unterEbenen) ? this.expandRowEinnahmen : undefined} rowClasses="kslplus-row-sum-0" />
            :
                null
        
      ),
      className: 'kslplus-tabellen-expanded',
      showExpandColumn: true,
      expandColumnPosition: 'left',
      expandHeaderColumnRenderer: ({ isAnyExpands } : { isAnyExpands: boolean}) => {
        return null;        
      },
      expandColumnRenderer: ({ expanded } : { expanded: boolean}) => {
        if (expanded) {
          return (
            <div className="kslplus-expand-column">(-)</div>
          );
        }
        return (
          <div className="kslplus-expand-column">(+)</div>
        );
      }
      
    };

    expandRowAusgaben = {
      renderer: (row: KSLPlusAPI.HaushaltsEbene) => (
            row.unterEbenen ?        
                <BootstrapTable hover={true} bordered={false} headerClasses="kslplus-hidden" keyField='id' data={row.unterEbenen} columns={this.createColumns(row, row, null, row, null, true, false, true, this.props.hh.jahre ? this.props.hh.jahre : [])} hiddenRows={this.createHiddenRowKeys(row.unterEbenen, true)} expandRow={row.unterEbenen && row.unterEbenen.find(e => e.unterEbenen) ? this.expandRowAusgaben : undefined} rowClasses="kslplus-row-sum-0" />
            :
                null
        
      ),
      className: 'kslplus-tabellen-expanded',
      showExpandColumn: true,
      expandColumnPosition: 'left',
      expandHeaderColumnRenderer: ({ isAnyExpands } : { isAnyExpands: boolean}) => {
        return null;        
      },
      expandColumnRenderer: ({ expanded } : { expanded: boolean}) => {
        if (expanded) {
          return (
            <div className="kslplus-expand-column">(-)</div>
          );
        }
        return (
          <div className="kslplus-expand-column">(+)</div>
        );
      }
      
    };

    componentDidMount() {
        window.addEventListener('resize', this.redrawCharts, false);
        if ( this.state.ebene )
            this.readFiltered(this.state.ebene);

        
    }

    componentDidUpdate(prevProps: Props) {        
        if (prevProps.uhconfig !== this.props.uhconfig && this.props.plantyp === 4 && this.state.ebene)
            this.readFiltered(this.state.ebene);

    } 

    readFiltered = (ebene: KSLPlusAPI.HaushaltsEbene) => {
        var request = new KSLPlusAPI.HaushaltRequest();
        var kunde = 0;
        if (this.props.kunde === undefined)
            return;
        request.kunde = this.props.kunde;
        var gemeinde = 0;
        if (this.props.gemeinde === undefined)
            return;
        request.gemeinde = this.props.gemeinde;
        var jahr = 0;
        if (this.props.jahr === undefined)
            return;
        request.jahr = this.props.jahr;

        request.plantyp = this.props.plantyp;
        request.planstufe = this.props.planstufe;
        request.uhConfig = this.props.uhconfig ? this.props.uhconfig.id : 0;
        request.verwaltungsAnsicht = this.props.verwaltungsansicht;


        const api = env.REACT_APP_KSLPLUS_API || "https://localhost:5001";
        var client = new KSLPlusAPI.DigitalHoushold_Client(api);

        
        request.ugStellen = ebene.ugStellen;
        request.gruppe = ebene.untergruppe;
        if (this.props.isvmhh) {
            request.gruppen = [3, 9];
            request.ignorierteGruppen = [0, 1, 2, 4, 5, 6, 7, 8];
        }
        else {
            request.gruppen = [0, 1, 2, 4, 5, 6, 7, 8];
            request.ignorierteGruppen = [3, 9];
        }
        
        //request.ignorierteGruppen = [800, 801, 802, 803, 804, 805, 806, 807, 808, 86, 91, 201, 202, 203, 204, 205, 206, 207, 208, 28, 31];
        client.getFilteredHaushaltByGliederung("", request).then((hh) => {
            ebene.filter = hh;
            this.setState({ ebene: ebene });
            //this.setState({ ready: true });
        }).catch(Reason => {
        });

    }

    createHighDifItems = (rows: KSLPlusAPI.HaushaltsEbene[], field1: string, field2: string) => {
        var items = [];

        for ( let i = 0; i < Math.min(3, rows.length); i++ )
        {              
            const rowAsArray = rows[i] as unknown as { [key: string]: number };
            if ( rowAsArray[field2]-rowAsArray[field1] != 0 )                                    
                items.push(<div key={i.toString()}><Row><Col lg="8"><div className="kslplus-change-popover-bezeichnung">{Utilities.Num2Str(rows[i].unterabschnitt, rows[i].uaStellen) + " " + rows[i].bezeichnung}</div></Col><Col><div className="kslplus-change-popover-wert">{Utilities.FormatCurrency(rowAsArray[field2]-rowAsArray[field1], 2, 0, '.', ',') + " EUR"}</div></Col></Row></div>);
        }
        return items;
    }   

    

    createChangeColumn = (field1: string, field2: string, footer1: number, footer2: number, ausgaben: boolean, id: number, showFooter: boolean = true, text: string = "Vorjahr", pjFarben: boolean = false) => {         
        return {
            dataField: "dummy" + id.toString(),
            text: "+/-",
            headerAlign: 'right',
            align: 'right',
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsEbene) => {                
                const rowAsArray = row as unknown as { [key: string]: number };
                
                let sorted : KSLPlusAPI.HaushaltsEbene[] = [];                
                if ( row.unterEbenen && row.uaStellen && row.uaStellen < 3 )                    
                    sorted = row.unterEbenen.slice();
                    sorted = sorted.sort((r1, r2) => {
                        const row1 = r1 as unknown as { [key: string]: number };
                        const row2 = r2 as unknown as { [key: string]: number };

                        return ( Math.abs(row2[field1]-row2[field2]) - Math.abs(row1[field1]-row1[field2]))

                });               
                var items = this.createHighDifItems(sorted, field1, field2);

                var res = Utilities.calcArrowRotAndColor(rowAsArray[field1], rowAsArray[field2], ausgaben, this.props.settings);                
                
                var ttId = row.id ? "kslplus-label-" + id + "_" + row.id : "";
                ttId = ttId + (ausgaben ? "-a" : "-e");
                
                return (
                    <div>
                        <div id={ttId}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 156" width="100%">
                                <g transform={"rotate(" + res.rotation + ", 82, 78)"}>
                                    <path d="m0,86h36v70h92V86h36L82,0" fill={res.color} />
                                </g>
                            </svg>
                        </div>
                        <UncontrolledPopover
                            size="lg"
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-change-popover">
                            <PopoverBody className="kslplus-change-popover-body">
                                <Container className={items.length > 0 ? "kslplus-change-popover-block":""}>                                          
                                    <div className="kslplus-change-popover-headline">{"Gegenüber " + text + ": " + Utilities.FormatCurrency(res.changeA, 2, 3, '.', ',') + " EUR (" + Utilities.FormatCurrency((res.changeP-1)*100, 2, 0, '.', ',') + "  %)"}</div>                                                                                                                                         
                                    {items.length > 0 && <br/>}
                                    {items.length > 0 && <div className="kslplus-change-popover-headline">Davon wesentlich:</div>}
                                    {items.length > 0 && 
                                        <div className="kslplus-change-popover-container-werte">
                                            {items}
                                        </div>
                                    }
                                </Container>
                            </PopoverBody>
                        </UncontrolledPopover>                           
                    </div>
                    
                );

            }, 
            footerStyle: (colum: any, colIndex: any) => {
                if (pjFarben)
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };

                return {};
            },
            headerStyle: (colum: any, colIndex: any) => {
                if (pjFarben)
                    return { width: "40px", backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };

                return { width: "40px" };
            },
            style: (colum: any, colIndex: any) => {
                if (pjFarben)
                    return { width: "40px", backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };

                return { width: "40px" };
            },           
            footer: showFooter ? id : undefined,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: number) => {                
                var res = Utilities.calcArrowRotAndColor(footer1, footer2, ausgaben, this.props.settings);
                
                var ttId = "kslplus-label-change-gr-" + (this.props.isvmhh?"vm-" : "vw-") +  "-" + (ausgaben ? "a-" : "e-") + columnIndex;
                
                return (
                    <div>
                        <div id={ttId}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 156" width="100%">
                                <g transform={"rotate(" + res.rotation + ", 82, 78)"}>
                                    <path d="m0,86h36v70h92V86h36L82,0" fill={res.color} />
                                </g>
                            </svg>
                        </div>
                        <UncontrolledPopover
                            size="lg"
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-change-popover">
                            <PopoverBody className="kslplus-change-popover-body">
                                    <div className="kslplus-change-popover-headline">{"Gegenüber " + text + ": " + Utilities.FormatCurrency(res.changeA, 2, 3, '.', ',') + " EUR (" + Utilities.FormatCurrency((res.changeP-1)*100, 2, 0, '.', ',') + "  %)"}</div>                                                                                                                                                                                                            
                            </PopoverBody>
                        </UncontrolledPopover>    
                    </div>
                    
                );
            }
        };
    }

    
    createDataColumnUH = (field: string, jahr: number, footer: number, footer2: number | undefined, id: number, showFooter: boolean, text: string, hidden: boolean = false, ttE: boolean = false, ttF: boolean = false, testfield: string = "", komfield: string = "", pkTip: boolean = false, gsTip: boolean = false, ausgefuehrtTip: boolean = false) => {
        return {
            dataField: field,
            text: text + " " + jahr + " (EUR)",
            headerAlign: 'right',
            align: 'right',
            hidden: hidden,
            headerFormatter: (column: any, columnIndex: any) => {
                if (ausgefuehrtTip) {

                    var ttId = "header-tt-gl-erg-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>

                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Ausgeführt errechnet sich wie folgt:</div></div>
                                        <br />

                                        {this.props.uhconfig && this.props.uhconfig.verfuegtSpaltenDic &&
                                            Utilities.createAusgefuehrtTooltipItems(this.props.uhconfig.verfuegtSpaltenDic, this.props.settings.importFormat)
                                        }


                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else if (gsTip) {

                    var ttId = "header-tt-gl-erg-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(EUR)</div></div>

                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Das Gesamtsoll errechnet sich wie folgt:</div></div>
                                        <br />

                                        {this.props.uhconfig && this.props.uhconfig.fortAnsatzSpaltenDic &&
                                            Utilities.createGSTooltipItems(this.props.uhconfig.fortAnsatzSpaltenDic, this.props.settings.importFormat)
                                        }


                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else if (ttE) {

                    var ttId = "header-tt-gl-erg-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-data">{text + " " + jahr + " (EUR)"} </div>

                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Das Ergebnis (Rechnungsergebnis) errechnet sich wie folgt:</div></div>
                                        <br />
                                        <div className="kslplus-header-tt-block"><div className="kslplus-label">Ausgeführtes Soll auf lfd. Ansatz</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang auf Kassenreste aus Vj.</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Neue Haushaltsreste</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang Haushaltsreste aus Vj.</div></div>


                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else if (ttF) {

                    var ttId = "header-tt-gl-fort-" + field;

                    return (
                        <div>
                            <div id={ttId} className="kslplus-tabellen-data">{text + " " + jahr + " (EUR)"} </div>
                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>
                                        <div><div className="kslplus-header-tt-block1">Der fortgeschrieben Ansatz errechnet sich wie folgt:</div></div>
                                        <br />
                                        <div className="kslplus-header-tt-block"><div className="kslplus-label">Ansatz</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Nachtragswerte</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">HHReste a. VJ</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Sollveränderungen</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Abgänge auf HHReste a. Vj</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Über-/außerplanmäßige Bewilligungen</div></div>
                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    );
                }
                else {
                    return (
                        <div className="kslplus-tabellen-data">{text + " " + jahr + " (EUR)"} </div>
                    );
                }
            },
            formatter: (cellContent: string, row: { [key: string]: number }) => {
                if (pkTip && row["pkTip"]) {
                    return (<div className="kslplus-tabellen-data" >{row["pkTip"] ? <div className="kslplus-info-progkom-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-progtip-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="left" target={row.id ? "kslplus-info-progtip-" + row.id : ""}>{row["pkTip"]}</UncontrolledTooltip></div> : null}{Utilities.FormatCurrency(row[field] as number, 2, 3, '.', ',')}</div>);
                }
                if (komfield !== "") {
                    return (<div className="kslplus-tabellen-data" >{row[komfield] ? <div className="kslplus-info-progkom-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-progkomm-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="left" target={row.id ? "kslplus-info-progkomm-" + row.id : ""}>{row[komfield]}</UncontrolledTooltip></div> : null}{Utilities.FormatCurrency(row[field] as number, 2, 3, '.', ',')}</div>);
                }
                return (
                    <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field], 2, 3, '.', ',')} </div>
                );

            },
            footer: showFooter ? id : undefined,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                if (footer2 !== undefined)
                    return (<div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')} </div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer2, 2, 3, '.', ',')} </div></div>);

                return (
                    <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')} </div>
                );
            },
            style: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if (testfield != "" && row[testfield])
                    return { backgroundColor: this.props.uhconfig && this.props.uhconfig.hfarbeProgMan ? this.props.uhconfig.hfarbeProgMan : 'lightcoral', color: this.props.uhconfig && this.props.uhconfig.vfarbeProgMan ? this.props.uhconfig.vfarbeProgMan : 'black' }
            }
        };
    }
    
    createDataColumnJR = (field: string, jahr: number, footer: number, footer2: number|undefined, id: number, showFooter : boolean, text: string, ttE: boolean = false, ttF: boolean = false) => {        
        return {
            dataField: field,
            text: text + " "+ jahr + " (EUR)",
            headerAlign: 'right',
            align: 'right',      
            headerFormatter: (column: any, columnIndex: any) => {
                if ( ttE )    
                {
                    
                    var ttId = "header-tt-gl-erg-" + field;
                
                    return (  
                        <div>                                          
                            <div id={ttId} className="kslplus-tabellen-data">{text + " "+ jahr + " (EUR)"} </div>
                    
                            <UncontrolledPopover
                                size="xl"
                                placement="right"
                                target={ttId}
                                trigger="hover"
                                className="kslplus-header-popover">
                                <PopoverBody className="kslplus-header-popover-body">
                                    <Container>                                          
                                            <div><div className="kslplus-header-tt-block1">Das Ergebnis (Rechnungsergebnis) errechnet sich wie folgt:</div></div>
                                            <br/>                                        
                                        <div className="kslplus-header-tt-block"><div className="kslplus-label">Ausgeführtes Soll auf lfd. Ansatz</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang auf Kassenreste aus Vj.</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">+&nbsp;</div><div className="kslplus-label">Neue Haushaltsreste</div></div>
                                        <div className="kslplus-header-tt-block"><div className="kslplus-sign">-&nbsp;</div><div className="kslplus-label">Abgang Haushaltsreste aus Vj.</div></div>
                                        

                                    </Container>
                                </PopoverBody>
                            </UncontrolledPopover>                    
                        </div>
                        ); 
                }
                else if ( ttF )    
                {
                    
                    var ttId = "header-tt-gl-fort-" + field;
                
                    return (             
                            <div>                               
                                <div id={ttId} className="kslplus-tabellen-data">{text + " "+ jahr + " (EUR)"} </div>                    
                                <UncontrolledPopover
                                    size="xl"
                                    placement="right"
                                    target={ttId}
                                    trigger="hover"
                                    className="kslplus-header-popover">
                                    <PopoverBody className="kslplus-header-popover-body">
                                        <Container>                                          
                                             <div><div className="kslplus-header-tt-block1">Der fortgeschrieben Ansatz errechnet sich wie folgt:</div></div>
                                             <br/>                                        
                                            <div className="kslplus-header-tt-block"><div className="kslplus-label">Ansatz</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Nachtragswerte</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">HHReste a. VJ</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Sollveränderungen</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Abgänge auf HHReste a. Vj</div></div>
                                            <div className="kslplus-header-tt-block"><div className="kslplus-sign">+/-</div><div className="kslplus-label">Über-/außerplanmäßige Bewilligungen</div></div>                                                                                    
                                        </Container>
                                    </PopoverBody>
                                </UncontrolledPopover>                    
                            </div>
                        ); 
                }
                else
                {
                    return (                                            
                        <div className="kslplus-tabellen-data">{text + " "+ jahr + " (EUR)"} </div>
                    );
                }
            },                 
            formatter: (cellContent: string, row: {[key: string]: number}) => {                
                return (
                    <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field], 2, 3, '.', ',')} </div>
                );
                
            },
            footer: showFooter ? id : undefined,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                if ( footer2 !== undefined )
                    return (<div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')} </div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer2, 2, 3, '.', ',')} </div></div>);
            
                return (                    
                        <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, 2, 3, '.', ',')} </div>
                    );
            }
        };
    }

    createDataColumn = (field: string, jahr: number, footer: number, footer2: number | undefined, id: number, showFooter : boolean = true) => {
        var text = "";
        if (jahr - this.props.jahr == -2 && this.props.plantyp < 3 && this.props.settings.vvjVorl)
            text = "vorl. Ergebnis ";
        else if (  jahr - this.props.jahr < -1 )
            text = "Ergebnis ";
        else if ( jahr - this.props.jahr > (this.props.settings.doppelhaushalt ? 1 : 0) )
            text = "Finanzplan ";
        else
            text = "Ansatz "; 
            
        return {
            dataField: field,
            text: text + jahr + " (EUR)",            
            headerAlign: 'center',
            align: 'right',
            footerStyle: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr === 0 || this.props.settings.doppelhaushalt && jahr - this.props.jahr === 1)) {
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };
                }
                return {};

            },
            formatter: (cellContent: string, row: { [key: string]: number }) => {
                var nk = 2;
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr > -2) && this.props.settings.ansatzOhneDS) {
                    nk = 0;
                }

                return (
                    <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field], nk, 3, '.', ',')} </div>
                );

            },           
            footer: showFooter ? id : undefined,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                var nk = 2;
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr > -2) && this.props.settings.ansatzOhneDS) {
                    nk = 0;
                }

                if (footer2 !== undefined)
                    return (<div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, nk, 3, '.', ',')} </div><div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer2, nk, 3, '.', ',')} </div></div>);

                return (
                    <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footer, nk, 3, '.', ',')} </div>
                );
            },
            style: (cell: any, row: { [key: string]: number }, rowIndex: number, colIndex: number) => {
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr === 0 || this.props.settings.doppelhaushalt && jahr - this.props.jahr === 1)) {
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };
                }

                //return { width: '15%' };
            },
            headerStyle: (colum: any, colIndex: any) => {
                if ((this.props.plantyp === 1 || this.props.plantyp === 2) && (jahr - this.props.jahr === 0 || this.props.settings.doppelhaushalt && jahr - this.props.jahr === 1)) {
                    return { backgroundColor: this.props.settings.hFarbePJ, color: this.props.settings.vFarbePJ };
                }
                else
                    return {};
            }
        };
    }

    createDataPercentColumn = (field: string, field1: string, jahr: number, id: number, text: string = "", hidden: boolean = false, ttE: boolean = false, ttF: boolean = false, footVal: number, footVal1: number) => {    
        if (text == "") {
            if (jahr - this.props.jahr < -1)
                text = "Ergebnis";
            else if (jahr - this.props.jahr > (this.props.settings.doppelhaushalt ? 1 : 0))
                text = "Finanzplan";
            else
                text = "Ansatz";
        }
        return {
            dataField: field,
            text: text + " " + jahr + " (%)",
            headerAlign: 'right',
            align: 'right',
            hidden: hidden,
            headerFormatter: (column: any, columnIndex: any) => {
                return (
                    <div className="kslplus-tabellen-header"><div className="kslplus-tabellen-header-sub1">{text + " " + jahr}</div><div className="kslplus-tabellen-header-sub2">(%)</div></div>
                );
            },
            formatter: (cellContent: string, row: { [key: string]: number }) => {
                if (Math.round(row[field] * 100) / 100 === 0)
                    return (
                        <div className="kslplus-tabellen-data">0,00</div>
                    );
                else if (row[field1] === 0 && row[field] < 0) {
                    return (<div className="kslplus-tabellen-data">-Ꝏ</div>);
                }
                else if (row[field1] === 0) {

                    return (<div className="kslplus-tabellen-data">Ꝏ</div>);
                }
                else
                    return (
                        <div className="kslplus-tabellen-data">{Utilities.FormatCurrency(row[field] / row[field1] * 100.0, 2, 3, '.', ',')}</div>
                    );

            },
            footer: id,
            footerAlign: 'right',
            footerFormatter: (column: any, columnIndex: any) => {
                if (Math.round(footVal * 100) / 100 === 0)
                    return (
                        <div className="kslplus-tabellen-footer">0,00</div>
                    );
                else if (footVal1 === 0 && footVal < 0) {
                    return (<div className="kslplus-tabellen-footer">-Ꝏ</div>);
                }
                else if (footVal1 === 0) {

                    return (<div className="kslplus-tabellen-footer">Ꝏ</div>);
                }
                return (
                    <div className="kslplus-tabellen-footer">{Utilities.FormatCurrency(footVal / footVal1 * 100.0, 2, 3, '.', ',')} </div>
                );
            }
        };
    }


    createColumnsJR = (data: KSLPlusAPI.HaushaltsEbene | null, totalSum: KSLPlusAPI.HaushaltsEbene, totalSub: KSLPlusAPI.HaushaltsEbene | null | undefined, totalHaben: KSLPlusAPI.HaushaltsEbene | null, totalHabenSub: KSLPlusAPI.HaushaltsEbene | null, ausgaben: boolean, showFooter : boolean = true, showEA : boolean = true, jahre: number[] = []) => {
        var columns = [];
        
        if ( data && data.unterEbenen && !data.unterEbenen.find(e => e.unterEbenen) ) {        
            columns.push({            
                dataField: 'df1',
                isDummyField: true,
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '35px', textAlign: 'left' };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: '35px', textAlign: 'left' };
                },
                footer: showFooter ? "1" : undefined,
                
            });
        }

        columns.push({
            dataField: 'bezeichnung',
            text: ausgaben ? "Ausgaben/Bezeichnung" : "Einnahmen" + "/Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '250px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsEbene) => {
                //var label = "";
                var label: JSX.Element[] = [];
                var tooltip = "";
                var tooltipMn = "";
                if (row.unterEbenen) {
                    //label = row.unterabschnitt !== undefined && row.uaStellen !== undefined ? Utilities.Num2Str(row.unterabschnitt, row.uaStellen) + " - " : "";
                    if (row.unterabschnitt !== undefined && row.uaStellen !== undefined)
                        label.push(<span key={"kslplus-label-" + row.id + "ua"}>{Utilities.Num2Str(row.unterabschnitt, row.uaStellen) + " - "}</span>);
                }
                else {
                    /*
                    if ( row.unterabschnitt !== undefined && row.uaStellen !== undefined )
                        label += Utilities.Num2Str(row.unterabschnitt, row.uaStellen) + "."; 
                    if ( row.massnahme !== null && row.massnahme !== undefined )                    
                        label += Utilities.Num2Str(row.massnahme, 3) + "."; 
                    if ( row.unterkonto !== undefined && row.ugStellen !== undefined )
                        label += Utilities.Num2Str(row.untergruppe, row.ugStellen);
                    if ( row.unterkonto  )
                        label += "." + Utilities.Num2Str(row.unterkonto, 2);

                    label += " - ";
                    
                    tooltip = row.ugText ? row.ugText : "";
                    */

                    if (row.unterabschnitt !== undefined && row.uaStellen)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "ua"}>{Utilities.Num2Str(row.unterabschnitt, row.uaStellen)}</span>);
                    if (row.massnahme !== null && row.massnahme !== undefined) {
                        var mn = this.props.massnahmen.find(m => m.nr == row.massnahme && m.unterabschnitt == row.unterabschnitt && m.untergruppe == row.untergruppe);
                        if (mn && mn.bezeichnung)
                            tooltipMn = mn.bezeichnung;
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-" + row.id + "mn"} key={"kslplus-label-" + row.id + "mn"}>{(label.length > 0 ? "." : "") + Utilities.Num2Str(row.massnahme, 3)}</span>);
                    }
                    if (row.untergruppe !== undefined && row.ugStellen)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-" + row.id} key={"kslplus-label-" + row.id + "ug"}>{(label.length > 0 ? "." : "") + Utilities.Num2Str(row.untergruppe, row.ugStellen)}</span>);
                    if (row.unterkonto)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "uk"}>{(label.length > 0 ? "." : "") + Utilities.Num2Str(row.unterkonto, 2)}</span>);

                    label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "sep"}> - </span>);
                    tooltip = row.ugText ? row.ugText : "";
                }
                //label = label + (row.uaText ? row.uaText : cellContent);                
                label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "uatext"}>{row.uaText ? row.uaText : cellContent}</span>);
                return (                                                            
                    <div>
                        <div className="kslplus-tabellen-bezeichnung" >{label} {row.isHHS && row.beschreibung != null && row.beschreibung.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-text-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-info-text-" + row.id : ""}>{row.beschreibung}</UncontrolledTooltip></div> : null}</div>{tooltip.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id : ""}>{tooltip}</UncontrolledTooltip> : null}
                        {tooltipMn.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id + "mn" : ""}>{tooltipMn}</UncontrolledTooltip> : null}                        
                    </div>
                );

            },
            style: (colum: any, colIndex: any) => {
                if ( !showFooter )                
                    return { width: '250px', paddingLeft: "20px" };
                return { width: '250px' };
            },
            footer: showFooter ? "2" : undefined,
            footerFormatter: (column: any, columnIndex: any) => {
                if (ausgaben && showEA)
                    return (<div><div>Ausgaben gesamt</div><div>Ergebnis E-A</div></div>);
                else if (ausgaben)
                    return (<div>Ausgaben gesamt</div>);

                return <div>Einnahmen gesamt</div>;
            }
        });

        if (jahre.length == 0 && data && data.jahre)
            jahre = data.jahre;

        if (data == null)
            return columns;

        var id = 3;
        if (ausgaben) {
            var fieldPrev: string | undefined;
            var sumPrev = 0;

            
            if (jahre.findIndex(d => d == this.props.jahr - 4) >= 0 &&totalSum.ergebnisPJM4_haben != null && totalSum.ergebnisPJM4_soll != null /*&& totalSum.ergebnisPJM4_soll != 0*/ && data.ergebnisPJM4_haben != null && data.ergebnisPJM4_soll != null /*&& data.ergebnisPJM4_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM4_haben != null && totalHaben.ergebnisPJM4_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_haben != undefined ? totalSub.ergebnisPJM4_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_soll != undefined ? totalSub.ergebnisPJM4_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM4_haben != undefined ? totalHabenSub.ergebnisPJM4_haben : 0);                
                columns.push(this.createDataColumnJR("ergebnisPJM4_soll", this.props.jahr - 4, totalSum.ergebnisPJM4_soll - subsoll, showEA ? totalHaben.ergebnisPJM4_haben - totalSum.ergebnisPJM4_soll - subErgebnis + subsoll : undefined, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4_soll", sumPrev, totalSum.ergebnisPJM4_soll - subsoll, true, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisPJM4_soll";
                sumPrev = totalSum.ergebnisPJM4_soll - subsoll;                
            }
            else {
                fieldPrev = undefined;
            }
            

            if (jahre.findIndex(d => d == this.props.jahr - 3) >= 0 &&totalSum.ergebnisPJM3_haben != null && totalSum.ergebnisPJM3_soll != null /*&& totalSum.ergebnisPJM3_soll != 0*/ && data.ergebnisPJM3_haben != null && data.ergebnisPJM3_soll != null /*&& data.ergebnisPJM3_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM3_haben != null && totalHaben.ergebnisPJM3_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_haben != undefined ? totalSub.ergebnisPJM3_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_soll != undefined ? totalSub.ergebnisPJM3_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM3_haben != undefined ? totalHabenSub.ergebnisPJM3_haben : 0);                
                columns.push(this.createDataColumnJR("ergebnisPJM3_soll", this.props.jahr - 3, totalSum.ergebnisPJM3_soll - subsoll, showEA ? totalHaben.ergebnisPJM3_haben - totalSum.ergebnisPJM3_soll - subErgebnis + subsoll : undefined, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3_soll", sumPrev, totalSum.ergebnisPJM3_soll - subsoll, true, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisPJM3_soll";
                sumPrev = totalSum.ergebnisPJM3_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 2) >= 0 &&totalSum.ergebnisPJM2_haben != null && totalSum.ergebnisPJM2_soll != null /*&& totalSum.ergebnisPJM2_soll != 0*/ && data.ergebnisPJM2_haben != null && data.ergebnisPJM2_soll != null /*&& data.ergebnisPJM2_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM2_haben != null && totalHaben.ergebnisPJM2_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_haben != undefined ? totalSub.ergebnisPJM2_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_soll != undefined ? totalSub.ergebnisPJM2_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM2_haben != undefined ? totalHabenSub.ergebnisPJM2_haben : 0);               
                columns.push(this.createDataColumnJR("ergebnisPJM2_soll", this.props.jahr - 2, totalSum.ergebnisPJM2_soll - subsoll, showEA ? totalHaben.ergebnisPJM2_haben - totalSum.ergebnisPJM2_soll - subErgebnis + subsoll : undefined, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2_soll", sumPrev, totalSum.ergebnisPJM2_soll - subsoll, true, id, showFooter));
                    id++;
                }                
                fieldPrev = "ergebnisPJM2_soll";
                sumPrev = totalSum.ergebnisPJM2_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 1) >= 0 &&totalSum.ergebnisPJM1_haben != null && totalSum.ergebnisPJM1_soll != null /*&& totalSum.ergebnisPJM1_soll != 0*/ && data.ergebnisPJM1_haben != null && data.ergebnisPJM1_soll != null /*&& data.ergebnisPJM1_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM1_haben != null && totalHaben.ergebnisPJM1_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_haben != undefined ? totalSub.ergebnisPJM1_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_soll != undefined ? totalSub.ergebnisPJM1_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM1_haben != undefined ? totalHabenSub.ergebnisPJM1_haben : 0);                
                columns.push(this.createDataColumnJR("ergebnisPJM1_soll", this.props.jahr - 1, totalSum.ergebnisPJM1_soll - subsoll, showEA ? totalHaben.ergebnisPJM1_haben - totalSum.ergebnisPJM1_soll - subErgebnis + subsoll : undefined, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1_soll", sumPrev, totalSum.ergebnisPJM1_soll - subsoll, true, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisPJM1_soll";
                sumPrev = totalSum.ergebnisPJM1_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }
            
            if (jahre.findIndex(d => d == this.props.jahr) >= 0 &&totalSum.ansatzPJfort_haben != null && totalSum.ansatzPJfort_soll != null /*&& totalSum.ansatzPJfort_soll != 0*/ && data.ansatzPJfort_haben != null && data.ansatzPJfort_soll != null /*&& data.ansatzPJfort_soll != 0*/ && totalHaben != null && totalHaben.ansatzPJfort_haben != null && totalHaben.ansatzPJfort_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_haben != undefined ? totalSub.ansatzPJfort_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_soll != undefined ? totalSub.ansatzPJfort_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ansatzPJfort_haben != undefined ? totalHabenSub.ansatzPJfort_haben : 0);                
                columns.push(this.createDataColumnJR("ansatzPJfort_soll", this.props.jahr, totalSum.ansatzPJfort_soll - subsoll, showEA ? totalHaben.ansatzPJfort_haben - totalSum.ansatzPJfort_soll - subErgebnis + subsoll : undefined, id, showFooter, "Fortgeschriebener Ansatz", false, true));
                id++;
                
                fieldPrev = "ansatzPJfort_soll";
                sumPrev = totalSum.ansatzPJfort_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 &&totalSum.ergebnisPJ_haben != null && totalSum.ergebnisPJ_soll != null /*&& totalSum.ergebnisPJ_soll != 0*/ && data.ergebnisPJ_haben != null && data.ergebnisPJ_soll != null /*&& data.ergebnisPJ_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJ_haben != null && totalHaben.ergebnisPJ_haben !== undefined )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJ_haben != undefined ? totalSub.ergebnisPJ_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJ_soll != undefined ? totalSub.ergebnisPJ_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJ_haben != undefined ? totalHabenSub.ergebnisPJ_haben : 0);                
                columns.push(this.createDataColumnJR("ergebnisPJ_soll", this.props.jahr, totalSum.ergebnisPJ_soll - subsoll, showEA ? totalHaben.ergebnisPJ_haben - totalSum.ergebnisPJ_soll - subErgebnis + subsoll : undefined, id, showFooter, "Ergebnis", true));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJ_soll", sumPrev, totalSum.ergebnisPJ_soll - subsoll, true, id, showFooter, "fortgeschriebenem Ansatz"));
                    id++;
                }
                fieldPrev = "ergebnisPJ_soll";
                sumPrev = totalSum.ergebnisPJ_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }                        

        }
        else {            
            var fieldPrev: string | undefined;
            var sumPrev = 0;

            if (jahre.findIndex(d => d == this.props.jahr - 4) >= 0 &&totalSum.ergebnisPJM4_soll != null && totalSum.ergebnisPJM4_haben != null /*&& totalSum.ergebnisPJM4_haben != 0*/ )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_haben != undefined ? totalSub.ergebnisPJM4_haben : 0);                            
                columns.push(this.createDataColumnJR("ergebnisPJM4_haben", this.props.jahr - 4, totalSum.ergebnisPJM4_haben - subhaben, undefined, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4_haben", sumPrev, totalSum.ergebnisPJM4_haben - subhaben, false, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisPJM4_haben";
                sumPrev = totalSum.ergebnisPJM4_haben - subhaben;
            }
            else {                
                fieldPrev = undefined;
            }
            
            if (jahre.findIndex(d => d == this.props.jahr - 3) >= 0 && totalSum.ergebnisPJM3_soll != null && totalSum.ergebnisPJM3_haben != null /*&& totalSum.ergebnisPJM3_haben != 0*/ )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_haben != undefined ? totalSub.ergebnisPJM3_haben : 0);
                columns.push(this.createDataColumnJR("ergebnisPJM3_haben", this.props.jahr - 3, totalSum.ergebnisPJM3_haben - subhaben, undefined, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3_haben", sumPrev, totalSum.ergebnisPJM3_haben - subhaben, false, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisPJM3_haben";
                sumPrev = totalSum.ergebnisPJM3_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 2) >= 0 &&totalSum.ergebnisPJM2_soll != null && totalSum.ergebnisPJM2_haben != null /*&& totalSum.ergebnisPJM2_haben != 0*/ )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_haben != undefined ? totalSub.ergebnisPJM2_haben : 0);
                columns.push(this.createDataColumnJR("ergebnisPJM2_haben", this.props.jahr - 2, totalSum.ergebnisPJM2_haben - subhaben, undefined, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2_haben", sumPrev, totalSum.ergebnisPJM2_haben - subhaben, false, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisPJM2_haben";
                sumPrev = totalSum.ergebnisPJM2_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 1) >= 0 &&totalSum.ergebnisPJM1_soll != null && totalSum.ergebnisPJM1_haben != null /*&& totalSum.ergebnisPJM1_haben != 0*/ )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_haben != undefined ? totalSub.ergebnisPJM1_haben : 0);
                columns.push(this.createDataColumnJR("ergebnisPJM1_haben", this.props.jahr - 1, totalSum.ergebnisPJM1_haben - subhaben, undefined, id, showFooter, "Ergebnis"));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1_haben", sumPrev, totalSum.ergebnisPJM1_haben - subhaben, false, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisPJM1_haben";
                sumPrev = totalSum.ergebnisPJM1_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }
            
            if (jahre.findIndex(d => d == this.props.jahr) >= 0 &&totalSum.ansatzPJfort_soll != null && totalSum.ansatzPJfort_haben != null /*&& totalSum.ansatzPJfort_haben != 0*/ )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_haben != undefined ? totalSub.ansatzPJfort_haben : 0);
                columns.push(this.createDataColumnJR("ansatzPJfort_haben", this.props.jahr, totalSum.ansatzPJfort_haben - subhaben, undefined, id, showFooter, "Fortgeschriebener Ansatz", false, true));
                id++;                
                fieldPrev = "ansatzPJfort_haben";
                sumPrev = totalSum.ansatzPJfort_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 &&totalSum.ergebnisPJ_soll != null && totalSum.ergebnisPJ_haben != null /*&& totalSum.ergebnisPJ_haben != 0*/ )
            {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJ_haben != undefined ? totalSub.ergebnisPJ_haben : 0);
                columns.push(this.createDataColumnJR("ergebnisPJ_haben", this.props.jahr, totalSum.ergebnisPJ_haben - subhaben, undefined, id, showFooter, "Ergebnis", true));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJ_haben", sumPrev, totalSum.ergebnisPJ_haben - subhaben, false, id, showFooter, "fortgeschriebenem Ansatz"));
                    id++;
                }
                fieldPrev = "ergebnisPJ_haben";
                sumPrev = totalSum.ergebnisPJ_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }                        

        }
        return columns;
    }

    createColumnsUH = (data: KSLPlusAPI.HaushaltsEbene | null, totalSum: KSLPlusAPI.HaushaltsEbene, totalSub: KSLPlusAPI.HaushaltsEbene | null | undefined, totalHaben: KSLPlusAPI.HaushaltsEbene | null, totalHabenSub: KSLPlusAPI.HaushaltsEbene | null, ausgaben: boolean, showFooter: boolean = true, showEA: boolean = true, jahre: number[] = []) => {
        var columns = [];

        if (data && data.unterEbenen && !data.unterEbenen.find(e => e.unterEbenen)) 
        {
            columns.push({
                dataField: 'df1',
                isDummyField: true,
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '35px', textAlign: 'left' };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: '35px', textAlign: 'left' };
                },
                footer: showFooter ? "1" : undefined,

            });
        }

        columns.push({
            dataField: 'bezeichnung',
            text: ausgaben ? "Ausgaben/Bezeichnung" : "Einnahmen" + "/Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '250px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsEbene) => {
                //var label = "";
                var label: JSX.Element[] = [];
                var tooltip = "";
                var tooltipMn = "";
                if (row.unterEbenen) {
                    //label = row.unterabschnitt !== undefined && row.uaStellen !== undefined ? Utilities.Num2Str(row.unterabschnitt, row.uaStellen) + " - " : "";
                    if (row.unterabschnitt !== undefined && row.uaStellen !== undefined)
                        label.push(<span key={"kslplus-label-" + row.id + "ua"}>{Utilities.Num2Str(row.unterabschnitt, row.uaStellen) + " - "}</span>);
                }
                else {
                    /*
                    if ( row.unterabschnitt !== undefined && row.uaStellen !== undefined )
                        label += Utilities.Num2Str(row.unterabschnitt, row.uaStellen) + "."; 
                    if ( row.massnahme !== null && row.massnahme !== undefined )                    
                        label += Utilities.Num2Str(row.massnahme, 3) + "."; 
                    if ( row.unterkonto !== undefined && row.ugStellen !== undefined )
                        label += Utilities.Num2Str(row.untergruppe, row.ugStellen);
                    if ( row.unterkonto  )
                        label += "." + Utilities.Num2Str(row.unterkonto, 2);

                    label += " - ";
                    
                    tooltip = row.ugText ? row.ugText : "";
                    */

                    if (row.unterabschnitt !== undefined && row.uaStellen)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "ua"}>{Utilities.Num2Str(row.unterabschnitt, row.uaStellen)}</span>);
                    if (row.massnahme !== null && row.massnahme !== undefined) {
                        var mn = this.props.massnahmen.find(m => m.nr == row.massnahme && m.unterabschnitt == row.unterabschnitt && m.untergruppe == row.untergruppe);
                        if (mn && mn.bezeichnung)
                            tooltipMn = mn.bezeichnung;
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-" + row.id + "mn"} key={"kslplus-label-" + row.id + "mn"}>{(label.length > 0 ? "." : "") + Utilities.Num2Str(row.massnahme, 3)}</span>);
                    }
                    if (row.untergruppe !== undefined && row.ugStellen)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-" + row.id} key={"kslplus-label-" + row.id + "ug"}>{(label.length > 0 ? "." : "") + Utilities.Num2Str(row.untergruppe, row.ugStellen)}</span>);
                    if (row.unterkonto)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "uk"}>{(label.length > 0 ? "." : "") + Utilities.Num2Str(row.unterkonto, 2)}</span>);

                    label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "sep"}> - </span>);
                    tooltip = row.ugText ? row.ugText : "";
                }
                //label = label + (row.uaText ? row.uaText : cellContent);                
                label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "uatext"}>{row.uaText ? row.uaText : cellContent}</span>);
                return (
                    <div>
                        <div className="kslplus-tabellen-bezeichnung" >{label} {row.isHHS && row.beschreibung != null && row.beschreibung.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-text-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-info-text-" + row.id : ""}>{row.beschreibung}</UncontrolledTooltip></div> : null}</div>{tooltip.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id : ""}>{tooltip}</UncontrolledTooltip> : null}
                        {tooltipMn.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id + "mn" : ""}>{tooltipMn}</UncontrolledTooltip> : null}
                    </div>
                );

            },
            style: (colum: any, colIndex: any) => {
                if (!showFooter)
                    return { width: '250px', paddingLeft: "20px" };
                return { width: '250px' };
            },
            footer: showFooter ? "2" : undefined,
            footerFormatter: (column: any, columnIndex: any) => {
                if (ausgaben && showEA)
                    return (<div><div>Ausgaben gesamt</div><div>Ergebnis E-A</div></div>);
                else if (ausgaben)
                    return (<div>Ausgaben gesamt</div>);

                return <div>Einnahmen gesamt</div>;
            }
        });

        if (jahre.length == 0 && data && data.jahre)
            jahre = data.jahre;

        if (data == null)
            return columns;

        if (jahre.findIndex(d => d == this.props.jahr - 3) != -1 || jahre.findIndex(d => d == this.props.jahr - 4) != -1) {        
            columns.push({
                dataField: '',
                isDummyField: true,
                headerAlign: 'middle',
                headerFormatter: (column: any, columnIndex: any) => {
                    var ttId = "kslplus-tt-pjcol-gr" + (ausgaben ? "a" : "e");
                    return (<div>
                        <div id={ttId}>{this.state.alleJahre ? "-" : "+"}</div >
                        <UncontrolledPopover
                            placement="right"
                            target={ttId}
                            trigger="hover"
                            className="kslplus-header-popover">
                            <PopoverBody className="kslplus-header-popover-body">
                                <Container>
                                    <div>
                                        {"Zusätzliche Jahre " + (this.state.alleJahre ? "verbergen" : "anzeigen")}
                                    </div>
                                </Container>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div >);

                },
                text: this.state.alleJahre ? "-" : "+",
                headerStyle: (colum: any, colIndex: any) => {
                    return { fontWeight: 900, width: '30px', textAlign: 'left' };
                },

                style: (colum: any, colIndex: any) => {
                    return { width: '30px' };
                },
                footer: -1,
                headerEvents: {
                    onClick: (e: any, column: any, columnIndex: any) => { this.setState({ alleJahre: !this.state.alleJahre }) }
                }
            });
        }

        var id = 3;
        if (ausgaben) {
            var fieldPrev: string | undefined;
            var sumPrev = 0;


            if (jahre.findIndex(d => d == this.props.jahr - 4) >= 0 && totalSum.ergebnisPJM4_haben != null && totalSum.ergebnisPJM4_soll != null /*&& totalSum.ergebnisPJM4_soll != 0*/ && data.ergebnisPJM4_haben != null && data.ergebnisPJM4_soll != null /*&& data.ergebnisPJM4_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM4_haben != null && totalHaben.ergebnisPJM4_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_haben != undefined ? totalSub.ergebnisPJM4_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_soll != undefined ? totalSub.ergebnisPJM4_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM4_haben != undefined ? totalHabenSub.ergebnisPJM4_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM4_soll", this.props.jahr - 4, totalSum.ergebnisPJM4_soll - subsoll, showEA ? totalHaben.ergebnisPJM4_haben - totalSum.ergebnisPJM4_soll - subErgebnis + subsoll : undefined, id, showFooter, "Ergebnis", !this.state.alleJahre));

                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4_soll", sumPrev, totalSum.ergebnisPJM4_soll - subsoll, true, id, showFooter));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM4_soll";
                //sumPrev = totalSum.ergebnisPJM4_soll - subsoll;                
            }
            else {
                fieldPrev = undefined;
            }


            if (jahre.findIndex(d => d == this.props.jahr - 3) >= 0 && totalSum.ergebnisPJM3_haben != null && totalSum.ergebnisPJM3_soll != null /*&& totalSum.ergebnisPJM3_soll != 0*/ && data.ergebnisPJM3_haben != null && data.ergebnisPJM3_soll != null /*&& data.ergebnisPJM3_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM3_haben != null && totalHaben.ergebnisPJM3_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_haben != undefined ? totalSub.ergebnisPJM3_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_soll != undefined ? totalSub.ergebnisPJM3_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM3_haben != undefined ? totalHabenSub.ergebnisPJM3_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM3_soll", this.props.jahr - 3, totalSum.ergebnisPJM3_soll - subsoll, showEA ? totalHaben.ergebnisPJM3_haben - totalSum.ergebnisPJM3_soll - subErgebnis + subsoll : undefined, id, showFooter, "Ergebnis", !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3_soll", sumPrev, totalSum.ergebnisPJM3_soll - subsoll, true, id, showFooter));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM3_soll";
                //sumPrev = totalSum.ergebnisPJM3_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisPJM2_haben != null && totalSum.ergebnisPJM2_soll != null /*&& totalSum.ergebnisPJM2_soll != 0*/ && data.ergebnisPJM2_haben != null && data.ergebnisPJM2_soll != null /*&& data.ergebnisPJM2_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM2_haben != null && totalHaben.ergebnisPJM2_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_haben != undefined ? totalSub.ergebnisPJM2_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_soll != undefined ? totalSub.ergebnisPJM2_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM2_haben != undefined ? totalHabenSub.ergebnisPJM2_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM2_soll", this.props.jahr - 2, totalSum.ergebnisPJM2_soll - subsoll, showEA ? totalHaben.ergebnisPJM2_haben - totalSum.ergebnisPJM2_soll - subErgebnis + subsoll : undefined, id, showFooter, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2_soll", sumPrev, totalSum.ergebnisPJM2_soll - subsoll, true, id, showFooter));
                    id++;
                } 
                */
                fieldPrev = "ergebnisPJM2_soll";
                //sumPrev = totalSum.ergebnisPJM2_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ergebnisPJM1_haben != null && totalSum.ergebnisPJM1_soll != null /*&& totalSum.ergebnisPJM1_soll != 0*/ && data.ergebnisPJM1_haben != null && data.ergebnisPJM1_soll != null /*&& data.ergebnisPJM1_soll != 0*/ && totalHaben != null && totalHaben.ergebnisPJM1_haben != null && totalHaben.ergebnisPJM1_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_haben != undefined ? totalSub.ergebnisPJM1_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_soll != undefined ? totalSub.ergebnisPJM1_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisPJM1_haben != undefined ? totalHabenSub.ergebnisPJM1_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM1_soll", this.props.jahr - 1, totalSum.ergebnisPJM1_soll - subsoll, showEA ? totalHaben.ergebnisPJM1_haben - totalSum.ergebnisPJM1_soll - subErgebnis + subsoll : undefined, id, showFooter, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1_soll", sumPrev, totalSum.ergebnisPJM1_soll - subsoll, true, id, showFooter));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM1_soll";
                //sumPrev = totalSum.ergebnisPJM1_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPJfort_haben != null && totalSum.ansatzPJfort_soll != null /*&& totalSum.ansatzPJfort_soll != 0*/ && data.ansatzPJfort_haben != null && data.ansatzPJfort_soll != null /*&& data.ansatzPJfort_soll != 0*/ && totalHaben != null && totalHaben.ansatzPJfort_haben != null && totalHaben.ansatzPJfort_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_haben != undefined ? totalSub.ansatzPJfort_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_soll != undefined ? totalSub.ansatzPJfort_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ansatzPJfort_haben != undefined ? totalHabenSub.ansatzPJfort_haben : 0);
                columns.push(this.createDataColumnUH("ansatzPJfort_soll", this.props.jahr, totalSum.ansatzPJfort_soll - subsoll, showEA ? totalHaben.ansatzPJfort_haben - totalSum.ansatzPJfort_soll - subErgebnis + subsoll : undefined, id, showFooter, "Gesamtsoll", false, false, false, "", "", false, true));
                id++;

                fieldPrev = "ansatzPJfort_soll";
                sumPrev = totalSum.ansatzPJfort_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.verfuegt_haben != null && totalSum.verfuegt_soll != null /*&& totalSum.verfuegt_soll != 0*/ && data.verfuegt_haben != null && data.verfuegt_soll != null /*&& data.verfuegt_soll != 0*/ && totalHaben != null && totalHaben.verfuegt_haben != null && totalHaben.verfuegt_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.verfuegt_haben != undefined ? totalSub.verfuegt_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.verfuegt_soll != undefined ? totalSub.verfuegt_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.verfuegt_haben != undefined ? totalHabenSub.verfuegt_haben : 0);
                columns.push(this.createDataColumnUH("verfuegt_soll", this.props.jahr, totalSum.verfuegt_soll - subsoll, showEA ? totalHaben.verfuegt_haben - totalSum.verfuegt_soll - subErgebnis + subsoll : undefined, id, showFooter, "Ausgeführt", false, false, false, "", "", false, false, true));

                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "verfuegt_soll", sumPrev, totalSum.verfuegt_soll - subsoll, true, id, showFooter, "fortgeschriebenem Ansatz"));
                    id++;
                }
                */
                fieldPrev = "verfuegt_soll";

                columns.push(this.createDataPercentColumn("verfuegt_soll", "ansatzPJfort_soll", this.props.jahr, id, "Ausgeführt", this.state.alleJahre, false, false, totalSum.verfuegt_soll - subsoll, sumPrev ));
                id++;
                //sumPrev = totalSum.verfuegt_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.verfuegbar_haben != null && totalSum.verfuegbar_soll != null /*&& totalSum.verfuegbar_soll != 0*/ && data.verfuegbar_haben != null && data.verfuegbar_soll != null /*&& data.verfuegbar_soll != 0*/ && totalHaben != null && totalHaben.verfuegbar_haben != null && totalHaben.verfuegbar_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.verfuegbar_haben != undefined ? totalSub.verfuegbar_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.verfuegbar_soll != undefined ? totalSub.verfuegbar_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.verfuegbar_haben != undefined ? totalHabenSub.verfuegbar_haben : 0);
                columns.push(this.createDataColumnUH("verfuegbar_soll", this.props.jahr, totalSum.verfuegbar_soll - subsoll, showEA ? totalHaben.verfuegbar_haben - totalSum.verfuegbar_soll - subErgebnis + subsoll : undefined, id, showFooter, "Verfuegbar", false, true));

                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "verfuegbar_soll", sumPrev, totalSum.verfuegbar_soll - subsoll, true, id, showFooter, "fortgeschriebenem Ansatz"));
                    id++;
                }
                */
                fieldPrev = "verfuegbar_soll";

                columns.push(this.createDataPercentColumn("verfuegbar_soll", "ansatzPJfort_soll", this.props.jahr, id, "Verfuegbar", this.state.alleJahre, false, false, totalSum.verfuegbar_soll - subsoll, sumPrev ));
                id++;
                //sumPrev = totalSum.verfuegbar_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.progAuto_haben != null && totalSum.progAuto_soll != null /*&& totalSum.progAuto_soll != 0*/ && data.progAuto_haben != null && data.progAuto_soll != null /*&& data.progAuto_soll != 0*/ && totalHaben != null && totalHaben.progAuto_haben != null && totalHaben.progAuto_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.progAuto_haben != undefined ? totalSub.progAuto_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.progAuto_soll != undefined ? totalSub.progAuto_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.progAuto_haben != undefined ? totalHabenSub.progAuto_haben : 0);
                columns.push(this.createDataColumnUH("progAuto_soll", this.props.jahr, totalSum.progAuto_soll - subsoll, showEA ? totalHaben.progAuto_haben - totalSum.progAuto_soll - subErgebnis + subsoll : undefined, id, showFooter, "Prognose errechnet", false, false, false, "", "", true));

                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "progAuto_soll", sumPrev, totalSum.progAuto_soll - subsoll, true, id, showFooter, "fortgeschriebenem Ansatz"));
                    id++;
                }
                */
                fieldPrev = "progAuto_soll";

                columns.push(this.createChangeColumn("ansatzPJfort_soll", "progAuto_soll", sumPrev, totalSum.progAuto_soll - subsoll, true, id, true, "Gesamtsoll"));
                id++;
                //sumPrev = totalSum.progAuto_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.progMan_haben != null && totalSum.progMan_soll != null /*&& totalSum.progMan_soll != 0*/ && data.progMan_haben != null && data.progMan_soll != null /*&& data.progMan_soll != 0*/ && totalHaben != null && totalHaben.progMan_haben != null && totalHaben.progMan_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.progMan_haben != undefined ? totalSub.progMan_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.progMan_soll != undefined ? totalSub.progMan_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.progMan_haben != undefined ? totalHabenSub.progMan_haben : 0);
                columns.push(this.createDataColumnUH("progMan_soll", this.props.jahr, totalSum.progMan_soll - subsoll, showEA ? totalHaben.progMan_haben - totalSum.progMan_soll - subErgebnis + subsoll : undefined, id, showFooter, "Prognose manuell", false, false, false, "hasProgMan", "progKomm"));

                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "progMan_soll", sumPrev, totalSum.progMan_soll - subsoll, true, id, showFooter, "fortgeschriebenem Ansatz"));
                    id++;
                }
                */
                fieldPrev = "progMan_soll";

                columns.push(this.createChangeColumn("ansatzPJfort_soll", "progMan_soll", sumPrev, totalSum.progMan_soll - subsoll, true, id, true, "Gesamtsoll"));
                id++;
                //sumPrev = totalSum.progMan_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.istAo_haben != null && totalSum.istAo_soll != null && totalSum.istAo_soll != 0 && data.istAo_haben != null && data.istAo_soll != null && data.istAo_soll != 0 && totalHaben != null && totalHaben.istAo_haben != null && totalHaben.istAo_haben !== undefined) {                        
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.istAo_haben != undefined ? totalSub.istAo_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.istAo_soll != undefined ? totalSub.istAo_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.istAo_haben != undefined ? totalHabenSub.istAo_haben : 0);
                columns.push(this.createDataColumnUH("istAo_soll", this.props.jahr - 1, totalSum.istAo_soll - subsoll, showEA ? totalHaben.istAo_haben - totalSum.istAo_soll - subErgebnis + subsoll : undefined, id, showFooter, "Ist AO"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "istAo_soll", sumPrev, totalSum.istAo_soll - subsoll, true, id, showFooter));
                    id++;
                }
                */
                fieldPrev = "istAo_soll";
                //sumPrev = totalSum.istAo_soll - subsoll;
            }
            else {
                fieldPrev = undefined;
            }

        }
        else {
            var fieldPrev: string | undefined;
            var sumPrev = 0;

            if (jahre.findIndex(d => d == this.props.jahr - 4) >= 0 && totalSum.ergebnisPJM4_soll != null && totalSum.ergebnisPJM4_haben != null && totalSum.ergebnisPJM4_haben != 0) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM4_haben != undefined ? totalSub.ergebnisPJM4_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM4_haben", this.props.jahr - 4, totalSum.ergebnisPJM4_haben - subhaben, undefined, id, showFooter, "Ergebnis", !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM4_haben", sumPrev, totalSum.ergebnisPJM4_haben - subhaben, false, id, showFooter));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM4_haben";
                
                //sumPrev = totalSum.ergebnisPJM4_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 3) >= 0 && totalSum.ergebnisPJM3_soll != null && totalSum.ergebnisPJM3_haben != null /*&& totalSum.ergebnisPJM3_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM3_haben != undefined ? totalSub.ergebnisPJM3_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM3_haben", this.props.jahr - 3, totalSum.ergebnisPJM3_haben - subhaben, undefined, id, showFooter, "Ergebnis", !this.state.alleJahre));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM3_haben", sumPrev, totalSum.ergebnisPJM3_haben - subhaben, false, id, showFooter));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM3_haben";
                //sumPrev = totalSum.ergebnisPJM3_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisPJM2_soll != null && totalSum.ergebnisPJM2_haben != null /*&& totalSum.ergebnisPJM2_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM2_haben != undefined ? totalSub.ergebnisPJM2_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM2_haben", this.props.jahr - 2, totalSum.ergebnisPJM2_haben - subhaben, undefined, id, showFooter, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM2_haben", sumPrev, totalSum.ergebnisPJM2_haben - subhaben, false, id, showFooter));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM2_haben";
                //sumPrev = totalSum.ergebnisPJM2_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ergebnisPJM1_soll != null && totalSum.ergebnisPJM1_haben != null /*&& totalSum.ergebnisPJM1_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisPJM1_haben != undefined ? totalSub.ergebnisPJM1_haben : 0);
                columns.push(this.createDataColumnUH("ergebnisPJM1_haben", this.props.jahr - 1, totalSum.ergebnisPJM1_haben - subhaben, undefined, id, showFooter, "Ergebnis"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisPJM1_haben", sumPrev, totalSum.ergebnisPJM1_haben - subhaben, false, id, showFooter));
                    id++;
                }
                */
                fieldPrev = "ergebnisPJM1_haben";
                //sumPrev = totalSum.ergebnisPJM1_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPJfort_soll != null && totalSum.ansatzPJfort_haben != null /*&& totalSum.ansatzPJfort_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPJfort_haben != undefined ? totalSub.ansatzPJfort_haben : 0);
                columns.push(this.createDataColumnUH("ansatzPJfort_haben", this.props.jahr, totalSum.ansatzPJfort_haben - subhaben, undefined, id, showFooter, "Gesamtsoll", false, false, false, "", "", false, true));
                id++;
                fieldPrev = "ansatzPJfort_haben";
                sumPrev = totalSum.ansatzPJfort_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.verfuegt_soll != null && totalSum.verfuegt_haben != null /*&& totalSum.verfuegt_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.verfuegt_haben != undefined ? totalSub.verfuegt_haben : 0);
                columns.push(this.createDataColumnUH("verfuegt_haben", this.props.jahr, totalSum.verfuegt_haben - subhaben, undefined, id, showFooter, "Eingenommen", false, false, false, "", "", false, false, true));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "verfuegt_haben", sumPrev, totalSum.verfuegt_haben - subhaben, false, id, showFooter, "fortgeschriebenem Ansatz"));
                    id++;
                }
                Ü*/
                fieldPrev = "verfuegt_haben";

                columns.push(this.createDataPercentColumn("verfuegt_haben", "ansatzPJfort_haben", this.props.jahr, id, "Eingenommen", this.state.alleJahre, false, false, totalSum.verfuegt_haben - subhaben, sumPrev ));
                id++;
                //sumPrev = totalSum.verfuegt_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.verfuegbar_soll != null && totalSum.verfuegbar_haben != null /*&& totalSum.verfuegbar_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.verfuegbar_haben != undefined ? totalSub.verfuegbar_haben : 0);
                columns.push(this.createDataColumnUH("verfuegbar_haben", this.props.jahr, totalSum.verfuegbar_haben - subhaben, undefined, id, showFooter, "Einzunehmen", false, false, false));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "verfuegbar_haben", sumPrev, totalSum.verfuegbar_haben - subhaben, false, id, showFooter, "fortgeschriebenem Ansatz"));
                    id++;
                }
                Ü*/
                fieldPrev = "verfuegbar_haben";

                columns.push(this.createDataPercentColumn("verfuegbar_haben", "ansatzPJfort_haben", this.props.jahr, id, "Einzunehmen", this.state.alleJahre, false, false, totalSum.verfuegbar_haben - subhaben, sumPrev ));
                id++;
                //sumPrev = totalSum.verfuegbar_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.progAuto_soll != null && totalSum.progAuto_haben != null /*&& totalSum.progAuto_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.progAuto_haben != undefined ? totalSub.progAuto_haben : 0);
                columns.push(this.createDataColumnUH("progAuto_haben", this.props.jahr, totalSum.progAuto_haben - subhaben, undefined, id, showFooter, "Prognose errechnet", false, false, false, "", "", true));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "progAuto_haben", sumPrev, totalSum.progAuto_haben - subhaben, false, id, showFooter, "fortgeschriebenem Ansatz"));
                    id++;
                }
                Ü*/
                fieldPrev = "progAuto_haben";

                columns.push(this.createChangeColumn("ansatzPJfort_haben", "progAuto_haben", sumPrev, totalSum.progAuto_haben - subhaben, false, id, true, "Gesamtsoll"));

                id++;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.progMan_soll != null && totalSum.progMan_haben != null /*&& totalSum.progMan_haben != 0*/) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.progMan_haben != undefined ? totalSub.progMan_haben : 0);
                columns.push(this.createDataColumnUH("progMan_haben", this.props.jahr, totalSum.progMan_haben - subhaben, undefined, id, showFooter, "Prognose manuell", false, false, false, "hasProgMan", "progKomm"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "progMan_haben", sumPrev, totalSum.progMan_haben - subhaben, false, id, showFooter, "fortgeschriebenem Ansatz"));
                    id++;
                }
                Ü*/
                fieldPrev = "progMan_haben";

                columns.push(this.createChangeColumn("ansatzPJfort_haben", "progMan_haben", sumPrev, totalSum.progMan_haben - subhaben, false, id, true, "Gesamtsoll"));

                id++;
            }
            else {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.istAo_haben != null && totalSum.istAo_haben != 0) {            
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.istAo_haben != undefined ? totalSub.istAo_haben : 0);
                columns.push(this.createDataColumnUH("istAo_haben", this.props.jahr - 1, totalSum.istAo_haben - subhaben, undefined, id, showFooter, "Ist AO"));
                id++;
                /*
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "istAo_haben", sumPrev, totalSum.istAo_haben - subhaben, false, id, showFooter));
                    id++;
                }
                */
                fieldPrev = "istAo_haben";
                //sumPrev = totalSum.istAo_haben - subhaben;
            }
            else {
                fieldPrev = undefined;
            }
        }
        return columns;
    }

    createColumns = (data: KSLPlusAPI.HaushaltsEbene | null, totalSum: KSLPlusAPI.HaushaltsEbene, totalSub: KSLPlusAPI.HaushaltsEbene | null | undefined, totalHaben: KSLPlusAPI.HaushaltsEbene | null, totalHabenSub: KSLPlusAPI.HaushaltsEbene | null, ausgaben: boolean, showFooter : boolean = true, showEA : boolean = true, jahre: number[] = []) => {
        if ( this.props.plantyp == 3 )
            return this.createColumnsJR(data, totalSum, totalSub, totalHaben, totalHabenSub, ausgaben, showFooter, showEA, jahre);
        if (this.props.plantyp == 4)
            return this.createColumnsUH(data, totalSum, totalSub, totalHaben, totalHabenSub, ausgaben, showFooter, showEA, jahre);

        var columns = [];
        
        if ( data && data.unterEbenen && !data.unterEbenen.find(e => e.unterEbenen) ) {        
            columns.push({            
                dataField: 'df1',
                isDummyField: true,
                headerStyle: (colum: any, colIndex: any) => {
                    return { width: '35px', textAlign: 'left' };
                },
                style: (colum: any, colIndex: any) => {
                    return { width: '35px', textAlign: 'left' };
                },
                footer: showFooter ? "1" : undefined,
                
            });
        }

        columns.push({
            dataField: 'bezeichnung',
            text: ausgaben ? "Ausgaben/Bezeichnung" : "Einnahmen" + "/Bezeichnung",
            headerStyle: (colum: any, colIndex: any) => {
                return { width: '250px', textAlign: 'left' };
            },
            formatter: (cellContent: string, row: KSLPlusAPI.HaushaltsEbene) => {
                //var label = "";
                var label: JSX.Element[] = [];
                var tooltip = "";
                var tooltipMn = "";
                if (row.unterEbenen) {
                    //label = row.unterabschnitt !== undefined && row.uaStellen !== undefined ? Utilities.Num2Str(row.unterabschnitt, row.uaStellen) + " - " : "";
                    if (row.unterabschnitt !== undefined && row.uaStellen !== undefined)
                        label.push(<span key={"kslplus-label-" + row.id + "ua"}>{Utilities.Num2Str(row.unterabschnitt, row.uaStellen) + " - "}</span>);
                }
                else {
                    /*
                    if ( row.unterabschnitt !== undefined && row.uaStellen !== undefined )
                        label += Utilities.Num2Str(row.unterabschnitt, row.uaStellen) + "."; 
                    if ( row.massnahme !== null && row.massnahme !== undefined )                    
                        label += Utilities.Num2Str(row.massnahme, 3) + "."; 
                    if ( row.unterkonto !== undefined && row.ugStellen !== undefined )
                        label += Utilities.Num2Str(row.untergruppe, row.ugStellen);
                    if ( row.unterkonto  )
                        label += "." + Utilities.Num2Str(row.unterkonto, 2);

                    label += " - ";
                    
                    tooltip = row.ugText ? row.ugText : "";
                    */

                    if (row.unterabschnitt !== undefined && row.uaStellen)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "ua"}>{Utilities.Num2Str(row.unterabschnitt, row.uaStellen)}</span>);
                    if (row.massnahme !== null && row.massnahme !== undefined) {
                        var mn = this.props.massnahmen.find(m => m.nr == row.massnahme && m.unterabschnitt == row.unterabschnitt && m.untergruppe == row.untergruppe);
                        if (mn && mn.bezeichnung)
                            tooltipMn = mn.bezeichnung;
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-" + row.id + "mn"} key={"kslplus-label-" + row.id + "mn"}>{(label.length > 0 ? "." : "") + Utilities.Num2Str(row.massnahme, 3)}</span>);
                    }
                    if (row.untergruppe !== undefined && row.ugStellen)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" id={"kslplus-label-" + row.id} key={"kslplus-label-" + row.id + "ug"}>{(label.length > 0 ? "." : "") + Utilities.Num2Str(row.untergruppe, row.ugStellen)}</span>);
                    if (row.unterkonto)
                        label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "uk"}>{(label.length > 0 ? "." : "") + Utilities.Num2Str(row.unterkonto, 2)}</span>);

                    label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "sep"}> - </span>);
                    tooltip = row.ugText ? row.ugText : "";
                }
                //label = label + (row.uaText ? row.uaText : cellContent);                
                label.push(<span className="kslplus-tabellen-bezeichnung-part" key={"kslplus-label-" + row.id + "uatext"}>{row.uaText ? row.uaText : cellContent}</span>);
                return (                                                            
                    <div>
                        <div className="kslplus-tabellen-bezeichnung" >{label} {row.isHHS && row.beschreibung != null && row.beschreibung.length > 0 ? <div className="kslplus-info-badge-container"><Badge className="kslplus-info-badge" id={row.id ? "kslplus-info-text-" + row.id : ""}>i</Badge><UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-info-text-" + row.id : ""}>{row.beschreibung}</UncontrolledTooltip></div> : null}</div>{tooltip.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id : ""}>{tooltip}</UncontrolledTooltip> : null}
                        {tooltipMn.length ? <UncontrolledTooltip className="kslplus-tooltip" placement="right" target={row.id ? "kslplus-label-" + row.id + "mn" : ""}>{tooltipMn}</UncontrolledTooltip> : null}                        
                    </div>
                );

            },
            style: (colum: any, colIndex: any) => {
                if ( !showFooter )                
                    return { width: '250px', paddingLeft: "20px" };
                return { width: '250px' };
            },
            footer: showFooter ? "2" : undefined,
            footerFormatter: (column: any, columnIndex: any) => {
                if (ausgaben && showEA)
                    return (<div><div>Ausgaben gesamt</div><div>Ergebnis E-A</div></div>);
                else if (ausgaben)
                    return (<div>Ausgaben gesamt</div>);

                return <div>Einnahmen gesamt</div>;
            }
        });

        if (jahre.length == 0 && data && data.jahre)
            jahre = data.jahre;

        if (data == null)
            return columns;

        var id = 3;
        if (ausgaben) {
            var fieldPrev: string|undefined;
            var sumPrev = 0;
            /*
            if (totalSum.ergebnisVvvJ_haben != null && totalSum.ergebnisVvvJ_soll != null && data.ergebnisVvvJ_haben != null && totalHaben != null && totalHaben.ergebnisVvvJ_haben != null && totalHaben.ergebnisVvvJ_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvvJ_haben != undefined ? totalSub.ergebnisVvvJ_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvvJ_soll != undefined ? totalSub.ergebnisVvvJ_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisVvvJ_haben != undefined ? totalHabenSub.ergebnisVvvJ_haben : 0);
                columns.push(this.createDataColumn("ergebnisVvvJ_soll", this.props.jahr - 3, totalSum.ergebnisVvvJ_soll - subsoll, totalHaben.ergebnisVvvJ_haben - totalSum.ergebnisVvvJ_soll - subErgebnis + subsoll, id));
                id++;
            }*/
            if (jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisVvJ_haben != null && totalSum.ergebnisVvJ_soll != null && data.ergebnisVvJ_haben != null && data.ergebnisVvJ_soll != null && totalHaben != null && totalHaben.ergebnisVvJ_haben != null && totalHaben.ergebnisVvJ_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvJ_haben != undefined ? totalSub.ergebnisVvJ_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvJ_soll != undefined ? totalSub.ergebnisVvJ_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ergebnisVvJ_haben != undefined ? totalHabenSub.ergebnisVvJ_haben : 0);
                
                columns.push(this.createDataColumn("ergebnisVvJ_soll", this.props.jahr - 2, totalSum.ergebnisVvJ_soll - subsoll, !showEA ? undefined : totalHaben.ergebnisVvJ_haben - totalSum.ergebnisVvJ_soll - subErgebnis + subsoll, id, showFooter));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvJ_soll", sumPrev, totalSum.ergebnisVvJ_soll - subsoll, true, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisVvJ_soll";
                sumPrev = totalSum.ergebnisVvJ_soll - subsoll;                
            }
            else
            {
                fieldPrev = undefined;
            }
            

            if (jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ansatzVJ_haben != null && totalSum.ansatzVJ_soll != null && data.ansatzVJ_haben != null && data.ansatzVJ_soll != null && totalHaben != null && totalHaben.ansatzVJ_haben != null && totalHaben.ansatzVJ_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzVJ_haben != undefined ? totalSub.ansatzVJ_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ansatzVJ_soll != undefined ? totalSub.ansatzVJ_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ansatzVJ_haben != undefined ? totalHabenSub.ansatzVJ_haben : 0);                
                columns.push(this.createDataColumn("ansatzVJ_soll", this.props.jahr - 1, totalSum.ansatzVJ_soll - subsoll, !showEA ? undefined : totalHaben.ansatzVJ_haben - totalSum.ansatzVJ_soll - subErgebnis + subsoll, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzVJ_soll", sumPrev, totalSum.ansatzVJ_soll - subsoll, true, id, showFooter));
                    id++;
                }                 
                fieldPrev = "ansatzVJ_soll";
                sumPrev = totalSum.ansatzVJ_soll - subsoll;               
            }
            else
            {
                fieldPrev = undefined;
            }    

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPj_haben != null && totalSum.ansatzPj_soll != null && data.ansatzPj_haben != null && data.ansatzPj_soll != null && totalHaben != null && totalHaben.ansatzPj_haben != null && totalHaben.ansatzPj_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPj_haben != undefined ? totalSub.ansatzPj_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.ansatzPj_soll != undefined ? totalSub.ansatzPj_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.ansatzPj_haben != undefined ? totalHabenSub.ansatzPj_haben : 0);                
                columns.push(this.createDataColumn("ansatzPj_soll", this.props.jahr, totalSum.ansatzPj_soll - subsoll, !showEA ? undefined : totalHaben.ansatzPj_haben - totalSum.ansatzPj_soll - subErgebnis + subsoll, id, showFooter));                
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzPj_soll", sumPrev, totalSum.ansatzPj_soll - subsoll, true, id, showFooter, "Vorjahr", true));
                    id++;
                }                
                fieldPrev = "ansatzPj_soll";
                sumPrev = totalSum.ansatzPj_soll - subsoll;               
            }
            else
            {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr + 1) >= 0 && totalSum.fp1_haben != null && totalSum.fp1_soll != null && data.fp1_haben != null && data.fp1_soll != null && totalHaben != null && totalHaben.fp1_haben != null && totalHaben.fp1_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp1_haben != undefined ? totalSub.fp1_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.fp1_soll != undefined ? totalSub.fp1_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.fp1_haben != undefined ? totalHabenSub.fp1_haben : 0);                
                columns.push(this.createDataColumn("fp1_soll", this.props.jahr + 1, totalSum.fp1_soll - subsoll, !showEA ? undefined : totalHaben.fp1_haben - totalSum.fp1_soll - subErgebnis + subsoll, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "fp1_soll", sumPrev, totalSum.fp1_soll - subsoll, true, id, showFooter, "Vorjahr", this.props.settings.doppelhaushalt ? true : false));
                    id++;
                }                
                fieldPrev = "fp1_soll";
                sumPrev = totalSum.fp1_soll - subsoll;               
            }
            else
            {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr + 2) >= 0 && totalSum.fp2_haben != null && totalSum.fp2_soll != null && data.fp2_haben != null && data.fp2_soll != null && totalHaben != null && totalHaben.fp2_haben != null && totalHaben.fp2_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp2_haben != undefined ? totalSub.fp2_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.fp2_soll != undefined ? totalSub.fp2_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.fp2_haben != undefined ? totalHabenSub.fp2_haben : 0);                
                columns.push(this.createDataColumn("fp2_soll", this.props.jahr + 2, totalSum.fp2_soll - subsoll, !showEA ? undefined : totalHaben.fp2_haben - totalSum.fp2_soll - subErgebnis + subsoll, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "fp2_soll", sumPrev, totalSum.fp2_soll - subsoll, true, id, showFooter));
                    id++;
                }                
                fieldPrev = "fp2_soll";
                sumPrev = totalSum.fp2_soll - subsoll;               
            }
            else
            {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr + 3) >= 0 && totalSum.fp3_haben != null && totalSum.fp3_soll != null && data.fp3_haben != null && data.fp3_soll != null && totalHaben != null && totalHaben.fp3_haben != null && totalHaben.fp3_haben !== undefined) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp3_haben != undefined ? totalSub.fp3_haben : 0);
                var subsoll = (totalSub != null && totalSub !== undefined && totalSub.fp3_soll != undefined ? totalSub.fp3_soll : 0);
                var subErgebnis = (totalHabenSub != null && totalHabenSub !== undefined && totalHabenSub.fp3_haben != undefined ? totalHabenSub.fp3_haben : 0);                
                columns.push(this.createDataColumn("fp3_soll", this.props.jahr + 3, totalSum.fp3_soll - subsoll, !showEA ? undefined : totalHaben.fp3_haben - totalSum.fp3_soll - subErgebnis + subsoll, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "fp3_soll", sumPrev, totalSum.fp3_soll - subsoll, true, id, showFooter));
                    id++;
                }                
                fieldPrev = "fp3_soll";
                sumPrev = totalSum.fp3_soll - subsoll;               
            }
            else
            {
                fieldPrev = undefined;
            }


        }
        else {
            var fieldPrev: string|undefined;
            var sumPrev = 0;

            /*
            if (totalSum.ergebnisVvvJ_haben != null ) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvvJ_haben != undefined ? totalSub.ergebnisVvvJ_haben : 0);
                columns.push(this.createDataColumn("ergebnisVvvJ_haben", this.props.jahr - 3, totalSum.ergebnisVvvJ_haben - subhaben, undefined, id));
                id++;
            }*/
            if (jahre.findIndex(d => d == this.props.jahr - 2) >= 0 && totalSum.ergebnisVvJ_haben != null ) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ergebnisVvJ_haben != undefined ? totalSub.ergebnisVvJ_haben : 0);                
                columns.push(this.createDataColumn("ergebnisVvJ_haben", this.props.jahr - 2, totalSum.ergebnisVvJ_haben - subhaben, undefined, id, showFooter));
                id++;
                if (fieldPrev && id > 3) {
                    columns.push(this.createChangeColumn(fieldPrev, "ergebnisVvJ_haben", sumPrev, totalSum.ergebnisVvJ_haben - subhaben, false, id, showFooter));
                    id++;
                }
                fieldPrev = "ergebnisVvJ_haben";
                sumPrev = totalSum.ergebnisVvJ_haben - subhaben;               
            }
            else
            {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr - 1) >= 0 && totalSum.ansatzVJ_haben != null ) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzVJ_haben != undefined ? totalSub.ansatzVJ_haben : 0);                
                columns.push(this.createDataColumn("ansatzVJ_haben", this.props.jahr - 1, totalSum.ansatzVJ_haben - subhaben, undefined, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzVJ_haben", sumPrev, totalSum.ansatzVJ_haben - subhaben, false, id, showFooter));                    
                    id++;
                }                
                fieldPrev = "ansatzVJ_haben";
                sumPrev = totalSum.ansatzVJ_haben - subhaben;               
            }
            else
            {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr) >= 0 && totalSum.ansatzPj_haben != null ) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.ansatzPj_haben != undefined ? totalSub.ansatzPj_haben : 0);                
                columns.push(this.createDataColumn("ansatzPj_haben", this.props.jahr, totalSum.ansatzPj_haben - subhaben, undefined, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "ansatzPj_haben", sumPrev, totalSum.ansatzPj_haben - subhaben, false, id, showFooter, "Vorjahr", true ));                    
                    id++;
                }                
                fieldPrev = "ansatzPj_haben";
                sumPrev = totalSum.ansatzPj_haben - subhaben;               
            }
            else
            {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr + 1) >= 0 && totalSum.fp1_haben != null ) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp1_haben != undefined ? totalSub.fp1_haben : 0);                
                columns.push(this.createDataColumn("fp1_haben", this.props.jahr + 1, totalSum.fp1_haben - subhaben, undefined, id, showFooter));                
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "fp1_haben", sumPrev, totalSum.fp1_haben - subhaben, false, id, showFooter, "Vorjahr", this.props.settings.doppelhaushalt ? true : false));                    
                    id++;
                }                
                fieldPrev = "fp1_haben";
                sumPrev = totalSum.fp1_haben - subhaben;               
            }
            else
            {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr + 2) >= 0 && totalSum.fp2_haben != null ) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp2_haben != undefined ? totalSub.fp2_haben : 0);                
                columns.push(this.createDataColumn("fp2_haben", this.props.jahr + 2, totalSum.fp2_haben - subhaben, undefined, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "fp2_haben", sumPrev, totalSum.fp2_haben - subhaben, false, id, showFooter));                    
                    id++;
                }                
                fieldPrev = "fp2_haben";
                sumPrev = totalSum.fp2_haben - subhaben;               
            }
            else
            {
                fieldPrev = undefined;
            }

            if (jahre.findIndex(d => d == this.props.jahr + 3) >= 0 && totalSum.fp3_haben != null ) {
                var subhaben = (totalSub != null && totalSub !== undefined && totalSub.fp3_haben != undefined ? totalSub.fp3_haben : 0);                
                columns.push(this.createDataColumn("fp3_haben", this.props.jahr + 3, totalSum.fp3_haben - subhaben, undefined, id, showFooter));
                id++;
                if (fieldPrev && id > 3)
                {
                    columns.push(this.createChangeColumn(fieldPrev, "fp3_haben", sumPrev, totalSum.fp3_haben - subhaben, false, id, showFooter));                    
                    id++;
                }                
                fieldPrev = "fp3_haben";
                sumPrev = totalSum.fp3_haben - subhaben;               
            }
            else
            {
                fieldPrev = undefined;
            }


        }
        return columns;
    }

    createHiddenRowKeys = (ebenen: KSLPlusAPI.HaushaltsEbene[], ausgaben: boolean ) => {
        var hidden : string[] = [];        
        for ( var i = 0; i < ebenen.length; i++ )
        {
            const ebene = ebenen[i];
            if ( ebene.untergruppe === undefined )
                continue;


            if ( ausgaben && !ebene.containsAusgaben )
            {
                hidden.push(ebene.id?ebene.id:"");
            }        
            else if ( !ausgaben && !ebene.containsEinnahmen )
            {   
                hidden.push(ebene.id ? ebene.id : "");
            }            
        }
        
        return hidden;
    }

    handleSelectHHEbene = (ebene: KSLPlusAPI.HaushaltsEbene) => {        
        this.setState({ ebene: undefined });
        if (!ebene.filter && !ebene.isHHS )
            this.readFiltered(ebene);
        else
            setTimeout(() => {this.setState({ ebene: ebene })}, 10);
    }

    render() {
        if (!this.props.isVisible)
            return null;

        const einnahmen = this.state.ebene !== undefined && this.state.ebene.filter && this.state.ebene.filter.containsEinnahmen;
        const ausgaben = this.state.ebene !== undefined && this.state.ebene.filter && this.state.ebene.filter.containsAusgaben;
        var graphData: (string | number)[][] = [];
        var colors = ['#264d99', '#dc3912', '#ADD8E6', '#FF7F7F'];
        var barColors = ['#264d99', '#264d99', '#264d99', '#dc3912' ,'#dc3912', '#dc3912'];
        var series = { 0: { targetAxisIndex: 0 }, 1: { targetAxisIndex: 0 }, 2: { type: 'line', targetAxisIndex: 1 }, 3: { type: 'line', targetAxisIndex: 1 } }
        var series1 = { 0: { targetAxisIndex: 0 }, 1: { type: 'line', targetAxisIndex: 1 } };

        var max: any[] = [-Number.MAX_VALUE, -Number.MAX_VALUE];
        var min: any[] = [Number.MAX_VALUE, Number.MAX_VALUE];
        
        var graphOpt;

        if ( this.state.ebene && (!this.state.ebene.unterEbenen || (this.state.ebene.filter && this.state.ebene.filter.unterEbenen))) {
            if (einnahmen && ausgaben) {
                graphData = this.props.plantyp == 3 ? Utilities.createGraphDataJR(this.state.ebene.filter, this.state.ebene.filter, null, null, this.props.jahr, min, max) : (this.props.plantyp == 4 ? Utilities.createGraphDataUH(this.state.ebene.filter, this.state.ebene.filter, null, null, this.props.jahr, min, max) : Utilities.createGraphData(this.state.ebene.filter, this.state.ebene.filter, null, null, this.props.jahr, min, max));
                //graphData = Utilities.createGraphData(this.state.ebene.filter, this.state.ebene.filter, null, null, this.props.jahr)                
            }
            else if (einnahmen) {                
                graphData = this.props.plantyp == 3 ? Utilities.createGraphDataEinnahmenJR(this.state.ebene.filter, null, this.props.jahr, min, max) : (this.props.plantyp == 4 ? Utilities.createGraphDataEinnahmenUH(this.state.ebene.filter, null, this.props.jahr, min, max) : Utilities.createGraphDataEinnahmen(this.state.ebene.filter, null, this.props.jahr, min, max));
                colors = ['#264d99', '#ADD8E6'];                
            }
            else {
                graphData = this.props.plantyp == 3 ? Utilities.createGraphDataAusgabenJR(this.state.ebene.filter, null, this.props.jahr, min, max) : (this.props.plantyp == 4 ? Utilities.createGraphDataAusgabenUH(this.state.ebene.filter, null, this.props.jahr, min, max) : Utilities.createGraphDataAusgaben(this.state.ebene.filter, null, this.props.jahr, min, max));                
                colors = ['#dc3912', '#FF7F7F'];
            }
        }
        graphOpt = Utilities.calcGrapOptions(min, max, this.props.settings.nullAchseAnzeigen);
        
   
        var num = "";
        
        if ( this.state.ebene && this.state.ebene.id != this.props.hh.id && this.state.ebene.untergruppe !== undefined && this.state.ebene.ugStellen !== undefined)
            num = Utilities.Num2Str(this.state.ebene.untergruppe, this.state.ebene.ugStellen);       

        var options = {
            backgroundColor: 'none',
            is3D: this.props.settings.tD3d,
            pieSliceText: this.props.settings.tdText,
            legend: { position: this.props.settings.tdLegend, labeledValueText: this.props.settings.tdText == "value" ? "percentage" : "value" },
            pieSliceTextStyle: { fontSize: this.props.settings.tdFontSize != -1 ? this.props.settings.tdFontSize : undefined },
            sliceVisibilityThreshold: 0.00001                
        };

        return (                             
                 <div>  
                    <HaushaltsNavTree hh={this.props.hh} onSelChange={this.handleSelectHHEbene} useUAText={false} showNumber={true} selected={num} typ={EbenenTyp.Gruppierung} auswahlText="Auswahl nach HG/GR/UG od. Suche per Eingabe"/>
                    <div className="kslplus-hhcomp-content">  
                        <div className="kslplus-content">                        
                            {                            
                                this.state.ebene && (!this.state.ebene.unterEbenen || (this.state.ebene.filter && this.state.ebene.filter.unterEbenen)) ?
                                <div>
                                    {this.state.alleJahre ?
                                        <Row>
                                            <div></div>
                                            <Col className={"ksl-plus-table" + (this.props.plantyp === 4 ? "-ubw" : "")}>
                                                {
                                                    einnahmen && this.state.ebene.filter && this.state.ebene.filter.unterEbenen &&
                                                    <BootstrapTable ref={(n: any) => this.node = n} hover={true} bordered={true} keyField='id' data={this.state.ebene.filter.unterEbenen} columns={this.createColumns(this.state.ebene.filter, this.state.ebene.filter, null, null, null, false, true, false)} hiddenRows={this.createHiddenRowKeys(this.state.ebene.filter.unterEbenen, false)} expandRow={this.expandRowEinnahmen} rowClasses="kslplus-row-sum-0" />
                                                }
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col className={"ksl-plus-table" + (this.props.plantyp === 4 ? "-ubw" : "")}>
                                                {
                                                    einnahmen && this.state.ebene.filter && this.state.ebene.filter.unterEbenen &&
                                                    <BootstrapTable ref={(n: any) => this.node = n} hover={true} bordered={true} keyField='id' data={this.state.ebene.filter.unterEbenen} columns={this.createColumns(this.state.ebene.filter, this.state.ebene.filter, null, null, null, false, true, false)} hiddenRows={this.createHiddenRowKeys(this.state.ebene.filter.unterEbenen, false)} expandRow={this.expandRowEinnahmen} rowClasses="kslplus-row-sum-0" />
                                                }
                                            </Col>
                                        </Row>
                                    }
                                    {this.state.alleJahre ?
                                        <Row>
                                            <div></div>
                                            <Col className={"ksl-plus-table" + (this.props.plantyp === 4 ? "-ubw" : "")}>
                                                {
                                                    ausgaben && this.state.ebene.filter && this.state.ebene.filter.unterEbenen &&
                                                    <BootstrapTable ref={(n: any) => this.node = n} hover={true} bordered={true} keyField='id' data={this.state.ebene.filter.unterEbenen} columns={this.createColumns(this.state.ebene.filter, this.state.ebene.filter, null, this.state.ebene.filter, null, true, true, einnahmen)} hiddenRows={this.createHiddenRowKeys(this.state.ebene.filter.unterEbenen, true)} expandRow={this.expandRowAusgaben} rowClasses="kslplus-row-sum-0" />
                                                }
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col className={"ksl-plus-table" + (this.props.plantyp === 4 ? "-ubw" : "")}>
                                                {
                                                    ausgaben && this.state.ebene.filter && this.state.ebene.filter.unterEbenen &&
                                                    <BootstrapTable ref={(n: any) => this.node = n} hover={true} bordered={true} keyField='id' data={this.state.ebene.filter.unterEbenen} columns={this.createColumns(this.state.ebene.filter, this.state.ebene.filter, null, this.state.ebene.filter, null, true, true, einnahmen)} hiddenRows={this.createHiddenRowKeys(this.state.ebene.filter.unterEbenen, true)} expandRow={this.expandRowAusgaben} rowClasses="kslplus-row-sum-0" />
                                                }
                                            </Col>
                                        </Row>
                                    }
                                        

                                    {this.props.plantyp == 3 ?
                                        <Row>
                                            <Col>                                
                                                <div className="kslplus-headline-third">{((einnahmen && ausgaben) ? "Einnahmen und Ausgaben" : (einnahmen ? "Einnahmen" : "Ausgaben")) + " des " + (this.props.isvmhh ? "VMHH " : "VWHH ") + this.props.jahr.toString() + " - Abweichungen zwischen Ansatz und Ergebnis"}</div>                                                                                
                                            </Col>                                                
                                        </Row>:null
                                    }                         
                                    {this.props.plantyp == 3 ?       
                                        <Row>
                                                <Col>
                                                    <div className="ksl-grafik-container">

                                                        <Chart
                                                            width="100%"
                                                            height={'400px'}
                                                            chartType="BarChart"
                                                            chartLanguage='de'
                                                            loader={<div>Loading Chart</div>}
                                                            data={Utilities.createBarDataAEAJR(this.state.ebene.filter, null, null, barColors, ausgaben , einnahmen)}                                                    
                                                            options={{
                                                          
                                                                  bar: { groupWidth: "50%" },
                                                                  legend: { position: "none"},
                                                                  chartArea: {width: '80%', left: '20%'},
                                                                  hAxis: { gridlines: { count: 10 }}
                                                        
                                                            }}
                                                    
                                                            rootProps={{ 'data-testid': '2' }}
                                                        />
                                                    </div>
                                                </Col>
                                        </Row>
                                        : null
                                    }
                                    {this.props.plantyp == 4 ?
                                        <Row>
                                            <Col>
                                                <div className="kslplus-headline-third">{((einnahmen && ausgaben) ? "Einnahmen und Ausgaben" : (einnahmen ? "Einnahmen" : "Ausgaben")) + " des " + (this.props.isvmhh ? "VMHH " : "VWHH ") + this.props.jahr.toString() + " - Abweichungen zwischen Gesamtsoll und Prognosewert (s. Spalte Prognose manuell)"}</div>
                                            </Col>
                                        </Row> : null
                                    }
                                    {this.props.plantyp == 4 ?
                                        <Row>
                                            <Col>
                                                <div className="ksl-grafik-container">

                                                    <Chart
                                                        width="100%"
                                                        height={'400px'}
                                                        chartType="BarChart"
                                                        chartLanguage='de'
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createBarDataAEAUH(this.state.ebene.filter, null, null, barColors, ausgaben, einnahmen)}
                                                        options={{

                                                            bar: { groupWidth: "50%" },
                                                            legend: { position: "none" },
                                                            chartArea: { width: '80%', left: '20%' },
                                                            hAxis: { gridlines: { count: 10 } }

                                                        }}

                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        : null
                                    }
                                    {this.props.plantyp == 3 && !this.props.isvmhh && einnahmen ?                                        
                                                <Row>    
                                                    <Col >
                                                        <div className="kslplus-headline-third">{"Abweichungen der Einnahmen zwischen Ansatz und Ergebnis nach Einzelplänen"}</div>
                                                        <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                            
                                                            <Chart
                                                                width="100%"
                                                                height={'400px'}
                                                                chartType="BarChart"
                                                                chartLanguage='de'                                                            
                                                                loader={<div>Loading Chart</div>}
                                                                data={Utilities.createAenderungsBarDataJR(this.state.ebene.filter, undefined, false )}
                                                                options={{                                                                                                      
                                                                    legend: { position: "none"},
                                                                    chartArea: {width: '70%', left:'30%' },
                                                                    hAxis: { gridlines: { count: 10 }}
                                                                }}
                                                                formatters={[
                                                                            {
                                                                                type: 'NumberFormat',
                                                                                column: 1,
                                                                                options: {
                                                                                suffix: ' EUR'
                                                                                },
                                                                            }]}
                                                                rootProps={{ 'data-testid': '2' }}
                                                            />
                                                            </div>
                                                    </Col>
                                                </Row>:null
                                            }
                                            {this.props.plantyp == 3 && !this.props.isvmhh && ausgaben ?
                                                <Row>
                                                <Col >
                                                    <div className="kslplus-headline-third">{"Abweichungen der Ausgaben zwischen Ansatz und Ergebnis nach Einzelplänen"}</div>
                                                    <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                            
                                                        <Chart
                                                            width="100%"
                                                            height={'400px'}
                                                            chartType="BarChart"
                                                            chartLanguage='de'                                                            
                                                            loader={<div>Loading Chart</div>}
                                                            data={Utilities.createAenderungsBarDataJR(this.state.ebene.filter, undefined, true )}
                                                            options={{                                                                                                       
                                                                legend: { position: "none"},
                                                                chartArea: {width: '70%', left:'30%' },
                                                                hAxis: { gridlines: { count: 10 }}
                                                            }}
                                                            formatters={[
                                                                        {
                                                                            type: 'NumberFormat',
                                                                            column: 1,
                                                                            options: {
                                                                            suffix: ' EUR'
                                                                            },
                                                                        }]}
                                                            rootProps={{ 'data-testid': '2' }}
                                                        />
                                                        </div>
                                                </Col>
                                                </Row>:null
                                            }
                                    
                                        
                                    {this.props.plantyp == 4 && !this.props.isvmhh && einnahmen ?
                                        <Row>
                                            <Col >
                                                <div className="kslplus-headline-third">{"Abweichungen der Einnahmen zwischen Gesamtsoll und Prognosewert (s. Spalte Prognose manuell) nach Einzelplänen"}</div>
                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">

                                                    <Chart
                                                        width="100%"
                                                        height={'400px'}
                                                        chartType="BarChart"
                                                        chartLanguage='de'
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createAenderungsBarDataUH(this.state.ebene.filter, undefined, false)}
                                                        options={{
                                                            legend: { position: "none" },
                                                            chartArea: { width: '70%', left: '30%' },
                                                            hAxis: { gridlines: { count: 10 } }
                                                        }}
                                                        formatters={[
                                                            {
                                                                type: 'NumberFormat',
                                                                column: 1,
                                                                options: {
                                                                    suffix: ' EUR'
                                                                },
                                                            }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row> : null
                                    }
                                    {this.props.plantyp == 4 && !this.props.isvmhh && ausgaben ?
                                        <Row>
                                            <Col >
                                                <div className="kslplus-headline-third">{"Abweichungen der Ausgaben zwischen Gesamtsoll und Prognosewert (s. Spalte Prognose manuell) nach Einzelplänen"}</div>
                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">

                                                    <Chart
                                                        width="100%"
                                                        height={'400px'}
                                                        chartType="BarChart"
                                                        chartLanguage='de'
                                                        loader={<div>Loading Chart</div>}
                                                        data={Utilities.createAenderungsBarDataUH(this.state.ebene.filter, undefined, true)}
                                                        options={{
                                                            legend: { position: "none" },
                                                            chartArea: { width: '70%', left: '30%' },
                                                            hAxis: { gridlines: { count: 10 } }
                                                        }}
                                                        formatters={[
                                                            {
                                                                type: 'NumberFormat',
                                                                column: 1,
                                                                options: {
                                                                    suffix: ' EUR'
                                                                },
                                                            }]}
                                                        rootProps={{ 'data-testid': '2' }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row> : null
                                    }
                                    
                                    
                                    <Row>
                                        <Col>
                                            <div className="kslplus-headline-third">{this.props.plantyp == 3 ? "Entwicklung der Ergebnisse der " + ((einnahmen && ausgaben) ? "Einnahmen und Ausgaben" : (einnahmen ? "Einnahmen" : "Ausgaben")) + " des " + (this.props.isvmhh ? "VMHH" : "VWHH") : "Entwicklung der " + ((einnahmen && ausgaben) ? "Einnahmen und Ausgaben" : (einnahmen ? "Einnahmen" : "Ausgaben")) + " des " + (this.props.isvmhh ? "VMHH" : "VWHH")}</div>                                                                                                                            
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="ksl-grafik-container">
                                                <Chart
                                                    width="100%"
                                                    height={'300px'}
                                                    chartType="ComboChart"
                                                    chartLanguage='de'
                                                    loader={<div>Loading Chart</div>}
                                                        data={graphData}
                                                    options={{
                                                        colors: colors,
                                                        vAxes: { 0: { title: 'EUR', minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max0, min: graphOpt.min0 } }, 1: { title: '%', minValue: graphOpt.minG, maxValue: graphOpt.maxG, viewWindow: { max: graphOpt.max1, min: graphOpt.min1 } } },
                                                        hAxis: { title: 'Jahr' },
                                                        seriesType: 'bars',
                                                        series: einnahmen && ausgaben ? series : series1,
                                                    }}                                                    
                                                    formatters={Utilities.createFormatters((einnahmen && ausgaben) ? 4 : 2, 2)}
                                                    rootProps={{ 'data-testid': '1' }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    {einnahmen &&
                                    <div>
                                        <Row>
                                            <Col>
                                                    <div className="kslplus-headline-third">{this.props.plantyp == 3 ?"Entwicklung der Ergebnisse der Einnahmen nach Einzelplänen":"Entwicklung der Einnahmen des " + (this.props.isvmhh ? "VMHH" : "VWHH") + " nach Einzelplänen"}</div>                                                                                                        
                                            </Col>
                                        </Row>
                                            {this.props.plantyp == 3 || (this.props.plantyp == 4 && this.state.ebene.filter && this.state.ebene.filter.jahre && this.state.ebene.filter.jahre.length > 3) ?
                                                 <div>
                                                    <Row>
                                                        <Col className="ksl-container-piechart3">
                                                            <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-4).toString()}</div>
                                                            <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                            
                                                                <Chart
                                                                    width="100%"
                                                                    height={'300px'}
                                                                    chartType="PieChart"
                                                                    chartLanguage='de'                                                            
                                                                    loader={<div>Loading Chart</div>}
                                                                    data={Utilities.createPieDataJR(this.state.ebene.filter, -4, [0,1,2], false )}
                                                                    options={options}
                                                                    formatters={[
                                                                                {
                                                                                    type: 'NumberFormat',
                                                                                    column: 1,
                                                                                    options: {
                                                                                        fractionDigits: 0,
                                                                                        suffix: ' TEUR'
                                                                                    },
                                                                                }]}
                                                                    rootProps={{ 'data-testid': '2' }}
                                                                />
                                                                </div>
                                                        </Col>
                                                        <Col className="ksl-container-piechart3">
                                                            <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-3).toString()}</div>
                                                            <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                                <Chart
                                                                    width="100%"
                                                                    height={'300px'}
                                                                    chartType="PieChart"
                                                                    chartLanguage='de'                                                            
                                                                    loader={<div>Loading Chart</div>}
                                                                    data={Utilities.createPieDataJR(this.state.ebene.filter, -3, [0,1,2], false )}
                                                                    options={options}
                                                                    formatters={[
                                                                                {
                                                                                    type: 'NumberFormat',
                                                                                    column: 1,
                                                                                    options: {
                                                                                        fractionDigits: 0,
                                                                                        suffix: ' TEUR'
                                                                                    },
                                                                                }]}
                                                                    rootProps={{ 'data-testid': '2' }}
                                                                />
                                                                </div>
                                                        </Col>
                                                        <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-2).toString()}</div>
                                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                            
                                                                <Chart
                                                                    width="100%"
                                                                    height={'300px'}
                                                                    chartType="PieChart"
                                                                    chartLanguage='de'                                                            
                                                                    loader={<div>Loading Chart</div>}
                                                                    data={Utilities.createPieDataJR(this.state.ebene.filter, -2, [0,1,2], false )}
                                                                    options={options}
                                                                    formatters={[
                                                                                {
                                                                                    type: 'NumberFormat',
                                                                                    column: 1,
                                                                                    options: {
                                                                                        fractionDigits: 0,
                                                                                        suffix: ' TEUR'
                                                                                    },
                                                                                }]}
                                                                    rootProps={{ 'data-testid': '2' }}
                                                                />
                                                                </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>  
                                                        <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-1).toString()}</div>
                                                                <div className="ksl-grafik-container  ksl-grafik-container-piechart">
                                            
                                                                <Chart
                                                                    width="100%"
                                                                    height={'300px'}
                                                                    chartType="PieChart"
                                                                    chartLanguage='de'                                                            
                                                                    loader={<div>Loading Chart</div>}
                                                                    data={Utilities.createPieDataJR(this.state.ebene.filter, -1, [0,1,2], false )}
                                                                    options={options}
                                                                    formatters={[
                                                                                {
                                                                                    type: 'NumberFormat',
                                                                                    column: 1,
                                                                                    options: {
                                                                                        fractionDigits: 0,
                                                                                        suffix: ' TEUR'
                                                                                    },
                                                                                }]}
                                                                    rootProps={{ 'data-testid': '2' }}
                                                                />
                                                                </div>
                                                        </Col>
                                                        <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ergebnis ") +(this.props.jahr).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                                
                                                                <Chart
                                                                    width="100%"
                                                                    height={'300px'}
                                                                    chartType="PieChart"
                                                                    chartLanguage='de'                                                            
                                                                    loader={<div>Loading Chart</div>}
                                                                    data={Utilities.createPieDataJR(this.state.ebene.filter, 0, [0, 1, 2], false, this.props.plantyp === 4 )}
                                                                    options={options}
                                                                    formatters={[
                                                                                {
                                                                                    type: 'NumberFormat',
                                                                                    column: 1,
                                                                                    options: {
                                                                                        fractionDigits: 0,
                                                                                        suffix: ' TEUR'
                                                                                    },
                                                                                }]}
                                                                    rootProps={{ 'data-testid': '2' }}
                                                                />
                                                                </div>
                                                        </Col>
                                    
                                                    </Row>   
                                                </div>
                                                :                                                
                                                <Row>
                                                    <Col className="ksl-container-piechart3">
                                                        <div className="kslplus-headline-third">{"Ergebnis " + (this.props.jahr - 2).toString()}</div>
                                                        <div className="ksl-grafik-container ksl-grafik-container-piechart">

                                                            <Chart
                                                                width="100%"
                                                                height={'300px'}
                                                                chartType="PieChart"
                                                                chartLanguage='de'
                                                                loader={<div>Loading Chart</div>}
                                                                data={Utilities.createPieData(this.state.ebene.filter, -2, [0, 1, 2], false, this.props.plantyp === 4)}
                                                                options={options}
                                                                formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                                rootProps={{ 'data-testid': '2' }}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col className="ksl-container-piechart3">
                                                        <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Ergebnis " : "Ansatz ") + (this.props.jahr - 1).toString()}</div>
                                                        <div className="ksl-grafik-container ksl-grafik-container-piechart">

                                                            <Chart
                                                                width="100%"
                                                                height={'300px'}
                                                                chartType="PieChart"
                                                                chartLanguage='de'
                                                                loader={<div>Loading Chart</div>}
                                                                data={Utilities.createPieData(this.state.ebene.filter, -1, [0, 1, 2], false, this.props.plantyp === 4)}
                                                                options={options}
                                                                formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                                rootProps={{ 'data-testid': '2' }}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col className="ksl-container-piechart3">
                                                        <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ansatz ") + (this.props.jahr).toString()}</div>
                                                        <div className="ksl-grafik-container ksl-grafik-container-piechart">

                                                            <Chart
                                                                width="100%"
                                                                height={'300px'}
                                                                chartType="PieChart"
                                                                chartLanguage='de'
                                                                loader={<div>Loading Chart</div>}
                                                                data={Utilities.createPieData(this.state.ebene.filter, 0, [0, 1, 2], false, this.props.plantyp === 4)}
                                                                options={options}
                                                                formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                                rootProps={{ 'data-testid': '2' }}
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>
                                            }
                                        </div>}
                                        
                                        {ausgaben  &&
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <div className="kslplus-headline-third">{this.props.plantyp == 3 ?"Entwicklung der Ergebnisse der Ausgaben nach Einzelplänen":"Entwicklung der Ausgaben des " + (this.props.isvmhh ? "VMHH" : "VWHH") + " nach Einzelplänen"}</div>                                                                                                        
                                                        </Col>
                                                    </Row>
                                            {this.props.plantyp == 3 || (this.props.plantyp == 4 && this.state.ebene.filter && this.state.ebene.filter.jahre && this.state.ebene.filter.jahre.length > 3) ?
                                                    <div>
                                                        <Row>                                    
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-4).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                                    <Chart
                                                                        width="100%"
                                                                        height={'300px'}
                                                                        chartType="PieChart"
                                                                        chartLanguage='de'                                                            
                                                                        loader={<div>Loading Chart</div>}
                                                                        data={Utilities.createPieDataJR(this.state.ebene.filter, -4, undefined, true )}
                                                                        options={options}
                                                                        formatters={[
                                                                                    {
                                                                                        type: 'NumberFormat',
                                                                                        column: 1,
                                                                                        options: {
                                                                                            fractionDigits: 0,
                                                                                            suffix: ' TEUR'
                                                                                        },
                                                                                    }]}
                                                                        rootProps={{ 'data-testid': '2' }}
                                                                    />
                                                                    </div>
                                                            </Col>
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-3).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                                    <Chart
                                                                        width="100%"
                                                                        height={'300px'}
                                                                        chartType="PieChart"
                                                                        chartLanguage='de'                                                            
                                                                        loader={<div>Loading Chart</div>}
                                                                        data={Utilities.createPieDataJR(this.state.ebene.filter, -3, undefined, true )}
                                                                        options={options}
                                                                        formatters={[
                                                                                    {
                                                                                        type: 'NumberFormat',
                                                                                        column: 1,
                                                                                        options: {
                                                                                            fractionDigits: 0,
                                                                                            suffix: ' TEUR'
                                                                                        },
                                                                                    }]}
                                                                        rootProps={{ 'data-testid': '2' }}
                                                                    />
                                                                    </div>
                                                            </Col>
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-2).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                                    <Chart
                                                                        width="100%"
                                                                        height={'300px'}
                                                                        chartType="PieChart"
                                                                        chartLanguage='de'                                                            
                                                                        loader={<div>Loading Chart</div>}
                                                                        data={Utilities.createPieDataJR(this.state.ebene.filter, -2, undefined, true )}
                                                                        options={options}
                                                                        formatters={[
                                                                                    {
                                                                                        type: 'NumberFormat',
                                                                                        column: 1,
                                                                                        options: {
                                                                                            fractionDigits: 0,
                                                                                            suffix: ' TEUR'
                                                                                        },
                                                                                    }]}
                                                                        rootProps={{ 'data-testid': '2' }}
                                                                    />
                                                                    </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{"Ergebnis " +(this.props.jahr-1).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                                    <Chart
                                                                        width="100%"
                                                                        height={'300px'}
                                                                        chartType="PieChart"
                                                                        chartLanguage='de'                                                            
                                                                        loader={<div>Loading Chart</div>}
                                                                        data={Utilities.createPieDataJR(this.state.ebene.filter, -1, undefined, true )}
                                                                        options={options}
                                                                        formatters={[
                                                                                    {
                                                                                        type: 'NumberFormat',
                                                                                        column: 1,
                                                                                        options: {
                                                                                            fractionDigits: 0,
                                                                                            suffix: ' TEUR'
                                                                                        },
                                                                                    }]}
                                                                        rootProps={{ 'data-testid': '2' }}
                                                                    />
                                                                    </div>
                                                            </Col>
                                                            <Col className="ksl-container-piechart3">
                                                                <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ergebnis ") +(this.props.jahr).toString()}</div>
                                                                <div className="ksl-grafik-container ksl-grafik-container-piechart">
                                            
                                                                    <Chart
                                                                        width="100%"
                                                                        height={'300px'}
                                                                        chartType="PieChart"
                                                                        chartLanguage='de'                                                            
                                                                        loader={<div>Loading Chart</div>}
                                                                        data={Utilities.createPieDataJR(this.state.ebene.filter, 0, undefined, true, this.props.plantyp === 4 )}
                                                                        options={options}
                                                                        formatters={[
                                                                                    {
                                                                                        type: 'NumberFormat',
                                                                                        column: 1,
                                                                                        options: {
                                                                                            fractionDigits: 0,
                                                                                            suffix: ' TEUR'
                                                                                        },
                                                                                    }]}
                                                                        rootProps={{ 'data-testid': '2' }}
                                                                    />
                                                                    </div>
                                                            </Col>
                                                        </Row>
                                                     </div>
                                                :
                                        
                                                <Row>
                                                    <Col className="ksl-container-piechart3">
                                                        <div className="kslplus-headline-third">{"Ergebnis " + (this.props.jahr - 2).toString()}</div>
                                                        <div className="ksl-grafik-container ksl-grafik-container-piechart">

                                                            <Chart
                                                                width="100%"
                                                                height={'300px'}
                                                                chartType="PieChart"
                                                                chartLanguage='de'
                                                                loader={<div>Loading Chart</div>}
                                                                data={Utilities.createPieData(this.state.ebene.filter, -2, undefined, true, this.props.plantyp === 4)}
                                                                options={options}
                                                                formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                                rootProps={{ 'data-testid': '2' }}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col className="ksl-container-piechart3">
                                                        <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Ergebnis " : "Ansatz ") + (this.props.jahr - 1).toString()}</div>
                                                        <div className="ksl-grafik-container ksl-grafik-container-piechart">

                                                            <Chart
                                                                width="100%"
                                                                height={'300px'}
                                                                chartType="PieChart"
                                                                chartLanguage='de'
                                                                loader={<div>Loading Chart</div>}
                                                                data={Utilities.createPieData(this.state.ebene.filter, -1, undefined, true, this.props.plantyp === 4)}
                                                                options={options}
                                                                formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                                rootProps={{ 'data-testid': '2' }}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col className="ksl-container-piechart3">
                                                        <div className="kslplus-headline-third">{(this.props.plantyp === 4 ? "Prognose " : "Ansatz ") + (this.props.jahr).toString()}</div>
                                                        <div className="ksl-grafik-container ksl-grafik-container-piechart">

                                                            <Chart
                                                                width="100%"
                                                                height={'300px'}
                                                                chartType="PieChart"
                                                                chartLanguage='de'
                                                                loader={<div>Loading Chart</div>}
                                                                data={Utilities.createPieData(this.state.ebene.filter, 0, undefined, true, this.props.plantyp === 4)}
                                                                options={options}
                                                                formatters={[
                                                                    {
                                                                        type: 'NumberFormat',
                                                                        column: 1,
                                                                        options: {
                                                                            fractionDigits: 0,
                                                                            suffix: ' TEUR'
                                                                        },
                                                                    }]}
                                                                rootProps={{ 'data-testid': '2' }}
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>
                                            }
                                        </div>
                                    }
                                    
                                </div> :
                                <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>
                             }
                        </div>
                    </div>
                </div>
            
        );
    }
}